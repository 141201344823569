import { RootAppState } from 'store/configureStore'

export const projectOperationSelector =
  (operationName: string) => (state: RootAppState) => {
    return state.project?.currentOperations[operationName]
  }

export const projectOperationIsPendingSelector =
  (operationName: string) => (state: RootAppState) => {
    return (
      projectOperationSelector(operationName)(state)?.requestStatus ===
      'pending'
    )
  }

export const projectOperationProgressSelector =
  (operationName: string) => (state: RootAppState) => {
    return state.project?.currentOperations[operationName]?.progress
  }
