/* eslint-disable @typescript-eslint/no-unused-vars */
import cadex, { ModelData_Vertex } from '@cadexchanger/web-toolkit'

export class SelectedFacePointsCollector extends cadex.ModelPrs_SelectedEntityVisitor {
  public points: cadex.ModelData_Point[]

  visitPolyShapeEntity(
    polyShapeEntity: cadex.ModelPrs_SelectedPolyShapeEntity
  ): void {
    // console.log('polyShapeEntity', polyShapeEntity)
    return
  }
  visitPolyVertexEntity(
    polyVertexEntity: cadex.ModelPrs_SelectedPolyVertexEntity
  ): void {
    // console.log('polyVertexEntity', polyVertexEntity)
    return
  }
  visitShapeEntity(shapeEntity: cadex.ModelPrs_SelectedShapeEntity): void {
    // console.log('shapeEntity', shapeEntity)

    return
  }
}

export class SelectedGeometryPointsCollector extends cadex.ModelPrs_GeometryVisitor {
  public points: cadex.ModelData_Point[] = []

  visitBody(
    theBody: cadex.ModelData_Body,
    theRep: cadex.ModelData_BRepRepresentation
  ): void {
    // console.log('visitBody', theBody, theRep)

    return
  }
  visitBRepRepresentation(theBRep: cadex.ModelData_BRepRepresentation): void {
    // console.log('visitBRepRepresentation', theBRep)

    theBRep.bodyList().then((bodyList) => {
      for (let i = 0; i < bodyList.size(); i++) {
        theBRep.subshapes().then((shapes) => {
          for (const shape of shapes) {
            if (shape.type === cadex.ModelData_ShapeType.Vertex) {
              const vertex: ModelData_Vertex = shape as ModelData_Vertex

              // console.log('will push vertex', vertex)
              this.points.push(vertex.point)
            }
          }
        })
      }
    })

    return
  }
  visitDrawingElement(theElement: cadex.ModelData_DrawingElement): void {
    // console.log('visitDrawingElement', theElement)
    return
  }
  visitMeasurement(theMeasurement: cadex.ModelPrs_Measurement): void {
    // console.log('visitMeasurement', theMeasurement)
    return
  }
  visitPMIGraphicalElement(
    theElement: cadex.ModelData_PMIGraphicalElement
  ): void {
    // console.log('visitPMIGraphicalElement', theElement)
    return
  }
  visitPolyRepresentation(theRep: cadex.ModelData_PolyRepresentation): void {
    // console.log('visitPolyRepresentation', theRep)
    return
  }
  visitPolyVertexSet(thePVS: cadex.ModelData_PolyVertexSet): void {
    // console.log('visitPolyVertexSet', thePVS)
    return
  }
}
