import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemController } from 'controllers/Project/BomItemController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { PartTypeRow } from 'model/Project/BoMItemRow'
import { ShowException } from 'store/Application/appActions'
import { newProjectActions } from '../projectReducer'
import { bomItemSelector } from '../selectors/bomItemSelector'
import { RootAppState } from 'store/configureStore'

export const toggleManufacturabilityFlag = createAsyncThunk<
  void,
  { bomItemPointer: BomItemPointer },
  { state: RootAppState }
>('boM/toggleManufacturabilityFlag', async ({ bomItemPointer }, thunkAPI) => {
  const bomItemController = new BomItemController()

  const bomItem = bomItemSelector(
    thunkAPI.getState(),
    bomItemPointer
  ) as PartTypeRow

  try {
    thunkAPI.dispatch(
      newProjectActions.setBomItemProperties({
        bomItemPointer,
        properties: {
          isManufacturable: !bomItem.isManufacturable,
        },
      })
    )

    thunkAPI.dispatch(newProjectActions.updateHeadersFilteredParts())

    return await bomItemController.SwitchManufacturabilityFlag(bomItemPointer)
  } catch (err) {
    ShowException('project', err)
    throw err
  }
})
