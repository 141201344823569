import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const canEditRowSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem) return false

    switch (bomItem?.type) {
      case BomItemType.assemblyType:
        return bomItem.canBeEdit
      case BomItemType.assemblyInstance:
      case BomItemType.partType:
      case BomItemType.partInstance:
        return bomItem.canBeEdit
      default:
        return true
    }
  }
