import { IconButton, IconButtonProps, SxProps, Theme } from '@mui/material'

export const ToolButton = (props: IconButtonProps & { active?: boolean }) => {
  const { active, sx, ...rest } = props

  const sxProps: SxProps<Theme> = {
    ...sx,
    flexDirection: 'column',
    alignItems: 'center',
    gap: (theme) => theme.spacing(0.5),
    borderRadius: (theme) => theme.shape.borderRadius,
    backgroundColor: (theme) =>
      active ? theme.palette.primary.main : 'transparent',
    '&:hover': {
      backgroundColor: (theme) =>
        active ? theme.palette.primary.dark : theme.palette.action.hover,
    },
    color: (theme) =>
      active
        ? theme.palette.primary.contrastText
        : theme.palette.text.secondary,
  }

  return (
    <IconButton
      {...rest}
      sx={sxProps}
      onClick={(e) => {
        e.stopPropagation()
        props.onClick?.(e)
      }}
    >
      {props.children}
    </IconButton>
  )
}
