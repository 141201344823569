import {
  AssemblyHeaderRow,
  BomItemType,
  PartTypeRow,
} from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

/**
 * Get a list of working step types that are not filtered in the BOM
 * Will be influenced by article filter
 * @param state app state
 * @returns Array of unique working steps that are not filtered in the BOM
 */
export const VisibleWorkingStepsSelector = (state: RootAppState) => {
  if (state.project.filters?.byArticleId.active) {
    const visibleWorkingSteps = new Set(
      state.project.assemblyHeadersIds
        .map(
          (x) =>
            bomItemSelector(state, {
              id: x,
              type: BomItemType.assemblyType,
            }) as AssemblyHeaderRow
        )
        .flatMap((x) => x.filteredPartTypePointers)
        .map((x) => bomItemSelector(state, x) as PartTypeRow)
        .flatMap((x) => x.activities?.map((x) => x.primaryWorkingStep))
    )

    return Array.from(visibleWorkingSteps)
  }

  return Array.from(
    new Set(
      Object.values(state.project?.priceSummaries)
        .filter(
          (x) =>
            x?.isWorkingStep &&
            x.workingStep.primaryWorkingStep ===
              x.workingStep.secondaryWorkingStep
        )
        .map((x) => x.workingStep.primaryWorkingStep)
    )
  )
}
