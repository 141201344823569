import { DeprecatedThemeOptions, ThemeOptions } from '@mui/material'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type TenantConfigurationState = {
  partyId: string
  parentTitle?: string
  parentHref?: string
  theme?: Partial<DeprecatedThemeOptions>
  themeV5?: Partial<ThemeOptions>
}

const tenantSlice = createSlice({
  name: 'tenantConfiguration',
  initialState: {
    partyId: '',
    parentTitle: '',
    parentHref: '',
    theme: {},
    themeV5: {},
  } as TenantConfigurationState,
  reducers: {
    setTenantConfiguration: (
      state,
      action: PayloadAction<TenantConfigurationState>
    ) => {
      state.parentHref = action.payload?.parentHref
      state.parentTitle = action.payload?.parentTitle
      state.partyId = action.payload?.partyId
      state.theme = action.payload?.theme as unknown
      state.themeV5 = action.payload?.themeV5 as unknown
    },
  },
})

export default tenantSlice.reducer
export const tenantActions = tenantSlice.actions
