import { PartInstanceRow, PartTypeRow } from 'model/Project/BoMItemRow'
import { TodosFilter } from 'store/Project/ProjectTypes'

export function showPartBasedOnTodos(
  row: PartTypeRow | PartInstanceRow,
  filter: TodosFilter
): boolean {
  if ((filter.enabledFilters?.length || 0) === 0) {
    return (row.issues?.length || 0) === 0
  }

  const rowIssueCodes = row.issues?.map((x) => x.issueCode) || []

  return filter.enabledFilters?.some((issueCode) =>
    rowIssueCodes.includes(issueCode)
  )
}
