import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from '../store/selectors/bomItemSelector'

export function BomItemTodosSelector(bomItemPointer: BomItemPointer) {
  return function (state: RootAppState) {
    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItem.type) {
      case BomItemType.partType:
      case BomItemType.partInstance: {
        return bomItem.issues
      }
      default:
        return null
    }
  }
}
