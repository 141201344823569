// import { RootAppState } from 'store/configureStore'
// import { projectActions } from 'store/Project/ProjectActions'
import { ModalTypeProps, ModalTypes, UIActionNames } from './UITypes'

export const UIActions = {
  // ChangeProjectView:
  //   (view: ProjectViews) => (dispatch /*getState: () => RootAppState*/) => {
  //     // const currentView = getState().ui.CurrentView
  //     dispatch({
  //       type: UIActionNames.ChangeProjectView,
  //       view,
  //     })

  //     // setTimeout(() =>
  //     //   dispatch(projectActions.CollapseOrExpandAllHeaders(false, currentView))
  //     //   , 0)
  //   },
  // UploadProgress: (operation: string, progress: ProgressEvent) => {
  //   return {
  //     type: UIActionNames.UploadProgress,
  //     operation,
  //     progress: (progress.loaded / progress.total) * 100,
  //   }
  // },
  // UploadFinished: (operation: string) => ({
  //   type: UIActionNames.DeleteProgress,
  //   operation,
  // }),
  // CollapseAllHeaders: () => ({
  //   type: UIActionNames.CollapseAllHeaders,
  // }),
  // ExpandAllHeaders: () => ({
  //   type: UIActionNames.ExpandAllHeaders,
  // }),
  OpenModal: (modalType: ModalTypes, modalProps: ModalTypeProps) => ({
    type: UIActionNames.OpenModal,
    modalType,
    modalProps,
  }),
  CloseModal: () => ({ type: UIActionNames.CloseModal }),
}
