import cadex, { ModelData_ModelLoadResult } from '@cadexchanger/web-toolkit'
import { debounce } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { SceneDataStore } from './store/SceneDataStore'
import { ModelLoaderVisitor } from './visitors/ModelLoaderVisitor'

export class ModelEnvironment {
  private htmlElement: HTMLElement
  private viewPort: cadex.ModelPrs_ViewPort
  private scene: cadex.ModelPrs_Scene
  private model: cadex.ModelData_Model
  private store: SceneDataStore
  private resizeObserver: ResizeObserver
  private bomItemPointer: BomItemPointer

  constructor(
    htmlElement: HTMLElement,
    store: SceneDataStore,
    bomItemPointer: BomItemPointer
  ) {
    this.store = store
    this.htmlElement = htmlElement
    this.bomItemPointer = bomItemPointer
  }

  public dispose() {
    if (this.viewPort) {
      this.viewPort.detachFromScene()
      this.viewPort = undefined
    }
    if (this.scene) {
      this.scene.clear()
    }

    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }

    this.htmlElement.innerHTML = ''
  }

  public get HtmlElement(): HTMLElement {
    return this.htmlElement
  }

  public get ViewPort(): cadex.ModelPrs_ViewPort {
    return this.viewPort
  }

  public get Scene(): cadex.ModelPrs_Scene {
    return this.scene
  }

  private createViewPort() {
    const viewPort = new cadex.ModelPrs_ViewPort(
      {
        showLogo: false,
        showViewCube: true,
        addDefaultInputHandlers: true,
        addDefaultKeyboardHandlers: true,
        addDefaultMouseHandlers: true,
        addDefaultLights: true,
        autoResize: false,
      },
      this.htmlElement
    )

    viewPort.inputManager.isHoverEnabled = true
    viewPort.inputManager.pushInputHandler(
      new cadex.ModelPrs_HighlightingHandler()
    )

    this.viewPort = viewPort

    return this
  }

  private createScene = () => {
    if (this.scene) {
      this.scene.clear()
    }

    const scene = new cadex.ModelPrs_Scene()

    this.scene = scene

    return scene
  }

  private enableZoomToPoint() {
    for (const handler of this.viewPort.inputManager.inputHandlers()) {
      if (handler instanceof cadex.ModelPrs_CameraManipulationHandler) {
        const zoomHandler =
          handler.zoomHandler as cadex.ModelPrs_CameraZoomHandler
        zoomHandler.wheelZoomGravityPoint =
          cadex.ModelPrs_CameraZoomHandlerGravityPoint.PointerPosition
      }
    }
  }

  private initResizeObserver() {
    const resizeHandler = debounce(
      () => {
        this.viewPort?.resize(
          this.htmlElement?.clientWidth,
          this.htmlElement?.clientHeight
        )
      },
      150,
      {
        leading: false,
        trailing: true,
      }
    )

    this.resizeObserver = new ResizeObserver(resizeHandler)

    this.resizeObserver.observe(this.htmlElement)
  }

  public init() {
    this.createViewPort()
    this.createScene()
    this.enableZoomToPoint()

    this.initResizeObserver()

    this.viewPort.attachToScene(this.scene)

    return this
  }

  public createModel() {
    if (this.scene) {
      this.viewPort.detachFromScene()
      this.scene.clear()
      this.htmlElement.innerHTML = ''

      this.init()
    }

    this.model = new cadex.ModelData_Model()

    return this.model
  }

  async loadModel(loadResult: ModelData_ModelLoadResult) {
    const repMask = loadResult.hasBRepRep
      ? cadex.ModelData_RepresentationMask.ModelData_RM_BRep
      : cadex.ModelData_RepresentationMask.ModelData_RM_Poly

    const modelVisitor = new ModelLoaderVisitor(
      repMask,
      this.store,
      this.bomItemPointer
    )
    await this.model.accept(modelVisitor)

    const sceneRoot = modelVisitor.getRootNode()

    sceneRoot.selectionMode = cadex.ModelPrs_SelectionMode.Face
    sceneRoot.displayMode = loadResult.hasBRepRep
      ? cadex.ModelPrs_DisplayMode.ShadedWithBoundaries
      : cadex.ModelPrs_DisplayMode.Shaded

    this.scene.addRoot(sceneRoot)

    await this.scene.update()

    if (this.htmlElement.clientWidth && this.htmlElement.clientHeight) {
      this.viewPort.fitAll(16)
    }
  }

  public async accept(visitor: cadex.ModelData_SceneGraphElementVisitor) {
    return await this.model.accept(visitor)
  }

  public onSelectionChangedByTheScene = () =>
    // args: cadex.ModelPrs_SelectionChangedEvent
    {
      return
    }
}
