import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faDraftingCompass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useEffect, useState } from 'react'
import { MeasurementMode } from '../manager/InputHandlers/MeasurementsManager'
import { ModelController } from '../manager/ModelController'
import { CadexMeasurementHelpText } from './LeftButtonIcon'
import { ToolButton } from './ToolButton'

export const AngleMeasurementTool = (props: {
  controller: ModelController
  setHelpComponent: (component: React.ReactElement) => void
}) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    props.controller.onMeasurentModeChanged((mode) => {
      setActive(mode === MeasurementMode.ThreePointAngle)
    })
  }, [props.controller])

  const handleClick = () => {
    if (active) {
      props.controller.resetMeasurement()
      setActive(false)
      props.setHelpComponent(undefined)
    } else {
      props.controller.enableAngleMeasurement()
      setActive(true)
      props.setHelpComponent(
        <CadexMeasurementHelpText
          measurementMode={MeasurementMode.ThreePointAngle}
        />
      )
    }
  }

  return (
    <ToolButton onClick={handleClick} active={active}>
      <FontAwesomeIcon icon={faDraftingCompass as IconProp} />
      <LocalizedTypography
        translationKey="project:viewer-angle"
        variant="body2"
        component="p"
      >
        angle
      </LocalizedTypography>
    </ToolButton>
  )
}
