import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemRowNumberSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem?.type) {
      return null
    }

    switch (bomItem?.type) {
      case BomItemType.partType:
      case BomItemType.partInstance:
        return bomItem.rowNumber
      default:
        return 0
    }
  }
