import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BoMItemActivityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from '../bomItemSelector'
import { bomItemRawMaterialActivitySelector } from './bomItemRawMaterialActivitySelector'

export const bomItemAllActivitiesSelector =
  (bomItemPointer: BomItemPointer) =>
  (state: Partial<RootAppState>): Array<BoMItemActivityDto> => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItem?.type) {
      case BomItemType.assemblyType:
        return bomItem.assemblyActivities || []
      case BomItemType.assemblyInstance:
        return bomItem.assemblyActivities || []
      case BomItemType.partType:
      case BomItemType.partInstance: {
        const rawMaterialActivity =
          bomItemRawMaterialActivitySelector(bomItemPointer)(state)

        return [
          rawMaterialActivity,
          ...(bomItem.activities?.filter((x) => x.usesRawMaterial === false) ||
            []),
        ]
      }
      case BomItemType.project:
        return bomItem.activities
      case BomItemType.materialHeader:
        const firstBomItemPointer = bomItem.filteredPartTypePointers[0]

        const rawMaterialActivity =
          bomItemRawMaterialActivitySelector(firstBomItemPointer)(state)

        return [rawMaterialActivity]
      default:
        return []
    }
  }
