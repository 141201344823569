import {
  IMaterialsAPI,
  MaterialsOperations,
} from '../../../controllers/MaterialsController'
import { OnRequestChangeCallback } from '../BaseAPI'
import {
  AddKeywordsToPartsArgs,
  KeywordGroupDto,
  MaterialsAndStandardsDto,
  MaterialSelectorDto,
  StandardKeywordDto,
} from './internal-api.contracts'
import { InternalAPI } from './InternalAPI'

export class MaterialsAPI extends InternalAPI implements IMaterialsAPI {
  constructor(partyId: string, onRequestChange: OnRequestChangeCallback) {
    super(`/api/parties/${partyId}`, onRequestChange)
  }

  public async GetMaterialsAsync(selectedTokens: string[]) {
    return await this.GetAsync<MaterialSelectorDto>({
      id: MaterialsOperations.GetMaterials,
      relativePath: '/materials',
      params: {
        selectedTokens,
      },
    })
  }

  public async GetStandardsAndMaterials() {
    return await this.GetAsync<MaterialsAndStandardsDto[]>({
      id: MaterialsOperations.GetStandardsAndMaterials,
      relativePath: '/settings/articles/standardsandmaterials',
    })
  }

  public async SaveKeyword(keywords: StandardKeywordDto[]) {
    return await this.PutAsync<MaterialsAndStandardsDto[]>({
      id: MaterialsOperations.SaveKeyword,
      relativePath: '/settings/articles/standardsandmaterials',
      data: keywords,
    })
  }

  public async AddKeywordsToParts(
    projectId: string,
    args: AddKeywordsToPartsArgs
  ) {
    return await this.PostAsync<void>({
      id: 'AddKeywordsToRows',
      relativePath: `/projects/${projectId}/materials/AddKeywordsToParts`,
      data: args,
    })
  }

  public async GetSelectableKeywordsForAssembly(
    projectId: string,
    assemblyId: string,
    selectedKeywords: string[]
  ) {
    return await this.GetAsync<KeywordGroupDto>({
      id: 'GetSelectableKeywordsForAssembly',
      relativePath: `/projects/${projectId}/materials/GetSelectableKeywordsForAssembly`,
      params: {
        assemblyId,
        selectedTokens: selectedKeywords,
      },
    })
  }

  public async GetSelectableKeywordsForProject(
    projectId: string,
    selectedKeywords: string[]
  ) {
    return await this.GetAsync<KeywordGroupDto>({
      id: 'get-project-keywords',
      relativePath: `/projects/${projectId}/materials/GetSelectableKeywordsForProject`,
      params: {
        selectedTokens: selectedKeywords,
      },
    })
  }
}
