import { Action } from 'redux'
import { PurchasingDataDto } from '../../services/APIs/InternalAPI/internal-api.contracts'
import { MaterialSummaryModel } from './SettingsReducer'

export enum SettingsConstants {
  MATERIAL_SETTINGS_LIST_RECEIVED = 'settings/material-settings/list-received',
  MATERIAL_SETTINGS_CLEAR_LIST = 'settings/material-settings/clear-list',
  MATERIAL_SETTINGS_ITEM_CHANGED = 'settings/material-settings/purchasingData-changed',
  MATERIAL_SETTINGS_ITEM_RECEIVED = 'MATERIAL_SETTINGS_ITEM_RECEIVED',
  CAPABILITIES_UPLOAD_PROGRESS = 'settings/capabilities/UPLOAD_PROGRESS',
  MATERIAL_DATA_RECEIVED = 'MATERIAL_DATA_RECEIVED',
}

export interface MaterialSettingsListReceived extends Action {
  type: SettingsConstants.MATERIAL_SETTINGS_LIST_RECEIVED
  materialsSettings: MaterialSummaryModel[]
}

export interface MaterialDataReceived extends Action {
  type: SettingsConstants.MATERIAL_DATA_RECEIVED
  materialData: MaterialSummaryModel
}

export interface MaterialSettingItemReceived extends Action {
  type: SettingsConstants.MATERIAL_SETTINGS_ITEM_RECEIVED
  materialSetting: MaterialSummaryModel
}

export interface ClearMaterialSettingsList extends Action {
  type: SettingsConstants.MATERIAL_SETTINGS_CLEAR_LIST
}

export interface PurchasingDataChanged extends Action {
  type: SettingsConstants.MATERIAL_SETTINGS_ITEM_CHANGED
  materialId: string
  purchasingData: PurchasingDataDto
}

export type SettingsActionTypes =
  | MaterialSettingsListReceived
  | ClearMaterialSettingsList
  | PurchasingDataChanged
  | MaterialSettingItemReceived
  | MaterialDataReceived
