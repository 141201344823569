import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'

export const SellerProjectEditableState = {
  [ProjectStateAsString.DEFINING]: true,
  [ProjectStateAsString.REQUESTED]: true,
  [ProjectStateAsString.QUOTING]: true,
  [ProjectStateAsString.QUOTED]: false,
  [ProjectStateAsString.NEGOTIATING]: true,
  [ProjectStateAsString.ORDERED]: false,

  [ProjectStateAsString.PRODUCING]: false,
  [ProjectStateAsString.PRODUCED]: false,
  [ProjectStateAsString.PACKAGING]: false,
  [ProjectStateAsString.PACKAGED]: false,
  [ProjectStateAsString.DELIVERING]: false,
  [ProjectStateAsString.DELIVERED]: false,

  [ProjectStateAsString.CANCELLED]: false,
}

export const BuyerProjectEditableState = {
  [ProjectStateAsString.DEFINING]: true,
  [ProjectStateAsString.REQUESTED]: false,
  [ProjectStateAsString.QUOTING]: false,
  [ProjectStateAsString.QUOTED]: false,
  [ProjectStateAsString.NEGOTIATING]: false,
  [ProjectStateAsString.ORDERED]: false,

  [ProjectStateAsString.PRODUCING]: false,
  [ProjectStateAsString.PRODUCED]: false,
  [ProjectStateAsString.PACKAGING]: false,
  [ProjectStateAsString.PACKAGED]: false,
  [ProjectStateAsString.DELIVERING]: false,
  [ProjectStateAsString.DELIVERED]: false,

  [ProjectStateAsString.CANCELLED]: false,
}

export const SellerProjectReferenceEditableState = {
  [ProjectStateAsString.DEFINING]: true,
  [ProjectStateAsString.REQUESTED]: true,
  [ProjectStateAsString.QUOTING]: true,
  [ProjectStateAsString.QUOTED]: true,
  [ProjectStateAsString.NEGOTIATING]: true,
  [ProjectStateAsString.ORDERED]: false,

  [ProjectStateAsString.PRODUCING]: false,
  [ProjectStateAsString.PRODUCED]: false,
  [ProjectStateAsString.PACKAGING]: false,
  [ProjectStateAsString.PACKAGED]: false,
  [ProjectStateAsString.DELIVERING]: false,
  [ProjectStateAsString.DELIVERED]: false,

  [ProjectStateAsString.CANCELLED]: false,
}

export const BuyerProjectReferenceEditableState = {
  [ProjectStateAsString.DEFINING]: true,
  [ProjectStateAsString.REQUESTED]: true,
  [ProjectStateAsString.QUOTING]: true,
  [ProjectStateAsString.QUOTED]: true,
  [ProjectStateAsString.NEGOTIATING]: true,
  [ProjectStateAsString.ORDERED]: false,

  [ProjectStateAsString.PRODUCING]: false,
  [ProjectStateAsString.PRODUCED]: false,
  [ProjectStateAsString.PACKAGING]: false,
  [ProjectStateAsString.PACKAGED]: false,
  [ProjectStateAsString.DELIVERING]: false,
  [ProjectStateAsString.DELIVERED]: false,

  [ProjectStateAsString.CANCELLED]: false,
}

export const BuyerBomItemAttachmentEditableState = {
  [ProjectStateAsString.DEFINING]: true,
  [ProjectStateAsString.REQUESTED]: false,
  [ProjectStateAsString.QUOTING]: true,
  [ProjectStateAsString.QUOTED]: true,
  [ProjectStateAsString.NEGOTIATING]: true,
  [ProjectStateAsString.ORDERED]: false,

  [ProjectStateAsString.PRODUCING]: false,
  [ProjectStateAsString.PRODUCED]: false,
  [ProjectStateAsString.PACKAGING]: false,
  [ProjectStateAsString.PACKAGED]: false,
  [ProjectStateAsString.DELIVERING]: false,
  [ProjectStateAsString.DELIVERED]: false,

  [ProjectStateAsString.CANCELLED]: false,
}
export const SellerBomItemAttachmentEditableState = {
  [ProjectStateAsString.DEFINING]: true,
  [ProjectStateAsString.REQUESTED]: true,
  [ProjectStateAsString.QUOTING]: true,
  [ProjectStateAsString.QUOTED]: true,
  [ProjectStateAsString.NEGOTIATING]: true,
  [ProjectStateAsString.ORDERED]: true,

  [ProjectStateAsString.PRODUCING]: false,
  [ProjectStateAsString.PRODUCED]: false,
  [ProjectStateAsString.PACKAGING]: false,
  [ProjectStateAsString.PACKAGED]: false,
  [ProjectStateAsString.DELIVERING]: false,
  [ProjectStateAsString.DELIVERED]: false,

  [ProjectStateAsString.CANCELLED]: false,
}
