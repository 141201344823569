import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import { DashboardOpenedEventData } from './DashboardOpenedEventData'
import { ProjectsListedEventData } from './ProjectListOpenedEventData'
import { ProjectOpenedEventData } from './ProjectOpenedEventData'

export type AppEvents =
  | ProjectOpenedEventData
  | DashboardOpenedEventData
  | ProjectsListedEventData

export interface IAppEventsAPI {
  PostEvent: (args: AppEvents) => Promise<void>
}

export class AppEventsAPI extends InternalAPI implements IAppEventsAPI {
  constructor(
    partyId: string,
    private onRequestChange: OnRequestChangeCallback
  ) {
    super(`/api/parties/${partyId}/app-events`)
  }

  public async PostEvent(args: AppEvents) {
    return await this.PostAsync<void>({
      relativePath: '/',
      data: args,
      id: 'post-event',
      onRequestChange: this.onRequestChange,
    })
  }
}
