import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { ShowException } from 'store/Application/appActions'
import { projectAPIBuilder } from '../asyncActions/BoMAsyncActions'
import { newProjectActions } from '../projectReducer'
import { fetchProject } from './fetchProject'
import { RootAppState } from 'store/configureStore'

export const saveProjectStatus = createAsyncThunk<
  void,
  { from: ProjectStateAsString; to: ProjectStateAsString },
  { state: RootAppState }
>(
  'project/saveProjectStatus',
  async (
    args: { from: ProjectStateAsString; to: ProjectStateAsString },
    thunkAPI
  ) => {
    const { projectId, api } = projectAPIBuilder(thunkAPI.getState)

    try {
      thunkAPI.dispatch(
        newProjectActions.setActiveProjectProperties({
          status: args.to,
        })
      )

      return await api.SetProjectStatus(projectId, args.from, args.to)
    } catch (ex) {
      ShowException('project', ex)
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      throw ex
    }
  }
)
