import { createAsyncThunk } from '@reduxjs/toolkit'
import { ShowException } from 'store/Application/appActions'
import { RootAppState } from 'store/configureStore'
import { projectAPIBuilder } from '../asyncActions/BoMAsyncActions'
import { newProjectActions } from '../projectReducer'
import { fetchProject } from './fetchProject'

export const saveProjectDeliveryDays = createAsyncThunk<
  void,
  number,
  { state: RootAppState }
>('project/saveProjectDeliveryDays', async (estimatedDeliveryDays: number, thunkAPI) => {
  const { projectId, api } = projectAPIBuilder(thunkAPI.getState)

  try {
    thunkAPI.dispatch(
      newProjectActions.setActiveProjectProperties({
        estimatedDeliveryDays: estimatedDeliveryDays,
      })
    )

    await api.SetEstimatedDeliveryDays(projectId, {
      estimatedDeliveryDays: estimatedDeliveryDays,
    })
  } catch (err) {
    ShowException('Delivery Days', err)
    thunkAPI.dispatch(fetchProject({ projectId: projectId }))
    throw err
  }
})
