import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItem2DPathSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItem?.type) {
      case BomItemType.partType:
      case BomItemType.partInstance:
        return bomItem.twoDModelPath
      default:
        return null
    }
  }
