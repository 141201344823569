/* eslint-disable @typescript-eslint/no-unused-vars */
import cadex from '@cadexchanger/web-toolkit'

export class SelectedPointsCollector extends cadex.ModelPrs_SelectedEntityVisitor {
  points: cadex.ModelData_Point[]

  constructor() {
    super()
    this.points = []
  }
  visitPolyShapeEntity(
    _thePolyShapeEntity: cadex.ModelPrs_SelectedPolyShapeEntity
  ) {
    return
  }

  visitPolyVertexEntity(
    thePolyVertexEntity: cadex.ModelPrs_SelectedPolyVertexEntity
  ) {
    this.points.push(
      thePolyVertexEntity.polyShape.coordinate(thePolyVertexEntity.vertexIndex)
    )
  }
  /**
   * @override
   * @param {cadex.ModelPrs_SelectedShapeEntity} theShapeEntity
   */
  visitShapeEntity(theShapeEntity) {
    if (theShapeEntity.shape.point) {
      this.points.push(
        /** @type {cadex.ModelData_Vertex} */ theShapeEntity.shape.point.clone()
      )
    }
  }
}
