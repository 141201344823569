import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { ProjectsAPI } from 'controllers/Projects/ProjectsAPI'
import { ConfirmationDialogProps } from 'features/ConfirmationDialog/ConfirmationDialog'
import i18n from 'i18next'
import { ErrorMessage, ShowException } from 'store/Application/appActions'
import { UIActions } from 'store/UI/UIActions'
import { RootAppState } from 'store/configureStore'
import { GetPartyId } from 'store/storeUtils'
import { fetchProjectSummaries } from './fetchProjectSummaries'

/**
 * do not call this directly, use deleteProjects.
 * this one is just to keep track of async operation status
 */
export const deleteProjectsAction = createAsyncThunk<
  void,
  {
    partyId: string
    projectIds: string[]
    callback: (projectIds: string[]) => void
  },
  { state: RootAppState }
>(
  'projectList/deleteProjectAction',
  async (
    payload: {
      partyId: string
      projectIds: string[]
      callback: (projectIds: string[]) => void
    },
    thunkAPI
  ) => {
    try {
      const projectsAPI = new ProjectsAPI(payload.partyId)
      await projectsAPI.DeleteProjects(payload.projectIds)
      payload.callback?.(payload.projectIds)
    } catch (err) {
      ShowException('project delete', err)
      thunkAPI.dispatch(fetchProjectSummaries({ subscribeToUpdates: false }))
    }
  }
)

export const deleteProjects = createAsyncThunk<
  void,
  {
    requests: Array<{
      projectId: string
      projectStatus: ProjectStateAsString
      confirmationDone?: boolean
    }>
    successCallback?: (deletedIds: string[]) => void
  },
  { state: RootAppState }
>('projectList/deleteProject', async (payload, thunkAPI) => {
  const partyId = GetPartyId()

  const orderedProjects = payload.requests.filter(
    (x) =>
      x.projectStatus === ProjectStateAsString[ProjectStateAsString.ORDERED]
  )

  if (orderedProjects.length === payload.requests.length) {
    ErrorMessage(
      i18n.t('common:delete-project'),
      i18n.t(
        'errors:cannot-delete-project-in-current-state',
        'projects with ordered status cannot be deleted.'
      )
    )

    return
  }

  const deleteProjectAction = (callback: (projectIds: string[]) => void) =>
    thunkAPI.dispatch(
      deleteProjectsAction({
        partyId: partyId,
        projectIds: payload.requests
          .filter(
            (x) =>
              x.projectStatus !==
              ProjectStateAsString[ProjectStateAsString.ORDERED]
          )
          .map((x) => x.projectId),
        callback,
      })
    )

  if (payload.requests.length === 1 && payload.requests[0].confirmationDone) {
    deleteProjectAction(payload.successCallback)
    return
  }

  if (orderedProjects.length > 0) {
    thunkAPI.dispatch(
      UIActions.OpenModal('ConfirmationDialog', {
        title: i18n.t('common:delete-project', 'delete projects'),
        message: i18n.t(
          'common:delete-projects-with-ordered-confirmation',
          'projects with ordered status cannot be deleted. Do you want to proceed deleting other {{count}} project?',
          {
            count: payload.requests.length - orderedProjects.length,
          }
        ),
        onConfirm: () => deleteProjectAction(payload.successCallback),
      } as ConfirmationDialogProps)
    )

    return
  }

  thunkAPI.dispatch(
    UIActions.OpenModal('ConfirmationDialog', {
      title: i18n.t(
        'common:delete-projects-count',
        'delete {{count}} project',
        {
          count: payload.requests.length - orderedProjects.length,
        }
      ),
      onConfirm: () => deleteProjectAction(payload.successCallback),
    })
  )
})
