import { GeometryAllowedExtensions } from 'model/GeometryAllowedExtensions'
import { AppState } from 'store'

export const acceptedDocumentTypesSelector = (state: AppState) => {
  let configuredImportDocumentTypes = []
  if (state.user.organizationContext.isBuyingParty) {
    configuredImportDocumentTypes =
      state.user.organizationContext.allowedImportDocumentTypes
  }

  return GeometryAllowedExtensions(configuredImportDocumentTypes)
}
