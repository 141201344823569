import { ListItem, SxProps, Theme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModelController } from './manager/ModelController'

type Props = {
  modelViewer: ModelController
  onNoPMIData: () => void
  onPMIDataFound: () => void
}

export const ModelPMIData = (props: Props) => {
  const { t } = useTranslation()
  const [pmiViewNames, setPmiViewNames] = useState<string[]>()

  const [currentView, setCurrentView] = useState<string>('ALL')

  const onClick = (viewName: string) => {
    setCurrentView(viewName)
    props.modelViewer.LoadPmiView(viewName)
  }

  const pmiLoader = useCallback(() => {
    const viewNames = Array.from(
      props.modelViewer.PMIManager?.getPMIData()?.sceneNodesBySavedViews?.keys() ||
        []
    )

    setTimeout(() => {
      setPmiViewNames(viewNames)
      if (!viewNames?.length) {
        props.onNoPMIData?.()
      } else {
        props.onPMIDataFound()
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modelViewer.PMIManager])

  useEffect(() => {
    if (props.modelViewer.isInitialized) {
      pmiLoader()
    }

    props.modelViewer.onPMIDataLoaded(pmiLoader)
  }, [pmiLoader, props.modelViewer])

  const styleProps = (view: string): SxProps<Theme> => ({
    backgroundColor: currentView === view ? 'primary.main' : '',
    color: currentView === view ? 'primary.contrastText' : '',
    textTransform: 'lowercase',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: (theme) =>
        currentView === view
          ? theme.palette.primary.dark
          : theme.palette.action.hover,
      color: (theme) =>
        currentView === view
          ? theme.palette.primary.contrastText
          : theme.palette.text.primary,
    },
  })

  return (
    <ul>
      <ListItem
        sx={styleProps('ALL')}
        key={'ALL'}
        onClick={() => onClick('ALL')}
      >
        {t('common:all', 'all')}
      </ListItem>
      {pmiViewNames?.map((viewName) => (
        <ListItem
          sx={styleProps(viewName)}
          key={viewName}
          onClick={() => onClick(viewName)}
        >
          {viewName}
        </ListItem>
      )) || <ListItem>No PMI data</ListItem>}
      <ListItem
        key={'NONE'}
        sx={styleProps('NONE')}
        onClick={() => onClick('NONE')}
      >
        {t('common:none')}
      </ListItem>
    </ul>
  )
}
