import _ from 'lodash'
import { KeywordDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { MaterialKeywordsFilter } from 'store/Project/ProjectTypes'

export function showPartTypeBasedOnTokens(
  keywords: Record<string, Array<KeywordDto>>,
  filter: MaterialKeywordsFilter,
  showWithAllKeywords: boolean
) {
  const enabledGroups = Object.keys(filter.enabledFilters || {})
  // console.log('enabledGroups', enabledGroups)

  if (enabledGroups.length === 0) {
    return Object.keys(keywords || {}).length === 0
  }

  if (_.isEmpty(keywords)) {
    return false
  }

  let shouldShowThisRow = true

  if (showWithAllKeywords) {
    shouldShowThisRow = enabledGroups.every((group) => {
      const enabledKeywords = filter.enabledFilters[group]
      const rowKeywords = keywords[group]

      if (!enabledKeywords?.length) {
        return true
      }

      return enabledKeywords.every((filterToken) =>
        rowKeywords?.some(
          (rowToken) => rowToken.originalKeyword === filterToken.originalKeyword
        )
      )
    })
  } else {
    shouldShowThisRow = enabledGroups.some((group) => {
      const enabledKeywords = filter.enabledFilters[group]
      const rowKeywords = keywords[group]

      if (!enabledKeywords?.length) {
        return true
      }

      return rowKeywords?.some((rowToken) =>
        enabledKeywords.some(
          (filterToken) =>
            rowToken.originalKeyword === filterToken.originalKeyword
        )
      )
    })
  }

  return shouldShowThisRow
}
