import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import {
  CreateWorkspaceRequest,
  CreateWorkspaceResponse,
  GetAvailableWorkspacesResponse,
  JoinWorkspaceRequest,
  JoinWorkspaceResponse,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { FactoryForm, UserForm } from '../store/GettingStartedTypes'
import { IGettingStartedAPI } from './GettingStartedController'

export class GettingStartedAPI
  extends InternalAPI
  implements IGettingStartedAPI
{
  constructor(private onRequestChange: OnRequestChangeCallback) {
    super('/api/getting-started', onRequestChange)
  }
  public async createWorkspace(data: CreateWorkspaceRequest) {
    return await this.PostAsync<CreateWorkspaceResponse>({
      id: 'createWorkspace',
      relativePath: '/CreateWorkspace',
      data,
    })
  }

  public async setUserData(
    data: {
      userForm: UserForm
      factoryForm: FactoryForm
    },
    recaptchaToken: string
  ) {
    return await this.PostAsync<void>({
      id: 'setOnboardingData',
      relativePath: '/',
      data: {
        userForm: data.userForm,
        factoryForm: data.factoryForm,
        recaptchaToken,
      },
    })
  }

  public async getAvailableWorkspaces() {
    return await this.GetAsync<GetAvailableWorkspacesResponse>({
      id: 'getAvailableWorkspaces',
      relativePath: '/GetAvailableWorkspaces',
    })
  }

  public async joinWorkspace(request: JoinWorkspaceRequest) {
    return await this.PostAsync<JoinWorkspaceResponse>({
      id: 'joinWorkspace',
      relativePath: '/JoinWorkspace',
      data: request,
    })
  }
}
