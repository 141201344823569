import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemNestingTypeSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItem?.type) {
      case BomItemType.partType:
      case BomItemType.partInstance:
        return {
          selectedDirection: bomItem.selectedNestingDirection,
          selectableDirections: bomItem.selectableNestingDirections,
        }
      default:
        return null
    }
  }
