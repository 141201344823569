import cadex from '@cadexchanger/web-toolkit'

/**
 * Changes display mode of the model.
 *
 * ideally the wires color when in Wireframe mode should be the black, to be visible in the ui
 * we need to change the appearence and then restore it back when not in wireframe mode
 */
export class ChangeDisplayModeVisitor extends cadex.ModelPrs_SceneNodeVisitor {
  private _appearances: Map<cadex.Base_Uuid, cadex.ModelData_Appearance> =
    new Map()

  private _currentDisplayMode: cadex.ModelPrs_DisplayMode

  public set DisplayMode(value: cadex.ModelPrs_DisplayMode) {
    this._currentDisplayMode = value
  }

  visitEnter(node: cadex.ModelPrs_SceneNode): boolean {
    if (
      node.appearance &&
      this._currentDisplayMode === cadex.ModelPrs_DisplayMode.Wireframe
    ) {
      this._appearances.set(node['elementId'], node.appearance.clone())
      node.appearance = new cadex.ModelData_Appearance(
        new cadex.ModelData_ColorObject(0, 0, 0, 1)
      )
      node.invalidate()
    } else if (node.appearance && this._appearances.size > 0) {
      if (this._appearances.has(node['elementId'])) {
        const oldAppearence = this._appearances.get(node['elementId'])
        node.appearance = oldAppearence
      } else {
        console.error('no appearence found for node', node['elementId'])
      }

      node.invalidate()
    }

    return true
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  visitLeave(node: cadex.ModelPrs_SceneNode): void {
    return
  }
}
