import { RootAppState } from 'store/configureStore'
import {
  BuyerProjectEditableState,
  BuyerProjectReferenceEditableState,
  SellerProjectEditableState,
  SellerProjectReferenceEditableState,
  BuyerBomItemAttachmentEditableState,
  SellerBomItemAttachmentEditableState,
} from '../../ProjectState/ProjectEditableState'

export const ProjectEditableStateSelector = (state: RootAppState): boolean => {
  return state.project.activeProject
    ? state.project.activeProject.isBuyingPartyView
      ? BuyerProjectEditableState[state.project.activeProject.status]
      : SellerProjectEditableState[state.project.activeProject.status]
    : false
}
export const ProjectReferenceEditableStateSelector = (
  state: RootAppState
): boolean => {
  return state.project.activeProject
    ? state.project.activeProject.isBuyingPartyView
      ? BuyerProjectReferenceEditableState[state.project.activeProject.status]
      : SellerProjectReferenceEditableState[state.project.activeProject.status]
    : false
}

export const BomItemAttachementEditableStateSelector = (
  state: RootAppState
): boolean => {
  return state.project.activeProject
    ? state.project.activeProject.isBuyingPartyView
      ? BuyerBomItemAttachmentEditableState[state.project.activeProject.status]
      : SellerBomItemAttachmentEditableState[state.project.activeProject.status]
    : false
}
