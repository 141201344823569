class Debugger {
  static ToggleDebugger() {
    window['metalheaven-debugger'] = !window['metalheaven-debugger'] || true
  }

  static Log(
    type: 'warning' | 'info' | 'error',
    message: string,
    ...optionalParams: unknown[]
  ) {
    if (window['metalheaven-debugger']) {
      switch (type) {
        case 'warning':
          console.warn(message, JSON.stringify(optionalParams))
          break
        case 'info':
          console.info(message, JSON.stringify(optionalParams))
          break
        case 'error':
          console.error(message, JSON.stringify(optionalParams))
          break
      }
    }
  }

  static Info(message: string, ...optionalParams: unknown[]) {
    Debugger.Log('info', message, optionalParams)
  }

  static Warn(message: string, ...optionalParams: unknown[]) {
    Debugger.Log('warning', message, optionalParams)
  }

  static Error(message: string, ...optionalParams: unknown[]) {
    Debugger.Log('error', message, optionalParams)
  }

  static Configure() {
    if (process.env.NODE_ENV === 'development') {
      window['metalheaven-debugger'] = false
    }
  }
}



/**
 * use to add a variable to the window object for debugging purposes
 * @param name variable name
 * @param value variable value (any type)
 * @returns
 */
export function debugAddToWindow(name: string, value: unknown) {
  // should not be enabled in production
  const enabled =
    process.env.NODE_ENV === 'development' ||
    window.location.search === 'debug' ||
    window['metalheaven-debugger']

  if (!enabled) {
    return
  }

  const props: [] = (window[name] as unknown as []) || []

  props.push(value as unknown as never)

  window[name] = props

  console.info(name, props)
}

export { Debugger }
