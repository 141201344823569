import { AppState } from '..'
import { action, Action } from 'typesafe-actions'
import { ApplicationActionTypes, APICallArgs } from '../Application/appState'
import {
  SuccessMessage,
  ErrorMessage,
  APICall,
} from '../Application/appActions'
import { ResourceDto } from '../../services/APIs/InternalAPI/internal-api.contracts'
import { ResourceSummaryDto } from '../../services/APIs/InternalAPI/internal-api.contracts'
import { WorkingStepType } from '../../services/APIs/InternalAPI/internal-api.contracts'
import { ActivityDto } from '../../services/APIs/InternalAPI/internal-api.contracts'
import { getContext } from '../getContext'

export enum Actions {
  CAPABILITIES_UPLOAD_PROGRESS = 'settings/capabilities/uploadprogress',
  MACHINES_LOADED = 'settings/capabilities/machines_loaded',
  UNLOAD_MACHINES = 'settings/capabilities/unload_machines',
  MACHINE_TEMPLATES_LOADED = 'settings/capabilities/machines/load_templates',
  UNLOAD_MACHINE_TEMPLATES = 'settings/capabilities/machines/unload_templates',
  TECHNIQUE_UPDATED = 'settings/capabilitites/machines/technique_updated',
}

export interface OnUploadProgress extends Action {
  techniqueType: number
  machineId: string
  type: Actions.CAPABILITIES_UPLOAD_PROGRESS
  progressPercentage: number
}

export interface OnMachinesLoaded extends Action {
  type: Actions.MACHINES_LOADED
  machines: ResourceDto[]
}

export interface UnloadMachines extends Action {
  type: Actions.UNLOAD_MACHINES
}

export interface LoadMachineTemplates extends Action {
  type: Actions.MACHINE_TEMPLATES_LOADED
  templates: ResourceSummaryDto[]
}

export interface TechniqueUpdated extends Action {
  type: Actions.TECHNIQUE_UPDATED
  machineId: string
  technique: ActivityDto
}

export interface UnloadMachineTemplates extends Action {
  type: Actions.UNLOAD_MACHINE_TEMPLATES
}

export type CapabalitiesSettingsActionTypes =
  | OnUploadProgress
  | OnMachinesLoaded
  | UnloadMachines
  | LoadMachineTemplates
  | UnloadMachineTemplates
  | TechniqueUpdated

const MachineActions = {
  UnloadMachines: () => ({
    type: Actions.UNLOAD_MACHINES,
  }),

  LoadOrganizationMachines: () => (dispatch, getState: () => AppState) => {
    const organizationId = getState().user.organizationContext.id

    dispatch(
      APICall({
        label: 'LOADING_MACHINES',
        configuration: {
          method: 'GET',
          url: `api/parties/${organizationId}/settings/resources`,
          onSuccess: (data) => {
            dispatch({
              type: Actions.MACHINES_LOADED,
              machines: data,
            })
          },
        },
      })
    )
  },
  uploadConfigurationFile:
    (
      files: File[],
      machineId: string,
      workingStepType: WorkingStepType,
      techniqueType: number,
      onUploadSuccessful: () => void,
      onUploadError: () => void
    ) =>
    (dispatch, getState: () => AppState) => {
      const organizationId = getState().user.organizationContext.id
      dispatch(
        action(
          ApplicationActionTypes.API,
          new APICallArgs({
            label: 'UploadFiles',
            configuration: {
              method: 'POST',
              url: `api/parties/${organizationId}/settings/resources/${machineId}/workingsteps/${workingStepType}/techniques/${techniqueType}/upload`,
              data: files,
              isFileUpload: true,
              onSuccess: () => {
                SuccessMessage('upload', 'upload finished')
                dispatch(MachineActions.LoadOrganizationMachines())
                onUploadSuccessful()
              },
              onError: (err: string) => {
                ErrorMessage('upload', err)
                onUploadError()
              },
              onProgress: (progress: { loaded: number; total?: number }) => {
                dispatch(
                  MachineActions.OnUploadProgress(
                    machineId,
                    techniqueType,
                    (!progress.total
                      ? 0
                      : (progress.loaded / progress.total) * 100
                    ).toFixed(2)
                  )
                )
              },
            },
          })
        )
      )
    },
  OnUploadProgress: (
    machineId: string,
    techniqueType: number,
    progressPercentage: string
  ) => ({
    type: Actions.CAPABILITIES_UPLOAD_PROGRESS,
    machineId,
    techniqueType,
    progressPercentage,
  }),

  // UpdateTechniqueProps: (
  //   machineId: string,
  //   workingStepType: WorkingStepType,
  //   techniqueType: TechniqueType,
  //   field:
  //     | 'ratePerHour'
  //     | 'purchasingLength'
  //     | 'purchasingWidth'
  //     | 'purchasingHeight',
  //   value: Money | QuantityDto | boolean
  // ) => (dispatch, getState: () => AppState) => {
  //   const organizationId = getState().user.organizationContext.id
  //   dispatch(
  //     APICall({
  //       label: 'UPDATE_TECHNIQUE_SETTINGS',
  //       configuration: {
  //         method: 'PUT',
  //         url: `api/parties/${organizationId}/settings/resources/${machineId}/workingsteps/${workingStepType}/techniques/${techniqueType}`,
  //         data: {
  //           field,
  //           value
  //         },
  //         onSuccess: data => {
  //           SuccessMessage('technique', 'technique saved')
  //           dispatch({
  //             type: Actions.TECHNIQUE_UPDATED,
  //             machineId,
  //             technique: {
  //               ...getState()
  //                 .capabilitiesSettings.Machines.find(x => x.id === machineId)
  //                 .techniques.find(x => x.techniqueType === techniqueType),
  //               [field]: value
  //             }
  //           })
  //         },
  //         onError: message => {
  //           ErrorMessage('error', message)
  //           dispatch(MachineActions.LoadOrganizationMachines())
  //         }
  //       }
  //     })
  //   )
  // },
  LoadMachineTemplates: () => (dispatch, getState: () => AppState) => {
    const { partyId: organizationId } = getContext(getState)

    dispatch(
      APICall({
        label: 'LOAD_MACHINE_TEMPLATES',
        configuration: {
          method: 'GET',
          url: `api/parties/${organizationId}/resources/templates`,
          onSuccess: (templates: ResourceSummaryDto[]) => {
            dispatch({
              type: Actions.MACHINE_TEMPLATES_LOADED,
              templates: templates,
            })
          },
          onError: (message) => {
            ErrorMessage('machine templates', message)
          },
        },
      })
    )
  },
  AddMachine:
    (templateId: string, onSuccess: () => void) =>
    (dispatch, getState: () => AppState) => {
      const organizationId = getState().user.organizationContext.id
      dispatch(
        APICall({
          label: 'ADD_MACHINE_BY_TEMPLATE',
          configuration: {
            method: 'POST',
            url: `api/parties/${organizationId}/settings/resources`,
            data: { templateId },
            onSuccess: () => {
              SuccessMessage('machines', 'new machine added')
              dispatch(MachineActions.LoadOrganizationMachines())
              onSuccess()
            },
            onError: (message) => {
              ErrorMessage('machines', message)
            },
          },
        })
      )
    },
  UnloadMachineTemplates: () => ({
    type: Actions.UNLOAD_MACHINE_TEMPLATES,
  }),
  DeleteMachine:
    (machine: ResourceDto) => (dispatch, getState: () => AppState) => {
      const organizationId = getState().user.organizationContext.id

      dispatch(
        APICall({
          label: 'Delete machine',
          configuration: {
            url: `api/parties/${organizationId}/settings/resources/${machine.id}`,
            method: 'DELETE',
            onSuccess: () => {
              SuccessMessage('machines', `machine ${machine.name} deleted`)
              dispatch(MachineActions.LoadOrganizationMachines())
            },
            onError: (message) => {
              ErrorMessage('machines', message)
            },
          },
        })
      )
    },
  UpdateMachineName:
    (machineId: string, machineName: string) =>
    (dispatch, getState: () => AppState) => {
      const organizationId = getState().user.organizationContext.id

      dispatch(
        APICall({
          label: 'Update_Machine_Name',
          configuration: {
            url: `api/parties/${organizationId}/settings/resources/${machineId}/setName`,
            method: 'PUT',
            data: {
              name: machineName,
            },
            onSuccess: () => {
              SuccessMessage('machines', 'machine updated')
            },
            onError: (message) => {
              ErrorMessage('machines', message)
              dispatch(MachineActions.LoadOrganizationMachines())
            },
          },
        })
      )
    },
}

export { MachineActions }
