import { createAsyncThunk } from '@reduxjs/toolkit'
import { ShowException } from 'store/Application/appActions'
import { projectAPIBuilder } from './BoMAsyncActions'
import { RootAppState } from 'store/configureStore'

export const manualExportToErp = createAsyncThunk<
  void,
  null,
  { state: RootAppState }
>('project/manualExportToErp', async (_args, thunkAPI) => {
  const { api, projectId } = projectAPIBuilder(thunkAPI.getState)

  try {
    return await api.ManualExportToErp(projectId)
  } catch (ex) {
    ShowException('project', ex)
    throw ex
  }
})
