import cadex from '@cadexchanger/web-toolkit'
import { QuantityString } from 'model/Quantity'
import { SelectedPointsCollector } from './SelectedPointsCollector'

export enum MeasurementMode {
  TwoPointDistance = 0,
  ThreePointAngle = 1,
}

export class MeasurementsManager extends cadex.ModelPrs_InputHandler {
  scene: cadex.ModelPrs_Scene
  selectedMeasurements: cadex.ModelPrs_SceneNode[]
  selectedPoints: cadex.ModelData_Point[]
  measurementMode: MeasurementMode
  fontSize: number
  lengthUnit: cadex.Base_LengthUnit
  angleUnit: cadex.Base_AngleUnit
  measurementsFactory: cadex.ModelPrs_MeasurementFactory
  measurementsSceneNodeFactory: cadex.ModelPrs_SceneNodeFactory
  measurementsRootNode: cadex.ModelPrs_SceneNode

  /**
   * @param {cadex.ModelPrs_Scene} theScene
   */
  constructor(
    theScene: cadex.ModelPrs_Scene,
    fontSize = 21,
    lengthUnit = cadex.Base_LengthUnit.Base_LU_Millimeters
  ) {
    super()
    this.scene = theScene

    /** @type {Array<cadex.ModelPrs_SceneNode>} */
    this.selectedMeasurements = []
    /** @type {Array<cadex.ModelData_Point>} */
    this.selectedPoints = []

    this.measurementMode = MeasurementMode.TwoPointDistance
    this.fontSize = fontSize
    this.lengthUnit = lengthUnit
    this.angleUnit = cadex.Base_AngleUnit.Base_AU_Degrees

    this.measurementsFactory = new cadex.ModelPrs_MeasurementFactory()
    this.measurementsSceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory()

    this.measurementsRootNode = new cadex.ModelPrs_SceneNode()
    this.measurementsRootNode.displayMode = cadex.ModelPrs_DisplayMode.Shaded
    this.measurementsRootNode.selectionMode = cadex.ModelPrs_SelectionMode.Node
    this.measurementsRootNode.appearance = new cadex.ModelData_Appearance(
      cadex.ModelData_ColorObject.fromHex(0x000)
    )
    this.scene.addRoot(this.measurementsRootNode)
    this.scene.update()
  }

  /**
   * @override
   */
  // get isAcceptKeyEvents() {
  //   return true
  // }

  clear() {
    // To release internal data previously created, the factories are just re-created
    this.measurementsFactory = new cadex.ModelPrs_MeasurementFactory()
    this.measurementsSceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory()
    this.measurementsRootNode.removeChildNodes()
  }

  /**
   * @param {cadex.ModelPrs_SelectionChangedEvent} theEvent
   */
  checkSelectedItems(theEvent: cadex.ModelPrs_SelectionChangedEvent) {
    theEvent.added.forEach((theSelectedItem) => {
      if (theSelectedItem.isWholeSelectedNode) {
        this.selectedMeasurements.push(theSelectedItem.node)
      } else {
        const aSelectedPointsCollector = new SelectedPointsCollector()

        for (const aSelectedEntity of theSelectedItem.entities()) {
          aSelectedEntity.accept(aSelectedPointsCollector)
        }

        aSelectedPointsCollector.points.forEach((thePoint) => {
          const aTransformation = theSelectedItem.node.combinedTransformation

          if (aTransformation) {
            thePoint.transform(theSelectedItem.node.combinedTransformation)
          }

          this.selectedPoints.push(thePoint)
        })
      }
    })

    theEvent.removed.forEach((theSelectedItem) => {
      if (theSelectedItem.isWholeSelectedNode) {
        const anIndex = this.selectedMeasurements.findIndex(
          (theNode) => theNode === theSelectedItem.node
        )

        this.selectedMeasurements.splice(anIndex, 1)
      } else {
        const aSelectedPointsCollector = new SelectedPointsCollector()

        for (const aSelectedEntity of theSelectedItem.entities()) {
          aSelectedEntity.accept(aSelectedPointsCollector)
        }

        aSelectedPointsCollector.points.forEach((thePoint) => {
          const aTransformation = theSelectedItem.node.combinedTransformation
          if (aTransformation) {
            thePoint.transform(theSelectedItem.node.combinedTransformation)
          }

          const anIndex = this.selectedPoints.findIndex((theSelectedPoint) =>
            theSelectedPoint.isEqual(thePoint)
          )

          if (anIndex !== -1) {
            this.selectedPoints.splice(anIndex, 1)
          }
        })
      }
    })

    if (
      this.measurementMode === MeasurementMode.TwoPointDistance &&
      this.selectedPoints.length === 2
    ) {
      this.createDistanceMeasurement(
        this.selectedPoints[0],
        this.selectedPoints[1]
      )
    }
    if (
      this.measurementMode === MeasurementMode.ThreePointAngle &&
      this.selectedPoints.length === 3
    ) {
      this.createAngleMeasurement(
        this.selectedPoints[0],
        this.selectedPoints[1],
        this.selectedPoints[2]
      )
    }
  }

  async createVolumeMeasurement(
    width: {
      start: cadex.ModelData_Point
      end: cadex.ModelData_Point
    },
    height: {
      start: cadex.ModelData_Point
      end: cadex.ModelData_Point
    },
    depth: {
      start: cadex.ModelData_Point
      end: cadex.ModelData_Point
    },
    lengthUnit: cadex.Base_LengthUnit = cadex.Base_LengthUnit
      .Base_LU_Millimeters
  ) {
    // const widthMeasurement = width.start.distance(width.end)
    // const heightMeasurement = height.start.distance(height.end)
    // const depthMeasurement = depth.start.distance(depth.end)

    const widthMeasurement = this.measurementsFactory.createDistanceFromPoints(
      width.start,
      width.end
    )
    const heightMeasurement = this.measurementsFactory.createDistanceFromPoints(
      height.start,
      height.end
    )
    const depthMeasurement = this.measurementsFactory.createDistanceFromPoints(
      depth.start,
      depth.end
    )

    widthMeasurement.lengthUnit = lengthUnit
    heightMeasurement.lengthUnit = lengthUnit
    depthMeasurement.lengthUnit = lengthUnit

    const volume =
      widthMeasurement.value * heightMeasurement.value * depthMeasurement.value

    const drawingText = new cadex.ModelData_DrawingText()

    drawingText.text = `bounding box volume: \n${
      QuantityString(
        {
          value: volume,
          unit: lengthUnit + 'mm3',
        },
        {
          showUnit: false,
        }
      ) + 'mm3' //'mm³'
    }`

    drawingText.textProperties.fontSize = 32

    drawingText.origin = new cadex.ModelData_Point2d(0, -80)

    const volumeTextNode =
      this.measurementsSceneNodeFactory.createNodeFromDrawingElement(
        drawingText
      )

    this.measurementsRootNode.addChildNode(volumeTextNode)

    this.scene.update()
  }

  /**
   * @param {cadex.ModelData_Point} thePoint1
   * @param {cadex.ModelData_Point} thePoint2
   */
  async createDistanceMeasurement(
    thePoint1: cadex.ModelData_Point,
    thePoint2: cadex.ModelData_Point,
    lengthUnit = cadex.Base_LengthUnit.Base_LU_Millimeters
  ) {
    const aDistanceMeasurement =
      this.measurementsFactory.createDistanceFromPoints(thePoint1, thePoint2)

    aDistanceMeasurement.lengthUnit = lengthUnit
    if (!aDistanceMeasurement) {
      return null
    }

    this.scene.selectionManager.deselectAll()

    // find the extension line direction
    // the main idea is to use direction aligned with vector from scene bbox center to measurement points.
    const aBBoxCenter = this.scene.boundingBox.getCenter()

    // use center of measurement reference point for extension line direction
    const anExtensionLineDirection = new cadex.ModelData_Vector()
      .addVectors(thePoint1, thePoint2)
      .multiplyScalar(0.5)
      .subtract(aBBoxCenter)
      .normalize()

    // next try to align annotation direction with X, Y, Z axes.
    const aP1P2Direction = new cadex.ModelData_Vector()
      .subtractVectors(thePoint1, thePoint2)
      .normalize()
    const aDirXAbs = Math.abs(anExtensionLineDirection.x)
    const aDirYAbs = Math.abs(anExtensionLineDirection.y)
    const aDirZAbs = Math.abs(anExtensionLineDirection.z)

    if (aDirZAbs && aDirZAbs >= aDirXAbs && aDirZAbs >= aDirYAbs) {
      if (
        Math.abs(aP1P2Direction.x) < 1e-5 &&
        Math.abs(aP1P2Direction.y) < 1e-5
      ) {
        // degenerate case, choose X axis
        anExtensionLineDirection.z = 0
      } else {
        anExtensionLineDirection.x = 0
      }
      anExtensionLineDirection.y = 0
    } else if (
      aDirXAbs > 1e-5 &&
      aDirXAbs >= aDirYAbs &&
      aDirXAbs >= aDirZAbs
    ) {
      if (
        Math.abs(aP1P2Direction.y) < 1e-5 &&
        Math.abs(aP1P2Direction.z) < 1e-5
      ) {
        // degenerate case, choose Z axis
        anExtensionLineDirection.x = 0
      } else {
        anExtensionLineDirection.z = 0
      }
      anExtensionLineDirection.y = 0
    } else if (aDirYAbs > 1e-5) {
      if (
        Math.abs(aP1P2Direction.x) < 1e-5 &&
        Math.abs(aP1P2Direction.z) < 1e-5
      ) {
        // degenerate case, choose Z axis
        anExtensionLineDirection.y = 0
      } else {
        anExtensionLineDirection.z = 0
      }
      anExtensionLineDirection.x = 0
    } else {
      // default is Z axis
      anExtensionLineDirection.setCoord(0, 0, 1)
    }

    // orthogonalize extension line direction
    const anOrthogonalizedExtensionLineDirection =
      cadex.ModelData_Direction.fromXYZ(aP1P2Direction)
        .cross(anExtensionLineDirection)
        .cross(aP1P2Direction)

    // For better UX place measurement label out of model bbox

    // find the max distance between points to BBox boundaries in chosen direction
    const tmp = new cadex.ModelData_Vector()
    const aBBoxMinCorner = this.scene.boundingBox.minCorner
    const aBBoxMaxCorner = this.scene.boundingBox.maxCorner
    let anExtensionLineLength = Math.max(
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection)
    )

    if (anExtensionLineLength < 0) {
      anExtensionLineLength *= -1
    }

    // add addition offset for better UX
    anExtensionLineLength += 3 * this.fontSize

    aDistanceMeasurement.fontSize = this.fontSize
    aDistanceMeasurement.lengthUnit = this.lengthUnit
    aDistanceMeasurement.extensionLineDirection =
      anOrthogonalizedExtensionLineDirection
    aDistanceMeasurement.extensionLineLength = anExtensionLineLength
    aDistanceMeasurement.extensionOverhangLength = 0.4 * this.fontSize

    // if (relativeFontSize) {
    //   const distance = aDistanceMeasurement.value
    //   const fontSize =
    //     distance > 300
    //       ? Math.max(60, (aDistanceMeasurement.value * this.fontSize) % 10)
    //       : this.fontSize

    //   console.log(distance, fontSize)

    //   aDistanceMeasurement.fontSize = fontSize
    // }

    const aDistanceMeasurementNode =
      this.measurementsSceneNodeFactory.createNodeFromMeasurement(
        aDistanceMeasurement
      )
    this.measurementsRootNode.addChildNode(aDistanceMeasurementNode)
    await this.scene.update()

    return aDistanceMeasurement
  }

  public async update(measurements: Array<cadex.ModelPrs_LinearMeasurement>) {
    this.measurementsRootNode.removeChildNodes()

    measurements
      .map((measurement) =>
        this.measurementsSceneNodeFactory.createNodeFromMeasurement(measurement)
      )
      .forEach((node) => this.measurementsRootNode.addChildNode(node))

    await this.scene.update()
  }

  /**
   * @param {cadex.ModelData_Point} thePoint1
   * @param {cadex.ModelData_Point} thePoint2
   * @param {cadex.ModelData_Point} thePoint3
   */
  async createAngleMeasurement(
    thePoint1: cadex.ModelData_Point,
    thePoint2: cadex.ModelData_Point,
    thePoint3: cadex.ModelData_Point
  ) {
    const anAngleMeasurement = this.measurementsFactory.createAngleFromPoints(
      thePoint1,
      thePoint2,
      thePoint3
    )
    if (!anAngleMeasurement) {
      return
    }
    this.scene.selectionManager.deselectAll()

    anAngleMeasurement.fontSize = this.fontSize
    anAngleMeasurement.angleUnit = this.angleUnit
    anAngleMeasurement.extensionLineLength = 100 * this.fontSize
    anAngleMeasurement.extensionOverhangLength = 0.4 * this.fontSize

    console.info(
      `New angle measurement created:\nVertex 1: ${thePoint1}\nVertex 2 ${thePoint2}\nVertex 3 ${thePoint3}\nResult: ${
        anAngleMeasurement.value
      }\nRendered text: ${anAngleMeasurement.toString()}`
    )

    const anAngleMeasurementNode =
      this.measurementsSceneNodeFactory.createNodeFromMeasurement(
        anAngleMeasurement
      )
    this.measurementsRootNode.addChildNode(anAngleMeasurementNode)
    await this.scene.update()
  }

  /**
   * @override
   * @param {!cadex.ModelPrs_KeyboardInputEvent} theEvent
   * @returns {boolean}
   */
  keyDown(theEvent) {
    if (theEvent.code === 'Delete') {
      this.selectedMeasurements.forEach((theNode) => {
        this.measurementsRootNode.removeChildNode(theNode)
      })
      if (this.selectedMeasurements.length > 0) {
        this.selectedMeasurements.length = 0
        this.scene.update()
      }
      return true
    }
    return false
  }

  setMeasurementMode(mode: MeasurementMode) {
    this.measurementMode = mode
  }
}
