import cadex from '@cadexchanger/web-toolkit'
import { SceneDataStore } from '../store/SceneDataStore'
import { PickedEntityVisitor } from '../visitors/PickedEntityVisitor'

export class ModelContextMenuHandler extends cadex.ModelPrs_ContextMenuHandler {
  private onSelectionChanged: (
    name: string,
    position: { x: number; y: number },
    shapeId: string,
    shapeName: string,
    shapeType: string
  ) => void
  private scene: cadex.ModelPrs_Scene

  public color: cadex.ModelData_ColorObject
  private sceneStore: SceneDataStore

  constructor(
    scene: cadex.ModelPrs_Scene,
    sceneStore: SceneDataStore,
    onSelectionChanged?: (
      name: string,
      position: { x: number; y: number },
      shapeId: string,
      shapeName: string,
      shapeType: string
    ) => void
  ) {
    super()

    this.scene = scene
    this.onSelectionChanged = onSelectionChanged
    this.sceneStore = sceneStore
    // this.color = new cadex.ModelData_ColorObject(1, 0, 0, 1)
  }

  async contextMenu(event: cadex.ModelPrs_PointerInputEvent): Promise<boolean> {
    await this.scene.update()
    const position = event.point.position

    const pickResult = this.scene.selectionManager.pickFromViewport(
      position.x,
      position.y,
      event.viewport
    )

    if (pickResult) {
      const pickedEntityVisitor = new PickedEntityVisitor()

      pickResult.pickedEntity.accept(pickedEntityVisitor)

      this.onSelectionChanged?.(
        pickedEntityVisitor.formattedEntity,
        position,
        pickedEntityVisitor.shapeId?.toString(),
        pickedEntityVisitor.shapeName,
        pickedEntityVisitor.shapeType
      )
    }

    return true
  }
}
