import { ExpandMoreOutlined } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Collapse,
  Divider,
  Typography,
} from '@mui/material'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import React, { useState } from 'react'
import { ModelController } from '../manager/ModelController'
import { AngleMeasurementTool } from './AngleMeasurementTool'
import { BoundingBoxTool } from './BoundingBoxTool'
import { DisplayModeTool } from './DisplayMode'
import { DistanceMeasurementTool } from './DistanceMeasurementTool'
import { ExplodeTool } from './ExplodeTool'
import { FitAllTool } from './FitAllTool'
import { SelectionMode } from './SelectionMode'

type Props = {
  bomItemPointer: BomItemPointer
  viewController: ModelController
}

export const ViewerTools = (props: Props) => {
  const [HelpComponent, setHelpComponent] = useState<
    React.ReactElement | undefined
  >(null)

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 10,
        width: (theme) => theme.spacing(50),
      }}
    >
      <Accordion
        defaultExpanded={false}
        elevation={0}
        sx={{
          opacity: 0.8,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          onClick={(e) => e.stopPropagation()}
          sx={{
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              flexWrap: 'wrap',
              gap: (theme) => theme.spacing(1),
            },
          }}
        >
          <div style={{ display: 'flex', width: '100%', gap: '1em' }}>
            <FitAllTool viewManager={props.viewController} />
            <DistanceMeasurementTool
              controller={props.viewController}
              setHelpComponent={setHelpComponent}
            />
            <AngleMeasurementTool
              controller={props.viewController}
              setHelpComponent={setHelpComponent}
            />
            <BoundingBoxTool
              bomItemPointer={props.bomItemPointer}
              controller={props.viewController}
            />
            {/* <AutoMeasurementsTool
              controller={props.viewController}
              setHelpComponent={setHelpComponent}
            /> */}
            {/* <FaceMeasurementTool controller={props.viewController} /> */}
          </div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Collapse in={Boolean(HelpComponent)}>{HelpComponent}</Collapse>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: (theme) => theme.spacing(1, 0) }}>
          <Box sx={{ p: 2, pt: 0 }}>
            <Typography>selection mode</Typography>
            <SelectionMode viewManager={props.viewController} />
          </Box>
          {[BomItemType.assemblyInstance, BomItemType.assemblyType].includes(
            props.bomItemPointer.type
          ) && (
            <>
              <Divider />
              <Box sx={{ p: 2 }}>
                <Typography>exploder</Typography>
                <ExplodeTool viewController={props.viewController} />
              </Box>
            </>
          )}

          <Divider />
          <Box sx={{ p: 2 }}>
            <Typography>display mode</Typography>
            <DisplayModeTool viewManager={props.viewController} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
