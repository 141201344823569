import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemController } from 'controllers/Project/BomItemController'
import { NestingDirection } from 'services/APIs/InternalAPI/internal-api.contracts'
import { newProjectActions } from '../projectReducer'
import { fetchProject } from './fetchProject'
import { GetContext } from 'store/storeUtils'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'

export const saveNestingDirection = createAsyncThunk<
  void,
  { bomItemPointer: BomItemPointer; nestingDirection: NestingDirection },
  { state: RootAppState }
>(
  'bom/save-nesting-direction',
  async ({ bomItemPointer: bomItemPointer, nestingDirection }, thunkAPI) => {
    const bomItemController = new BomItemController()
    const { projectId } = GetContext()

    try {
      thunkAPI.dispatch(
        newProjectActions.setBomItemProperties({
          bomItemPointer,
          properties: {
            selectedNestingDirection: nestingDirection,
          },
        })
      )

      await bomItemController.SetNestingDirection(
        bomItemPointer,
        nestingDirection
      )
    } catch (err) {
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      throw err
    }
  }
)
