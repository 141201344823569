import { FactoryOutlined } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useAppController } from 'customHooks/useAppController'
import { useNavigate } from 'react-router-dom'
import { ShowException } from 'store/Application/appActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { GettingStartedBody } from '../Components/GettingStartedBody'
import { GettingStartedCard } from '../Components/GettingStartedCard'
import { GettingStartedUserHeader } from '../Components/GettingStartedUserHeader'
import { GettingStartedStep } from '../Components/GettingStatedStep'
import { GettingStartedController } from '../Services/GettingStartedController'
import { navigateToNextStep } from '../store/GettingStartedActions'
import { gettingStartedSelectors } from '../store/GettingStartedSelectors'

export function JoinWorkspaceStep() {
  const availableWorkspaces = useAppSelector(
    gettingStartedSelectors.getAvailableWorkspaces
  )
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { controller } = useAppController(() => {
    return new GettingStartedController()
  })

  const handleJoinDomain = async (partyId: string) => {
    try {
      await controller.JoinDomain(partyId)
      dispatch(navigateToNextStep(navigate))
    } catch (err) {
      ShowException('getting started', err)
    }
  }

  return (
    <GettingStartedStep>
      <GettingStartedCard>
        <GettingStartedUserHeader />
        <GettingStartedBody>
          <LocalizedTypography
            translationKey="getting-started:join-workspace-title"
            variant="h5"
            style={{
              textAlign: 'center',
            }}
          >
            welcome to Quotation Factory
          </LocalizedTypography>
          <LocalizedTypography
            translationKey="getting-started:join-workspace-info"
            style={{
              textAlign: 'center',
            }}
          >
            The following workspaces are available for you to join:
          </LocalizedTypography>
          <ul style={{ textAlign: 'center' }}>
            {availableWorkspaces?.map((workspace) => (
              <li key={workspace.id}>
                <Button
                  variant="outlined"
                  onClick={() => handleJoinDomain(workspace.id)}
                >
                  <div>
                    <Typography color="textPrimary" component="span">
                      <FactoryOutlined
                        style={{
                          width: '4rem',
                          height: '4rem',
                          strokeWidth: '.1rem',
                        }}
                      />
                    </Typography>
                    <Typography variant="body1">{workspace.name}</Typography>
                  </div>
                </Button>
              </li>
            ))}
          </ul>
          <LocalizedTypography
            translationKey="getting-started:join-workspace-info-2"
            variant="caption"
            color="textSecondary"
            style={{
              textAlign: 'center',
            }}
          >
            click in the workspace you would like to join
          </LocalizedTypography>
        </GettingStartedBody>
      </GettingStartedCard>
    </GettingStartedStep>
  )
}
