import { CaseReducer } from '@reduxjs/toolkit'
import { ProjectState } from 'store/Project/ProjectTypes'
import { updateHeadersFilteredParts } from '../filters/updateHeadersFilteredParts'

export const removeAllFilters: CaseReducer<ProjectState> = (state) => {
  Object.keys(state.filters).forEach((filterKey) => {
    state.filters[filterKey].active = false
    state.filters[filterKey].filter.enabledFilters = []
    state.filters[filterKey].filter.showIfAll = false
  })

  updateHeadersFilteredParts(state)
}
