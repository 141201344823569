import {
  AdditionalWorkingStepDto,
  JsonSchema,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'

export interface AdditionalWorkingStepFormConfiguration {
  jsonSchema: JsonSchema
  uiSchema: JsonSchema
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any
  workingStepType: WorkingStepType
}

export function AdditionalWorkingStepFormModelBuilder() {
  const FromAPIResponse = (
    resp: AdditionalWorkingStepDto,
    workingStepType: WorkingStepType
  ): AdditionalWorkingStepFormConfiguration => {
    const model: AdditionalWorkingStepFormConfiguration = {
      jsonSchema: JSON.parse(resp.jsonSchema.replace(/\r?\n|\r/g, '')),
      formData: resp.formData,
      uiSchema: JSON.parse(resp.uiSchema?.replace(/\r?\n|\r/g, '')),
      workingStepType,
    }

    delete model.jsonSchema['$schema']

    // needs to fix the QuantityDto definition to allow additionalProperties
    if (model.jsonSchema?.definitions?.QuantityDto) {
      // model.jsonSchema.definitions.QuantityDto.additionalProperties = true
    }

    return model
  }

  return {
    FromAPIResponse,
  }
}
