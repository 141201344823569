import { ChevronRight } from '@mui/icons-material'
import { Box, Collapse, IconButton, Typography } from '@mui/material'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback, useEffect, useState } from 'react'
import { ModelController } from './manager/ModelController'
import { ModelData } from './manager/TreeDataManager'

type Props = {
  bomItemPointer: BomItemPointer
  modelViewer: ModelController
  onNoModelExplorer?: () => void
}

const ModelDetailItem = (props: {
  modelData: ModelData
  onHover: (modelData: ModelData) => void
  onClick: (modelData: ModelData) => void
  onFitPart: (modelData: ModelData) => void
  onChangeColor: (modelData: ModelData) => void
}) => {
  const [open, setOpen] = useState(props.modelData.nodeType === 'ASSEMBLY')

  if (!props.modelData) {
    return null
  }

  const handleHover = (modelData: ModelData) => {
    props.onHover(modelData)
  }

  const handleClick = (modelData: ModelData) => {
    if (modelData.nodeType === 'ASSEMBLY') {
      return null
    }

    props.onClick(modelData)
  }

  const fitPart = (modelData: ModelData) => {
    props.onFitPart(modelData)
  }

  return (
    <li>
      <Box
        sx={{
          position: 'relative',
          padding: 1,
          paddingLeft: 0,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          '&:hover': { backgroundColor: 'action.hover' },
          // paddingLeft: (theme) => theme.spacing(2 * props.modelData.level),
          // border: '1px solid red',
        }}
        onMouseEnter={() => {
          handleHover(props.modelData)
        }}
        onClick={(e) => {
          e.stopPropagation()
          handleClick(props.modelData)
        }}
      >
        {props.modelData.children?.length ? (
          <IconButton
            onClick={() => setOpen((c) => !c)}
            size="small"
            sx={{
              position: 'absolute',
              left: '0px',
            }}
          >
            <ChevronRight sx={{ transform: open ? 'rotate(90deg)' : '' }} />
          </IconButton>
        ) : null}
        <div style={{ paddingLeft: '36px', flex: '1 1 100%' }}>
          <Typography
            variant="body2"
            sx={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {props.modelData.name}
          </Typography>
          <Typography
            color="CaptionText"
            variant="caption"
            sx={{ textTransform: 'lowercase' }}
          >
            {props.modelData.nodeType} {/*- {props.modelData.elementId} */}
          </Typography>
          {/* <Typography
            color="CaptionText"
            variant="caption"
            sx={{ textTransform: 'lowercase' }}
            component={'p'}
          >
            parent - {props.modelData.parentId}
          </Typography> */}
        </div>
        {/* <IconButton
          onClick={(e) => {
            e.stopPropagation()
            fitPart(props.modelData)
          }}
          size="small"
        >
          <ZoomInMapOutlined />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            props.onChangeColor(props.modelData)
          }}
          size="small"
        >
          <ColorizeOutlined />
        </IconButton> */}
      </Box>
      {props.modelData.children?.length ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box component="ul" sx={{ marginLeft: 4 }}>
            {props.modelData.children?.map((child, index) => (
              <ModelDetailItem
                key={child?.elementId + `-${index}`}
                modelData={child}
                onHover={(child) => {
                  // props.onHover(child)
                  handleHover(child)
                }}
                onClick={(child) => handleClick(child)}
                onFitPart={(child) => fitPart(child)}
                onChangeColor={(child) => props.onChangeColor(child)}
              />
            ))}
          </Box>
        </Collapse>
      ) : null}
      {/* <Collapse in={open}>
        {props.modelData.children?.map((child) => (
          <ModelDetailItem key={child.id} modelData={child} />
        ))}
      </Collapse> */}
    </li>
  )
}

export const ModelExplorer = (props: Props) => {
  const [detailedData, setDetailedData] = useState<ModelData[]>()

  useEffect(() => {
    const onFileLoadedHandler = async () => {
      if (props.modelViewer.isInitialized) {
        setDetailedData(await props.modelViewer.getDetailedData())
      } else {
        console.error('model not initialized')
      }
    }

    props.modelViewer.onFileLoaded(onFileLoadedHandler)
    props.modelViewer.onDataChanged(onFileLoadedHandler)

    if (props.modelViewer.isInitialized) {
      onFileLoadedHandler()
    }
  }, [props.modelViewer])

  const handleMouseOver = useCallback(
    async (modelData: ModelData) => {
      if (modelData.nodeType === 'ASSEMBLY') {
        return null
      }

      await props.modelViewer.highlightNode(modelData)
    },
    [props.modelViewer]
  )

  const handleSelectItem = useCallback(
    async (modelData: ModelData) => {
      await props.modelViewer.selectNode(modelData)
    },
    [props.modelViewer]
  )

  const handleFitPart = useCallback(
    async (modelData: ModelData) => {
      await props.modelViewer.FitPart(modelData)
    },
    [props.modelViewer]
  )

  const handleChangeColor = useCallback(
    async (modelData: ModelData) => {
      await props.modelViewer.setNodeColor(modelData)
    },
    [props.modelViewer]
  )

  // const [params] = useSearchParams()

  // const getFacesDetails = useCallback(async () => {
  //   await props.modelViewer.getFaceShapeData()
  // }, [props.modelViewer])

  // const updateScene = async () => {
  //   await props.modelViewer.updateScene()
  // }

  return (
    <Box>
      {/* {params?.get('debug') != null && ( */}
      {/* <Button onClick={getFacesDetails} variant="outlined">
        Get Faces Details
      </Button>
      <Button onClick={updateScene} variant="outlined">
        update scene
      </Button> */}
      {/* )} */}
      {detailedData?.map(
        (modelData, index) =>
          modelData && (
            <ModelDetailItem
              key={modelData.elementId + `${index}`}
              modelData={modelData}
              onHover={(modelData) => handleMouseOver(modelData)}
              onClick={(modelData) => handleSelectItem(modelData)}
              onFitPart={(modelData) => handleFitPart(modelData)}
              onChangeColor={(modelData) => handleChangeColor(modelData)}
            />
          )
      )}
    </Box>
  )
}
