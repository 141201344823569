import { createAsyncThunk } from '@reduxjs/toolkit'
import { RegressionTestsAPI } from 'services/APIs/InternalAPI/RegressionTestsAPI'
import { ShowException, SuccessMessage } from 'store/Application/appActions'
import { getContext } from 'store/getContext'
import { RootAppState } from 'store/configureStore'

export const addRegressionTest = createAsyncThunk<
  void,
  { bomItemId: string },
  { state: RootAppState }
>(
  'bomItem/addRegressionTest',
  async ({ bomItemId }: { bomItemId: string }, thunkAPI) => {
    const { partyId, projectId } = getContext(thunkAPI.getState)
    const api = new RegressionTestsAPI(partyId, projectId)

    try {
      await api.AddRegressionTestsAsync([bomItemId])
      SuccessMessage('tests', 'regression tests created')
    } catch (err) {
      ShowException('tests', err)
      console.error('addRegressionTests', err)
      throw err
    }
  }
)
