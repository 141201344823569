import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { ShowException } from 'store/Application/appActions'
import { newProjectActions } from '../projectReducer'
import { projectAPIBuilder } from './BoMAsyncActions'
import { fetchProject } from './fetchProject'
import { RootAppState } from 'store/configureStore'

export const cancelOrder = createAsyncThunk<
  Promise<void>,
  null,
  { state: RootAppState }
>('project/cancelOrder', async (_args, thunkAPI) => {
  const { api, projectId } = projectAPIBuilder(thunkAPI.getState)

  const projectCurrentStatus = thunkAPI.getState().project.activeProject?.status

  // if (projectCurrentStatus === ProjectStatus[ProjectStatus.ORDERED]) {
  //   ErrorMessage(
  //     i18n.t('errors:project'),
  //     i18n.t('errors:cancel-order-in-current-state', {
  //       defaultValue: 'cannot cancel a project in current state',
  //     })
  //   )
  //   return
  // }

  try {
    thunkAPI.dispatch(
      newProjectActions.setActiveProjectProperties({
        status: ProjectStateAsString.CANCELLED,
      })
    )

    return await api.SetProjectStatus(
      projectId,
      projectCurrentStatus,
      ProjectStateAsString.CANCELLED
    )
  } catch (ex) {
    ShowException('project', ex)
    // return thunkAPI.rejectWithValue(ex)
    thunkAPI.dispatch(fetchProject({ projectId: projectId }))
    throw ex
  }
})
