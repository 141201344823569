import { Button, MobileStepper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({ name: 'GettingStartedPreviousAndNextButtons' })(
  (theme) => ({
    root: {
      display: 'flex',
      gap: theme.spacing(2),
      width: '100%',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
    },
  })
)

export const GettingStartedPreviousAndNextButtons = (props: {
  currentStep: number
  handlePrevious?: () => void
  handleNext?: () => void
  submitButton?: boolean
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <MobileStepper
        sx={{
          width: '100%',
          backgroundColor: 'transparent',
          '&.MuiMobileStepper-root': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            justifyItems: 'flex-end',
          },
          '& .MuiMobileStepper-dots': {
            justifySelf: 'center',
          },
        }}
        variant="dots"
        steps={6}
        position="static"
        activeStep={props.currentStep}
        backButton={
          props.handlePrevious ? (
            <Button
              onClick={props.handlePrevious ? props.handlePrevious : undefined}
              style={{ justifySelf: 'flex-start' }}
            >
              {t('getting-started:previous')}
            </Button>
          ) : (
            <div />
          )
        }
        nextButton={
          <Button
            type="submit"
            onClick={props.handleNext ? props.handleNext : undefined}
            variant={
              !props.handleNext && !props.submitButton ? 'text' : 'contained'
            }
            disabled={!props.handleNext && !props.submitButton}
          >
            {t('getting-started:next') || 'Next'}
          </Button>
        }
      ></MobileStepper>
    </div>
  )
}
