import _ from 'lodash'
import { WorkingStepDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { WorkingStepFilter } from 'store/Project/ProjectTypes'

export function showPartTypeBasedOnWorkingSteps(
  workingSteps: WorkingStepDto[],
  filter: WorkingStepFilter
): boolean {
  if ((filter.enabledFilters?.length || 0) === 0) {
    return (workingSteps?.length || 0) === 0
  }

  if (!workingSteps?.length) {
    return false
  }

  const rowPrimaryWorkingSteps = workingSteps
    .filter((x) => x.primaryWorkingStep === x.secondaryWorkingStep)
    .map((x) => x.primaryWorkingStep)

  const intersection = _.intersection(
    rowPrimaryWorkingSteps,
    filter.enabledFilters
  )

  return intersection.length > 0
}
