import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemAttachmentsController } from 'services/APIs/InternalAPI/AttachmentAPI'
import { ShowException } from 'store/Application/appActions'
import { getContext } from 'store/getContext'
import { newProjectActions } from '../projectReducer'
import { RootAppState } from 'store/configureStore'

export const uploadAttachments = createAsyncThunk<
  void,
  { bomItemPointer: BomItemPointer; attachments: Array<File> },
  { state: RootAppState }
>(
  'bomItem/uploadAttachments',
  async ({ bomItemPointer, attachments }, thunkAPI) => {
    const { partyId, projectId } = getContext(thunkAPI.getState)
    const api = new BomItemAttachmentsController(partyId, projectId)

    try {
      return await api.UploadFiles(bomItemPointer.id, attachments, (progress) =>
        thunkAPI.dispatch(
          newProjectActions.uploadProgress({
            operation: 'bomItem/uploadAttachments/' + bomItemPointer.id,
            progress,
          })
        )
      )
    } catch (err) {
      ShowException('project', err)
      return thunkAPI.rejectWithValue(err)
    }
  }
)
