/**
 * Group over the project working steps and return data like total sales princing and quantity
 * @param state the redux state
 * @return a object where the key is the project working step id and the value is the total sales pricing and quantity
 */

import { SumMoney } from 'model/Money'
import { MoneyDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'

export type WorkingStepStats = {
  [key: string]: {
    totalSalesPrice: MoneyDto
    totalQuantity: number
    partTypesInvolved: number
    partInstancesInvolved: number
    assembliesInvolved: number
    subAssembliesInvolved: number
    projectInvolved: number
  }
}

export const projectWorkingStepsStatsSelector = (state: RootAppState) => {
  // Get all the working steps price summaries to be able to know the prices for each working step
  const workingStepsPriceSummaries = Object.values(
    state.project.priceSummaries
  ).filter((x) => x.isWorkingStep)

  const defaultMoney = {
    ...state.project.partTypes[0]?.financial?.costPricePerItem,
    value: 0,
  }

  const initialStat = {
    totalSalesPrice: { ...defaultMoney },
    totalQuantity: 0,
    partTypesInvolved: 0,
    assembliesInvolved: 0,
    subAssembliesInvolved: 0,
    projectInvolved: 0,
    partInstancesInvolved: 0,
  }

  const workingStepsStats: WorkingStepStats = workingStepsPriceSummaries.reduce(
    (acc, curr) => {
      const workingStepType = curr.workingStep.primaryWorkingStep

      if (!acc[workingStepType]) {
        acc[workingStepType] = { ...initialStat }
      }

      acc[workingStepType].totalSalesPrice = SumMoney([
        acc[workingStepType].totalSalesPrice,
        curr.totalSalesPrice,
      ])

      return acc
    },
    {} as WorkingStepStats
  )

  // Scan all bomItems to get the number of part types, assemblies, sub assemblies and project involved
  // starting with project
  state.project.activeProject?.activities?.forEach((activity) => {
    const workingStepType = activity.primaryWorkingStep

    if (!workingStepsStats[workingStepType]) {
      workingStepsStats[workingStepType] = { ...initialStat }
    }

    workingStepsStats[workingStepType].projectInvolved += 1
    workingStepsStats[workingStepType].totalQuantity += 1
  })

  // assemblies
  state.project.assemblyHeadersIds.forEach((assemblyHeaderId) => {
    const assemblyHeader = state.project.assemblyHeaders[assemblyHeaderId]

    assemblyHeader.assemblyActivities.forEach((assemblyWorkingStep) => {
      const workingStepType = assemblyWorkingStep.primaryWorkingStep

      if (!workingStepsStats[workingStepType]) {
        workingStepsStats[workingStepType] = { ...initialStat }
      }

      workingStepsStats[workingStepType].totalQuantity +=
        assemblyHeader.financial?.totalProjectQuantity || 0
    })
  })

  // sub assemblies
  state.project.assemblyInstancesIds.forEach((assemblyInstanceId) => {
    const assemblyInstance = state.project.assemblyInstances[assemblyInstanceId]

    assemblyInstance.assemblyActivities.forEach((assemblyWorkingStep) => {
      const workingStepType = assemblyWorkingStep.primaryWorkingStep

      if (!workingStepsStats[workingStepType]) {
        workingStepsStats[workingStepType] = { ...initialStat }
      }

      // workingStepsStats[workingStepType].subAssembliesInvolved += 1
      workingStepsStats[workingStepType].totalQuantity +=
        assemblyInstance.financial?.totalProjectQuantity || 0
    })
  })

  // part types
  state.project.partTypeIds.forEach((partTypeId) => {
    const partType = state.project.partTypes[partTypeId]

    partType.activities.forEach((partTypeWorkingStep) => {
      const workingStepType = partTypeWorkingStep.primaryWorkingStep

      if (!workingStepsStats[workingStepType]) {
        workingStepsStats[workingStepType] = { ...initialStat }
      }

      workingStepsStats[workingStepType].partTypesInvolved += 1
      workingStepsStats[workingStepType].totalQuantity +=
        partType.financial?.totalProjectQuantity || 0
    })
  })

  // part instances -> not needed for now (since we don't have working steps on part instances level)
  // and may lead to count the same working step twice
  // state.project.partInstanceIds.forEach((partInstanceId) => {
  //   const partInstance = state.project.partInstances[partInstanceId]

  //   partInstance.workingSteps.forEach((partInstanceWorkingStep) => {
  //     const workingStepType = partInstanceWorkingStep.primaryWorkingStep

  //     if (!workingStepsStats[workingStepType]) {
  //       workingStepsStats[workingStepType] = { ...initialStat }
  //     }

  //     workingStepsStats[workingStepType].partInstancesInvolved += 1
  //     workingStepsStats[workingStepType].totalQuantity +=
  //       partInstance.financial?.totalProjectQuantity || 0
  //   })
  // })

  // sorting the results by total sales price
  const sortedWorkingStepsStats = Object.fromEntries(
    Object.entries(workingStepsStats).sort(([, a], [, b]) => {
      return b.totalSalesPrice.value - a.totalSalesPrice.value
    })
  )

  return sortedWorkingStepsStats
}
