import { RootAppState } from 'store/configureStore'

export const ProjectProcessFlowSelector = (state: RootAppState) => {
  const flowState = state.project.activeProject?.processFlowState

  if (flowState == null || flowState === 'NotInitialized') {
    return null
  }

  return flowState
}
