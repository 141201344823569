import {
  ActionReducerMapBuilder,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit'
import { isArray } from 'lodash'
import { ProjectState } from 'store/Project/ProjectTypes'
import { CostDriversActions } from './asyncActions/CostDriversActions'
import { SolveIssueAsync } from './asyncActions/SolveIssueAsync'
import { addRegressionTest } from './asyncActions/addRegressionTest'
import { applyWorkingStepToAllParentFilteredRows } from './asyncActions/applyWorkingStepToAllParentFilteredRows'
import { batchUpdateBomItems } from './asyncActions/batchUpdateBomItems'
import { cancelOrder } from './asyncActions/cancelOrder'
import { checkManufacturability } from './asyncActions/checkManufacturability'
import { deleteBomItemAttachment } from './asyncActions/deleteAttachment'
import { deleteBoMItem } from './asyncActions/deleteBoMItem'
import { downloadAttachment } from './asyncActions/downloadAttachment'
import { downloadBomItemFiles } from './asyncActions/downloadBomItemFiles'
import { downloadProjectDeliveryDateEstimation } from './asyncActions/downloadProjectDeliveryDateEstimation'
import { fetchBomItem } from './asyncActions/fetchBomItem'
import { fetchProject } from './asyncActions/fetchProject'
import { manualExportToErp } from './asyncActions/manualExportToErp'
import { recalculateProject } from './asyncActions/recalculateProject'
import { recalculateProjectWithUpdatedPrices } from './asyncActions/recalculateProjectWithUpdatedPrices'
import {
  forgetAsPurchasingPart,
  rememberAsPurchasingPart,
} from './asyncActions/rememberAsPurchasingPart'
import { saveBomItemIsPurchasingPart } from './asyncActions/saveBomItemIsPurchasingPart'
import { saveBomItemMaterialCostPrice } from './asyncActions/saveBomItemMaterialCostPrice'
import { saveBomItemMaterials } from './asyncActions/saveBomItemMaterials'
import { saveBomItemName } from './asyncActions/saveBomItemName'
import { saveBomItemQuantity } from './asyncActions/saveBomItemQuantity'
import { saveBomItemResource } from './asyncActions/saveBomItemResource'
import { saveBomItemTargetPrice } from './asyncActions/saveBomItemTargetPrice'
import { saveBomItemComment } from './asyncActions/saveComment'
import { saveNestingDirection } from './asyncActions/saveNestingDirection'
import { saveOrderNumber } from './asyncActions/saveOrderNumber'
import { saveProjectBatchSize } from './asyncActions/saveProjectBatchSize'
import { saveProjectDeliveryDate } from './asyncActions/saveProjectDeliveryDate'
import { saveProjectDeliveryDays } from './asyncActions/saveProjectDeliveryDays'
import { saveProjectReference } from './asyncActions/saveProjectReference'
import { saveProjectRequestedDeliveryDate } from './asyncActions/saveProjectRequestedDeliveryDate'
import { saveProjectStatus } from './asyncActions/saveProjectStatus'
import { toggleManufacturabilityFlag } from './asyncActions/toggleManufacturabilityFlag'
import { uploadAttachments } from './asyncActions/uploadAttachments'
import { uploadGeometry } from './asyncActions/uploadGeometry'
import { getActionPrefix } from './projectReducer'

export function registerAsyncOperationStatus(
  builder: ActionReducerMapBuilder<ProjectState>
) {
  builder.addMatcher(
    isPending(
      uploadGeometry,
      uploadAttachments,
      saveOrderNumber,
      saveProjectReference,
      saveProjectStatus,
      cancelOrder,
      saveProjectBatchSize,
      saveBomItemQuantity,
      saveBomItemName,
      saveBomItemMaterials,
      saveBomItemIsPurchasingPart,
      saveBomItemResource,
      saveBomItemMaterialCostPrice,
      fetchProject,
      fetchBomItem,
      deleteBoMItem,
      downloadBomItemFiles,
      addRegressionTest,
      checkManufacturability,
      rememberAsPurchasingPart,
      forgetAsPurchasingPart,
      deleteBomItemAttachment,
      downloadAttachment,
      saveNestingDirection,
      saveBomItemTargetPrice,
      saveBomItemComment,
      toggleManufacturabilityFlag,
      recalculateProject,
      recalculateProjectWithUpdatedPrices,
      applyWorkingStepToAllParentFilteredRows,
      CostDriversActions.ResetCostDriverDiscountToDefault,
      CostDriversActions.ResetCostDriverSurchargeToDefault,
      CostDriversActions.SaveCostDriverDiscountRatio,
      CostDriversActions.SaveCostDriverSurchargeRatio,
      batchUpdateBomItems,
      SolveIssueAsync,
      saveProjectRequestedDeliveryDate,
      downloadProjectDeliveryDateEstimation,
      manualExportToErp,
      saveProjectDeliveryDate,
      saveProjectDeliveryDays
    ),
    recordRequestInfo
  )
  builder.addMatcher(
    isFulfilled(
      uploadGeometry,
      uploadAttachments,
      saveOrderNumber,
      saveProjectReference,
      saveProjectStatus,
      cancelOrder,
      saveProjectBatchSize,
      saveBomItemQuantity,
      saveBomItemName,
      saveBomItemMaterials,
      saveBomItemIsPurchasingPart,
      saveBomItemResource,
      saveBomItemMaterialCostPrice,
      fetchProject,
      fetchBomItem,
      deleteBoMItem,
      downloadBomItemFiles,
      addRegressionTest,
      checkManufacturability,
      rememberAsPurchasingPart,
      forgetAsPurchasingPart,
      deleteBomItemAttachment,
      downloadAttachment,
      saveNestingDirection,
      saveBomItemTargetPrice,
      saveBomItemComment,
      toggleManufacturabilityFlag,
      recalculateProject,
      recalculateProjectWithUpdatedPrices,
      applyWorkingStepToAllParentFilteredRows,
      CostDriversActions.ResetCostDriverDiscountToDefault,
      CostDriversActions.ResetCostDriverSurchargeToDefault,
      CostDriversActions.SaveCostDriverDiscountRatio,
      CostDriversActions.SaveCostDriverSurchargeRatio,
      batchUpdateBomItems,
      SolveIssueAsync,
      saveProjectRequestedDeliveryDate,
      downloadProjectDeliveryDateEstimation,
      manualExportToErp,
      saveProjectDeliveryDate,
      saveProjectDeliveryDays
    ),
    recordRequestInfo
  )
  builder.addMatcher(
    isRejected(
      uploadGeometry,
      uploadAttachments,
      saveOrderNumber,
      saveProjectReference,
      saveProjectStatus,
      cancelOrder,
      saveProjectBatchSize,
      saveBomItemQuantity,
      saveBomItemName,
      saveBomItemMaterials,
      saveBomItemIsPurchasingPart,
      saveBomItemResource,
      saveBomItemMaterialCostPrice,
      fetchProject,
      fetchBomItem,
      deleteBoMItem,
      downloadBomItemFiles,
      addRegressionTest,
      checkManufacturability,
      rememberAsPurchasingPart,
      forgetAsPurchasingPart,
      deleteBomItemAttachment,
      downloadAttachment,
      saveNestingDirection,
      saveBomItemTargetPrice,
      saveBomItemComment,
      toggleManufacturabilityFlag,
      recalculateProject,
      recalculateProjectWithUpdatedPrices,
      applyWorkingStepToAllParentFilteredRows,
      CostDriversActions.ResetCostDriverDiscountToDefault,
      CostDriversActions.ResetCostDriverSurchargeToDefault,
      CostDriversActions.SaveCostDriverDiscountRatio,
      CostDriversActions.SaveCostDriverSurchargeRatio,
      batchUpdateBomItems,
      SolveIssueAsync,
      saveProjectRequestedDeliveryDate,
      downloadProjectDeliveryDateEstimation,
      manualExportToErp,
      saveProjectDeliveryDate,
      saveProjectDeliveryDays
    ),
    recordRequestError
  )

  function recordRequestError(state, action) {
    if (isArray(action.meta.arg) && action.meta.arg[0].file) {
      delete action.meta.arg
    }

    state.currentOperations[
      getActionPrefix(
        action,
        action.meta.arg ? action.meta.arg['bomItemPointer']?.id : undefined
      )
    ] = {
      ...action.meta,
      error: action.error,
    }
  }

  function recordRequestInfo(state, action) {
    if (isArray(action.meta.arg) && action.meta.arg[0].file) {
      delete action.meta.arg
    }

    state.currentOperations[
      getActionPrefix(
        action,
        action.meta.arg ? action.meta.arg['bomItemPointer']?.id : undefined
      )
    ] = { ...action.meta }
  }
}
