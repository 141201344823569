import { createAsyncThunk } from '@reduxjs/toolkit'
import { projectAPIBuilder } from '../asyncActions/BoMAsyncActions'
import { newProjectActions } from '../projectReducer'
import { fetchProject } from './fetchProject'
import { RootAppState } from 'store/configureStore'

export const saveProjectReference = createAsyncThunk<
  void,
  string,
  { state: RootAppState }
>(
  'project/saveProjectReference',
  async (projectReference: string, thunkAPI) => {
    const { projectId, api } = projectAPIBuilder(thunkAPI.getState)

    try {
      thunkAPI.dispatch(
        newProjectActions.setActiveProjectProperties({
          projectReference: projectReference,
        })
      )

      await api.SetProjectReference(projectId, projectReference)
    } catch (err) {
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      throw err
    }
  }
)
