import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { merge } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  WorkingStepDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectState } from '../../../../store/Project/ProjectTypes'
import { bomItemSelector } from '../selectors/bomItemSelector'

export const bomItemWorkingStepsReplacer: CaseReducer<
  ProjectState,
  PayloadAction<{
    bomItemPointer: BomItemPointer
    workingStepType: WorkingStepType
    workingStep: Partial<WorkingStepDto>
  }>
> = (state, action) => {
  if (action.payload.bomItemPointer.id === state.activeProject?.id) {
    state.activeProject.activities.forEach((ws) => {
      if (
        ws.primaryWorkingStep === ws.secondaryWorkingStep &&
        ws.primaryWorkingStep === action.payload.workingStepType
      ) {
        ws = merge(ws, action.payload.workingStep)
      }
    })

    return state
  }

  const bomItem = bomItemSelector(
    { project: state },
    action.payload.bomItemPointer
  )

  switch (bomItem?.type) {
    case BomItemType.assemblyType: {
      bomItem.assemblyActivities.forEach((ws) => {
        if (
          ws.primaryWorkingStep === ws.secondaryWorkingStep &&
          ws.primaryWorkingStep === action.payload.workingStepType
        ) {
          ws = merge(ws, action.payload.workingStep)
        }
      })
      break
    }
    case BomItemType.assemblyInstance:
      bomItem.assemblyActivities.forEach((ws) => {
        if (
          ws.primaryWorkingStep === ws.secondaryWorkingStep &&
          ws.primaryWorkingStep === action.payload.workingStepType
        ) {
          ws = merge(ws, action.payload.workingStep)
        }
      })
      break
    case BomItemType.partType:
    case BomItemType.partInstance: {
      bomItem.activities.forEach((ws) => {
        if (
          ws.primaryWorkingStep === ws.secondaryWorkingStep &&
          ws.primaryWorkingStep === action.payload.workingStepType
        ) {
          ws = merge(ws, action.payload.workingStep)
        }
      })
    }
  }

  return state
}
