import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectsController } from 'controllers/Projects/ProjectsController'
import LogRocket from 'logrocket'
import { ProjectEventType } from 'model/ProjectEventLevel'
import { NavigateFunction } from 'react-router-dom'
import { ProjectDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { TelemetryService } from 'services/Telemetry'
import { ShowException } from 'store/Application/appActions'
import { RootAppState } from 'store/configureStore'
import { getContext } from 'store/getContext'
import { Debugger } from 'utils/debugger'
import { projectOpenedEvent } from './projectOpenedEvent'
import { subscribeToProjectOperationsLogs } from './subscribeToProjectOperationsLogs'
import { subscribeToProjectNotifications } from './subscribeToUpdates'

export function getOperationsNotificationsGroupName(
  projectId: string,
  isBuyingPartyView: boolean
) {
  const operationsGroupName = `${projectId}_operations_${
    isBuyingPartyView ? 'buyers' : 'sellers'
  }`

  return operationsGroupName
}

export function getProjectNotificationsGroupName(
  projectId: string,
  isBuyingPartyView: boolean
) {
  const projectUpdateGroupName = `${projectId}_${
    isBuyingPartyView ? 'buyers' : 'sellers'
  }`

  return projectUpdateGroupName
}

export const fetchCurrentProject = createAsyncThunk<
  void,
  void,
  { state: RootAppState }
>('project/fetchCurrentProject', async (_, thunkAPI) => {
  const state = thunkAPI.getState()
  const projectId = state.project.activeProject?.id

  if (projectId) {
    thunkAPI.dispatch(fetchProject({ projectId }))
  }
})

let projectsController: ProjectsController = undefined

export function cancelFetchProject() {
  if (projectsController) {
    projectsController.CancelRequests()
  }
}

export const fetchProject = createAsyncThunk<
  ProjectDto,
  {
    projectId: string
    navigateFunc?: NavigateFunction
    trackEvent?: boolean
    reason?: ProjectEventType
    reasonExtra?: string
  },
  { state: RootAppState }
>(
  'project/fetchProject',
  async (
    { projectId, navigateFunc, trackEvent, reason, reasonExtra },
    thunkAPI
  ) => {
    const { partyId } = getContext(thunkAPI.getState)
    try {
      if (!projectId) {
        console.warn('fetch project received null projectId')
        return null
      }

      if (
        projectsController === undefined ||
        projectsController.partyId !== partyId
      ) {
        console.info('creating new projects controller')
        projectsController = new ProjectsController()
      }

      projectsController.CancelRequests()

      const projectFromBackend = await projectsController.GetProjectInfo(
        projectId,
        reason,
        reasonExtra
      )

      if (!projectFromBackend) {
        // the project request was aborted
        // console.info('fetch project returned null', projectId)
        return null
      }

      if (typeof projectFromBackend === 'string') {
        const err = new Error(
          'Unable to open the project due to an unexpected network response. Please check your network connection or antivirus settings, which might be blocking or modifying the response.',
          {
            cause: 'network response',
          }
        )

        ShowException('project', err)
        TelemetryService.getInstance().logError(err)

        if (trackEvent) {
          thunkAPI.dispatch(
            projectOpenedEvent({
              projectId: '00000000-0000-0000-0000-000000000000',
              success: false,
            })
          )
        }

        if (navigateFunc) {
          navigateFunc('/app')
        } else {
          // window.location.replace('/app')
        }

        return null
      }

      // if (
      //   !projectFromBackend &&
      //   !thunkAPI.getState().project?.activeProject?.id
      // ) {
      //   // if no project is returned from the backend and we don't have an active project
      //   // go back to projects page
      //   console.error('fetch project returned null', projectId)
      //   ShowException('project', 'unable to open the project')
      //   navigateFunc('/app')
      //   return null
      // }

      if (!window.location.href.includes(projectId)) {
        // users may not be in the page when the api response is received
        // so we need to check if the current url contains the received projectId
        // if not, we ignore the response
        console.warn(
          '(fetchProject): received project id is different than the one in the URL. Ignoring the api response',
          {
            currentUrl: window.location.href,
            projectId,
          }
        )

        return null
      }

      const projectUpdateGroupName = getProjectNotificationsGroupName(
        projectFromBackend.id,
        projectFromBackend.isBuyingPartyView
      )
      const operationsGroupName = getOperationsNotificationsGroupName(
        projectFromBackend.id,
        projectFromBackend.isBuyingPartyView
      )

      const checkConnectionGroup = (groupName: string) =>
        (thunkAPI.getState() as RootAppState).connection.groups?.includes(
          groupName
        )

      if (
        !checkConnectionGroup(projectUpdateGroupName) ||
        !checkConnectionGroup(operationsGroupName)
      ) {
        thunkAPI.dispatch(
          subscribeToProjectNotifications(projectUpdateGroupName)
        )
        thunkAPI.dispatch(subscribeToProjectOperationsLogs(operationsGroupName))
      }

      if (trackEvent) {
        thunkAPI.dispatch(
          projectOpenedEvent({
            projectId: projectFromBackend.id,
            success: true,
          })
        )
      }

      return projectFromBackend
    } catch (err) {
      console.info('error fetching the project', err)
      if (err['message']?.toLowerCase() === 'network error') {
        try {
          console.warn(
            `fetchProject network error. Status: ${err['status']}`,
            err['stack']
          )
          LogRocket.captureException(err as Error)
          return null
        } catch (err) {
          console.warn('fetchProject network error, LogRocket failed', err)
          return null
        }
      }

      if (err['message'] === 'Request aborted') {
        console.warn('fetchProject aborted')
        return null
      }

      if (trackEvent) {
        thunkAPI.dispatch(
          projectOpenedEvent({
            projectId: projectId,
            success: false,
          })
        )
      }

      if (
        (err['message']?.toLowerCase() === 'action not allowed' ||
          err['message']?.toLowerCase() === 'not found') &&
        navigateFunc
      ) {
        Debugger.Info(
          'routes: not allowed to open the project using workspace',
          partyId
        )
        navigateFunc?.('/app/workspaces', {
          replace: true,
          state: { projectNotAllowedId: projectId },
        })
      } else {
        ShowException('project', err)
        window.location.replace('/app')
      }

      return null
    }
  }
)
