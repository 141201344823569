import { Box, BoxProps, CircularProgress, Typography } from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import Spinning from './Spinner/Spinning'

type Props = {
  loading: boolean
  variant?: 'spinner' | 'app-logo' | 'loading-text'
  message?: string
  messageTKey?: string
  empty?: React.ReactNode
  component?: React.ElementType
  noMessage?: boolean
  style?: React.CSSProperties
  emptyBox?: BoxProps
  disablePadding?: boolean
}

export const LoadingContainer = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { t } = useTranslation()

  if (props.loading) {
    if (props.variant === 'app-logo') {
      return <Spinning message={props.message} />
    } else if (props.variant === 'loading-text') {
      return (
        <Box
          padding={props.disablePadding ? 0 : 1}
          className="loading-container"
          display="flex"
          alignItems="center"
        >
          {props.noMessage ? null : (
            <LocalizedTypography
              variant="body2"
              translationKey="common:loading"
              suffix="..."
            />
          )}
        </Box>
      )
    } else {
      return (
        <Box
          width={props.style?.width || '100%'}
          height={props.style?.height || '100%'}
          display="flex"
          alignItems="center"
          component={props.component}
          justifyContent="center"
          flexDirection="column"
          className="loading-container"
          style={props.style}
        >
          <Box
            p={props.disablePadding ? 0 : 3}
            component={props.component === 'tr' ? 'td' : 'span'}
          >
            <CircularProgress />
          </Box>
          {!props.noMessage && (
            <Typography component="p" style={{ marginTop: '1em' }}>
              {props.message ?? props.messageTKey
                ? t(props.messageTKey)
                : t('common:loading')}
            </Typography>
          )}
        </Box>
      )
    }
  }

  if (props.empty) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        // flexDirection="column"
        className="loading-container"
        {...props.emptyBox}
      >
        {props.empty}
      </Box>
    )
  }

  return <>{props.children}</>
}
