import { MaterialHeaderRow } from 'model/Project/BoMItemRow'
import { MaterialHeaderPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemPriceSummarySelector =
  (bomItemPointer: MaterialHeaderPointer) => (state: RootAppState) => {
    if (!state.project.activeProject) {
      return null
    }

    const materialHeader = bomItemSelector(
      state,
      bomItemPointer
    ) as MaterialHeaderRow

    if (!materialHeader) {
      console.warn(
        'bomItemPriceSummarySelector: bomItem not found',
        bomItemPointer
      )
      return undefined
    }

    return state.project.priceSummaries[materialHeader.materialId]
  }
