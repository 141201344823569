import _ from 'lodash'
import { PartInstanceRow, PartTypeRow } from 'model/Project/BoMItemRow'
import { WorkingStepDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  ManufacturabilityCheckFilter,
  PartTypeIdFilter,
  ResourceIdFilter,
} from 'store/Project/ProjectTypes'

export function showPartBasedOnPartTypeId(
  row: PartTypeRow | PartInstanceRow,
  filter: PartTypeIdFilter
): boolean {
  if ((filter.enabledFilters?.length || 0) === 0) {
    return true
  }

  return filter.enabledFilters?.includes(row.id)
}

export function showPartBasedOnResourceId(
  workingStepDtos: WorkingStepDto[],
  filter: ResourceIdFilter
): boolean {
  if ((filter.enabledFilters?.length || 0) === 0) {
    return true
  }

  const allResourcesIds = workingStepDtos.flatMap((x) => x.resource?.id)

  return _.intersection(allResourcesIds, filter.enabledFilters).length > 0
}

export function showPartBasedOnManufacturability(
  row: PartTypeRow | PartInstanceRow,
  filter: ManufacturabilityCheckFilter
): boolean {
  if (!filter.manufacturabilityStatus?.length) {
    return true
  }

  switch (filter.manufacturabilityStatus) {
    case 'All':
      return true
    case 'Manufacturable':
      return (
        row.isManufacturable === true &&
        row.manufacturableStatus !== 'NotApplicable'
      )
    case 'NotManufacturable':
      return (
        row.isManufacturable === false &&
        row.manufacturableStatus !== 'NotApplicable'
      )
    case 'Requested':
      return row.manufacturableStatus === 'Requested'
  }
}
