import { User } from '@auth0/auth0-react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetAvailableWorkspacesResponse } from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  fetchAvailableWorkspaces,
  fetchUserProfile,
} from './GettingStartedActions'
import {
  FactoryForm,
  GettingStartedState,
  UserForm,
} from './GettingStartedTypes'

const initialState: GettingStartedState = {
  availableWorkspaces: undefined,
  factoryForm: undefined,
  userForm: undefined,
  auth0User: undefined,
  userProfile: undefined,
  loading: true,
}

const gettingStartedSlice = createSlice({
  name: 'gettingStarted',
  initialState: initialState,
  reducers: {
    setAvailableWorkspaces(
      state,
      action: PayloadAction<GetAvailableWorkspacesResponse>
    ) {
      state.availableWorkspaces = action.payload
    },
    setFactoryForm(state, action: PayloadAction<FactoryForm>) {
      state.factoryForm = {
        ...state.factoryForm,
        ...action.payload,
      }
    },
    setUserForm(state, action: PayloadAction<Partial<UserForm>>) {
      state.userForm = {
        ...state.userForm,
        ...action.payload,
      }
    },
    setAuth0User(state, action: PayloadAction<User>) {
      state.auth0User = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfile.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchUserProfile.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(fetchAvailableWorkspaces.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAvailableWorkspaces.fulfilled, (state) => {
      state.loading = false
    })
  },
})

export default gettingStartedSlice.reducer
export const gettingStartedActions = gettingStartedSlice.actions
