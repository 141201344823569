import { SearchOutlined } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  Checkbox,
  InputAdornment,
  Popper,
  PopperProps,
  TextField,
  Typography,
} from '@mui/material'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { isEqual, uniq } from 'lodash'
import { matchSorter } from 'match-sorter'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { markText } from 'utils/textFilterUtils'
import { WorkingStepProfile } from '../Components/FactoryProfile'
import { GettingStartedBody } from '../Components/GettingStartedBody'
import { GettingStartedCard } from '../Components/GettingStartedCard'
import { GettingStartedPreviousAndNextButtons } from '../Components/GettingStartedPreviousAndNextButtons'
import { GettingStartedUserHeader } from '../Components/GettingStartedUserHeader'
import { GettingStartedStep } from '../Components/GettingStatedStep'
import { gettingStartedRoutes } from '../GettingStartedRoutes'
import { navigateToNextStep } from '../store/GettingStartedActions'
import { gettingStartedActions } from '../store/GettingStartedReducer'
import { gettingStartedSelectors } from '../store/GettingStartedSelectors'
import { FactoryForm } from '../store/GettingStartedTypes'
import {
  FactoryProfile,
  FactoryProfiles,
  GettingStartedWorkingStepsAvailable,
} from '../store/WorkingStepsProfiles'

const useStyles = makeStyles({ name: 'ActivityWorkingStep' })((theme) => ({
  workingStep: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    height: '120px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    textAlign: 'center',
  },
  workingStepList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  factoryInfo: {
    padding: theme.spacing(2),
  },
  profileList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  factoryProfiles: {
    padding: theme.spacing(2),
  },
}))

const PopperAlwaysOnTop = (props: PopperProps) => {
  return <Popper {...props} placement="bottom" />
}

export function WorkingStepsStep() {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()

  const dispatch = useAppDispatch()
  const factoryForm = useAppSelector(
    gettingStartedSelectors.getFactoryFormSelector,
    isEqual
  )

  const handleAddWorkingStep = (workingStepTypes: WorkingStepType[]) => {
    const newAvailableWorkingsteps = uniq(workingStepTypes)

    dispatch(
      gettingStartedActions.setFactoryForm({
        availableWorkingSteps: newAvailableWorkingsteps,
      } as FactoryForm)
    )
  }

  const handleRemoveWorkingStep = (workingStepType: WorkingStepType[]) => {
    const newAvailableWorkingsteps = factoryForm.availableWorkingSteps.filter(
      (x) => !workingStepType.includes(x)
    )

    dispatch(
      gettingStartedActions.setFactoryForm({
        availableWorkingSteps: newAvailableWorkingsteps,
      })
    )
  }

  const navigate = useNavigate()

  const handleFactoryFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(navigateToNextStep(navigate))
  }

  const allWorkingSteps = useMemo(
    () =>
      GettingStartedWorkingStepsAvailable.sort((a, b) =>
        getLocalizedWorkingStep(a).localeCompare(getLocalizedWorkingStep(b))
      ),
    [getLocalizedWorkingStep]
  )

  const activeProfiles = useAppSelector(
    gettingStartedSelectors.getChosenProfiles,
    isEqual
  )

  const addToActiveProfiles = (profileKey: keyof typeof FactoryProfiles) => {
    dispatch(
      gettingStartedActions.setFactoryForm({
        chosenTemplates: [...activeProfiles, profileKey],
      })
    )
  }

  const removeFromActiveProfiles = (
    profileKey: keyof typeof FactoryProfiles
  ) => {
    dispatch(
      gettingStartedActions.setFactoryForm({
        chosenTemplates: activeProfiles.filter((x) => x !== profileKey),
      })
    )
  }

  // check if none of the active working steps are available in the selected profiles
  const profilesToRemove = activeProfiles.filter((profileKey) => {
    return !FactoryProfiles[profileKey].workingSteps.some((ws) =>
      factoryForm.availableWorkingSteps.includes(ws)
    )
  })

  if (profilesToRemove?.length) {
    const newActiveProfiles = activeProfiles.filter(
      (x) => !profilesToRemove.includes(x)
    )

    dispatch(
      gettingStartedActions.setFactoryForm({
        chosenTemplates: newActiveProfiles,
      })
    )
  }

  return (
    <GettingStartedStep>
      <GettingStartedCard>
        <GettingStartedUserHeader />
        <GettingStartedBody>
          <LocalizedTypography
            translationKey="getting-started:working-steps-title"
            variant="h5"
            fontWeight={'bold'}
            sx={{
              textAlign: 'center',
              top: '-16px',
              zIndex: 2,
              padding: 2,
            }}
          >
            Working steps
          </LocalizedTypography>

          <div className={classes.factoryProfiles}>
            <LocalizedTypography
              translationKey="getting-started:working-steps-selector-profile-title"
              variant="h6"
              component="h6"
            >
              factory profiles
            </LocalizedTypography>
            <LocalizedTypography
              translationKey="getting-started:working-steps-select-profile"
              variant="body2"
            >
              select the profiles that best represents your factory
            </LocalizedTypography>
            <ul className={classes.profileList}>
              {Object.keys(FactoryProfiles).map((key) => {
                const factoryProfile = FactoryProfiles[key]
                return (
                  <WorkingStepProfile
                    key={key}
                    factoryProfile={factoryProfile}
                    onAdd={(factoryProfile: FactoryProfile) => {
                      handleAddWorkingStep(
                        (factoryForm.availableWorkingSteps || []).concat(
                          factoryProfile.workingSteps
                        )
                      )
                      addToActiveProfiles(key)
                    }}
                    onRemove={(factoryProfile: FactoryProfile) => {
                      handleRemoveWorkingStep(factoryProfile.workingSteps)

                      removeFromActiveProfiles(key)
                    }}
                    isActive={() => {
                      return activeProfiles?.includes(key)
                    }}
                  />
                )
              })}
            </ul>
          </div>

          <LocalizedTypography
            translationKey="getting-started:working-steps-fine-tune"
            variant="body1"
            style={{ textAlign: 'left' }}
            className={classes.factoryInfo}
          >
            Fine tune the available working steps by adding specific ones or
            click in the working step to remove it
          </LocalizedTypography>
          <form onSubmit={handleFactoryFormSubmit} id="factory-form">
            <GettingStartedBody>
              <Autocomplete
                multiple
                value={factoryForm?.availableWorkingSteps || []}
                onChange={(e, value) => handleAddWorkingStep(value)}
                options={allWorkingSteps}
                PopperComponent={PopperAlwaysOnTop}
                disableCloseOnSelect
                autoHighlight
                renderTags={() => null}
                style={{
                  alignSelf: 'flex-end',
                  width: '40%',
                }}
                // renderTags={(value, getTagProps) => {
                //   return value.map((option, index) => {
                //     const { key, ...tagProps } = getTagProps({ index })
                //     return (
                //       <Chip
                //         key={key}
                //         label={getLocalizedWorkingStep(option)}
                //         {...tagProps}
                //       />
                //     )
                //   })
                // }}
                filterOptions={(options, params) => {
                  const translatedOptions = options.map((option) => {
                    return {
                      value: option,
                      label: getLocalizedWorkingStep(option),
                    }
                  })

                  const filtered = matchSorter(
                    translatedOptions,
                    params.inputValue,
                    {
                      keys: ['label'],
                    }
                  )

                  return filtered.map((x) => x.value)
                }}
                renderOption={(baseProps, option, state) => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const { key, ...rest } = baseProps as any
                  return (
                    <li
                      key={key}
                      {...rest}
                      style={{ display: 'flex', gap: '8px', width: '100%' }}
                    >
                      <Checkbox checked={state.selected} />
                      <WorkingStepIconSvg
                        workingStepType={option}
                        attributes={{
                          width: '32px',
                        }}
                        disableTitle
                      />
                      <Typography
                        style={{
                          backgroundColor: 'transparent',
                          marginLeft: '8px',
                          width: '100%',
                          whiteSpace: 'nowrap',
                          display: 'flex',
                        }}
                      >
                        {markText(
                          getLocalizedWorkingStep(option),
                          state.inputValue
                        )}
                      </Typography>
                    </li>
                  )
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t('common:working-steps')}
                      placeholder={t(
                        'common:select-working-steps',
                        'select working steps'
                      )}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlined />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )
                }}
              />

              <ul className={classes.workingStepList}>
                {[...(factoryForm?.availableWorkingSteps || [])]
                  .sort((a, b) => {
                    return getLocalizedWorkingStep(a).localeCompare(
                      getLocalizedWorkingStep(b)
                    )
                  })
                  .map((workingStep) => (
                    <li key={workingStep}>
                      <Button
                        onClick={() => handleRemoveWorkingStep([workingStep])}
                        className={classes.workingStep}
                      >
                        <div className={classes.title}>
                          <Typography variant="body2">
                            {getLocalizedWorkingStep(workingStep)}
                          </Typography>
                        </div>
                        <div>
                          <WorkingStepIconSvg
                            workingStepType={workingStep}
                            attributes={{
                              width: '56px',
                            }}
                          />
                        </div>
                      </Button>
                    </li>
                  ))}
              </ul>
            </GettingStartedBody>
            <GettingStartedPreviousAndNextButtons
              handlePrevious={() =>
                navigate(gettingStartedRoutes.factorySetup.path, {
                  state: {
                    from: 'previous',
                  },
                })
              }
              submitButton={factoryForm?.availableWorkingSteps?.length > 0}
              currentStep={3}
            />
          </form>
        </GettingStartedBody>
      </GettingStartedCard>
    </GettingStartedStep>
  )
}
