import {
  DeprecatedThemeOptions,
  ThemeOptions,
  createTheme,
  responsiveFontSizes,
} from '@mui/material'
import { deDE, enUS, frFR, nlNL, ptBR } from '@mui/material/locale'
import Logo from '_icons/logo/QF_logoVrt.png'
import BackgroundImage from 'images/bg_metal_tekening.png'
import _ from 'lodash'
import { CSSProperties } from 'react'

declare module '@mui/material' {
  interface VerticalMenu {
    backgroundColor?: string
    color?: string
    boxShadow?: string
  }

  interface IconColors {
    outline?: string
    highlight?: string
    white?: string
  }

  interface Theme {
    logoSrc?: string
    verticalMenu?: VerticalMenu
    BoMThumbnail: {
      mixBlendMode: CSSProperties['mixBlendMode']
    }
    googleFonts?: string
    backgroundImageSrc?: string
    iconColors?: IconColors
  }

  interface DeprecatedThemeOptions {
    logoSrc?: string
    verticalMenu?: VerticalMenu
    BoMThumbnail?: {
      mixBlendMode: CSSProperties['mixBlendMode']
    }
    googleFonts?: string
    backgroundImageSrc?: string
    iconColors?: IconColors
  }

  interface ThemeOptions {
    logoSrc?: string
    verticalMenu?: VerticalMenu
    breakpoints?: {
      values: {
        xs: number
        sm: number
        md: number
        lg: number
        xl: number
        xxl: number
        xxxl: number
        xxxxl: number
      }
    }
    BoMThumbnail?: {
      mixBlendMode: CSSProperties['mixBlendMode']
    }
    googleFonts?: string
    backgroundImageSrc?: string
    iconColors?: IconColors
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true
    xxxl: true
    xxxxl: true
  }
}

const defaultOverrides: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
      xxxl: 2560,
      xxxxl: 3840,
    },
  },
  verticalMenu: {
    backgroundColor: '#fafafa',
    boxShadow: '0px 0 2px 0 #a8a7a6',
  },
  iconColors: {
    outline: '#80878F',
    highlight: '#05B5CE',
    white: '#FFFFFF',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          a: {
            color: 'inherit',
          },
          body: {
            backgroundColor: '#fafafa',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0 2px 0 #a8a7a6',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiBadge: {
      defaultProps: {
        overlap: 'rectangular',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
  googleFonts: 'Montserrat',
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 12,
    button: {
      textTransform: 'none',
    },
  },
}

const createAppTheme = (
  rh24ThemeOptions: DeprecatedThemeOptions,
  language: string
) => {
  let lang = enUS

  switch (language) {
    case 'pt-BR':
      lang = ptBR
      break
    case 'nl-NL':
      lang = nlNL
      break
    case 'fr-FR':
      lang = frFR
      break
    case 'de-DE':
      lang = deDE
      break
    default:
      lang = enUS
      break
  }

  return responsiveFontSizes(
    createTheme(_.merge({}, defaultOverrides, rh24ThemeOptions), lang)
  )
}

const darkBlueTheme: ThemeOptions = {
  logoSrc: `${window.location.origin}${Logo}`,
  BoMThumbnail: {
    mixBlendMode: 'normal',
  },
  googleFonts: 'Montserrat',
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 12,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          body: {
            backgroundImage: 'none',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorTransparent: {
          backgroundColor: '#3a5575',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap' as never,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
    },
  },

  verticalMenu: {
    backgroundColor: '#24344d',
    boxShadow: '0px 0 2px 0 #a8a7a6',
  },

  iconColors: {
    white: 'transparent',
    highlight: '#05B5CE',
    outline: '#FFF',
  },

  palette: {
    mode: 'dark',
    primary: {
      main: '#67c2ff',
    },
    secondary: {
      main: '#2ec5d3',
    },
    background: {
      default: '#202f45',
      paper: '#24344d',
    },
    error: {
      main: '#f44337',
    },
    warning: {
      main: '#ffa727',
    },
    info: {
      main: '#29b6f7',
    },
    success: {
      main: '#4bb952',
    },
    divider: 'rgba(255,255,255,0.13)',
  },
}

const lightDefaultTheme: ThemeOptions = {
  BoMThumbnail: {
    mixBlendMode: 'multiply',
  },
  googleFonts: 'Montserrat',
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 12,
  },
  verticalMenu: {
    // backgroundColor: colors.grey[200],
    backgroundColor: '#fafafa',
    boxShadow: '0px 0 2px 0 #a8a7a6',
  },
  backgroundImageSrc: `${window.location.origin}${BackgroundImage}`,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          body: {
            backgroundColor: '#e9d9d9',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0 2px 0 #a8a7a6',
          borderRadius: 0,
          overflow: 'inherit',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap' as never,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap' as never,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
    },
  },
  iconColors: {
    outline: '#80878F',
    highlight: '#05B5CE',
    white: '#FFFFFF',
  },
  palette: {
    primary: { main: '#5B5AC5' },
    secondary: { main: '#E63F4D' },
    error: { main: '#E63F4D' },
    success: { main: '#06BA63' },
    text: {
      primary: '#444443',
      secondary: 'rgba(52, 52, 52, 0.7)',
    },
    background: {
      default: '#fafafa',
      paper: '#fff',
    },
  },
  logoSrc: `${window.location.origin}${Logo}`,
}

export type BuiltInThemes = 'light' | 'darkBlue'

export { createAppTheme, darkBlueTheme, lightDefaultTheme as rh24WebAppTheme }
