import { store } from './configureStore'

export const GetPartyId = () => store.getState().user?.organizationContext?.id
export const GetCurrentProjectId = () =>
  store.getState().project?.activeProject?.id
export const GetContext = () => ({
  partyId: GetPartyId(),
  projectId: GetCurrentProjectId(),
})

export const StoreUtils = {
  GetPartyId,
  GetCurrentProjectId,
  GetContext,
}
