import { BaseController } from 'controllers/BaseController'
import { InternalAPI } from './InternalAPI'

export class AttachmentAPI extends InternalAPI {
  constructor(
    organizationId: string,
    projectId: string,
    private onRequestChanged
  ) {
    super()
    this.baseAddress += `/api/parties/${organizationId}/projects/${projectId}`
  }

  _aggregateTypeBaseUrl = (aggregateId: string) => {
    return `/items/${aggregateId}/attachments`
  }

  async saveAttachments(
    files: File[],
    aggregateId: string,
    onUploadProgress: (progress: number) => void
  ) {
    return await this.UploadAsync<void>(
      {
        id: `upload-attachment-${aggregateId}`,
        relativePath: this._aggregateTypeBaseUrl(aggregateId),
        data: files,
        onUploadProgress: onUploadProgress,
        onRequestChange: this.onRequestChanged,
      },
      'files'
    )
  }

  async deleteAttachment(bomItemId: string, attachmentName: string[]) {
    const deleteReturn = await this.DeleteAsync({
      id: `delete-attachment-${bomItemId}-${attachmentName}`,
      relativePath: `${this._aggregateTypeBaseUrl(bomItemId)}`,
      data: attachmentName,
      onRequestChange: this.onRequestChanged,
    })

    return deleteReturn
  }

  async downloadFile(aggregateId: string, fileName: string) {
    return await this.DownloadAsync({
      id: `downloading-attachment-${aggregateId}-${fileName}`,
      relativePath: `${this._aggregateTypeBaseUrl(aggregateId)}/${fileName}`,
      onRequestChange: this.onRequestChanged,
    })
  }
}

export class BomItemAttachmentsController extends BaseController<AttachmentAPI> {
  constructor(partyId: string, private projectId: string) {
    super(
      (onRequestChanged) =>
        new AttachmentAPI(partyId, projectId, onRequestChanged)
    )
  }

  public async UploadFiles(
    bomItemId: string,
    files: File[],
    onProgressCallback: (progress: number) => void
  ) {
    try {
      return await this.api.saveAttachments(
        files,
        bomItemId,
        onProgressCallback
      )
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async DownloadFile(bomItemId: string, fileName: string) {
    try {
      return await this.api.downloadFile(bomItemId, fileName)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async DeleteFile(bomItemId: string, fileNames: string[]) {
    try {
      return await this.api.deleteAttachment(bomItemId, fileNames)
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
