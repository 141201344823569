import { uniqBy } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BoMItemActivityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemActivitiesSelector =
  ({
    bomItemPointer,
    getSummaryOfParts,
    includeOwnWorkingSteps,
  }: {
    bomItemPointer: BomItemPointer
    getSummaryOfParts?: boolean
    includeOwnWorkingSteps?: boolean
  }) =>
  (state: Partial<RootAppState>) => {
    if (!bomItemPointer) return null

    const projectId = state.project.activeProject?.id

    if (projectId === bomItemPointer.id) {
      return state.project.activeProject.activities
    }

    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem?.type) {
      return null
    }

    let workingStepsToReturn: BoMItemActivityDto[] = undefined

    switch (bomItem?.type) {
      case BomItemType.assemblyType:
        if (getSummaryOfParts) {
          workingStepsToReturn = uniqBy(
            bomItem.partTypeActivities.concat(
              includeOwnWorkingSteps ? bomItem.assemblyActivities : []
            ),
            (x) => x.primaryWorkingStep
          )
        } else {
          workingStepsToReturn = bomItem.assemblyActivities
        }
        break
      case BomItemType.assemblyInstance:
        if (getSummaryOfParts) {
          workingStepsToReturn = uniqBy(
            bomItem.partTypeActivities.concat(
              includeOwnWorkingSteps ? bomItem.assemblyActivities : []
            ),
            (x) => x.primaryWorkingStep
          )
        } else {
          workingStepsToReturn = bomItem.assemblyActivities
        }
        break
      case BomItemType.partType:
      case BomItemType.partInstance:
        workingStepsToReturn = bomItem.activities
        break
      case BomItemType.materialHeader:
        workingStepsToReturn = [bomItem.workingStep]
        break
      case BomItemType.routingHeader:
        // routing header has another data type and should be handled separately
        return []
      case BomItemType.project:
        workingStepsToReturn = bomItem.activities
        break
    }

    return [...workingStepsToReturn]
  }
