import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemType } from 'model/Project/BoMItemRow'
import { ProjectModel } from 'model/ProjectModel'
import { RootAppState } from 'store/configureStore'
import { projectAPIBuilder } from '../asyncActions/BoMAsyncActions'
import { newProjectActions } from '../projectReducer'
import { fetchProject } from './fetchProject'

export const saveProjectBatchSize = createAsyncThunk<
  void,
  number,
  { state: RootAppState }
>('project/saveProjectBatchSize', async (batchSize: number, thunkAPI) => {
  const { projectId, api } = projectAPIBuilder(thunkAPI.getState)

  thunkAPI.dispatch(
    newProjectActions.setActiveProjectProperties({
      financial: { quantity: parseInt(batchSize.toString()) },
    } as Partial<ProjectModel>)
  )

  thunkAPI.dispatch(
    newProjectActions.resetBomItemFinancials([
      {
        id: projectId,
        type: BomItemType.project,
      },
    ])
  )

  thunkAPI.dispatch(
    newProjectActions.updateOperationsFlags({
      isNesting: true,
    })
  )
  try {
    return await api.SetProjectBatchSize(projectId, batchSize)
  } catch (err) {
    thunkAPI.dispatch(fetchProject({ projectId: projectId }))
    throw err
  }
})
