import { RootAppState } from 'store/configureStore'

/**
 * selects the root assemblies ids (also called assembly headers ids, since they have the same id and infos)
 * @param state
 * @returns
 */
export const rootAssembliesSelector = (state: RootAppState) => {
  return state.project.filteredAssemblyHeaderIds
}
