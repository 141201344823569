import { BomItemType, MaterialHeaderRow } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'
import { BomItemPointer } from '../../../../model/Project/BomItemPointer'

export const bomItemCuttingPlansSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    if (bomItem?.type === BomItemType.partType) {
      const headers = Object.values(state.project.materialHeaders || {}).filter(
        (x) => x.type === BomItemType.materialHeader
      ) as MaterialHeaderRow[]

      const cuttingPlans = headers.flatMap((x) =>
        x.cuttingPlans?.filter((plan) =>
          plan.partTypeIds.includes(bomItemPointer.id)
        )
      )

      return cuttingPlans
    }

    return null
  }
