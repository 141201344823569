/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import cadex, {
  ModelData_Assembly,
  ModelData_Instance,
  ModelData_Part,
} from '@cadexchanger/web-toolkit'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { SceneDataStore } from '../store/SceneDataStore'
import { Rh24SceneNode } from '../store/types/Rh24SceneNode'
import { FaceDataVisitor } from './FacesDataVisitor'

export class ModelLoaderVisitor extends cadex.ModelData_SceneGraphElementVisitor {
  private repMask: cadex.ModelData_RepresentationMask
  private rootNode: Rh24SceneNode[]
  private sceneNodeFactory: cadex.ModelPrs_SceneNodeFactory =
    new cadex.ModelPrs_SceneNodeFactory()

  private sceneDataStore: SceneDataStore

  private bomItemPointer: BomItemPointer

  public sceneNodeMap = new Map<string, Array<Rh24SceneNode>>()

  public GetSceneNodeItem(id: string) {
    return this.sceneNodeMap.get(id)
  }

  constructor(
    repMask: cadex.ModelData_RepresentationMask,
    sceneStore: SceneDataStore,
    bomItemPointer: BomItemPointer
  ) {
    super()
    this.repMask = repMask
    this.sceneDataStore = sceneStore
    this.rootNode = [new cadex.ModelPrs_SceneNode()]
    this.bomItemPointer = bomItemPointer
  }

  public getRootNode() {
    return this.rootNode[this.rootNode.length - 1]
  }

  public addToSceneMap(element: Rh24SceneNode) {
    this.sceneDataStore.addToSceneMap(element)
  }

  private addToScene(element: cadex.ModelData_SceneGraphElement) {
    if (!element.uuid) {
      element.uuid = new cadex.Base_Uuid(window.crypto.randomUUID())
    }

    const sceneNodeToAdd =
      this.sceneDataStore.createFromSceneGraphElement(element)

    this.getRootNode().addChildNode(sceneNodeToAdd)

    if (
      element instanceof cadex.ModelData_Assembly ||
      element instanceof cadex.ModelData_Instance
    ) {
      this.rootNode.push(sceneNodeToAdd)
    }

    return sceneNodeToAdd
  }

  public visitAssemblyEnter(
    assembly: ModelData_Assembly
  ): boolean | Promise<boolean> {
    const node = this.addToScene(assembly)

    this.addToSceneMap(node)

    return true
  }
  public visitAssemblyLeave(
    assembly: ModelData_Assembly
  ): void | Promise<void> {
    this.rootNode.pop()
  }

  public visitInstanceEnter(
    instance: ModelData_Instance
  ): boolean | Promise<boolean> {
    const instanceNode = this.addToScene(instance)

    this.addToSceneMap(instanceNode)

    return true
  }
  public visitInstanceLeave(
    instance: ModelData_Instance
  ): void | Promise<void> {
    this.rootNode.pop()
  }

  public async visitPart(part: ModelData_Part): Promise<void> {
    const nodeAdded = this.addToScene(part)

    const representation = part.representation(this.repMask)

    if (representation) {
      const representationNode =
        this.sceneNodeFactory.createNodeFromRepresentation(
          representation
        ) as Rh24SceneNode

      if (part.uuid) {
        representationNode.elementId = part.uuid.toString()
      } else {
        console.warn('part without uuid', part)
      }

      representationNode.nodeType = 'PART'
      representationNode.representation = representation

      nodeAdded.addChildNode(representationNode)

      // visit subshapes to grab FACES data
      if (this.bomItemPointer.type === BomItemType.partType) {
        const representationVisitor = new FaceDataVisitor(
          representationNode,
          this.sceneDataStore
        )

        await representation.accept(representationVisitor)
      }
    }

    this.addToSceneMap(nodeAdded)

    return
  }
}
