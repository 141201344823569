import { Route, Routes } from 'react-router-dom'
import { WelcomeStep } from './Steps/1-Welcome'
import { ValidateEmailStep } from './Steps/2-ValidateEmailStep'
import { FactoryStep } from './Steps/3-Factory'
import { WorkingStepsStep } from './Steps/4-WorkingSteps'
import { ReviewAndConfirmStep } from './Steps/5-ReviewAndConfirm'
import { NextStepsStep } from './Steps/6-NextSteps'
import { JoinWorkspaceStep } from './Steps/7-JoinWorkspace'

export type GettingStartedRouteDef = {
  path: string
  menuTranslationKey: string
  index: number
}

export type GettingStartedRouteKeys =
  | 'welcome'
  | 'validateEmail'
  | 'factorySetup'
  | 'workingSteps'
  | 'confirm'
  | 'nextStep'
  | 'joinWorkspace'

export const gettingStartedRoutes: Record<
  GettingStartedRouteKeys,
  GettingStartedRouteDef
> = {
  welcome: {
    path: '/getting-started',
    menuTranslationKey: 'menu-create-user',
    index: 0,
  },

  validateEmail: {
    path: '/getting-started/validate-email',
    menuTranslationKey: 'menu-validate-email',
    index: 1,
  },

  factorySetup: {
    path: '/getting-started/factory-setup',
    menuTranslationKey: 'menu-factory-setup',
    index: 2,
  },

  workingSteps: {
    path: '/getting-started/working-steps',
    menuTranslationKey: 'menu-working-steps',
    index: 3,
  },

  confirm: {
    path: '/getting-started/confirm',
    menuTranslationKey: 'menu-completed',
    index: 4,
  },

  nextStep: {
    path: '/getting-started/next-steps',
    menuTranslationKey: 'menu-next-steps',
    index: 5,
  },

  joinWorkspace: {
    path: '/getting-started/join-workspace',
    menuTranslationKey: 'menu-join-workspace',
    index: 6,
  },
}

export const GettingStartedRoutes = () => {
  const normalizeRoute = (route: string) =>
    route.replace('/getting-started', '')

  return (
    <Routes>
      <Route index element={<WelcomeStep />} />
      <Route
        path={normalizeRoute(gettingStartedRoutes.validateEmail.path)}
        element={<ValidateEmailStep />}
      />
      <Route
        path={normalizeRoute(gettingStartedRoutes.factorySetup.path)}
        element={<FactoryStep />}
      />
      <Route
        path={normalizeRoute(gettingStartedRoutes.workingSteps.path)}
        element={<WorkingStepsStep />}
      />
      <Route
        path={normalizeRoute(gettingStartedRoutes.confirm.path)}
        element={<ReviewAndConfirmStep />}
      />
      <Route
        path={normalizeRoute(gettingStartedRoutes.nextStep.path)}
        element={<NextStepsStep />}
      />
      <Route
        path={normalizeRoute(gettingStartedRoutes.joinWorkspace.path)}
        element={<JoinWorkspaceStep />}
      />
    </Routes>
  )
}
