import RestUtils from '../../utils/restUtils'
import {
  EndNetwork,
  ErrorMessage,
  GetAccessToken,
  StartNetwork,
} from '../Application/appActions'
import { APICallArgs, ApplicationActionTypes } from '../Application/appState'

function readAsString(callback: (string) => void, message: Blob) {
  const fr = new FileReader()

  fr.onload = (e) => {
    callback(JSON.parse(e.target.result.toString()).message)
  }

  fr.readAsText(message)
}

const API =
  ({ dispatch }) =>
  (
    next: (param: {
      type: ApplicationActionTypes
      payload: APICallArgs
    }) => void
  ) =>
  async (action: { type: ApplicationActionTypes; payload: APICallArgs }) => {
    if (!action) return null
    if (action.type !== ApplicationActionTypes.API || !action.payload) {
      return next(action)
    }

    const { label } = action.payload || {}

    const {
      method,
      url,
      data,
      additionalHeaders,
      isFileUpload,
      isFileDownload,
      onSuccess,
      onError,
      onProgress,
      responseType,
      uploadParameterName,
    } = action.payload.configuration

    dispatch(StartNetwork(label))

    let accessToken = ''
    try {
      accessToken = await GetAccessToken()
    } catch (err) {
      console.error(err)
      window.location.replace('/error')
      return
    }

    RestUtils.request(
      method,
      url,
      data,
      isFileUpload,
      isFileDownload,
      onProgress,
      additionalHeaders,
      accessToken,
      responseType,
      uploadParameterName
    )
      .then((response) => {
        if (!response.content) return null

        try {
          return JSON.parse(response.content)
        } catch (err) {
          console.warn(
            'error parsing api response, return raw content',
            err,
            response.content
          )
          return response.content
        }
      })
      .then((resp) => {
        onSuccess && onSuccess(resp)
      })
      .catch((err) => {
        console.error(err)
        if (err !== undefined && !onError) {
          if (err['message'] instanceof Blob) {
            readAsString(
              (message) => ErrorMessage('error', message),
              err['message']
            )
          }

          if (err.response.data) {
            ErrorMessage('Error', err.response?.data?.message)
          } else {
            console.warn(err)
            ErrorMessage('Error', err)
          }
        } else if (!onError) {
          ErrorMessage('Error', 'Undefined error')
        }

        if (onError) {
          if (!err) {
            onError('request has no response', 500)
          } else {
            // if (err.response?.data instanceof Blob) {
            //   readAsString(
            //     (message) => onError(message, err.statusCode),
            //     err.response.data
            //   )
            // } else {
            onError(
              err.response?.data?.message ??
                err.error ??
                err ??
                'request has no response',
              err.statusCode
            )
            // }
          }
        }
      })
      .finally(() => dispatch(EndNetwork(label)))

    return next(action)
  }

export default API
