import {
  ProjectStateAsString,
  ProjectStatus,
} from 'controllers/Project/ProjectStateMachine'
import _ from 'lodash'
import {
  AssemblyInstanceRow,
  BomItemType,
  PartInstanceRow,
  ProjectRow,
} from 'model/Project/BoMItemRow'
import {
  AssemblyHeaderDto,
  PriceSummaryDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  AssemblyStructureDto,
  ProjectDto,
} from '../../services/APIs/InternalAPI/internal-api.contracts'
import { BoMItemsFilter, ProjectState } from './ProjectTypes'

/**
 * return the working steps that should be show in WorkingStepFilter
 * @param assemblyHeaders assembly headers that came from server
 */
export function GetWorkingStepFilters(assemblyHeaders: AssemblyHeaderDto[]) {
  const workingStepFilters = new Set<WorkingStepType>()

  assemblyHeaders.forEach((x) => {
    _.union(
      x.assembly.partTypeActivities
      // x.assembly.assemblyWorkingStepDtos
    ).forEach((x) => workingStepFilters.add(x.primaryWorkingStep))
  })

  return Array.from(workingStepFilters)
}

export function SetFilter(
  state: ProjectState,
  boMItemFilter: BoMItemsFilter
): ProjectState {
  const newFilters: Partial<ProjectState['filters']> = {}

  switch (boMItemFilter.type) {
    case 'IssuesFilter': {
      newFilters.byIssues = {
        ...state.filters.byIssues,
        filter: {
          ...state.filters.byIssues.filter,
          ...boMItemFilter,
        },
      }
      break
    }
    case 'MaterialKeywordsFilter': {
      newFilters.byMaterialKeywords = {
        ...state.filters.byMaterialKeywords,
        filter: {
          ...state.filters.byMaterialKeywords.filter,
          ...boMItemFilter,
        },
      }
      break
    }
    case 'WorkingStepsFilter': {
      newFilters.byWorkingStep = {
        ...state.filters.byWorkingStep,
        filter: {
          ...state.filters.byWorkingStep.filter,
          ...boMItemFilter,
        },
      }
      break
    }
  }

  const newState = {
    ...state,
    filters: {
      ...state.filters,
      ...newFilters,
    },
  }

  return newState
}

export const getPriceSummaryFromAPIResponse = (
  priceSummaries: PriceSummaryDto[]
): Record<string, PriceSummaryDto> => {
  const ps: Record<string, PriceSummaryDto> = {}

  priceSummaries
    .sort((a, b) => (a.description < b.description ? -1 : 1))
    .forEach((priceSummary) => {
      const priceSummaryKey = getPriceSummaryKey(priceSummary)
      ps[priceSummaryKey] = Object.assign(
        { type: 'PriceSummaryDto' },
        { ...priceSummary }
      )
    })

  return ps
}

export const getAssemblyStructure = (
  rootAssembly: AssemblyStructureDto,
  parentId: string,
  selectedBomItemId: string,
  currentState: ProjectState,
  parentType?: BomItemType.assemblyInstance | BomItemType.assemblyType
): {
  assemblyInstances: Record<string, AssemblyInstanceRow>
  partInstances: Record<string, PartInstanceRow>
} => {
  if (!rootAssembly /*|| !rootAssembly.partTypeRows?.length*/) return null

  const assemblyInstanceBomItem: Record<string, AssemblyInstanceRow> = {
    [rootAssembly.assembly.id]: {
      ...((currentState?.assemblyInstances &&
        currentState.assemblyInstances[rootAssembly.assembly.id]) ||
        {}),
      ...rootAssembly.assembly,
      id: rootAssembly.assembly.id ?? 'undefined id',
      type: BomItemType.assemblyInstance,
      parentBomItemPointer: {
        id: parentId,
        type: parentType ?? BomItemType.assemblyInstance,
      },
      originalFileName: rootAssembly.originalFileName,
      workingSteps: rootAssembly.workingStepDtos,
      validationHighlight: rootAssembly.id === selectedBomItemId,
      partInstancePointers: rootAssembly.partTypeRows
        .sort((a, b) => a.rowNumber - b.rowNumber)
        .map((x) => ({
          id: x.id,
          type: BomItemType.partInstance,
        })),
      subAssembliesPointers: rootAssembly.subAssemblies.map((x) => ({
        id: x.id,
        type: BomItemType.assemblyInstance,
      })),
    },
  }

  const partInstancesArray: Array<PartInstanceRow> = rootAssembly.partTypeRows
    .sort((a, b) => {
      return a.rowNumber - b.rowNumber
    })
    .map((x) => ({
      ...((currentState?.partInstances && currentState?.partInstances[x.id]) ||
        {}),
      id: x.id ?? 'undefined id',

      type: BomItemType.partInstance,
      validationHighlight: x.id === selectedBomItemId,
      parentBomItemPointer: {
        id: rootAssembly.assembly.id,
        type: BomItemType.assemblyInstance,
      },
      ...x,
    }))

  const partInstances: Record<string, PartInstanceRow> = _.keyBy(
    partInstancesArray,
    'id'
  )

  const recursiveItems: {
    assemblyInstances: Record<string, AssemblyInstanceRow>
    partInstances: Record<string, PartInstanceRow>
  } = {
    assemblyInstances: {},
    partInstances: {},
  }

  rootAssembly.subAssemblies.forEach((x) => {
    const assemblyStructure = getAssemblyStructure(
      x,
      rootAssembly.id,
      selectedBomItemId,
      currentState
    )

    _.merge(recursiveItems, assemblyStructure)
  })

  return {
    assemblyInstances: {
      ...recursiveItems.assemblyInstances,
      ...assemblyInstanceBomItem,
    },
    partInstances: {
      ...recursiveItems.partInstances,
      ...partInstances,
    },
  }
}

export function getPriceSummaryKey(priceSummary: PriceSummaryDto) {
  if (!priceSummary.materialId && !priceSummary.workingStep) {
    throw 'priceSummary should have a materialId or workingStep'
  }
  if (priceSummary?.isMaterial) return priceSummary.materialId
  if (priceSummary?.isPurchasePart) return priceSummary.materialId
  if (priceSummary?.isPurchasePartWithoutArticle)
    return priceSummary.description
  else
    return `${priceSummary.workingStep.resource.id}_${priceSummary.workingStep.primaryWorkingStep}_${priceSummary.workingStep.secondaryWorkingStep}`
}

/**
 * will remove not needed data from boM array.
 * Since we are normalizing the bom state, we don't need duplicated data
 * @param project the projectDto from server
 */
export function compressProject(project: ProjectDto): ProjectRow {
  const compressedProj: ProjectRow = {
    ...project,
    id: project.id,
    type: BomItemType.project,
  }
  delete compressedProj.boM

  return compressedProj
}

const lockedStates = [
  ProjectStateAsString.CANCELLED,
  ProjectStateAsString.ORDERED,
  ProjectStateAsString.PRODUCING,
  ProjectStateAsString.PRODUCED,
  ProjectStateAsString.PACKAGING,
  ProjectStateAsString.PACKAGED,
  ProjectStateAsString.DELIVERING,
  ProjectStateAsString.DELIVERED,
]

export function isTheProjectLocked(project: ProjectDto): {
  projectIsLocked: boolean
  projectStatus: string
} {
  if (!project)
    return {
      projectIsLocked: false,
      projectStatus: ProjectStateAsString.DEFINING,
    }

  let isLocked = false

  const fix = project.status ? project.status.toUpperCase() : ''

  const projState = ProjectStateAsString[fix]

  if (lockedStates.includes(projState)) {
    isLocked = true
  } else if (
    project.isBuyingPartyView &&
    projState !== ProjectStatus.DEFINING
  ) {
    isLocked = true
  }

  return { projectIsLocked: isLocked, projectStatus: projState }
}

// /**
//  * @deprecated use activityFilter instead
//  * @param bomItems
//  * @returns
//  */
// export function GetMaterialKeywords(
//   bomItems: Record<string, BoMItemRow>
// ): Record<string, KeywordDto[]> {
//   const availableTokens: Record<string, KeywordDto[]> = {}

//   Object.keys(bomItems).forEach((key) => {
//     if (bomItems[key].type === BomItemType.partType) {
//       const bomItem = bomItems[key] as PartTypeRow
//       const keywords = bomItem.activities
//         .map((x) => x.keywords)

//       Object.keys(keywords || {}).forEach(
//         (group) =>
//           (availableTokens[group] = _.uniqBy(
//             [...(availableTokens[group] || []), ...(keywords[group] || [])],
//             (x) => x.originalKeyword
//           ))
//       )
//     }
//   })

//   return availableTokens
// }
