import { MenuItem, TextField } from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { isEqual } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { CurrenciesOptions } from '../../../utils/CurrenciesOptions'
import { GettingStartedBody } from '../Components/GettingStartedBody'
import { GettingStartedCard } from '../Components/GettingStartedCard'
import { GettingStartedPreviousAndNextButtons } from '../Components/GettingStartedPreviousAndNextButtons'
import { GettingStartedUserHeader } from '../Components/GettingStartedUserHeader'
import { GettingStartedStep } from '../Components/GettingStatedStep'
import { gettingStartedActions } from '../store/GettingStartedReducer'
import { gettingStartedSelectors } from '../store/GettingStartedSelectors'

const TeamOptions = {
  Sales: 'Sales',
  WorkPreparation: 'Work preparation & planning',
  Engineering: 'Engineering',
  ICT: 'ICT',
  Consultant: 'Consultant',
  BusinessOwner: 'Business owner',
  Other: 'Other',
}

const useStyles = makeStyles({ name: 'ActivityWorkingStep' })((theme) => ({
  factoryInfo: {
    padding: theme.spacing(2),
  },
}))

export function FactoryStep() {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const factoryForm = useAppSelector(
    gettingStartedSelectors.getFactoryFormSelector,
    isEqual
  )
  const canEnableAutoJoin = useAppSelector(
    gettingStartedSelectors.getCanEnableAutoJoin
  )
  const userForm = useAppSelector(gettingStartedSelectors.getUserFormSelector)

  const dispatch = useAppDispatch()

  const handleFactoryFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      gettingStartedActions.setFactoryForm({ [e.target.name]: e.target.value })
    )
  }

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currency = CurrenciesOptions[e.target.value]

    if (!currency) return null

    dispatch(
      gettingStartedActions.setFactoryForm({
        currency,
      })
    )
  }

  // const handleEnableAutoDomainJoinChange = (checked: boolean) => {
  //   dispatch(
  //     gettingStartedActions.setFactoryForm({
  //       enableAutoDomainJoin: checked,
  //     })
  //   )
  // }

  const navigate = useNavigate()

  const handleFactoryFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    navigate('/getting-started/working-steps')
  }

  const nextStateIsAvailable =
    Boolean(factoryForm?.factoryName) &&
    Boolean(factoryForm?.team) &&
    Boolean(factoryForm?.currency)

  return (
    <GettingStartedStep>
      <GettingStartedCard>
        <GettingStartedUserHeader />
        <LocalizedTypography
          translationKey="getting-started:factory-info-title"
          variant="h5"
          fontWeight={'bold'}
          sx={{
            textAlign: 'center',
            position: 'sticky',
            top: '-16px',
            zIndex: 2,
            padding: 2,
          }}
        >
          Factory information
        </LocalizedTypography>

        <LocalizedTypography
          translationKey="getting-started:factory-info-description"
          variant="body1"
          style={{ textAlign: 'left' }}
          className={classes.factoryInfo}
        >
          The following information is required to create a new factory in the
          Quotation Factory platform. You can edit this information later in the
          settings.
        </LocalizedTypography>
        <form onSubmit={handleFactoryFormSubmit} id="factory-form">
          <GettingStartedBody>
            <TextField
              label={t('getting-started:factory-info-factory-name')}
              name="factoryName"
              value={factoryForm?.factoryName || ''}
              onChange={handleFactoryFormChange}
              variant="filled"
              fullWidth
              required
              autoFocus
            />
            <TextField
              select
              label={t('getting-started:factory-info-team')}
              name="team"
              value={factoryForm?.team || ''}
              onChange={handleFactoryFormChange}
              variant="filled"
              fullWidth
              required
            >
              {Object.keys(TeamOptions).map((key) => (
                <MenuItem key={key} value={key}>
                  {t(
                    `getting-started:team-option-${TeamOptions[key]}`,
                    TeamOptions[key]
                  ).toString()}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label={t('getting-started:factory-info-currency')}
              name="currency"
              value={factoryForm?.currency?.code ?? ''}
              onChange={handleCurrencyChange}
              variant="filled"
              fullWidth
              required
            >
              {Object.keys(CurrenciesOptions).map((key) => (
                <MenuItem
                  key={CurrenciesOptions[key].code}
                  value={CurrenciesOptions[key].code}
                >
                  <span
                    style={{
                      width: '4ch',
                      display: 'inline-block',
                    }}
                  >
                    {CurrenciesOptions[key].symbol}
                  </span>
                  <span>
                    {t(
                      `getting-started:factory-currency-${CurrenciesOptions[key].code}`,
                      CurrenciesOptions[key].name
                    ).toString()}
                  </span>
                </MenuItem>
              ))}
            </TextField>
            {/* {canEnableAutoJoin && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={factoryForm?.enableAutoDomainJoin || false}
                    onChange={(e, checked) =>
                      handleEnableAutoDomainJoinChange(checked)
                    }
                  />
                }
                label={
                  <>
                    <LocalizedTypography
                      translationKey="getting-started:enable-automatic-domain-join"
                      variant="body1"
                    >
                      Enable automatic domain join for new users
                    </LocalizedTypography>
                    <Typography
                      variant="caption"
                      component="p"
                      whiteSpace={'wrap'}
                    >
                      <Trans
                        i18nKey={
                          'getting-started:enable-automatic-domain-join-description'
                        }
                        components={{
                          b: <b />,
                        }}
                        values={{
                          emailDomain: '@' + userForm?.email.split('@')[1],
                        }}
                      >
                        {` by enabling this flag, all users in the domain <b>{{ emailDomain }}</b> will be automatically joined to this factory `}
                      </Trans>
                    </Typography>
                  </>
                }
              />
            )} */}
          </GettingStartedBody>

          <GettingStartedPreviousAndNextButtons
            currentStep={2}
            submitButton={nextStateIsAvailable}
          />
        </form>
      </GettingStartedCard>
    </GettingStartedStep>
  )
}
