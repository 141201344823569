import { RefreshOutlined } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { unsubscribeFromUpdates } from 'features/BillOfMaterials/store/asyncActions/liveUpdatesActions'
import { subscribeToProjectOperationsLogs } from 'features/BillOfMaterials/store/asyncActions/subscribeToProjectOperationsLogs'
import { subscribeToProjectNotifications } from 'features/BillOfMaterials/store/asyncActions/subscribeToUpdates'
import _, { isEqual, noop } from 'lodash'
import React, { useEffect } from 'react'
import { subscribeToProjectListUpdates } from 'store/ProjectList/asyncActions/subscribeToProjectListUpdates'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { ConnectionStatus } from '../store/ConnectionReducer'

type StyleProps = {
  connectionState: ConnectionStatus
}

const useStyles = makeStyles<StyleProps>()((theme, props) => {
  let backgroundColor = theme.palette.warning.light

  switch (props.connectionState) {
    case 'online':
      backgroundColor = theme.palette.info.light
      break
    case 'disconnected':
      backgroundColor = theme.palette.secondary.main
      break
  }

  return {
    connectionStateRoot: {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      height: theme.spacing(10),
      minWidth: `${theme.breakpoints.values.sm}px`,
      borderRadius: '4px 4px 0px 0',
      transform: `translateX(-50%)`,
      backgroundColor: backgroundColor,
      color: theme.palette.getContrastText(backgroundColor),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: -1,
      opacity: 0,
      transition: 'all 1.25s ease',
      [theme.breakpoints.down(1024)]: {
        bottom: theme.spacing(9),
      },
    },
  }
})

type Props = {
  expectedGroups: string[]
}

export function getNotificationGroupAction(groupName: string) {
  if (groupName.indexOf('_project_list')) {
    return () => {
      subscribeToProjectListUpdates()
    }
  } else if (groupName.indexOf('project') > -1) {
    return () => {
      subscribeToProjectNotifications(groupName)
    }
  } else if (groupName.indexOf('operations') > -1) {
    return () => {
      subscribeToProjectOperationsLogs(groupName)
    }
  }

  return () => noop()
}

const _ConnectionState = (props: Props) => {
  const { status, error, groups, retryDescription, retryCount } =
    useAppSelector((state: RootAppState) => {
      return {
        status: state.connection.status,
        error: state.connection.error,
        groups: state.connection.groups,
        retryDescription: state.connection.retryDescription,
        retryCount: state.connection.retryCount,
      }
    }, _.isEqual)

  const showTimeout = React.useRef<NodeJS.Timeout>(undefined)

  const [show, setShow] = React.useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMessage, setErrorMessage] = React.useState<string>(undefined)
  const dispatch = useAppDispatch()

  useEffect(() => {
    clearTimeout(showTimeout.current)

    if (groups.length > 0 && !_.isEqual(groups, props.expectedGroups)) {
      // should unsubscribe from groups not in expected groups
      const groupsToUnsubscribe = groups.filter(
        (group) => !props.expectedGroups.includes(group)
      )

      groupsToUnsubscribe.forEach((group) => {
        console.info('unsubscribing from group', group)
        dispatch(
          unsubscribeFromUpdates({
            groupName: group,
          })
        )
      })
    }

    // console.log('groups', {
    //   groups,
    //   expected: props.expectedGroups,
    // })

    const inExpectedGroup = props.expectedGroups
      ? props.expectedGroups.every((expectedGroup) =>
          groups.includes(expectedGroup)
        )
      : true

    if (!inExpectedGroup) {
      console.info('not in expected update groups, waiting 15s to try again', {
        groups,
        expected: props.expectedGroups,
      })
      showTimeout.current = setTimeout(() => {
        props.expectedGroups.forEach((expectedGroup) =>
          dispatch(getNotificationGroupAction(expectedGroup))
        )
      }, 15_000)
    }

    if (status === 'disconnected' && props.expectedGroups) {
      console.info(
        `disconnected, setting a timeout to retry the connection`,
        props.expectedGroups
      )
      showTimeout.current = setTimeout(() => {
        props.expectedGroups.forEach((expectedGroup) =>
          dispatch(getNotificationGroupAction(expectedGroup))
        )
      }, 60_000)
    }

    return () => {
      clearTimeout(showTimeout.current)
    }
  }, [dispatch, groups, props.expectedGroups, status])

  const { classes } = useStyles({ connectionState: status })

  const shouldShowNow =
    window['showConnectionStatus'] || //for debugging
    error?.message ||
    errorMessage ||
    retryCount >= 3

  React.useEffect(() => {
    setShow(shouldShowNow)
  }, [shouldShowNow])

  const visibilityStyles = show
    ? { opacity: 1, zIndex: 100 }
    : { opacity: 0, zIndex: -1 }

  return (
    <div className={classes.connectionStateRoot} style={visibilityStyles}>
      <div>
        <Box display="flex">
          <Typography variant="subtitle1">{status}</Typography>
          <IconButton size="small" onClick={() => window.location.reload()}>
            <RefreshOutlined />
          </IconButton>
        </Box>
        <Typography variant="caption" component="p">
          {errorMessage}
        </Typography>
        <Typography variant="caption" hidden={!Boolean(retryDescription)}>
          {retryDescription}
        </Typography>
      </div>
    </div>
  )
}

export const ConnectionState = React.memo(_ConnectionState, isEqual)
