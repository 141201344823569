import { QuantityString } from 'model/Quantity'
import { RootAppState } from 'store/configureStore'

export const projectDiscountRatioSelector = (state: RootAppState) => {
  if (!state.project.activeProject?.financial?.discountRatio) {
    return null
  }

  return QuantityString({
    value: state.project.activeProject?.financial.discountRatio.value,
    unit: '%',
  })
}
