import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'
import { BomItemType } from 'model/Project/BoMItemRow'

export const bomItemPrecisionsSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItem?.type) {
      case BomItemType.assemblyType:
        if (bomItem.showPrecisions) return bomItem.precisions
        else return null
      case BomItemType.partType: {
        if (bomItem.showPrecisions) return bomItem.precisions
        else return null
      }
      case BomItemType.assemblyInstance:
        return bomItem.precisions
      case BomItemType.partInstance:
        return bomItem.precisions
      default:
        return null
    }
  }
