import { BoMItemRow, BomItemType } from 'model/Project/BoMItemRow'
import {
  ArticleIdFilter,
  ManufacturabilityCheckFilter,
  PartTypeIdFilter,
  ResourceIdFilter,
} from 'store/Project/ProjectTypes'
import { showPartBasedOnArticleId } from './showPartBasedOnArticleId'
import {
  showPartBasedOnManufacturability,
  showPartBasedOnPartTypeId,
  showPartBasedOnResourceId,
} from './showPartBasedOnPartTypeId'

export function applyPartTypeIdFilter(
  filter: PartTypeIdFilter,
  row: BoMItemRow
): boolean {
  let partTypeIdFilterAppliedShowItem = true

  switch (row.type) {
    case BomItemType.partType:
    case BomItemType.partInstance: {
      partTypeIdFilterAppliedShowItem = showPartBasedOnPartTypeId(row, filter)
    }
  }

  return partTypeIdFilterAppliedShowItem
}

export function applyArticleIdFilter(
  filter: ArticleIdFilter,
  row: BoMItemRow
): boolean {
  let partTypeIdFilterAppliedShowItem = false

  switch (row.type) {
    case BomItemType.partType:
    case BomItemType.partInstance: {
      partTypeIdFilterAppliedShowItem = showPartBasedOnArticleId(row, filter)
    }
  }

  return partTypeIdFilterAppliedShowItem
}

export function applyResourceIdFilter(
  filter: ResourceIdFilter,
  row: BoMItemRow
): boolean {
  let partTypeIdFilterAppliedShowItem = false

  switch (row.type) {
    case BomItemType.partType:
    case BomItemType.partInstance: {
      partTypeIdFilterAppliedShowItem = showPartBasedOnResourceId(
        row.activities,
        filter
      )
      break
    }
    case BomItemType.assemblyType: {
      partTypeIdFilterAppliedShowItem = showPartBasedOnResourceId(
        row.assemblyActivities.concat(row.partTypeActivities),
        filter
      )
      break
    }
  }

  return partTypeIdFilterAppliedShowItem
}

export function applyManufacturabilityFilter(
  filter: ManufacturabilityCheckFilter,
  row: BoMItemRow
): boolean {
  let manufacturabilityCheckFilterApplied = true

  switch (row.type) {
    case BomItemType.partType:
    case BomItemType.partInstance:
      {
        manufacturabilityCheckFilterApplied = showPartBasedOnManufacturability(
          row,
          filter
        )
      }
      break
  }

  return manufacturabilityCheckFilterApplied
}
