import { ProjectState } from 'store/Project/ProjectTypes'

export function todosFilterActive(byTodos: ProjectState['filters']['byTodos']) {
  if (
    !byTodos?.active ||
    (byTodos.filter?.availableFilters?.length || 0) === 0
  ) {
    return false
  }

  return true
}
