import { SumMoney } from 'model/Money'
import { BomItemType } from 'model/Project/BoMItemRow'
import {
  FinancialDto,
  MoneyDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { BomItemPointer } from '../../../../model/Project/BomItemPointer'
import { bomItemSelector } from './bomItemSelector'

export const bomItemTargetPriceSelector =
  (bomItemPointer: BomItemPointer, priceScope?: 'perUnit' | 'total') =>
  (state: RootAppState) => {
    const targetPrice = bomItemFinancialSelector(
      bomItemPointer,
      'targetSalesPricePerItem'
    )(state) as MoneyDto

    if (priceScope === 'total') {
      const totalProjectQuantity: number = bomItemFinancialSelector(
        bomItemPointer,
        'totalProjectQuantity'
      )(state) as unknown as number

      return {
        ...targetPrice,
        value: targetPrice.value * totalProjectQuantity,
      }
    }

    return targetPrice
  }

export const bomItemFinancialSum =
  (bomItemPointers: BomItemPointer[], financialProp: keyof FinancialDto) =>
  (state: RootAppState) => {
    if (!state.project.activeProject?.id) {
      return null
    }

    const total = bomItemPointers.reduce((acc, bomItemPointer) => {
      const financial = bomItemFinancialSelector(
        bomItemPointer,
        financialProp
      )(state)

      if (typeof financial[financialProp] === 'number') {
        return (acc as number) + (financial[financialProp] as number)
      } else if (Boolean(financial['currency'])) {
        return SumMoney([acc, financial])
      } else {
        console.warn(
          'financialProp is not a number or MoneyDto',
          financialProp,
          financial
        )
        throw new Error('Financial property is not a number or a MoneyDto')
      }
    }, {})

    return total
  }

export const bomItemFinancialSelector =
  (bomItemPointer: BomItemPointer, financialProp: keyof FinancialDto) =>
  (state: RootAppState) => {
    if (!state.project.activeProject?.id) {
      return null
    }

    if (!bomItemPointer) return null
    if (bomItemPointer.id === state.project.activeProject?.id) {
      return state.project.activeProject.financial[financialProp]
    }

    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItem?.type) {
      case BomItemType.assemblyType:
        return bomItem.financial[financialProp]
      case BomItemType.assemblyInstance:
      case BomItemType.partType:
      case BomItemType.partInstance:
        return bomItem.financial[financialProp]
    }

    return null
  }

export const bomItemMaterialFinancialSelector =
  (
    bomItemPointer: BomItemPointer,
    activityId: string,
    financialProp: keyof FinancialDto
  ) =>
  (state: RootAppState) => {
    if (!state.project.activeProject?.id) {
      return null
    }

    if (!activityId) {
      return null
    }

    if (!bomItemPointer) return null
    if (bomItemPointer.id === state.project.activeProject?.id) {
      return state.project.activeProject.financial[financialProp]
    }

    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItem?.type) {
      case BomItemType.partType: {
        return bomItem.rawMaterialSummary?.financial?.[financialProp]
        // return bomItem.activities.find((x) => x.id === activityId)?.article
        //   ?.financial?.[financialProp]
      }
    }

    return null
  }
