import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  AssemblyHeaderRow,
  AssemblyInstanceRow,
  BomItemType,
  MaterialHeaderRow,
  PartInstanceRow,
  PartTypeRow,
  ProjectRow,
  RoutingHeaderRow,
} from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectState } from '../../../../store/Project/ProjectTypes'
import { bomItemSelector } from '../selectors/bomItemSelector'

export const removeWorkingStepFromBomItem: CaseReducer<
  ProjectState,
  PayloadAction<{
    bomItemPointer: BomItemPointer
    workingStepType: WorkingStepType
  }>
> = (state, action) => {
  function updateBomItem(
    bomItem:
      | ProjectRow
      | AssemblyHeaderRow
      | AssemblyInstanceRow
      | PartTypeRow
      | PartInstanceRow
      | MaterialHeaderRow
      | RoutingHeaderRow
  ) {
    switch (bomItem?.type) {
      case BomItemType.assemblyType: {
        bomItem.assemblyActivities = bomItem.assemblyActivities.filter(
          (ws) => ws.primaryWorkingStep !== action.payload.workingStepType
        )
        break
      }
      case BomItemType.assemblyInstance: {
        bomItem.assemblyActivities = bomItem.assemblyActivities.filter(
          (ws) => ws.primaryWorkingStep !== action.payload.workingStepType
        )
        break
      }
      case BomItemType.partType:
      case BomItemType.partInstance: {
        bomItem.activities = bomItem.activities.filter(
          (ws) => ws.primaryWorkingStep !== action.payload.workingStepType
        )
        break
      }
    }
  }

  if (action.payload.bomItemPointer.id === state.activeProject?.id) {
    state.activeProject.activities = state.activeProject.activities.filter(
      (ws) => ws.primaryWorkingStep !== action.payload.workingStepType
    )
    return state
  }

  updateBomItem(
    bomItemSelector({ project: state }, action.payload.bomItemPointer)
  )

  return state
}
