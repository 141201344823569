import { RootAppState } from 'store/configureStore'

export const bomItemFocusedItemsSelector = (state: RootAppState) => {
  return state.project.focusedBomItemPointer
}

export const bomItemIsFocusedSelector =
  (bomItemId: string) => (state: RootAppState) => {
    return state.project.focusedBomItemPointer?.id === bomItemId
  }
