import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserController } from 'features/CustomerPortal/ConnectedParties/controllers/UserController'
import { NavigateFunction } from 'react-router-dom'
import {
  GetAvailableWorkspacesResponse,
  UserDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import store, { RootAppState } from 'store/configureStore'
import {
  GettingStartedRouteKeys,
  gettingStartedRoutes,
} from '../GettingStartedRoutes'
import { GettingStartedController } from '../Services/GettingStartedController'
import { gettingStartedActions } from './GettingStartedReducer'
import { gettingStartedSelectors } from './GettingStartedSelectors'

export const fetchUserProfile = createAsyncThunk<
  UserDto,
  void,
  { state: RootAppState }
>('getting-started/fetchUserProfile', async (_, thunkAPI) => {
  try {
    const userController = new UserController()

    const userProfile = await userController.GetProfile({
      disableRedirect: true,
    })

    thunkAPI.dispatch(
      gettingStartedActions.setUserForm({
        email: userProfile.email,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
      })
    )

    return userProfile
  } catch (err) {
    ShowException('getting started', err)
    return undefined
  }
})

export const fetchAvailableWorkspaces = createAsyncThunk<
  GetAvailableWorkspacesResponse,
  void,
  { state: RootAppState }
>('getting-started/fetchAvailableWorkspaces', async (_, thunkAPI) => {
  try {
    const gettingStartedController = new GettingStartedController()
    const availableWorkspaces =
      await gettingStartedController.GetAvailableWorkspaces()

    thunkAPI.dispatch(
      gettingStartedActions.setAvailableWorkspaces(availableWorkspaces)
    )

    if (availableWorkspaces.canEnableAutoJoin) {
      thunkAPI.dispatch(
        gettingStartedActions.setFactoryForm({
          enableAutoDomainJoin: true,
        })
      )
    }

    return availableWorkspaces
  } catch (err) {
    ShowException('getting started', err)
    return undefined
  }
})

export const navigateToNextStep = createAsyncThunk<
  GettingStartedRouteKeys,
  NavigateFunction,
  { state: RootAppState }
>('getting-started/navigateToNextStep', async (navigate: NavigateFunction) => {
  const state = store.getState()

  const nextRoute = gettingStartedSelectors.getNextRouteSelector()(state)

  if (nextRoute) {
    navigate(gettingStartedRoutes[nextRoute].path, { state: { from: 'next' } })
    return nextRoute
  }

  return undefined
})
