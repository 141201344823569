import { ProjectState } from 'store/Project/ProjectTypes'

export function partTypeFilterIsActive(
  byPartTypeId: ProjectState['filters']['byPartTypeId']
) {
  if (
    !byPartTypeId?.active ||
    (byPartTypeId.filter?.enabledFilters?.length || 0) === 0
  ) {
    return false
  }

  return true
}

export function articleIdFilterIsActive(
  byArticleId: ProjectState['filters']['byArticleId']
) {
  if (
    !byArticleId?.active ||
    (byArticleId.filter?.enabledFilters?.length || 0) === 0
  ) {
    return false
  }

  return true
}

export function resourceIdFilterIsActive(
  byResourceId: ProjectState['filters']['byResourceId']
) {
  if (
    !byResourceId?.active ||
    (byResourceId.filter?.enabledFilters?.length || 0) === 0
  ) {
    return false
  }

  return true
}

export function manufacturabilityFilterEnabled(
  byManufacturability: ProjectState['filters']['byManufacturability']
) {
  if (!byManufacturability?.active) {
    return false
  }

  return true
}

export function erpErrorFilterEnabled(
  byErpError: ProjectState['filters']['byErpError']
) {
  return Boolean(byErpError?.active)
}
