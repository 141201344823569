import { PartInstanceRow, PartTypeRow } from 'model/Project/BoMItemRow'
import { ArticleIdFilter } from 'store/Project/ProjectTypes'

export function showPartBasedOnArticleId(
  row: PartTypeRow | PartInstanceRow,
  filter: ArticleIdFilter
): boolean {
  if ((filter.enabledFilters?.length || 0) === 0) {
    return true
  }

  return (
    filter.enabledFilters?.includes(row.rawMaterialSummary?.id) ||
    row.activities
      .map((x) => x.article?.id)
      .some((x) => filter.enabledFilters?.includes(x))
  )
}
