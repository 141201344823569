export function expect(
  truthy: boolean | string,
  message: string,
  ...args: unknown[]
) {
  if (!Boolean(truthy)) {
    console.error(message, args)

    if (process.env.NODE_ENV === 'development') {
      debugger
      throw new Error(message)
    }
  }
}
