import { createAsyncThunk } from '@reduxjs/toolkit'
import { ShowException } from 'store/Application/appActions'
import { RootAppState } from 'store/configureStore'
import { projectAPIBuilder } from '../asyncActions/BoMAsyncActions'
import { fetchProject } from './fetchProject'

export const saveProjectDeliveryDate = createAsyncThunk<
  void,
  string,
  { state: RootAppState }
>('project/saveProjectDeliveryDate', async (estimatedDeliveryDate: string, thunkAPI) => {
  const { projectId, api } = projectAPIBuilder(thunkAPI.getState)

  try {
    await api.SetEstimatedDeliveryDate(projectId, {
      estimatedDeliveryDate: estimatedDeliveryDate as unknown as Date,
    })
  } catch (err) {
    ShowException('Delivery Date', err)
    thunkAPI.dispatch(fetchProject({ projectId: projectId }))
    throw err
  }
})
