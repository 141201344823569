import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectModel } from 'model/ProjectModel'
import { newProjectActions } from '../projectReducer'
import { projectAPIBuilder } from './BoMAsyncActions'
import { fetchProject } from './fetchProject'
import { RootAppState } from 'store/configureStore'

export const saveOrderNumber = createAsyncThunk<
  void,
  string,
  { state: RootAppState }
>('project/saveOrderNumber', async (orderNumber: string, thunkAPI) => {
  const { projectId, api } = projectAPIBuilder(thunkAPI.getState)

  thunkAPI.dispatch(
    newProjectActions.setActiveProjectProperties({
      orderNumber: orderNumber,
    } as Partial<ProjectModel>)
  )

  try {
    return await api.SetOrderNumber(projectId, orderNumber)
  } catch (err) {
    thunkAPI.dispatch(fetchProject({ projectId: projectId }))
    throw err
  }
})
