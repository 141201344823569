import { Slide } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({ name: 'GettingStartedBody' })((theme) => ({
  body: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
  },
}))

export function GettingStartedBody(props: React.PropsWithChildren) {
  const { classes } = useStyles()
  const cardRef = React.useRef<HTMLDivElement>(null)

  const location = useLocation()

  return (
    <div ref={cardRef}>
      <Slide
        in={true}
        container={() => document.getElementById('getting-started-body')}
        direction={location.state?.from === 'previous' ? 'right' : 'left'}
      >
        <div className={classes.body}>{props.children}</div>
      </Slide>
    </div>
  )
}
