import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemController } from 'controllers/Project/BomItemController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { ShowException } from 'store/Application/appActions'
import { GetContext } from 'store/storeUtils'
import { newProjectActions } from '../projectReducer'
import { fetchProject } from './fetchProject'
import { RootAppState } from 'store/configureStore'
import { projectSelectors } from '../selectors/projectSelectors'

export const saveBomItemComment = createAsyncThunk<
  void,
  { bomItemPointer: BomItemPointer; comment: string },
  { state: RootAppState }
>('bom/saveComment', async ({ bomItemPointer, comment }, thunkAPI) => {
  if (!comment) {
    // if the current comment is not empty, we should clean it up.
    // otherwise, we should not do anything
    const currentComment = projectSelectors.bomItemCommentSelector(
      bomItemPointer
    )(thunkAPI.getState())

    if (!currentComment) {
      return
    }
  }
  const bomItemController = new BomItemController()

  const { projectId } = GetContext()

  try {
    if (thunkAPI.getState().project.activeProject?.id === bomItemPointer.id) {
      thunkAPI.dispatch(
        newProjectActions.setActiveProjectProperties({
          comment: comment,
        })
      )
    } else {
      thunkAPI.dispatch(
        newProjectActions.setBomItemProperties({
          bomItemPointer,
          properties: {
            comment: comment.trim(),
          },
        })
      )
    }

    await bomItemController.updateComment(bomItemPointer, comment.trim())
  } catch (err) {
    ShowException('project', err)
    thunkAPI.dispatch(fetchProject({ projectId: projectId }))
    throw err
  }
})
