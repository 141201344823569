import { cloneDeep } from 'lodash'
import { BoMItemRow, PartTypeRow } from 'model/Project/BoMItemRow'

import {
  BomItemPointer,
  MaterialHeaderPointer,
} from 'model/Project/BomItemPointer'
import { ArticleSelectorUI } from '../../model/ArticleSelectorUI'
import {
  BoMItemActivityDto,
  MaterialSummaryDto,
  WorkingStepType,
} from '../../services/APIs/InternalAPI/internal-api.contracts'
import { MaterialActionsConsts, MaterialActionsTypes } from './MaterialActions'

export type ActivitiesClipboard = {
  baseBomItemPointer: BomItemPointer
  activities: BoMItemActivityDto[]
}

export type MaterialState = {
  readonly activeRows: PartTypeRow[]
  readonly articleSelectorUI: ArticleSelectorUI
  readonly isMaterialSelectorModalOpen: boolean
  activitiesClipboard: ActivitiesClipboard
  readonly wasChanged: boolean
  readonly materialHeaderPointer: MaterialHeaderPointer
  readonly isLoading: boolean
  remnantStrategyModal: {
    open: boolean
    materialId: string
    resourceId: string
    projectId: string
  }
  readonly workingStepType: WorkingStepType
}

const initialState: MaterialState = {
  activeRows: [],
  articleSelectorUI: undefined,
  activitiesClipboard: undefined,
  isMaterialSelectorModalOpen: false,
  wasChanged: false,
  materialHeaderPointer: undefined,
  isLoading: false,
  remnantStrategyModal: {
    open: false,
    materialId: '',
    resourceId: '',
    projectId: '',
  },
  workingStepType: undefined,
}

const MaterialReducer = (
  state = initialState,
  action: MaterialActionsTypes
): MaterialState => {
  switch (action.type) {
    case MaterialActionsConsts.OpenMaterialModal: {
      return {
        ...state,
        activeRows: action.activeRows,
        materialHeaderPointer: action.materialHeaderPointer,
        isLoading: true,
        isMaterialSelectorModalOpen: true,
        workingStepType: action.workingStepType,
      }
    }
    case MaterialActionsConsts.CloseWithoutSave: {
      return initialState
    }
    case MaterialActionsConsts.SetPossibleTokensAndMaterials: {
      const materialSelectorUI = new ArticleSelectorUI(
        action.tokensAndMaterials,
        state.articleSelectorUI?.selectedMaterial || action.selectedMaterial,
        action.workingStepType
      )

      return {
        ...state,
        articleSelectorUI: materialSelectorUI,
        isLoading: false,
      }
    }
    case MaterialActionsConsts.SetTokens: {
      const newMaterialSummary: MaterialSummaryDto = {
        id: undefined,
        tokens: action.selectedTokens,
      }

      const newRows = state.activeRows.map((x) => {
        return Object.assign({}, x, {
          materialSummary: newMaterialSummary,
        })
      })

      return {
        ...state,
        articleSelectorUI: {
          ...state.articleSelectorUI,
          selectedMaterial: newMaterialSummary,
          selectedTokens: Object.values(newMaterialSummary.tokens).flat(),
          selectableTokens: undefined,
        },
        activeRows: newRows,
      }
    }
    case MaterialActionsConsts.SetMaterial: {
      const newMaterialSummary = {
        ...action.material,
      }
      const newRows = state.activeRows.map((x) => {
        return {
          ...x,
          materialSummary: newMaterialSummary,
        }
      })

      const newUI = Object.assign({}, state.articleSelectorUI, {
        selectedMaterial: newMaterialSummary,
        selectedTokens: Object.values(action?.material?.tokens || {}).flat(),
      })

      return {
        ...state,
        articleSelectorUI: newUI,
        activeRows: newRows,
      }
    }
    case MaterialActionsConsts.SetIsLoading: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }
    case MaterialActionsConsts.Set3dModelPath: {
      const newRow: BoMItemRow = Object.assign({}, state.activeRows[0], {
        threeDModelPath: action.threeDModelPath,
      })

      return {
        ...state,
        activeRows: [newRow],
      }
    }
    case MaterialActionsConsts.CopyActivityToClipboard: {
      const clipboardActivities = []

      action.activities.forEach((activity) => {
        const _keywords = cloneDeep(activity.keywords)

        delete _keywords['dimensions']
        delete _keywords['shape']

        const clipboardActivity = {
          ...activity,
          keywords: _keywords,
        }

        clipboardActivities.push(clipboardActivity)
      })

      return {
        ...state,
        activitiesClipboard: {
          baseBomItemPointer: action.baseBomItemPointer,
          activities: [...clipboardActivities],
        },
      }
    }

    case MaterialActionsConsts.OpenModalRemnantStrategy: {
      return {
        ...state,
        remnantStrategyModal: {
          open: true,
          materialId: action.materialId,
          resourceId: action.resourceId,
          projectId: action.projectId,
        },
      }
    }

    case MaterialActionsConsts.CloseModalRemnantStrategy: {
      return {
        ...state,
        remnantStrategyModal: {
          ...state.remnantStrategyModal,
          open: false,
        },
      }
    }

    case MaterialActionsConsts.ClipboardClear: {
      return {
        ...state,
        activitiesClipboard: undefined,
      }
    }
    default:
      return state
  }
}

export default MaterialReducer
