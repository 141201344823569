/* eslint-disable @typescript-eslint/no-unused-vars */
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemMaterialHasCustomPrice =
  (bomItemPointer: BomItemPointer, activityId: string) =>
  (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItemPointer?.type) {
      case BomItemType.partType:
        //TODO: check how to know if the activity material price
        //is custom or not
        // return (bomItem as RowDto).activities?.find(x => x.id === activityId)?.isCustomPrice
        return false

      default:
        return false
    }
  }
