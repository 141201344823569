import { Action, ThunkDispatch, createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { ConfirmationDialogProps } from 'features/ConfirmationDialog/ConfirmationDialog'
import i18next from 'i18next'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { ShowException } from 'store/Application/appActions'
import { UIActions } from 'store/UI/UIActions'
import { RootAppState } from 'store/configureStore'
import { newProjectActions } from '../projectReducer'
import { bomItemSelector } from '../selectors/bomItemSelector'
import { projectSelectors } from '../selectors/projectSelectors'
import { projectAPIBuilder } from './BoMAsyncActions'
import { fetchProject } from './fetchProject'

type ProjectAPIBuilder = ReturnType<typeof projectAPIBuilder>

async function deleteBomItems(
  bomItemPointers: BomItemPointer[],
  dispatch: ThunkDispatch<RootAppState, unknown, Action<unknown>>,
  projectAPI: ProjectAPIBuilder
) {
  try {
    // client side delete
    dispatch(newProjectActions.deleteBomItemsFromStore(bomItemPointers))

    // server side delete
    const ret = await projectAPI.api.DeleteRowOrAssembly(
      projectAPI.projectId,
      bomItemPointers.map((x) => x.id)
    )

    return ret
  } catch (err) {
    dispatch(fetchProject({ projectId: projectAPI.projectId }))
    ShowException('project', err)
    throw err
  } finally {
    dispatch(UIActions.CloseModal())
  }
}

/**
 * Delete a BOM item from local store and server
 * if multiple BOM items are selected, delete all of them after confirmation
 * if one of the filters is active, delete the visible part types after confirmation
 * if no filters and is an assembly header, delete all the children after confirmation
 * @param bomItemPointer The pointer to the BOM item to delete
 */
export const deleteBoMItem = createAsyncThunk<
  void,
  { bomItemPointer?: BomItemPointer },
  { state: RootAppState }
>('boM/deleteBoMItem', async ({ bomItemPointer }, thunkAPI) => {
  const projectAPI = projectAPIBuilder(thunkAPI.getState)
  try {
    let bomItemsToDelete: BomItemPointer[] = []
    let affectedItemsCount = 1
    let confirmText: string = null

    const selectedBomItems = projectSelectors.selectedBomItemsSelector(
      thunkAPI.getState()
    )

    if (selectedBomItems?.length === 0 && bomItemPointer) {
      const bomItem = bomItemSelector(thunkAPI.getState(), bomItemPointer)

      switch (bomItem?.type) {
        case BomItemType.project: {
          console.error('use deleteProject to delete the project')
          break
        }
        case BomItemType.routingHeader:
        case BomItemType.materialHeader:
        case BomItemType.assemblyType:
          // if filters are active, delete just the visible part types
          // if no filters are active, delete the header only
          if (
            bomItem.filteredPartTypePointers.length ===
            bomItem.partTypePointers.length
          ) {
            bomItemsToDelete = [bomItemPointer]
            affectedItemsCount = bomItem.partTypePointers.length
          } else {
            bomItemsToDelete = bomItem.filteredPartTypePointers
            affectedItemsCount = bomItem.filteredPartTypePointers.length
          }
          break
        default: {
          bomItemsToDelete = [bomItemPointer]
          affectedItemsCount = 1

          const bomItemName = bomItem.name
          confirmText = i18next.t('project:delete-part-desc', {
            name: bomItemName,
          })
        }
      }
    } else {
      // if no bom item pointer is passed, delete all selected bom items
      bomItemsToDelete = selectedBomItems
      affectedItemsCount = bomItemsToDelete.length
    }

    if (bomItemsToDelete.length === 0) {
      console.error('no bom item to delete')
      return
    }

    const deleteModalTitle =
      confirmText ??
      i18next.t('delete-parts-title', {
        count: affectedItemsCount,
        ns: 'project',
      })

    const deleteModal = () =>
      thunkAPI.dispatch(
        UIActions.OpenModal('ConfirmationDialog', {
          title: deleteModalTitle,
          onConfirm: () => {
            deleteBomItems(bomItemsToDelete, thunkAPI.dispatch, projectAPI)
          },
          onCancel: () => {
            return Promise.resolve()
          },
        } as ConfirmationDialogProps)
      )

    const currentStatus = thunkAPI.getState().project.activeProject?.status

    if (currentStatus && currentStatus !== ProjectStateAsString.DEFINING) {
      // if the project is not in defining state, show a warning since user need to set the state to defining
      const localizedDefining = i18next
        .t(`project:state-${ProjectStateAsString.DEFINING.toLowerCase()}`)
        .toLocaleUpperCase()

      thunkAPI.dispatch(
        UIActions.OpenModal('ConfirmationDialog', {
          title: deleteModalTitle,
          message: i18next.t('change-project-status-defining-and-delete', {
            defaultValue: `Delete parts is only allowed in {{localizedDefining}} state. Do you want to change the project status to {{localizedDefining}} and delete the items?`,
            localizedDefining: localizedDefining,
            ns: 'project',
          }),
          onConfirm: async () => {
            try {
              await projectAPI.api.SetProjectStatus(
                projectAPI.projectId,
                thunkAPI
                  .getState()
                  .project.activeProject.status.toUpperCase() as ProjectStateAsString,
                ProjectStateAsString.DEFINING
              )

              await deleteBomItems(
                bomItemsToDelete,
                thunkAPI.dispatch,
                projectAPI
              )
            } catch (err) {
              ShowException('project', err)
              throw err
            }
          },
        })
      )
    } else {
      deleteModal()
    }
  } catch (err) {
    ShowException('project', err)
    throw err
  }
})
