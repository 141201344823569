import { ProjectState } from 'store/Project/ProjectTypes'

export function issueFilterActive(
  byIssues: ProjectState['filters']['byIssues']
) {
  if (
    !byIssues?.active ||
    (byIssues.filter?.availableFilters?.length || 0) === 0
  ) {
    return false
  }

  return true
}
