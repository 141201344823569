import { BoMItemRow } from 'model/Project/BoMItemRow'
import { ProjectState } from 'store/Project/ProjectTypes'
import { applyErpErrorFilter } from './IssuesFilter/applyErpErrorFilter'
import { applyIssuesFilter } from './IssuesFilter/applyIssuesFilter'
import {
  applyArticleIdFilter,
  applyManufacturabilityFilter,
  applyPartTypeIdFilter,
  applyResourceIdFilter,
} from './IssuesFilter/applyPartTypeIdFilter'
import { applyTodosFilter } from './IssuesFilter/applyTodosFilter'
import { issueFilterActive } from './IssuesFilter/issueFilterActive'
import {
  articleIdFilterIsActive,
  erpErrorFilterEnabled,
  manufacturabilityFilterEnabled,
  partTypeFilterIsActive,
  resourceIdFilterIsActive,
} from './IssuesFilter/partTypeFilterIsActive'
import { todosFilterActive } from './IssuesFilter/todosFilterActive'
import { applyMaterialFilter } from './MaterialFilter/applyMaterialFilter'
import { materialFilterActive } from './MaterialFilter/materialFilterActive'
import { applyWorkingStepFilter } from './WorkingStepFilter/applyWorkingStepFilter'
import { workingStepFilterActive } from './WorkingStepFilter/workingStepFilterActive'

export const BoMItemFilter = (projectState: ProjectState, row: BoMItemRow) => {
  let showItem = true

  if (materialFilterActive(projectState.filters?.byMaterialKeywords)) {
    showItem = applyMaterialFilter(
      projectState.filters?.byMaterialKeywords.filter,
      row,
      projectState.filters?.byMaterialKeywords.filter.showIfAll
    )
    // console.log('afterMaterialFilter', showItem)
  }

  if (workingStepFilterActive(projectState.filters?.byWorkingStep)) {
    showItem =
      showItem &&
      applyWorkingStepFilter(projectState.filters?.byWorkingStep.filter, row)

    // console.log('afterWorkingStepFilter', showItem)
  }

  if (issueFilterActive(projectState.filters?.byIssues)) {
    showItem =
      showItem && applyIssuesFilter(projectState.filters?.byIssues.filter, row)
  }

  if (todosFilterActive(projectState.filters?.byTodos)) {
    showItem =
      showItem && applyTodosFilter(projectState.filters?.byTodos.filter, row)
  }

  if (partTypeFilterIsActive(projectState.filters?.byPartTypeId)) {
    showItem =
      showItem &&
      applyPartTypeIdFilter(projectState.filters?.byPartTypeId.filter, row)
  }

  if (articleIdFilterIsActive(projectState.filters?.byArticleId)) {
    showItem =
      showItem &&
      applyArticleIdFilter(projectState.filters?.byArticleId.filter, row)
  }

  if (resourceIdFilterIsActive(projectState.filters?.byResourceId)) {
    showItem =
      showItem &&
      applyResourceIdFilter(projectState.filters?.byResourceId.filter, row)
  }

  if (
    manufacturabilityFilterEnabled(projectState.filters?.byManufacturability)
  ) {
    showItem =
      showItem &&
      applyManufacturabilityFilter(
        projectState.filters?.byManufacturability.filter,
        row
      )
  }

  if (erpErrorFilterEnabled(projectState.filters?.byErpError)) {
    showItem = showItem && applyErpErrorFilter(projectState, row)
  }

  return showItem
}
