import { RootAppState } from 'store/configureStore'

export function allFilteredPartTypeIds(state: RootAppState) {
  const partTypeIds = []

  for (const assemblyHeaderId of state.project?.filteredAssemblyHeaderIds) {
    for (const partTypePointer of state.project?.assemblyHeaders[
      assemblyHeaderId
    ].filteredPartTypePointers) {
      partTypeIds.push(partTypePointer.id)
    }
  }

  return partTypeIds
}
