import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faPencilRuler, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@mui/material'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { SetLoadingMessage } from '../../../store/Application/appActions'
import './spinner.sass'

const mapDispatchToProps = (dispatch) => ({
  setLoadingMessage: (message: string) => dispatch(SetLoadingMessage(message)),
})

type SpinningProps = {
  message?: string
  setLoadingMessage: (message: string) => void
  log?: boolean
  fixed?: boolean
}

const Spinning = (props: SpinningProps) => {
  const dispatch = useAppDispatch()

  const currentMessage = useAppSelector(
    (state: RootAppState) => props.message || state.app.loadingMessage
  )

  useEffect(() => {
    dispatch(SetLoadingMessage(props.message))

    return () => {
      dispatch(SetLoadingMessage(''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.message])

  return (
    <>
      <div
        className={`spinner ${props.fixed ? 'spinner-fixed' : ''}`}
        style={{}}
      >
        <div className="container">
          <div className={'loader theme-loader'} />
          {props.message && (
            <>
              <Typography variant="h6" color="textSecondary">
                {currentMessage}
              </Typography>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export const SpinningIcon = (props: { className?: string }) => {
  return (
    <div className={'spinning-icon ' + props.className || ''}>
      <FontAwesomeIcon icon={faSpinner as IconProp} spin={true} />
    </div>
  )
}

export const SpinningStaticIcon = (props: { className?: string }) => {
  return (
    <div className={`spinning-icon ${props.className ? props.className : ''}`}>
      <FontAwesomeIcon icon={faPencilRuler as IconProp} />
    </div>
  )
}

export default connect(null, mapDispatchToProps)(Spinning)
