import { AssemblyHeaderRow } from 'model/Project/BoMItemRow'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  ProjectState,
  WorkingStepFilter,
} from '../../../../store/Project/ProjectTypes'

export const refreshWorkingStepFilter = (state: ProjectState) => {
  if (!state.assemblyHeaders) {
    return state
  }

  const uniqueWorkingSteps = new Set<WorkingStepType>()

  state.assemblyHeadersIds.forEach((assemblyHeaderId) => {
    const assembly = state.assemblyHeaders[
      assemblyHeaderId
    ] as AssemblyHeaderRow

    assembly.partTypeActivities
      ?.map((x) => x.primaryWorkingStep)
      .forEach((x) => uniqueWorkingSteps.add(x))

    assembly.assemblyActivities
      ?.map((x) => x.primaryWorkingStep)
      .forEach((x) => uniqueWorkingSteps.add(x))
  })

  // const disabledWorkingSteps =
  //   state.filters.byWorkingStep.filter.disabledFilters

  const filter: WorkingStepFilter = {
    type: 'WorkingStepsFilter',
    availableFilters: Array.from(uniqueWorkingSteps),
    enabledFilters: state.filters?.byWorkingStep?.filter?.enabledFilters
      .map((x) => (uniqueWorkingSteps.has(x) ? x : null))
      .filter((x) => x !== null),
  }

  // state.filters.byWorkingStep.filter = filter
  const newState: ProjectState = {
    ...state,
    filters: {
      ...state.filters,
      byWorkingStep: {
        ...state.filters.byWorkingStep,
        filter: filter,
        active: filter.enabledFilters.length > 0,
      },
    },
  }

  return newState
}
