import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemController } from 'controllers/Project/BomItemController'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { RootAppState } from 'store/configureStore'

export const checkManufacturability = createAsyncThunk<
  void,
  { bomItemId: string },
  { state: RootAppState }
>(
  'bomItem/checkManufacturability',
  async ({ bomItemId }: { bomItemId: string }, thunkAPI) => {
    const controller = new BomItemController()

    try {
      return await controller.CheckManufacturability(
        bomItemId,
        WorkingStepType.SheetBending
      )
    } catch (err) {
      ShowException('project', err)
      return thunkAPI.rejectWithValue(err)
    }
  }
)
