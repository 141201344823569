import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ProjectState } from 'store/Project/ProjectTypes'

export const setOperationProgress: CaseReducer<
  ProjectState,
  PayloadAction<{
    operation: string
    progress: number
  }>
> = (state, action) => {
  if (state.currentOperations[action.payload.operation]) {
    state.currentOperations[action.payload.operation].progress =
      action.payload.progress
  }
}
