import cadex from '@cadexchanger/web-toolkit'
import { Button, ButtonGroup } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModelController } from '../manager/ModelController'

export const DisplayModeTool = (props: {
  viewManager: ModelController
  allowedDisplayModes?: cadex.ModelPrs_DisplayMode[]
}) => {
  const [currentDisplayModel, setCurrentDisplayModel] = useState(
    cadex.ModelPrs_DisplayMode.ShadedWithBoundaries
  )

  const { t } = useTranslation('project')

  const translations = useMemo(() => {
    return {
      [cadex.ModelPrs_DisplayMode.Shaded]: t('model-view-shaded', 'shaded'),
      [cadex.ModelPrs_DisplayMode.ShadedWithBoundaries]: t(
        'model-view-shaded-with-boundaries',
        'shaded with boundaries'
      ),
      [cadex.ModelPrs_DisplayMode.Wireframe]: t(
        'model-view-wireframe',
        'wireframe'
      ),
    }
  }, [t])

  const allowedDisplayModes = props.allowedDisplayModes ?? [
    cadex.ModelPrs_DisplayMode.Shaded,
    cadex.ModelPrs_DisplayMode.ShadedWithBoundaries,
    cadex.ModelPrs_DisplayMode.Wireframe,
  ]

  useEffect(() => {
    props.viewManager.onDisplayModeChanged((mode) => {
      setCurrentDisplayModel(mode)
    })
  }, [props.viewManager])

  if (!props.viewManager) return null

  return (
    <ButtonGroup>
      {allowedDisplayModes.map((displayMode) => {
        return (
          <Button
            key={displayMode}
            onClick={() => {
              props.viewManager.setDisplayMode(displayMode)
            }}
            variant={
              currentDisplayModel === displayMode ? 'contained' : 'outlined'
            }
          >
            {translations[displayMode]}
          </Button>
        )
      })}
      {/* <Button
        onClick={() =>
          props.viewManager.setDisplayMode(cadex.ModelPrs_DisplayMode.Shaded)
        }
        variant={
          currentDisplayModel === cadex.ModelPrs_DisplayMode.Shaded
            ? 'contained'
            : 'outlined'
        }
      >
        Shaded
      </Button>

      <Button
        onClick={() =>
          props.viewManager.setDisplayMode(
            cadex.ModelPrs_DisplayMode.ShadedWithBoundaries
          )
        }
        variant={
          currentDisplayModel ===
          cadex.ModelPrs_DisplayMode.ShadedWithBoundaries
            ? 'contained'
            : 'outlined'
        }
      >
        Shaded with Bondaries
      </Button>
      <Button
        onClick={() =>
          props.viewManager.setDisplayMode(cadex.ModelPrs_DisplayMode.Wireframe)
        }
        variant={
          currentDisplayModel === cadex.ModelPrs_DisplayMode.Wireframe
            ? 'contained'
            : 'outlined'
        }
      >
        Wireframe
      </Button> */}
    </ButtonGroup>
  )
}
