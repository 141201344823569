import { TextField, Typography } from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useAppLogin } from 'features/Auth/useAppLogin'
import { isEqual } from 'lodash'
import { useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { GettingStartedBody } from '../Components/GettingStartedBody'
import { GettingStartedCard } from '../Components/GettingStartedCard'
import { GettingStartedPreviousAndNextButtons } from '../Components/GettingStartedPreviousAndNextButtons'
import { GettingStartedUserHeader } from '../Components/GettingStartedUserHeader'
import { GettingStartedStep } from '../Components/GettingStatedStep'
import { navigateToNextStep } from '../store/GettingStartedActions'
import { gettingStartedActions } from '../store/GettingStartedReducer'
import { gettingStartedSelectors } from '../store/GettingStartedSelectors'

const useStyles = makeStyles({ name: 'WelcomeStep' })((theme) => ({
  userInfoForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  userFormSection: {},
}))

export function WelcomeStep() {
  const { classes } = useStyles()

  // const context = useContext(GettingStartedPageContext)
  const { t } = useTranslation()

  const userForm = useAppSelector(
    gettingStartedSelectors.getUserFormSelector,
    isEqual
  )
  const dispatch = useAppDispatch()

  const { appLoginWithPopup, error: loginError } = useAppLogin()

  const navigate = useNavigate()

  const handleUserFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity()
      return
    }

    await appLoginWithPopup({
      emailHint: userForm.email,
      disableRedirection: true,
      screen_hint: 'signUp',
      prefill: {
        email: userForm.email,
        given_name: userForm.firstName,
        family_name: userForm.lastName,
      },
    })

    dispatch(navigateToNextStep(navigate))
  }

  const handleUserFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      gettingStartedActions.setUserForm({
        [e.target.name]: e.target.value,
      })
    )
  }

  // const handleAppLogin = async () => {
  //   try {
  //     await appLoginWithPopup({
  //       disableRedirection: true,
  //     })

  //     dispatch(navigateToNextStep(navigate))
  //   } catch (err) {
  //     ShowException('signin', err)
  //   }
  // }

  const formRef = useRef<HTMLFormElement>()

  const userFormValid =
    Boolean(userForm?.email) &&
    Boolean(userForm?.firstName) &&
    Boolean(userForm?.lastName)

  return (
    <GettingStartedStep>
      <GettingStartedCard>
        <GettingStartedUserHeader />
        <LocalizedTypography
          translationKey="getting-started:welcome-to-quotation-factory-title"
          variant="h5"
          sx={{ textAlign: 'center', paddingTop: 2 }}
          fontWeight={'bold'}
        >
          Getting Started using Quotation Factory platform
        </LocalizedTypography>
        <form
          style={{ height: '100%' }}
          onSubmit={handleUserFormSubmit}
          id="user-form"
          ref={formRef}
          noValidate
        >
          <GettingStartedBody>
            <section>
              <LocalizedTypography translationKey="getting-started:welcome-to-quotation-factory">
                Welcome to Quotation Factory. This platform is designed to help
                you create and manage your metal work bill of materials and
                quotations. You can create a new quotation, edit it, delete it,
                and much more, tailored for your factory needs.
              </LocalizedTypography>
            </section>
            <section className={classes.userFormSection}>
              <LocalizedTypography translationKey="getting-started:welcome-tell-us-more-about-you-and-your-factory">
                Tell us more about you and your factory so we can help you get
                started
              </LocalizedTypography>
              <div className={classes.userInfoForm}>
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    justifyContent: 'space-between',
                  }}
                >
                  <TextField
                    label={t('getting-started:first-name', 'first name')}
                    name="firstName"
                    value={userForm?.firstName || ''}
                    onChange={handleUserFormChange}
                    variant="filled"
                    fullWidth
                    required
                    autoFocus
                    inputProps={{
                      onInvalid: (e) => {
                        e.currentTarget.setCustomValidity(
                          t(
                            'getting-started:first-name-required',
                            'First name is required'
                          )
                        )
                      },
                      onChange: (e) => {
                        e.currentTarget.setCustomValidity('')
                      },
                    }}
                  />
                  <TextField
                    label={t('getting-started:last-name', 'last name')}
                    name="lastName"
                    value={userForm?.lastName || ''}
                    onChange={handleUserFormChange}
                    variant="filled"
                    fullWidth
                    required
                    inputProps={{
                      onInvalid: (e) => {
                        e.currentTarget.setCustomValidity(
                          t(
                            'getting-started:last-name-required',
                            'Last name is required'
                          )
                        )
                      },
                      onChange: (e) => {
                        e.currentTarget.setCustomValidity('')
                      },
                    }}
                  />
                </div>

                <TextField
                  label={t('getting-started:email', 'email')}
                  name="email"
                  value={userForm?.email || ''}
                  onChange={handleUserFormChange}
                  type="email"
                  variant="filled"
                  fullWidth
                  required
                  inputProps={{
                    onInvalid: (e) => {
                      if (e.currentTarget.validity.typeMismatch) {
                        e.currentTarget.setCustomValidity(
                          t(
                            'getting-started:email-invalid',
                            'Invalid e-mail address'
                          )
                        )
                        return
                      }
                      if (e.currentTarget.validity.valueMissing) {
                        e.currentTarget.setCustomValidity(
                          t(
                            'getting-started:email-required',
                            'E-mail is required'
                          )
                        )
                        return
                      }

                      e.currentTarget.setCustomValidity('')
                    },
                    onChange: (e) => {
                      e.currentTarget.setCustomValidity('')
                    },
                    onInput: (e) => {
                      e.currentTarget.setCustomValidity('')
                    },
                  }}
                />
              </div>
            </section>

            <Typography variant="body2" style={{ textAlign: 'center' }}>
              <Trans
                i18nKey={'getting-started:terms-and-privacy'}
                components={{
                  a: (
                    <a
                      href="https://www.quotationfactory.com/en/privacy/"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              >
                By clicking the next button, you agree to our{' '}
                <a>privacy policy</a>
              </Trans>
            </Typography>
            {/* <Button onClick={handleAppLogin} variant="outlined">
              {t(
                'getting-started:continue-with-existing-account',
                'or continue with your existing account'
              )}
            </Button> */}

            {loginError && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem',
                  paddingTop: '1rem',
                }}
              >
                <LocalizedTypography
                  translationKey="getting-started:login-confirmation"
                  variant="body1"
                  color="error"
                  style={{
                    textAlign: 'center',
                    paddingBottom: 0,
                  }}
                >
                  Your signup/login should be confirmed in the popup window. If
                  you have any issues, please try again.
                </LocalizedTypography>
                <LocalizedTypography
                  translationKey={`getting-started:login-error-${loginError.name}`}
                  variant="caption"
                  color="error"
                  style={{ textAlign: 'right' }}
                >
                  {loginError.message}
                </LocalizedTypography>
              </div>
            )}
          </GettingStartedBody>
          <GettingStartedPreviousAndNextButtons
            currentStep={0}
            submitButton={userFormValid}
          />
        </form>
      </GettingStartedCard>
    </GettingStartedStep>
  )
}
