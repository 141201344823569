import { projectBuyerSelector } from './ProjectBuyerSelector'
import {
  BomItemAttachementEditableStateSelector,
  ProjectEditableStateSelector,
} from './ProjectEditableStateSelector'
import { ProjectIsEmptySelector } from './ProjectIsEmptySelector'
import { ProjectProcessFlowSelector } from './ProjectProcessFlowSelector'
import { VisibleWorkingStepsSelector } from './VisibleWorkingStepsSelector'
import { articleSelectorAllowedSelector } from './articleSelectorAllowedSelector'
import {
  assemblyWorkingStepsSummaryWithCounterSelector,
  projectPriceSummariesWorkingStepSummary,
} from './assemblyWorkingStepsSummaryWithCounterSelector'
import { bomItemAttachmentSelector } from './bomItemAttachmentSelector'
import { bomItemCommentSelector } from './bomItemCommentSelector'
import { bomItemCuttingPlansSelector } from './bomItemCuttingPlansSelector'
import { bomItemDimensionsSelector } from './bomItemDimensionsSelector'
import { bomItemExportedToErpSelector } from './bomItemExportedToErpSelector'
import { bomItemExternalUrlSelector } from './bomItemExternalUrlSelector'
import { bomItemFileNameSelector } from './bomItemFileNameSelector'
import {
  bomItemFinancialSelector,
  bomItemFinancialSum,
  bomItemMaterialFinancialSelector,
  bomItemTargetPriceSelector,
} from './bomItemFinancialSelector'
import { bomItemFocusedItemsSelector } from './bomItemFocusedItemsSelector'
import { bomItemIsOpenSelector } from './bomItemIsOpenSelector'
import { bomItemIsPurchasingPartSelector } from './bomItemIsPurchasingPartSelector'
import { bomItemIsRememberedPurchasingBoMItemSelector } from './bomItemIsRememberedPurchasingBoMItemSelector'
import { bomItemIssueCodeSelector } from './bomItemIssueCodeSelector'
import { bomItemIssuesSelector } from './bomItemIssuesSelector'
import { bomItemManufacturabilitySelector } from './bomItemManufacturabilitySelector'
import { bomItemMaterialHasCustomPrice } from './bomItemMaterialHasCustomPriceSelector'
import { bomItemActivityArticleSelector } from './bomItemMaterialSummarySelector'
import { bomItemNameSelector } from './bomItemNameSelector'
import { bomItemNestingTypeSelector } from './bomItemNestingTypeSelector'
import { bomItemRowNumberSelector } from './bomItemNumberSelector'
import { bomItemPrecisionsSelector } from './bomItemPrecisionsSelector'
import { bomItemPriceSummarySelector } from './bomItemPriceSummarySelector'
import { bomItemQuantityIsEditableSelector } from './bomItemQuantityIsEditableSelector'
import { bomItemQuantitySelector } from './bomItemQuantitySelector'
import {
  bomItemIsSearchedSelector,
  bomItemIsSelectedSelector,
  bomItemSearchedSelector,
  bomItemSelectedCounter,
  headerIsSelectedSelector,
} from './bomItemSearched'
import { bomItemSelector } from './bomItemSelector'
import { bomItem3DPathSelector } from './bomItemThreeDPathSelector'
import { bomItemThumbnailSelector } from './bomItemThumbnailSelector'
import { bomItem2DPathSelector } from './bomItemTwoDPathSelector'
import { bomItemActivityHasArticleSelected } from './bomItemUsesArticleSelected'
import { bomItemWeightSelector } from './bomItemWeightSelector'
import { bomItemActivitiesSelector } from './bomItemWorkingStepsSelector'
import { bomItemsNamesSelector } from './bomItemsNamesSelector'
import { canEditRowSelector } from './canEditRowSelector'
import { projectBuyerContactInfoSelector } from './contactInfo/projectContactInfoSelector'
import { cuttingPlansSelector } from './cuttingPlansSelector'
import { headerFilteredRowsSelector } from './headerFilteredRowsSelector'
import { headerRowsCounter } from './headerRowsCounterSelector'
import { materialHeaderCuttingPlanQuantitySelector } from './materialHeaderCuttingPlanQuantitySelector'
import {
  allNotAllowedWorkingStepsSelector,
  notAllowedWorkingStepsSelector,
} from './notAllowedWorkingStepsSelector'
import { previousAndNextBomItemIdsSelector } from './previousAndNextBomitem/previousAndNextBomItemIdsSelector'
import { projectCreatedInPortalSelector } from './projectCreatedInPortalSelector'
import { projectCurrentViewSelector } from './projectCurrentViewSelector'
import { projectEstimatedDeliveryDateSelector } from './projectDeliveryDateSelector'
import { projectDeliveryDaysSelector } from './projectDeliveryDaysSelector'
import { projectDiscountRatioSelector } from './projectDiscountRatioSelector'
import { projectEstimatedDeliveryDaysSelector } from './projectEstimatedDeliveryDaysSelector'
import {
  projectCostPriceSelector,
  projectGrossSalesPriceSelector,
} from './projectGrossSalesPriceSelector'
import { projectIdSelector } from './projectIdSelector'
import { projectInBuyerViewSelector } from './projectInBuyerViewSelector'
import { projectIsExportingToErpSelector } from './projectIsExportingToErpSelector'
import { projectIsManufacturabilityCheckRunningSelector } from './projectIsManufacturabilityCheckRunningSelector'
import { projectIsNestingSelector } from './projectIsNestingSelector'
import {
  projectNetSalesPriceMoneySelector,
  projectNetSalesPriceSelector,
} from './projectNetSalesPriceSelector'
import * as projectOperationPendingSelector from './projectOperationPendingSelector'
import { projectOrderNumberSelector } from './projectOrderNumberSelector'
import { projectPricePerBatchSelector } from './projectPricePerBatchSelector'
import { projectQuantitySelector } from './projectQuantitySelector'
import { projectQuotationDateSelector } from './projectQuotationDateSelector'
import { projectReferenceSelector } from './projectReferenceSelector'
import { projectRequestedDeliveryDateSelector } from './projectRequestedDeliveryDateSelector'
import { projectSalesRepresentativeSelector } from './projectSalesRepresentativeSelector'
import { projectSellingPartySelector } from './projectSellingPartySelector'
import { projectStatusSelector } from './projectStatusSelector'
import { projectSurchargeRatioSelector } from './projectSurchargeRatioSelector'
import { projectVisibleInPortalSelector } from './projectVisibleInPortalSelector'
import { projectWorkingStepsStatsSelector } from './projectWorkingStepsStatsSelector'
import {
  selectedBomItemsPointersSelector,
  selectedBomItemsSelector,
} from './selectedBomItemsSelector'
import { shallUpdateLogsSelector } from './shallUpdateLogsSelector'
import { rootAssembliesSelector } from './treeView/rootAssembliesSelector'

import { BomItemTodosSelector } from 'features/BillOfMaterials/BomItemTodos/BomItemTodosSelector'
import { acceptedDocumentTypesSelector } from './acceptedDocumentTypesSelector'
import { allFilteredPartTypeIds } from './allFilteredPartTypeIds'
import { bomItemExists } from './bomItemExists'
import { bomItemGeometryTypeSelector } from './bomItemGeometryType'
import { bomItemRecognizedGeometryTypesSelector } from './bomItemRecognizedGeometryTypesSelector'
import { partInstancePartTypeSelector } from './partInstancePartTypeSelector'
import { projectValidityPeriodSelector } from './projectValidityPeriodSelector'
import { quotationExpirationDateSelector } from './quotationExpirationDateSelector'
import { bomItemMaterialIsProvidedSelector } from './bomItemMaterialIsProvidedSelector'

export const projectSelectors = {
  ProjectIsEmptySelector,
  ProjectEditableStateSelector,
  ProjectProcessFlowSelector,
  bomItemAttachmentSelector,
  bomItemCommentSelector,
  bomItemDimensionsSelector,
  bomItemExportedToErpSelector,
  bomItemExternalUrlSelector,
  bomItemFileNameSelector,
  bomItemFinancialSelector,
  bomItemFocusedItemsSelector,
  bomItemIsOpenSelector,
  bomItemIsPurchasingPartSelector,
  bomItemIsRememberedPurchasingBoMItemSelector,
  bomItemIssuesSelector,
  bomItemManufacturabilitySelector,
  bomItemMaterialSummarySelector: bomItemActivityArticleSelector,
  bomItemNameSelector,
  bomItemNestingTypeSelector,
  bomItemRowNumberSelector,
  bomItemPriceSummarySelector,
  bomItemQuantitySelector,
  bomItemSearchedSelector,
  bomItemIsSearchedSelector,
  bomItemSelector,
  bomItemThumbnailSelector,
  bomItemWorkingStepsSelector: bomItemActivitiesSelector,
  cuttingPlansSelector,
  headerFilteredRowsSelector,
  headerRowsCounter,
  materialHeaderCuttingPlanQuantitySelector,
  projectGrossSalesPriceSelector,
  projectIdSelector,
  projectInBuyerViewSelector,
  projectNetSalesPriceSelector,
  projectOperationPendingSelector,
  projectOrderNumberSelector,
  projectPricePerBatchSelector,
  projectQuantitySelector,
  projectReferenceSelector,
  projectSalesRepresentativeSelector,
  projectSellingPartySelector,
  projectStatusSelector,
  projectWorkingStepsStatsSelector,
  bomItemQuantityIsEditableSelector,
  selectedBomItemsSelector,
  assemblyWorkingStepsSummaryWithCounterSelector,
  projectPriceSummariesWorkingStepSummary,
  shallUpdateLogsSelector,
  canEditRowSelector,
  bomItemCuttingPlansSelector,
  bomItemTargetPriceSelector,
  projectIsNestingSelector,
  projectIsExportingToErpSelector,
  projectIsManufacturabilityCheckRunningSelector,
  previousAndNextBomItemIdsSelector,
  projectSurchargeRatioSelector,
  projectDiscountSelector: projectDiscountRatioSelector,
  projectNetSalesPriceMoneySelector,
  projectCostPriceSelector,
  notAllowedWorkingStepsSelector,
  bomItemIssueByCodeSelector: bomItemIssueCodeSelector,
  projectRequestedDeliveryDateSelector,
  projectCreatedInPortalSelector,
  bomItemFinancialSum,
  VisibleWorkingStepsSelector,
  projectCurrentViewSelector,
  bomItemIsSelectedSelector,
  bomItemSelectedCounter,
  selectedBomItemsPointersSelector,
  headerIsSelectedSelector,
  projectEstimatedDeliveryDaysSelector,
  BomItemAttachementEditableStateSelector,
  rootAssembliesSelector,
  allNotAllowedWorkingStepsSelector,
  bomItemsNamesSelector,
  projectVisibleInPortalSelector,
  articleSelectorAllowedSelector,
  bomItemPrecisionsSelector,
  projectBuyerContactInfoSelector,
  projectBuyerSelector,
  bomItemWeightSelector,
  bomItem2DPathSelector,
  bomItem3DPathSelector,
  projectDeliveryDaysSelector,
  projectQuotationDateSelector,
  projectDeliveryDateSelector: projectEstimatedDeliveryDateSelector,
  bomItemMaterialHasCustomPrice,
  bomItemHasArticleSelected: bomItemActivityHasArticleSelected,
  bomItemMaterialFinancialSelector,
  partInstancePartTypeSelector,
  quotationExpirationDateSelector,
  projectValidityPeriodSelector,
  bomItemGeometryTypeSelector,
  bomItemRecognizedGeometryTypesSelector,
  acceptedDocumentTypesSelector,
  BomItemTodosSelector,
  allFilteredPartTypeIds,
  bomItemExists,
  bomItemMaterialIsProvidedSelector
}
