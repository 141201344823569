import { BaseController } from 'controllers/BaseController'
import React, { useCallback } from 'react'

type ApiErrors = {
  validation: unknown
} & Error

export function useAppController<T extends BaseController<unknown>>(
  controllerFactory: () => T
) {
  const [loading, setLoading] = React.useState<{ [k: string]: boolean }>({})
  const [error, setError] = React.useState<{ [k: string]: ApiErrors }>({})
  const [validation, setValidation] = React.useState<{
    [validationKey: string]: string
  }>({})
  const [progress, setProgress] = React.useState<{
    [operationKey: string]: number
  }>({})

  const controller = React.useRef(controllerFactory())

  React.useEffect(() => {
    controller.current.subscribeToUpdates?.((status) => {
      setLoading(status.loading)
      setError(status.error)
      setValidation(status.validation)
      setProgress(status.progress)
    })
  }, [])

  const resetRequestsState = useCallback(() => {
    try {
      setLoading({})
      setError({})
      setValidation({})
      setProgress({})

      controller.current.CancelRequests()
    } catch (err) {
      console.info('requests states canceled', err)
    }
  }, [])

  return {
    controller: controller.current as T,
    loading,
    error,
    validation,
    progress,
    resetRequestsState,
    setLoading,
  }
}
