import { Reducer } from 'redux'
import { UIActionNames, UIActionTypes, UIState } from './UITypes'

const initialState: UIState = {
  Modals: undefined,
}

export const UIReducer: Reducer<UIState, UIActionTypes> = (
  state = initialState,
  action: UIActionTypes
) => {
  switch (action.type) {
    case UIActionNames.OpenModal: {
      return {
        ...state,
        Modals: {
          CurrentModal: action.modalType,
          ModalProps: action.modalProps,
        },
      }
    }
    case UIActionNames.CloseModal: {
      return {
        ...state,
        Modals: undefined,
      }
    }
    default:
      return state
  }
}
