import { BaseController } from 'controllers/BaseController'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import {
  MoneyPerQuantityDto,
  PriceSummaryDto,
  SetResourceDiscountRequest,
  SetResourceSurchargeRequest,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import store from 'store/configureStore'
import { GetContext } from 'store/storeUtils'
import { IProjectResourceAPI, ProjectResourceAPI } from './ProjectResourceAPI'

export class ProjectResourceController extends BaseController<IProjectResourceAPI> {
  private projectId: string

  constructor(resourceId: string, private priceSummaryKey: string) {
    let _projectId
    super((onRequestChanged) => {
      const { partyId, projectId } = GetContext()
      _projectId = projectId
      return new ProjectResourceAPI(
        partyId,
        projectId,
        resourceId,
        onRequestChanged
      )
    })

    this.projectId = _projectId
  }

  private ChangePriceSummaryInStore = (newValues: Partial<PriceSummaryDto>) => {
    store.dispatch(
      newProjectActions.setProjectStateProperties({
        priceSummaries: {
          [this.priceSummaryKey]: {
            ...newValues,
            totalSalesPrice: {
              value: null,
            },
          },
        },
      })
    )
  }

  public SetResourceSurcharge = async (req: SetResourceSurchargeRequest) => {
    try {
      await this.api.SetResourceSurcharge(req)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public SetResourceDiscount = async (req: SetResourceDiscountRequest) => {
    try {
      await this.api.SetResourceDiscount(req)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public SetResourceWorkingStepCostPrice = async (
    costPrice: MoneyPerQuantityDto,
    workingStepType: WorkingStepType
  ) => {
    try {
      this.ChangePriceSummaryInStore({ rate: costPrice })
      await this.api.SetResourceWorkingStepCostPrice(costPrice, workingStepType)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public DeleteResourceDiscount = async () => {
    try {
      await this.api.DeleteResourceDiscount()
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public DeleteResourceSurcharge = async () => {
    try {
      await this.api.DeleteResourceSurcharge()
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public DeleteResourceCostPrice = async (workingStepType: WorkingStepType) => {
    try {
      await this.api.DeleteResourceWorkingStepCostPrice(workingStepType)
      this.ChangePriceSummaryInStore({ isCostPriceManuallySet: false })
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
