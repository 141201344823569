import cadex, { ModelPrs_SelectionMode } from '@cadexchanger/web-toolkit'
import { MenuItem, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { ModelController } from '../manager/ModelController'

type Props = {
  viewManager: ModelController
  label?: string
}

const SelectionModes = [
  cadex.ModelPrs_SelectionMode.Face,
  cadex.ModelPrs_SelectionMode.Solid,
  cadex.ModelPrs_SelectionMode.Vertex,
]

export const SelectionMode = (props: Props) => {
  const [selectionMode, setSelectionMode] =
    useState<cadex.ModelPrs_SelectionMode>(cadex.ModelPrs_SelectionMode.Face)

  useEffect(() => {
    props.viewManager.onSelectionModeChanged((mode) => {
      setSelectionMode(mode)
    })
  })

  return (
    <TextField
      select
      label={props.label}
      onChange={(e) => {
        props.viewManager.SetSelectionMode(
          // cadex.ModelPrs_SelectionMode[e.target.value]
          e.target.value as unknown as ModelPrs_SelectionMode
        )
      }}
      value={selectionMode}
      fullWidth
    >
      {SelectionModes.map((entry) => {
        return (
          <MenuItem key={entry} value={entry}>
            {
              Object.keys(cadex.ModelPrs_SelectionMode)[
                Object.values(cadex.ModelPrs_SelectionMode).indexOf(entry)
              ]
            }
          </MenuItem>
        )
      })}
      {/* {Object.entries(cadex.ModelPrs_SelectionMode).map((entry) => {
        return (
          <MenuItem key={entry[0]} value={entry[1]}>
            {entry[0]}
          </MenuItem>
        )
      })} */}
    </TextField>
  )
}
