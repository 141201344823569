import { BoMItemRow, BomItemType } from 'model/Project/BoMItemRow'
import { WorkingStepFilter } from 'store/Project/ProjectTypes'
import { showPartTypeBasedOnWorkingSteps } from './showPartTypeBasedOnWorkingSteps'

export function applyWorkingStepFilter(
  filter: WorkingStepFilter,
  row: BoMItemRow
): boolean {
  let workingStepFilterAppliedShowItem = true

  switch (row.type) {
    case BomItemType.partType:
    case BomItemType.partInstance: {
      workingStepFilterAppliedShowItem = showPartTypeBasedOnWorkingSteps(
        row.activities,
        filter
      )
      break
    }
    case BomItemType.assemblyType: {
      workingStepFilterAppliedShowItem = showPartTypeBasedOnWorkingSteps(
        row.assemblyActivities.concat(row.partTypeActivities),
        filter
      )
      break
    }
    case BomItemType.assemblyInstance: {
      workingStepFilterAppliedShowItem = showPartTypeBasedOnWorkingSteps(
        row.assemblyActivities.concat(row.partTypeActivities),
        filter
      )
      break
    }
  }

  return workingStepFilterAppliedShowItem
}
