import { RootAppState } from 'store/configureStore'

export const shallUpdateLogsSelector =
  (logType: 'geometry' | 'summary') => (state: RootAppState) => {
    switch (logType) {
      case 'geometry':
        return state.project.projectOperations.shallUpdateOperations
      case 'summary':
        return state.project.projectOperations.shallUpdateSummary
    }
  }
