import { createAsyncThunk } from '@reduxjs/toolkit'
import { SignalRService } from 'features/SignalR/service/SignalRService'
import { ShowException } from 'store/Application/appActions'
import { GetPartyId } from 'store/storeUtils'
import { fetchProjectSummaries } from './fetchProjectSummaries'
import { RootAppState } from 'store/configureStore'

export const subscribeToProjectListUpdates = createAsyncThunk<
  void,
  null,
  { state: RootAppState }
>('projectList/subscribeToProjectListUpdates', async (_, thunkAPI) => {
  const partyId = GetPartyId()

  try {
    const hub = await SignalRService.GetHub()
    hub.registerHandler('onProjectsListUpdates', () => {
      requestIdleCallback(() =>
        thunkAPI.dispatch(
          fetchProjectSummaries({
            subscribeToUpdates: false,
            isRefresh: true,
          })
        )
      )
    })

    hub.JoinGroup(`${partyId}_project_list`, () => {
      console.info('connected to project list updates')
    })
  } catch (err) {
    ShowException('project list', err)
  }
})
