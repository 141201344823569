/* eslint-disable react/no-unescaped-entities */
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Typography } from '@mui/material'
import { SaveButton } from 'components/Common/Button/SaveButton'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useAppController } from 'customHooks/useAppController'
import { useAppLogout } from 'features/Auth/useAppLogout'
import { UserController } from 'features/CustomerPortal/ConnectedParties/controllers/UserController'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AuthService } from 'services/auth'
import { ShowException } from 'store/Application/appActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { GettingStartedBody } from '../Components/GettingStartedBody'
import { GettingStartedCard } from '../Components/GettingStartedCard'
import { GettingStartedPreviousAndNextButtons } from '../Components/GettingStartedPreviousAndNextButtons'
import { GettingStartedUserHeader } from '../Components/GettingStartedUserHeader'
import { GettingStartedStep } from '../Components/GettingStatedStep'
import { gettingStartedRoutes } from '../GettingStartedRoutes'
import { navigateToNextStep } from '../store/GettingStartedActions'
import { gettingStartedSelectors } from '../store/GettingStartedSelectors'

const useStyles = makeStyles({ name: 'WelcomeStep' })((theme) => ({
  validationRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    paddingTop: theme.spacing(2),
    marginTop: 'auto',
    marginBottom: 'auto',
    // textAlign: 'center',
  },
}))

export function ValidateEmailStep() {
  const { classes } = useStyles()

  const { appLogout } = useAppLogout()
  const auth0User = useAppSelector(
    gettingStartedSelectors.getAuth0UserSelector,
    isEqual
  )

  const auth0 = useAuth0()

  const validationEmailDone = useAppSelector(
    gettingStartedSelectors.stepIsDoneSelector('validateEmail')
  )
  const dispatch = useAppDispatch()

  const { controller: userController, loading } = useAppController(() => {
    return new UserController()
  })

  const [activationMailSent, setActivationMailSent] = useState(false)
  const [activationEmailDisabled, setActivationEmailDisabled] = useState(false)

  const handleResentEmail = useCallback(async () => {
    try {
      await userController.SendActivationEmail({
        redirectUrl: `${window.location.origin}${gettingStartedRoutes.validateEmail.path}`,
      })
      setActivationMailSent(true)
      setActivationEmailDisabled(true)

      setTimeout(() => {
        setActivationEmailDisabled(false)
      }, 10_000)
    } catch (error) {
      ShowException('getting started', error)
    }
  }, [userController])

  useEffect(() => {
    if (auth0?.user?.email_verified === false) {
      if (!AuthService.GetCurrent()) {
        AuthService.Build(auth0)
      }

      handleResentEmail()
    }
  }, [auth0, handleResentEmail])

  const navigate = useNavigate()
  const handleNext = () => {
    dispatch(navigateToNextStep(navigate))
  }

  const nextStateIsAvailable = validationEmailDone

  const handleReturnToSignup = async () => {
    await appLogout({
      logoutParams: {
        returnTo: window.location.origin + gettingStartedRoutes.welcome.path,
      },
    })
  }

  return (
    <GettingStartedStep>
      <GettingStartedCard>
        <GettingStartedUserHeader />
        <GettingStartedBody>
          <LocalizedTypography
            translationKey="getting-started:validate-your-email-title"
            variant="h5"
            sx={{ textAlign: 'center' }}
          >
            Verify your email
          </LocalizedTypography>
          <section className={classes.validationRoot}>
            {/* <LocalizedTypography translationKey="getting-started:validate-sent-email">
              We've sent a verification email to
            </LocalizedTypography> */}
            <p>
              <Trans
                i18nKey="getting-started:validate-your-email-desc"
                components={{ b: <b /> }}
                values={{
                  email: auth0User?.email,
                }}
              >
                {
                  "We've sent a verification email to <b>{{email}}</b>. Please check your inbox and click the link to confirm your email address."
                }
              </Trans>
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '2rem',
                alignItems: 'center',
              }}
            >
              <LocalizedTypography translationKey="getting-started:validate-email-dind-received">
                Didn't receive the email?
              </LocalizedTypography>
              <SaveButton
                variant="contained"
                onClick={handleResentEmail}
                disabled={activationEmailDisabled}
                saving={loading['send-activation-email']}
              >
                {t('getting-started:validate-resend-email', 'resend email')}
              </SaveButton>
            </div>
            <div style={{ textAlign: 'right' }}>
              <Button onClick={handleReturnToSignup} variant="outlined">
                {t('getting-started:validate-email-exit', 'exit')}
              </Button>
            </div>
            {activationMailSent && (
              <div style={{ textAlign: 'center' }}>
                <Typography variant="caption" fontWeight={'bold'}>
                  <Trans
                    i18nKey="getting-started:validate-email-sent"
                    components={{
                      a: (
                        <a
                          href={`${gettingStartedRoutes.validateEmail.path}`}
                        />
                      ),
                    }}
                  >
                    the validation email was sent. Reload this page after you
                    have validated your email account by clicking <a>here</a>
                  </Trans>
                </Typography>
              </div>
            )}
          </section>
        </GettingStartedBody>
        <GettingStartedPreviousAndNextButtons
          currentStep={1}
          handleNext={nextStateIsAvailable && handleNext}
        />
      </GettingStartedCard>
    </GettingStartedStep>
  )
}
