import { KeyOption, matchSorter } from 'match-sorter'

export function markText(
  text: string | number,
  searchValue: string | undefined,
  notMatchReturn?: string
) {
  try {
    if (!Boolean(text)) {
      return ''
    }

    if (!Boolean(searchValue)) {
      return text
    }

    const strArr = text
      .toString()
      .split(new RegExp(`(${searchValue.trimEnd()})`, 'ig'))
    return strArr.map((ea, i) => {
      if (ea.trim().toLowerCase() === searchValue?.trim().toLowerCase()) {
        return (
          <mark key={`match${i}`} className="search-suggestions-match">
            {ea}
          </mark>
        )
      } else {
        return notMatchReturn === undefined ? ea : notMatchReturn
      }
    })
  } catch {
    return notMatchReturn === undefined ? text : notMatchReturn
  }
}

const ignoreChars = ['.', '-', '_', ',']
export function markTextSplitBySpaces(
  text: string | undefined,
  searchValue: string | undefined,
  splitChar = ' '
): string | TrustedHTML {
  try {
    if (!Boolean(text) || !Boolean(searchValue)) {
      return text || ''
    }

    const terms = searchValue
      ?.split(splitChar)
      .map((x) => x.trim())
      .filter((x) => x?.length > 1 && !ignoreChars.includes(x)) // remove empty terms

    let htmlText = text

    terms?.forEach((term) => {
      const reg = new RegExp(`(${term.trimEnd()})`, 'ig')
      const match = htmlText?.match(reg)

      if (match) {
        match.forEach((m) => {
          const reg = new RegExp(`${m}(?![^<]*>)(?![^<]*<\/mark>)`, 'mi')
          htmlText = htmlText?.replace(reg, `<mark>${m.trimEnd()}</mark>`)
        })
      }
    })

    htmlText = htmlText?.replaceAll('<mark><mark>', '<mark>')
    htmlText = htmlText?.replaceAll('</mark></mark>', '</mark>')

    return htmlText || ''
  } catch {
    return text || ''
  }
}

export function markTextWithSpacesComponent(
  text: string,
  searchValue: string,
  splitChar = ' '
) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: markTextSplitBySpaces(text, searchValue, splitChar),
      }}
    ></span>
  )
}

export function fuzzySearchMultipleWords<T>(
  rows: Array<T>, // array of data [{a: "a", b: "b"}, {a: "c", b: "d"}]
  keys: KeyOption<T>[], // keys to search ["a", "b"]
  filterValue: string // potentially multi-word search string "two words"
) {
  if (!filterValue || !filterValue.length) {
    return rows
  }

  const terms = filterValue.split(' ')
  if (!terms) {
    return rows
  }

  // reduceRight will mean sorting is done by score for the _first_ entered word.
  return terms.reduceRight(
    (results, term) =>
      matchSorter(results, term, {
        keys: keys,
      }),
    rows
  )
}
