import { BomItemType } from 'model/Project/BoMItemRow'
import { MaterialHeaderPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const cuttingPlansSelector =
  (materialHeaderPointer: MaterialHeaderPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, materialHeaderPointer)

    if (!bomItem) {
      console.warn(
        'cuttingPlansSelector: bomItem not found',
        materialHeaderPointer
      )
      return null
    }

    if (bomItem.type !== BomItemType.materialHeader) {
      console.warn('bomItemId is not a MaterialHeader')
      return null
    }

    return bomItem.cuttingPlans
  }
