import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'

export function bomItemExists(
  bomItemPointer: BomItemPointer
): (state: Partial<RootAppState>) => boolean {
  return (state: Partial<RootAppState>) => {
    if (!state.project?.activeProject) {
      return null
    }

    switch (bomItemPointer?.type) {
      case BomItemType.project: {
        return state.project?.activeProject !== undefined
      }
      case BomItemType.assemblyType: {
        return state.project.assemblyHeaders?.[bomItemPointer.id] !== undefined
      }
      case BomItemType.partType: {
        return state.project.partTypes?.[bomItemPointer.id] !== undefined
      }
      case BomItemType.assemblyInstance: {
        return (
          state.project.assemblyInstances?.[bomItemPointer.id] !== undefined
        )
      }
      case BomItemType.partInstance: {
        return state.project.partInstances?.[bomItemPointer.id] !== undefined
      }
      case BomItemType.materialHeader: {
        return state.project.materialHeaders?.[bomItemPointer.id] !== undefined
      }
      case BomItemType.routingHeader: {
        return state.project.routingHeaders?.[bomItemPointer.id] !== undefined
      }
      default:
        return false
    }
  }
}
