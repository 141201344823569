import { SettingsActionTypes, SettingsConstants } from './SettingsConstants'
import _ from 'lodash'
import {
  MaterialSummaryDto,
  MoneyPerQuantityDto,
} from '../../services/APIs/InternalAPI/internal-api.contracts'
import { QuantityDto } from '../../services/APIs/InternalAPI/internal-api.contracts'

export interface MaterialSummaryModel {
  model: MaterialSummaryDto
  purchasingData: PurchasingData
}

export class PurchasingData {
  public id: string
  public grossPriceUpdatedOn: string
  public grossPrice: MoneyPerQuantityDto
  public preferredSupplier: Supplier

  /**
   * calculated field with the gross price per one unit
   */
  public grossPricePerMass: MoneyPerQuantityDto
  public grossPriceSource: GrossPriceSource
  public defaultPurchasingQuantity: QuantityDto
  public purchasingUnit: QuantityDto
}

export enum Supplier {
  NotInitialized = 0,
  MCB = 1,
}

export enum GrossPriceSource {
  NotInitialized = 0,
  StandardCatalog = 1,
  OrganizationLevelDefaults = 2,
  SupplyChainIntegration = 3,
}

export type SettingsState = {
  MaterialSettingsList: MaterialSummaryModel[]
}

const initialState: SettingsState = {
  MaterialSettingsList: undefined,
}

export const SettingsReducer = (
  state = initialState,
  action: SettingsActionTypes
): SettingsState => {
  switch (action.type) {
    case SettingsConstants.MATERIAL_SETTINGS_CLEAR_LIST: {
      return initialState
    }
    case SettingsConstants.MATERIAL_DATA_RECEIVED: {
      return {
        ...state,
        MaterialSettingsList: state.MaterialSettingsList.map((x) =>
          x.model.id === action.materialData.model.id ? action.materialData : x
        ),
      }
    }
    case SettingsConstants.MATERIAL_SETTINGS_LIST_RECEIVED: {
      return {
        ...state,
        MaterialSettingsList: action.materialsSettings.sort((a, b) =>
          a.model.id.localeCompare(b.model.id, 'en', { numeric: true })
        ),
      }
    }
    case SettingsConstants.MATERIAL_SETTINGS_ITEM_RECEIVED: {
      const currentSettingIndex =
        state.MaterialSettingsList &&
        state.MaterialSettingsList.findIndex(
          (x) => x.model.id === action.materialSetting.model.id
        )

      if (currentSettingIndex !== -1) {
        const newMaterialSettings = Object.assign(
          [],
          state.MaterialSettingsList
        )
        newMaterialSettings[currentSettingIndex] = action.materialSetting

        return {
          ...state,
          MaterialSettingsList: newMaterialSettings,
        }
      } else {
        return {
          ...state,
          MaterialSettingsList: [
            ...state.MaterialSettingsList,
            action.materialSetting,
          ].sort((a, b) =>
            a.model.description.localeCompare(b.model.description, 'en', {
              numeric: true,
            })
          ),
        }
      }
    }

    case SettingsConstants.MATERIAL_SETTINGS_ITEM_CHANGED: {
      const materialSetting = state.MaterialSettingsList.find(
        (x) => x.model.id === action.materialId
      )

      if (!materialSetting) throw new Error(`material not found`)

      const defaultPurchasingQuantity: QuantityDto = { value: 0, unit: 'kg' }

      const newRow = _.merge({}, materialSetting, {
        purchasingData: _.merge(
          {},
          {
            ...action.purchasingData,
            defaultPurchasingQuantity: {
              ...defaultPurchasingQuantity,
              ...action.purchasingData.defaultPurchasingQuantity,
            },
          }
        ),
      })

      return {
        ...state,
        MaterialSettingsList: state.MaterialSettingsList.map((x) => {
          return x.model.id === action.materialId ? newRow : x
        }),
      }
    }
    default:
      return state
  }
}
