import { IssueCode } from 'services/APIs/InternalAPI/internal-api.contracts'

export const sellerTodosIssueCodes = [
  IssueCode.MaterialNotSet,
  IssueCode.MaterialCostPriceNotSet,
  IssueCode.ProductionTimeNotSet,
  IssueCode.UpperSideNotSelected,
  IssueCode.UpperSideSelectedNotNeeded,
  IssueCode.MaterialAndManualCostPriceNotSet,
  IssueCode.WorkingStepUsingRawMaterialMissing,
  IssueCode.WorkingStepNotSet,
]

export const customerTodosIssueCodes = [
  IssueCode.MaterialNotSet,
  IssueCode.UpperSideNotSelected,
  IssueCode.UpperSideSelectedNotNeeded,
]

export const todosIssueCodes = new Set([
  ...sellerTodosIssueCodes,
  ...customerTodosIssueCodes,
])
