/* eslint-disable @typescript-eslint/no-unused-vars */
import cadex, { ModelData_Shape } from '@cadexchanger/web-toolkit'
import { ModelEnvironment } from './ModelEnvironment'
import { SceneDataStore } from './store/SceneDataStore'
import { Rh24SceneNode } from './store/types/Rh24SceneNode'
import { ModelPMIVisitor } from './visitors/ModelPMIVisitor'

export type PMIViewData = {
  parentId: string
  name: string
} & cadex.ModelData_PMITable

type PMISceneData = {
  sceneNodes: cadex.ModelPrs_SceneNode[]
  sceneNodesByType: Map<string, Rh24SceneNode[]>
  sceneNodesBySavedViews: Map<string, Rh24SceneNode[]>
}

export class ModelPMIDataManager {
  private pmiDataMap: Map<string, PMIViewData[]> = new Map()
  private sceneNodeFactory: cadex.ModelPrs_SceneNodeFactory
  private pmiDefaultAppearence: cadex.ModelData_Appearance
  private pmiSceneData: PMISceneData
  private store: SceneDataStore
  private environment: ModelEnvironment

  constructor(environment: ModelEnvironment, store: SceneDataStore) {
    this.environment = environment
    this.store = store
    this.sceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory()
    this.pmiDefaultAppearence = new cadex.ModelData_Appearance(
      new cadex.ModelData_ColorObject(0, 0, 0)
    )
  }

  public async loadPMIData() {
    const pmiModelVisitor = new ModelPMIVisitor()

    await this.environment.accept(pmiModelVisitor)

    const pmiModelData = pmiModelVisitor.PmiViewData

    this.pmiDataMap = pmiModelData
  }

  public getPMIData = () => {
    return this.pmiSceneData
  }

  public loadPmiView(viewName: string) {
    if (viewName === 'ALL') {
      this.pmiSceneData.sceneNodes.forEach((node) => {
        node.visibilityMode = cadex.ModelPrs_VisibilityMode.Visible
      })
      return
    }

    if (viewName === 'NONE') {
      this.pmiSceneData.sceneNodes.forEach((node) => {
        node.visibilityMode = cadex.ModelPrs_VisibilityMode.Hidden
      })
      return
    }

    const viewData = this.pmiSceneData.sceneNodesBySavedViews.get(viewName)

    if (!viewData) {
      console.error('pmi view not found')
      return
    }

    this.pmiSceneData.sceneNodes.forEach((node) => {
      node.visibilityMode = viewData.includes(node)
        ? cadex.ModelPrs_VisibilityMode.Visible
        : cadex.ModelPrs_VisibilityMode.Hidden
    })

    if (viewData) {
      const viewCamera = viewData[0].pmiCamera
      const defaultTransformation = new cadex.ModelData_Transformation()

      const transf = viewData[0].transformation
        ? viewData[0].transformation.copy(defaultTransformation)
        : defaultTransformation.makeIdentity()

      this.environment.ViewPort.camera.set(
        viewCamera.location.transformed(transf),
        viewCamera.targetPoint.transformed(transf),
        viewCamera.upDirection.transformed(transf)
      )
    }
  }

  public async createPMINodes() {
    for (const nodes of this.store.getAllSceneNodes()) {
      nodes.forEach((node) => {
        if (node.nodeType === 'PART' && this.pmiDataMap.has(node.elementId)) {
          const pmiTableData = this.pmiDataMap.get(node.elementId)

          if (pmiTableData.length) {
            pmiTableData.forEach(async (pmiData) => {
              const pmiSceneData = await this.convertTableDataToSceneNodes(
                pmiData,
                node
              )

              pmiSceneData.sceneNodes.forEach((sceneNode) => {
                node.addChildNode(sceneNode)
              })

              this.pmiSceneData = pmiSceneData
            })
          }
        }
      })
    }

    this.environment.Scene.update()
  }

  private async convertTableDataToSceneNodes(
    pmiTableData: cadex.ModelData_PMITable,
    node: Rh24SceneNode
  ): Promise<PMISceneData> {
    const pmiDataItems = await pmiTableData.pmiDataItems()

    const pmiGraphElementToSceneNodeMap = new Map<
      cadex.ModelData_PMIGraphicalElement,
      Rh24SceneNode
    >()

    const sceneNodesByTypeMap = new Map<string, Rh24SceneNode[]>()

    const pmiAssociations = new Map<
      cadex.ModelData_PMIData,
      cadex.ModelData_Shape[]
    >()

    if (node instanceof cadex.ModelData_Part) {
      const brepRepresentation = node.brepRepresentation()

      if (brepRepresentation) {
        for (const shape of await brepRepresentation.subshapes()) {
          for (const pmiData of brepRepresentation.pmiData(shape)) {
            let pmiDataShapes = pmiAssociations.get(pmiData)
            if (!pmiDataShapes) {
              pmiDataShapes = []
              pmiAssociations.set(pmiData, pmiDataShapes)
            }
            pmiDataShapes.push(shape)
          }
        }
      }
    }

    const allPmiSceneNodes: Rh24SceneNode[] = []

    for (const pmiData of pmiDataItems) {
      const sceneNode = this.convertDataToSceneNode(
        node,
        pmiData,
        pmiAssociations.get(pmiData) || []
      )

      allPmiSceneNodes.push(sceneNode)

      pmiGraphElementToSceneNodeMap.set(pmiData.graphicalElement, sceneNode)

      if (sceneNodesByTypeMap.has(pmiData.type.toString())) {
        sceneNodesByTypeMap.get(pmiData.type.toString()).push(sceneNode)
      } else {
        sceneNodesByTypeMap.set(pmiData.type.toString(), [sceneNode])
      }
    }

    const sceneNodesBySavedViewsMap = new Map<string, Rh24SceneNode[]>()

    if (pmiTableData.numberOfSavedViews > 0) {
      const savedViews = await pmiTableData.views()

      for (const savedView of savedViews) {
        const sceneNodes: Rh24SceneNode[] = []

        for (const el of savedView.graphicalElements()) {
          const sceneNode = pmiGraphElementToSceneNodeMap.get(el)

          if (sceneNode) {
            sceneNode.pmiCamera = savedView.camera

            sceneNodes.push(sceneNode)
          }
        }

        sceneNodesBySavedViewsMap.set(savedView.name, sceneNodes)
      }
    }

    return {
      sceneNodes: allPmiSceneNodes,
      sceneNodesByType: sceneNodesByTypeMap,
      sceneNodesBySavedViews: sceneNodesBySavedViewsMap,
    }
  }

  private convertDataToSceneNode(
    node: Rh24SceneNode,
    pmiData: cadex.ModelData_PMIData,
    associatedShapes: ModelData_Shape[]
  ): Rh24SceneNode {
    let sceneNode: Rh24SceneNode

    if (pmiData.graphicalElement) {
      sceneNode = this.sceneNodeFactory.createNodeFromPMIGraphicalElement(
        pmiData.graphicalElement
      )
    } else {
      sceneNode = new cadex.ModelPrs_SceneNode()
    }

    sceneNode.name = pmiData.name || 'Unnamed'
    sceneNode.nodeType = pmiData.type
    sceneNode.associatedShapes = associatedShapes

    sceneNode.appearance = this.pmiDefaultAppearence
    sceneNode.visibilityMode = cadex.ModelPrs_VisibilityMode.Visible
    sceneNode.displayMode = cadex.ModelPrs_DisplayMode.ShadedWithBoundaries

    if (associatedShapes?.length) {
      sceneNode.associatedShapesSelections = new cadex.ModelPrs_SelectionItem(
        node,
        associatedShapes.map(
          (shape) => new cadex.ModelPrs_SelectedShapeEntity(shape)
        )
      )
    }

    return sceneNode
  }
}
