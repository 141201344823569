import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ShowException } from 'store/Application/appActions'

export function useShowException(
  pageTitle: string,
  options?: {
    titleTranslationKey?: string
  }
) {
  const { t } = useTranslation()

  return useCallback(
    function ShowAppException(error: unknown) {
      let _pageTitle = pageTitle

      if (options?.titleTranslationKey) {
        _pageTitle = t(pageTitle, { defaultValue: pageTitle })
      }

      ShowException(_pageTitle, error)
    },
    [options?.titleTranslationKey, pageTitle, t]
  )
}
