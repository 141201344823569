import { RequestStatus } from '../BaseAPI'
import { InternalAPI } from './InternalAPI'

export class RegressionTestsAPI extends InternalAPI {
  constructor(organizationId: string, projectId: string) {
    super()
    this.baseAddress += `/api/parties/${organizationId}/projects/${projectId}/rows`
  }

  public async AddRegressionTestsAsync(
    rowIds: string[],
    onRequestChange?: (reqStatus: Partial<RequestStatus>) => void
  ) {
    return await this.PostAsync({
      relativePath: '/tests',
      data: { rowIds: rowIds },
      onRequestChange,
    })
  }
}
