import { BoMItemFilter } from 'features/BillOfMaterials/BoMItemFilter/Filter/BoMItemFilter'
import { bomItemSelector } from 'features/BillOfMaterials/store/selectors/bomItemSelector'
import _, { forEach } from 'lodash'
import { BoMItemRow, BomItemType, PartTypeRow } from 'model/Project/BoMItemRow'
import {
  WorkingStepDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { store } from 'store/configureStore'
import { ProjectState } from 'store/Project/ProjectTypes'

function applyWorkingStepFilter(
  workingSteps: WorkingStepDto[],
  enabledWorkingSteps: WorkingStepType[],
  bypassEmptyEnabledWorkingSteps?: boolean,
  showAll?: boolean,
  strategy: 'showIfAny' | 'showIfAll' = 'showIfAll'
) {
  if (showAll) {
    return true
  }

  if (!enabledWorkingSteps?.length) {
    // if (bypassEmptyEnabledWorkingSteps) return true

    return Boolean(workingSteps?.length) === false
  }

  if (strategy === 'showIfAll') {
    const intersection = _.intersection(
      workingSteps.map((x) => x.primaryWorkingStep),
      enabledWorkingSteps
    )
    return (
      intersection.length === enabledWorkingSteps.length &&
      workingSteps.length === enabledWorkingSteps.length
    )
  }

  return workingSteps
    .map((x) => x.primaryWorkingStep)
    .some((ws) => enabledWorkingSteps.includes(ws))
}

function applyMaterialKeywordFilter(
  bomItemKeywords: string[],
  enabledKeywords: string[],
  showAll: boolean,
  showIfAny?: boolean
) {
  if (showAll) {
    return true
  }

  if (enabledKeywords?.length === 0) {
    return bomItemKeywords.length === 0
  }

  if (!bomItemKeywords.length) {
    return false
  }

  // if (!enabledKeywords?.length || !bomItemKeywords.length) {
  //   return false
  // }

  const intersection = _.intersection(enabledKeywords, bomItemKeywords)

  if (showIfAny && intersection.length > 0) {
    return true
  }

  if (intersection.length === 0) {
    return false
  }

  return (
    intersection.length ===
    Math.min(enabledKeywords.length, bomItemKeywords.length)
  )
  // return _.difference(bomItemKeywords, enabledKeywords).length === 0
  // return bomItemKeywords.some((keyword) => enabledKeywords.includes(keyword))
}

export function BoMItemSatisfyActiveFilters(
  projectState: ProjectState,
  bomItem: BoMItemRow
) {
  if (
    Object.values(projectState.filters || {}).findIndex(
      (x) => x.active === true
    ) === -1
  ) {
    return true
  }

  if (!bomItem) return false

  let show = true

  switch (bomItem?.type) {
    case BomItemType.partType:
    case BomItemType.partInstance: {
      show = BoMItemFilter(projectState, bomItem)
      break
    }
    case BomItemType.materialHeader: {
      const partTypeWorkingSteps = _.flatMap(
        bomItem.partTypePointers,
        (pointer) =>
          (bomItemSelector(store.getState(), pointer) as PartTypeRow).activities
      )

      if (projectState?.filters.byMaterialKeywords.active) {
        const groups = Object.keys(
          projectState?.filters.byMaterialKeywords?.filter?.enabledFilters || {}
        )

        forEach(
          groups,
          (group) =>
            (show &&= applyMaterialKeywordFilter(
              Object.values(bomItem.materialKeywords || {})
                .flat()
                .map((x) => x.originalKeyword),
              Object.values(
                projectState.filters.byMaterialKeywords.filter.enabledFilters[
                  group
                ]
              )
                .flat()
                .map((x) => x.originalKeyword),
              projectState.filters.byMaterialKeywords.filter.enabledFilters[
                group
              ].length ===
                projectState.filters.byMaterialKeywords.filter.availableFilters[
                  group
                ].length
            ))
        )
      }

      show ||=
        (projectState.filters.byWorkingStep.active &&
          applyWorkingStepFilter(
            { ...bomItem.workingStep, ...partTypeWorkingSteps },
            projectState.filters.byWorkingStep.filter.enabledFilters
          )) ||
        bomItem.filteredPartTypePointers?.length > 0

      break
    }
    case BomItemType.assemblyType: {
      show =
        BoMItemFilter(projectState, bomItem) ||
        bomItem.filteredPartTypePointers?.length > 0

      break
      // show =
      //   !filter.byWorkingStep.active ||
      //   applyWorkingStepFilter(
      //     bomItem.assembly.assemblyWorkingStepDtos,
      //     filter.byWorkingStep.filter.enabledFilters,
      //     true,
      //     false,
      //     'showIfAny'
      //   ) ||
      //   applyWorkingStepFilter(
      //     bomItem.assembly.partTypeWorkingStepDtos,
      //     filter.byWorkingStep.filter.enabledFilters,
      //     false,
      //     false,
      //     'showIfAny'
      //   )
      // break
    }
    case BomItemType.assemblyInstance: {
      show = false //make it invisible by default

      show =
        (projectState.filters.byWorkingStep.active &&
          (applyWorkingStepFilter(
            [...bomItem.assemblyActivities, ...bomItem.partTypeActivities],
            projectState.filters.byWorkingStep.filter.enabledFilters,
            true,
            false,
            'showIfAny'
          ) ||
            applyWorkingStepFilter(
              bomItem.partTypeActivities,
              projectState.filters.byWorkingStep.filter.enabledFilters,
              false,
              false,
              'showIfAny'
            ))) ||
        bomItem.filteredPartInstancePointers?.length > 0
      break
    }
  }

  return show
}
