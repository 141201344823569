import { BoMItemRow, BomItemType } from 'model/Project/BoMItemRow'
import { IssuesFilter } from 'store/Project/ProjectTypes'
import { showPartBasedOnIssues } from './showPartBasedOnIssues'

export function applyIssuesFilter(
  filter: IssuesFilter,
  row: BoMItemRow
): boolean {
  let issuesFilterAppliedShowItem = false

  switch (row.type) {
    case BomItemType.partType:
    case BomItemType.partInstance: {
      issuesFilterAppliedShowItem = showPartBasedOnIssues(row, filter)
    }
  }

  return issuesFilterAppliedShowItem
}
