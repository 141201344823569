import { BoMItemRow, BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { BomItemPointer } from '../../../../model/Project/BomItemPointer'

/**
 *Get a BOM item from the Redux state
 * @param state The Redux state
 * @param bomItemPointer The pointer to the BOM item
 * @returns The BOM item or null if it does not exist
 */
export function bomItemSelector(
  state: Partial<RootAppState>,
  bomItemPointer: BomItemPointer
): BoMItemRow | null {
  if (!bomItemPointer?.id) {
    // eslint-disable-next-line no-console
    console.trace('bomItemSelector called with null id', bomItemPointer)
    return null
  }
  if (
    bomItemPointer.id === state.project.activeProject?.id ||
    bomItemPointer.type === BomItemType.project
  ) {
    return state.project.activeProject
  }

  switch (bomItemPointer.type) {
    case BomItemType.assemblyType:
      return state.project?.assemblyHeaders
        ? state.project?.assemblyHeaders[bomItemPointer.id]
        : null
    case BomItemType.assemblyInstance:
      return state.project?.assemblyInstances
        ? state.project?.assemblyInstances[bomItemPointer.id]
        : null
    case BomItemType.partType:
      return state.project?.partTypes
        ? state.project?.partTypes[bomItemPointer.id]
        : null
    case BomItemType.partInstance:
      return state.project?.partInstances
        ? state.project?.partInstances[bomItemPointer.id]
        : null
    case BomItemType.materialHeader:
      return state.project?.materialHeaders
        ? state.project?.materialHeaders[bomItemPointer.id]
        : null
    case BomItemType.routingHeader:
      return state.project?.routingHeaders
        ? state.project?.routingHeaders[bomItemPointer.id]
        : null
    default:
      return null
  }
}

/**
 * Wrapper around bomItemSelector to be used in a AppSelector selector
 * @param bomItemPointer
 * @returns A selector that returns the BOM item from the Redux state
 */
export const bomItemSelectorFunc =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) =>
    bomItemSelector(state, bomItemPointer)

export const BomItemSelectorFn =
  <T extends BoMItemRow>(bomItemPointer: BomItemPointer) =>
  (state: Partial<RootAppState>) =>
    bomItemSelector(state, bomItemPointer) as T

/**
 * Get the BOM items that are directly children of a BOM item
 * if project is passed as the parent, it returns the top level BOM items (assembly headers)
 * @param bomItemPointer pointer to the parent BOM item
 */
export const bomItemChildrenSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const parent = bomItemSelector(state, bomItemPointer)

    switch (parent?.type) {
      case BomItemType.project:
        return state.project.assemblyHeadersIds.map(
          (id) => state.project.assemblyHeaders[id]
        )
      case BomItemType.assemblyType:
        return parent.partTypePointers.map((pointer) =>
          bomItemSelector(state, pointer)
        )
      case BomItemType.assemblyInstance:
        return parent.partInstancePointers.map((pointer) =>
          bomItemSelector(state, pointer)
        )
      default:
        return []
    }
  }
