import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const materialHeaderCuttingPlanQuantitySelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem) {
      console.warn(
        'materialHeaderCuttingPlanQuantitySelector: bomItem not found',
        bomItemPointer
      )

      return null
    }

    if (bomItem?.type !== BomItemType.materialHeader) {
      console.warn('bomItemId is not a MaterialHeader')
      return null
    }

    return bomItem.cuttingPlans?.reduce(
      (acc, cuttingPlan) => acc + cuttingPlan.quantity,
      0
    )
  }
