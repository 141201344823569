import _ from 'lodash'
import { MaterialKeywordsFilter } from 'store/Project/ProjectTypes'

export function materialFilterActive(byMaterialKeywords: {
  filter: MaterialKeywordsFilter
  active: boolean
}) {
  if (
    !byMaterialKeywords?.active ||
    !byMaterialKeywords.filter?.availableFilters
  ) {
    return false
  }

  const availableFiltersLength = _.flatten(
    Object.values(byMaterialKeywords.filter?.availableFilters || {})
  ).length

  const enabledFiltersLength = _.flatten(
    Object.values(byMaterialKeywords.filter?.enabledFilters || {})
  ).length

  return availableFiltersLength !== enabledFiltersLength
}
