import axios, { AxiosProgressEvent, Method } from 'axios'
import { FileWithDetails } from 'model/FileWithDetails'

const apiBaseURL =
  import.meta.env.VITE_API_BASE_URL || 'https://localhost:44367'

export const withBaseUrl = (relativeUrl: string) => {
  return `${apiBaseURL}/${relativeUrl}`
}

function appendFileDetailFieldToFormData(
  formData: FormData,
  parameterName: string,
  index: number,
  fileWithDetail: FileWithDetails,
  key: string
) {
  const field = fileWithDetail[key]

  if (field instanceof File) {
    formData.append(`${parameterName}[${index}].${key}`, field as File)
  } else if (field instanceof Array) {
    field.forEach((item, i) => {
      formData.append(`${parameterName}[${index}].${key}[${i}]`, item)
    })
  } else if (field instanceof Object) {
    formData.append(`${parameterName}[${index}].${key}`, JSON.stringify(field))
  } else if (field) {
    formData.append(`${parameterName}[${index}].${key}`, field)
  }
}

export default class RestUtils {
  public static getFileUploadBody(
    data: Array<File | unknown>,
    parameterName = 'files'
  ) {
    let form: FormData

    if (data && data instanceof Array) {
      if (data[0] instanceof File) {
        form = new FormData()
        data.forEach((file) => form.append(parameterName, file as File))
      } else {
        form = new FormData()
        data.forEach((fileWithDetail, index) =>
          Object.keys(fileWithDetail).forEach((key) => {
            appendFileDetailFieldToFormData(
              form,
              parameterName,
              index,
              fileWithDetail as FileWithDetails,
              key
            )
          })
        )
      }
    }

    return form || data
  }

  private static getRequest(url, method) {
    return axios({
      url,
      method,
    })
  }

  public static request(
    method: Method,
    url: string,
    data: unknown,
    isFileUpload: boolean,
    isFileDownload: boolean,
    progressCallback: (progress: AxiosProgressEvent) => void,
    additionalHeaders: Record<string, unknown>,
    authenticationToken: string,
    responseType: 'text' | 'blob' = 'text',
    uploadParameterName?: string // the parameter name in controller for file upload
  ) {
    let body = null
    const headers = {}

    if (authenticationToken != null) {
      headers['Authorization'] = `Bearer ${authenticationToken}`
    }

    if (additionalHeaders) {
      Object.keys(additionalHeaders).map((x) => {
        headers[x] = additionalHeaders[x]

        return null
      })
    }

    if (isFileUpload) {
      body = this.getFileUploadBody(data as never, uploadParameterName)
    } else if (method !== 'GET') {
      body = JSON.stringify(data)
      headers['Content-Type'] = 'application/vnd.b-markable.hateoas+json'
    }

    return axios({
      url,
      method,
      headers,
      baseURL: apiBaseURL,
      params: method === 'GET' ? data : null,
      data: body,
      onUploadProgress: (progressEvent) => {
        if (progressCallback) {
          progressCallback(progressEvent)
        }
      },
      responseType: isFileDownload ? 'blob' : responseType,
    })
      .then((response) => {
        const resp = {
          is_error: response.status < 200 || response.status > 299,
          content: isFileDownload ? null : response.data,
        }

        if (isFileDownload) {
          RestUtils.DownloadFile(response)
        }

        return resp
      })
      .catch((error) => {
        if (error.code === 'ECONNABORTED') {
          return Promise.resolve({ is_error: false, content: [] })
        }
        if (!error.response) {
          return Promise.reject(new Error("request didn't receive a response"))
        } else {
          if (error.response.status === 401) {
            window.location.replace('/logout')
            return Promise.reject(/*new Error('Unauthorized')*/)
          }

          if (error.response.status === 403) {
            return Promise.reject({
              error: 'Action not allowed',
              statusCode: '403',
            })
          }

          return Promise.reject(error)
        }
      })
  }

  private static DownloadFile(response) {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')

    link.href = url

    let fileName = 'project-files.zip'

    if (response.headers['content-disposition']) {
      fileName = response.headers['content-disposition']
        .split(';')[1]
        .split('filename=')[1]
        .replace(/"/g, '')
    } else {
      console.warn('content-disposition header not found')
    }

    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }
}
