import { RootAppState } from './configureStore'

export const getContext = (getState: () => RootAppState | unknown) => {
  const appState = getState() as RootAppState

  const partyId = appState.user.organizationContext.id
  let projectId = undefined
  if (appState.project.activeProject) {
    projectId = appState.project.activeProject?.id
  }
  return { partyId, projectId: projectId || '000-000-000' }
}
