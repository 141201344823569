import { RootAppState } from 'store/configureStore'

export const articleSelectorAllowedSelector = (
  state: RootAppState
): boolean => {
  if (state.project.activeProject?.isBuyingPartyView === false) {
    return true
  }

  return Boolean(state.project.activeProject?.articleSelectionEnabledInPortal)
}
