import { BaseController } from 'controllers/BaseController'
import { fetchProject } from 'features/BillOfMaterials/store/asyncActions/fetchProject'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { ProjectModel } from 'model/ProjectModel'
import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import {
  ChangeVisibleInPortalRequest,
  FlattenAssemblyRequest,
  FlattenProjectRequest,
  GetAvailableDeliveryDaysResponse,
  SetValidityPeriodDaysRequest,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import store from 'store/configureStore'
import { GetContext } from 'store/storeUtils'

export interface IProjectAPI {
  downloadProjectDeliveryTimeEstimation: () => Promise<boolean>
  visibleInPortal: (req: ChangeVisibleInPortalRequest) => Promise<boolean>
  flattenProject: (
    flattenProjectRequest: FlattenProjectRequest
  ) => Promise<void>
  flattenAssembly: (
    flattenAssemblyRequest: FlattenAssemblyRequest
  ) => Promise<void>
  setValidityPeriodInDays: (req: SetValidityPeriodDaysRequest) => Promise<void>
  getAvailableDeliveryDays: () => Promise<GetAvailableDeliveryDaysResponse>
}

export class ProjectAPI extends InternalAPI implements IProjectAPI {
  constructor(
    partyId: string,
    projectId: string,
    private onRequestChanged: OnRequestChangeCallback
  ) {
    super(`/api/parties/${partyId}/projects/${projectId}`)
  }
  public async downloadProjectDeliveryTimeEstimation(): Promise<boolean> {
    return await this.DownloadAsync({
      id: 'DownloadProjectDeliveryTimeEstimation',
      relativePath: '/downloadProjectDeliveryTimeEstimation',
      onRequestChange: this.onRequestChanged,
    })
  }

  public async visibleInPortal(
    req: ChangeVisibleInPortalRequest
  ): Promise<boolean> {
    return await this.PutAsync({
      id: 'VisibleInPortal',
      relativePath: '/visibleInPortal',
      data: req,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async flattenProject(
    flattenProjectRequest: FlattenProjectRequest
  ): Promise<void> {
    await this.PostAsync({
      id: 'FlattenProject',
      relativePath: '/flattenProject',
      data: flattenProjectRequest,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async flattenAssembly(
    flattenAssemblyRequest: FlattenAssemblyRequest
  ): Promise<void> {
    await this.PostAsync({
      id: 'FlattenAssembly',
      relativePath: '/flattenAssembly',
      data: flattenAssemblyRequest,
      onRequestChange: this.onRequestChanged,
    })
  }
  public async setValidityPeriodInDays(req: SetValidityPeriodDaysRequest) {
    return await this.PostAsync<void>({
      id: 'set-quotation-validity',
      relativePath: `/ValidityPeriodDays`,
      data: req,
      onRequestChange: this.onRequestChanged,
    })
  }
  public async getAvailableDeliveryDays(): Promise<GetAvailableDeliveryDaysResponse> {
    return await this.GetAsync<GetAvailableDeliveryDaysResponse>({
      id: 'GetAvailableDeliveryDays',
      relativePath: '/availableDeliveryDays',
      onRequestChange: this.onRequestChanged,
    })
  }
}

export class ProjectController extends BaseController<IProjectAPI> {
  constructor(forceProjectId?: string) {
    super((onRequestChanged) => {
      const { partyId, projectId } = GetContext()
      return new ProjectAPI(
        partyId,
        forceProjectId ?? projectId,
        onRequestChanged
      )
    })
  }

  public async DownloadProjectDeliveryTimeEstimation(): Promise<boolean> {
    try {
      const response = await this.api.downloadProjectDeliveryTimeEstimation()
      return response
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async UpdateProjectVisibleInPortal(
    visible: boolean
  ): Promise<boolean> {
    try {
      store.dispatch(
        newProjectActions.setActiveProjectProperties({
          visibleInPortal: visible,
        })
      )
      const response = await this.api.visibleInPortal({
        visibleInPortal: visible,
      })
      return response
    } catch (err) {
      const { projectId } = GetContext()
      store.dispatch(fetchProject({ projectId }))
      throw this.HandleError(err)
    }
  }

  public async FlattenProject(
    flattenProjectRequest: FlattenProjectRequest
  ): Promise<void> {
    try {
      await this.api.flattenProject(flattenProjectRequest)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async FlattenAssembly(
    flattenAssemblyRequest: FlattenAssemblyRequest
  ): Promise<void> {
    try {
      await this.api.flattenAssembly(flattenAssemblyRequest)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async SetValidityPeriodInDays(numberOfDays: number): Promise<void> {
    try {
      store.dispatch(
        newProjectActions.setActiveProjectProperties({
          validityPeriodDays: numberOfDays,
        } as Partial<ProjectModel>)
      )

      await this.api.setValidityPeriodInDays({
        validityPeriodDays: numberOfDays,
      })
    } catch (err) {
      throw this.HandleError(err)
    } finally {
    }
  }

  public async GetNotDeliveriableDays(): Promise<GetAvailableDeliveryDaysResponse> {
    try {
      const resp = await this.api.getAvailableDeliveryDays()

      store.dispatch(
        newProjectActions.setAvailableDeliveryDays(resp.noDeliveryDate)
      )

      return resp.noDeliveryDate
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
