export enum AggregateEventLevel {
  NotInitialized = 0,
  Project = 1,
  Assembly = 2,
  PartType = 3,
  CalculatedValues = 4,
  NestingResults = 5,
}

export enum ProjectEventType {
  NotInitialized = 0,
  ProjectCreated = 1,
  ProjectUpdated = 2,
  ProjectDeleted = 3,
  ProgressInfo = 4,
  FinancialsUpdated = 5,
  PartTypeAreaAndVolumeSet = 6,
  RequestIssuesSolved = 7,
  PricingIssuesSolved = 8,
  ProjectPersisted = 10,
  EstimatorIssuesSolved = 11,
  ProjectActivated = 12,
  ProjectSummaryUpdated = 13,
  ManufacturabilityUpdated = 14,
  Notification = 15,
  OperationsUpdated = 16,
  ProjectOpened = 99999
}
