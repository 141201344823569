import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { BoMItemRow, BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { WorkingStepDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectState } from '../../../../store/Project/ProjectTypes'
import { bomItemSelector } from '../selectors/bomItemSelector'

// TODO: check this one (should still use WorkingStepDto?)
export const addActivityToBomItem: CaseReducer<
  ProjectState,
  PayloadAction<{
    bomItemPointer: BomItemPointer
    workingStep: WorkingStepDto
  }>
> = (state, action) => {
  if (action.payload.bomItemPointer.id === state.activeProject?.id) {
    state.activeProject.activities.push(action.payload.workingStep)
    return state
  }

  const bomItem = bomItemSelector(
    { project: state },
    action.payload.bomItemPointer
  )

  updateBomItem(bomItem)

  return state

  function updateBomItem(bomItem: BoMItemRow): void {
    switch (bomItem?.type) {
      case BomItemType.assemblyType:
      case BomItemType.assemblyInstance: {
        if (
          bomItem.assemblyActivities.findIndex(
            (x) => x.id === action.payload.workingStep.key
          ) > -1
        ) {
          bomItem.assemblyActivities.map((x) => {
            if (x.id === action.payload.workingStep.key) {
              return action.payload.workingStep
            }
            return x
          })
        } else {
          bomItem.assemblyActivities.push(action.payload.workingStep)
        }

        break
      }
      case BomItemType.project:
      case BomItemType.partType:
      case BomItemType.partInstance: {
        // TODO: check by working step type (not allowed to have 2 activities with the same working step type)
        if (
          bomItem.activities.findIndex(
            (x) => x.id === action.payload.workingStep.key
          ) > -1
        ) {
          bomItem.activities.map((x) => {
            if (x.id === action.payload.workingStep.key) {
              return action.payload.workingStep
            }
            return x
          })
        } else {
          bomItem.activities.push(action.payload.workingStep)
        }
        break
      }
    }
  }
}
