import { BaseController } from 'controllers/BaseController'
import { GetContext } from 'store/storeUtils'
import { AppEvents, AppEventsAPI, IAppEventsAPI } from './appEventsAPI'

export class AppEventsController extends BaseController<IAppEventsAPI> {
  constructor() {
    super((onRequestChange) => {
      const { partyId } = GetContext()
      return new AppEventsAPI(partyId, onRequestChange)
    })
  }

  public async PostEvent(args: AppEvents) {
    try {
      return await this.api.PostEvent(args)
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
