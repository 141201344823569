import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { merge } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  BoMItemActivityDto,
  WorkingStepDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectState } from '../../../../store/Project/ProjectTypes'
import { bomItemSelector } from '../selectors/bomItemSelector'

export const bomItemActivityReplacer: CaseReducer<
  ProjectState,
  PayloadAction<{
    bomItemPointer: BomItemPointer
    activityId: string
    activity: Partial<BoMItemActivityDto> | Partial<WorkingStepDto>
  }>
> = (state, action) => {
  const bomItem = bomItemSelector(
    { project: state },
    action.payload.bomItemPointer
  )

  if (!bomItem) {
    return state
  }

  switch (bomItem.type) {
    case BomItemType.partType:
    case BomItemType.partInstance:
    case BomItemType.project: {
      bomItem.activities.forEach((activity) => {
        if (activity.id === action.payload.activityId) {
          activity = merge(activity, action.payload.activity)
        }
      })
      break
    }
    case BomItemType.materialHeader: {
      merge(bomItem.workingStep, action.payload.activity)
      break
    }
    case BomItemType.assemblyType: {
      bomItem.assemblyActivities.forEach((activity) => {
        if (activity.id === action.payload.activityId) {
          activity = merge(activity, action.payload.activity)
        }
      })
      break
    }
    default:
      console.warn(
        `bomItemActivityReplacer: bomItem type not handled: ${bomItem.type}`
      )
  }

  return state
}
