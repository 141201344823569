import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ProjectState } from 'store/Project/ProjectTypes'
import { BomItemPointer } from '../../../../model/Project/BomItemPointer'

export const setBoMItemFocus: CaseReducer<
  ProjectState,
  PayloadAction<BomItemPointer>
> = (state, action) => {
  state.focusedBomItemPointer = action.payload
}
