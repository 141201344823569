import { ZoomOutMapOutlined } from '@mui/icons-material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { ModelController } from '../manager/ModelController'
import { ToolButton } from './ToolButton'

type Props = {
  viewManager: ModelController
}

export const FitAllTool = (props: Props) => {
  return (
    <ToolButton
      onClick={() => {
        props.viewManager.FitAll()
      }}
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        gap: (theme) => theme.spacing(0.5),
        borderRadius: (theme) => theme.shape.borderRadius,
      }}
    >
      <ZoomOutMapOutlined />
      <LocalizedTypography
        component="p"
        variant="body2"
        translationKey="project:viewer-fitAll"
        translationDefaultValue="fit all"
      />
    </ToolButton>
  )
}
