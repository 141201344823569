import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ProjectState } from 'store/Project/ProjectTypes'
import { ProjectViews } from 'store/UI/UITypes'

const saveCurrentView = (view: ProjectViews) => {
  try {
    localStorage.setItem('projectView', view.toString())
  } catch (err) {
    console.error(err)
  }
}

export const changeProjectView: CaseReducer<
  ProjectState,
  PayloadAction<ProjectViews>
> = (state, action) => {
  state.currentView = action.payload
  saveCurrentView(action.payload)
}

export const changeProjectViewIfInView: CaseReducer<
  ProjectState,
  PayloadAction<{
    desiredView: ProjectViews
    ifInView: ProjectViews
  }>
> = (state, action) => {
  if (state.currentView === action.payload.ifInView) {
    state.currentView = action.payload.desiredView
    saveCurrentView(action.payload.desiredView)
  }
}
