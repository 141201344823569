import { createAsyncThunk } from '@reduxjs/toolkit'
import { getNotificationGroupAction } from 'features/SignalR/components/ConnectionState'
import { noop } from 'lodash'
import { Notification } from 'model/Notification'
import { ShowException } from 'store/Application/appActions'
import store, { RootAppState } from 'store/configureStore'
import { newProjectActions } from '../projectReducer'
import {
  fetchProject,
  getOperationsNotificationsGroupName,
  getProjectNotificationsGroupName,
} from './fetchProject'
import {
  subscribeToUpdates,
  unsubscribeFromUpdates,
} from './liveUpdatesActions'
import { ProjectEventType } from 'model/ProjectEventLevel'

// let handlerCounter = 0

let timer: NodeJS.Timeout
let maxTimeout: NodeJS.Timeout

const dispatchUpdate = (projectId: string, eventType: ProjectEventType, projectVersion: string ) => {
  store.dispatch(
    fetchProject({
      projectId: projectId,
      reason: eventType,
      reasonExtra: projectVersion
    })
  )
  // if (!maxTimeout) {
  //   // console.log('no maxTimeout', random)
  //   store.dispatch(
  //     fetchProject({
  //       projectId: projectId,
  //       reason: eventType
  //     })
  //   )
  // } else {
  //   clearTimeout(maxTimeout)

  //   timer = setTimeout(() => {
  //     // console.log('timer', random)
  //     clearTimeout(timer)
  //     store.dispatch(
  //       fetchProject({
  //         projectId: projectId,
  //         reason: eventType
  //       })
  //     )
  //     if (store.getState().project.projectOperations.isNesting) {
  //       store.dispatch(
  //         newProjectActions.updateOperationsFlags({
  //           shallUpdateOperationsSummary: true,
  //         })
  //       )
  //     }
  //     clearTimeout(maxTimeout)
  //   }, 4000)
  //   // console.log('timer setted')
  // }
  // maxTimeout = setTimeout(() => {
  //   clearTimeout(timer)
  //   // console.log('maxTimeout', random)

  //   timer = undefined
  //   maxTimeout = undefined

  //   store.dispatch(
  //     fetchProject({
  //       projectId: projectId,
  //       reason: eventType
  //     })
  //   )
  // }, 10000)
}

// const throttledUpdateProject = throttle(dispatchUpdate, 1_500, {
//   leading: true,
//   trailing: true,
// })
const throttledUpdateProject = dispatchUpdate

export const subscribeToProjectNotifications = createAsyncThunk<
  void,
  string,
  { state: RootAppState }
>('project/subscribeToUpdates', async (groupName, thunkAPI) => {
  thunkAPI.dispatch(
    subscribeToUpdates({
      groupName,
      clientMethodName: 'onProjectProgress',
      clientMethod: (notification: Notification) => {
        const { id, version } = thunkAPI.getState().project.activeProject ?? {}

        if (notification.shallUpdateUI && (notification.version < 0 || notification.version > version)) {
          throttledUpdateProject(id, notification.eventType, notification.version.toString())
        }
      },
      onLeaveGroupCallback: (groupName) => {
        noop(groupName)
      },
    })
  )
})

export const subscribeToMultipleNotificationGroups = createAsyncThunk<
  void,
  string[],
  { state: RootAppState }
>(
  'project/subscribeToMultipleNotificationGroups',
  async (groupNames, thunkAPI) => {
    groupNames.forEach((groupName) => {
      thunkAPI.dispatch(getNotificationGroupAction(groupName))
    })
  }
)

export const unsubscribeFromActiveProjectUpdates = createAsyncThunk<
  void,
  { projectId: string; isBuyingPartyView: boolean },
  { state: RootAppState }
>(
  'project/unsubscribeFromUpdates',
  async ({ projectId, isBuyingPartyView }, thunkAPI) => {
    try {
      // throttledUpdateProject.cancel()
      timer && clearTimeout(timer)
      maxTimeout && clearTimeout(maxTimeout)

      if (!projectId) {
        return
      }

      const projectUpdateGroupName = getProjectNotificationsGroupName(
        projectId,
        isBuyingPartyView
      )
      const projectOperationsGroupName = getOperationsNotificationsGroupName(
        projectId,
        isBuyingPartyView
      )

      await thunkAPI.dispatch(
        unsubscribeFromUpdates({
          groupName: projectUpdateGroupName,
          clientMethodName: 'onProjectProgress',
        })
      )
      await thunkAPI.dispatch(
        unsubscribeFromUpdates({
          groupName: projectOperationsGroupName,
          clientMethodName: 'onProjectOperationProgress',
        })
      )
    } catch (ex) {
      ShowException('project', ex)
      throw ex
    }
  }
)
