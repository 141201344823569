import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemNameSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem?.type) {
      return null
    }
    switch (bomItem?.type) {
      case BomItemType.project:
        return bomItem.projectReference
      case BomItemType.assemblyType:
      case BomItemType.assemblyInstance:
      case BomItemType.partType:
      case BomItemType.partInstance:
        return bomItem.name
      case BomItemType.materialHeader:
        return bomItem.materialDescription
      default:
        return bomItem.id
    }
  }
