/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import cadex from '@cadexchanger/web-toolkit'
import { SceneDataStore } from '../store/SceneDataStore'

export class PaintNodesRepresentationVisitor extends cadex.ModelData_RepresentationVisitor {
  constructor(
    private color: cadex.ModelData_ColorObject,
    private sceneStore: SceneDataStore,
    private shapeIdFilter: string[]
  ) {
    super()
  }

  async visitBRepRepresentation(
    theBRepRep: cadex.ModelData_BRepRepresentation
  ): Promise<void> {
    for (let count = 0; count < (await theBRepRep.bodyList()).size(); count++) {
      for (const shape of await theBRepRep.subshapes()) {
        if (shape.type === cadex.ModelData_ShapeType.Face) {
          const shapeId = theBRepRep.shapeId(shape)

          if (!this.shapeIdFilter.includes(shapeId.toString())) {
            continue
          }

          const existingNode = this.sceneStore.getSceneNodeItem(
            theBRepRep.shapeId(shape).toString()
          )

          if (existingNode.length > 0) {
            theBRepRep.setShapeAppearance(
              existingNode[0].shape,
              new cadex.ModelData_Appearance(this.color)
            )
            existingNode[0].invalidate()

            existingNode[0]['parentNode'].invalidate()
          }
        }
      }
    }
  }
  visitPolyRepresentation(
    thePolyRep: cadex.ModelData_PolyRepresentation
  ): Promise<void> | void {}
}
