import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemActivityController } from 'features/BillOfMaterials/components/BomItemActivities/BomItemActivityController'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { MaterialSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { RootAppState } from 'store/configureStore'
import { getContext } from 'store/getContext'
import { newProjectActions } from '../projectReducer'
import { bomItemSelector } from '../selectors/bomItemSelector'
import { fetchProject } from './fetchProject'

export const saveBomItemMaterials = createAsyncThunk<
  void,
  { bomItemPointer: BomItemPointer; material: MaterialSummaryDto },
  { state: RootAppState }
>('boM/saveMaterial', async ({ bomItemPointer, material }, thunkAPI) => {
  const { partyId: organizationId, projectId } = getContext(thunkAPI.getState)

  const controller = new BomItemActivityController()

  const bomItem = bomItemSelector(thunkAPI.getState(), bomItemPointer)

  let bomItemPointers = Array<BomItemPointer>()

  // if the bom item is a material header, we need to update all the parts that are linked to it
  // otherwise we check if there are bomItems selected and the selected bom items include the one that was clicked
  // if so, we update all the selected bom items otherwise we just update the one that was clicked
  if (bomItem?.type === BomItemType.materialHeader) {
    bomItemPointers = bomItem.filteredPartTypePointers
  } else {
    const selectedBomItems = thunkAPI.getState().project.selectedBomItemPointers

    if (
      selectedBomItems.length > 0 &&
      selectedBomItems.findIndex(
        (pointer) => bomItemPointer.id === pointer.id
      ) > -1
    ) {
      bomItemPointers = selectedBomItems
    } else {
      bomItemPointers = [bomItemPointer]
    }
  }

  try {
    thunkAPI.dispatch(newProjectActions.resetBomItemFinancials(bomItemPointers))

    // await controller.SetBomItemActivityKeywords({
    //   bomItemPointers: bomItemPointers,
    //   keywords: Object.values(material.tokens)?.flat(),
    //   articleId: material.id,
    //   articleDescription: material.description,
    //   materialHeaderPointer:
    //     bomItem?.type === BomItemType.materialHeader ? bomItemPointer : null,
    // })
  } catch (err) {
    ShowException('saveBomItemMaterials', err)
    thunkAPI.dispatch(fetchProject({ projectId: projectId }))
    throw err
  }
})
