import { BomItemController } from 'controllers/Project/BomItemController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { ModelEnvironment } from './ModelEnvironment'

export class ModelFileManager {
  private Environment: ModelEnvironment
  private controller: BomItemController = new BomItemController()

  constructor(environment: ModelEnvironment) {
    this.Environment = environment
  }

  private dataLoaderFor3DFiles = async (
    bomItemPointer: BomItemPointer,
    fileName: string
  ) => {
    return await this.controller.Get3dViewerFile(bomItemPointer, fileName)
  }

  private dataLoaderFor2DFiles = async (
    bomItemPointer: BomItemPointer,
    fileName: string
  ) => {
    return await this.controller.Get2dViewerFile(bomItemPointer, fileName)
  }

  private async loadModel(
    bomItemPointer: BomItemPointer,
    fileName: string,
    dataLoader: (
      bomItemPointer: BomItemPointer,
      fileName: string
    ) => Promise<ArrayBuffer>
  ) {
    const model = this.Environment?.createModel()

    const loadResult = await model.loadFile(
      fileName,
      async (id: string, fileName: string) => {
        return await dataLoader(bomItemPointer, fileName)
      },
      false
    )

    if (loadResult) {
      return loadResult
    } else {
      throw new Error('unable to load model')
    }
  }

  public async load3DModel(bomItemPointer: BomItemPointer, fileName: string) {
    return await this.loadModel(
      bomItemPointer,
      fileName,
      this.dataLoaderFor3DFiles
    )
  }

  public async load2DModel(bomItemPointer: BomItemPointer, fileName: string) {
    return await this.loadModel(
      bomItemPointer,
      fileName,
      this.dataLoaderFor2DFiles
    )
  }
}
