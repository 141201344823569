import { ArticleSelectorDialogProps } from 'components/Common/ArticleSelector/ArticleSelectorDialog'
import { RALColorInfo } from 'components/RALSelector/RALColorInfo'
import RALData from 'components/RALSelector/RALData.json'
import { BomItemController } from 'controllers/Project/BomItemController'
import { fetchProject } from 'features/BillOfMaterials/store/asyncActions/fetchProject'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { bomItemSelector } from 'features/BillOfMaterials/store/selectors/bomItemSelector'
import i18next from 'i18next'
import _ from 'lodash'
import { BomItemType, PartTypeRow } from 'model/Project/BoMItemRow'
import {
  BomItemPointer,
  MaterialHeaderPointer,
} from 'model/Project/BomItemPointer'
import { MaterialSelectorDtoWithTranslations } from 'model/materials/MaterialSelectorDtoWithTranslation'
import { Action } from 'redux'
import { UIActions } from 'store/UI/UIActions'
import { AppDispatch, RootAppState } from 'store/configureStore'
import { AppState } from '..'
import {
  BoMItemActivityArticleDto,
  BoMItemActivityDto,
  KeywordDto,
  MaterialSelectorDto,
  MaterialSummaryDto,
  RowDto,
  WorkingStepType,
} from '../../services/APIs/InternalAPI/internal-api.contracts'
import { APICall, ErrorMessage, ShowException } from '../Application/appActions'
import { APICallArgs, ApplicationActionTypes } from '../Application/appState'
import { getContext } from '../getContext'

export enum MaterialActionsConsts {
  OpenMaterialModal = 'material/openMaterialModal',
  SaveChanges = 'material/SaveAndCloseMaterialModalAction',
  CloseWithoutSave = 'material/CloseWithoutSave',
  SetTokens = 'material/SetTokens',
  SetPossibleTokensAndMaterials = 'material/SetPossibleTokensAndMaterials',
  SetMaterial = 'material/SetRowMaterial',
  OpenModalRemnantStrategy = 'material/OpenModalRemnantStrategy',
  CloseModalRemnantStrategy = 'material/CloseModalRemnantStrategy',
  ClipboardClear = 'material/ClipboardClear',
  CopyActivityToClipboard = 'material/Copy',
  PasteFromClipboard = 'material/Paste',
  CleanSelectedMaterial = 'material/CleanSelectedMaterial',
  SetActiveRows = 'material/SetActiveRows',
  SetIsLoading = 'material/SetIsLoading',
  Set3dModelPath = 'material/Set3dModel',
}

interface OpenMaterialAction extends Action {
  type: MaterialActionsConsts.OpenMaterialModal
  activeRows: PartTypeRow[]
  materialHeaderPointer: MaterialHeaderPointer
  workingStepType: WorkingStepType
}

interface SaveAndCloseMaterialModalAction extends Action {
  type: MaterialActionsConsts.SaveChanges
}

interface CloseWithoutSaveModalAction extends Action {
  type: MaterialActionsConsts.CloseWithoutSave
}

interface SetMaterial extends Action {
  type: MaterialActionsConsts.SetMaterial
  material: MaterialSummaryDto
}

interface SetPossibleTokensAndMaterials extends Action {
  type: MaterialActionsConsts.SetPossibleTokensAndMaterials
  tokensAndMaterials: MaterialSelectorDtoWithTranslations
  selectedMaterial: BoMItemActivityArticleDto
  workingStepType: WorkingStepType
}

interface SetTokens extends Action {
  type: MaterialActionsConsts.SetTokens
  selectedTokens: Record<string, KeywordDto[]>
}

interface CopyMaterialToClipboard extends Action {
  type: MaterialActionsConsts.CopyActivityToClipboard
  baseBomItemPointer: BomItemPointer
  activities: BoMItemActivityDto[]
}

interface PasteMaterialFromClipboard extends Action {
  type: MaterialActionsConsts.PasteFromClipboard
  rowIds: string[]
}

interface OpenEditRemnantStrategyModal extends Action {
  type: MaterialActionsConsts.OpenModalRemnantStrategy
  materialId: string
  resourceId: string
  projectId: string
}

interface CloseModalRemnantStrategyModal extends Action {
  type: MaterialActionsConsts.CloseModalRemnantStrategy
}

interface CleanSelectedMaterial extends Action {
  type: MaterialActionsConsts.CleanSelectedMaterial
}

interface SetActiveRows extends Action {
  type: MaterialActionsConsts.SetActiveRows
  activeRows: RowDto
}

interface SetIsLoading extends Action {
  type: MaterialActionsConsts.SetIsLoading
  isLoading: boolean
}

interface Set3DModelPath extends Action {
  type: MaterialActionsConsts.Set3dModelPath
  rowId: string
  threeDModelPath: string
}

interface ClipboardClear extends Action {
  type: MaterialActionsConsts.ClipboardClear
}

export type MaterialActionsTypes =
  | OpenMaterialAction
  | SaveAndCloseMaterialModalAction
  | CloseWithoutSaveModalAction
  | SetMaterial
  | SetTokens
  | SetPossibleTokensAndMaterials
  | CopyMaterialToClipboard
  | PasteMaterialFromClipboard
  | OpenEditRemnantStrategyModal
  | CloseModalRemnantStrategyModal
  | CleanSelectedMaterial
  | SetActiveRows
  | SetIsLoading
  | Set3DModelPath
  | ClipboardClear

const debouncedChangeTokens = _.debounce(
  (
    dispatch,
    selectedTokens: KeywordDto[],
    workingStepType: WorkingStepType
  ) => {
    dispatch(
      MaterialActions.getTokensAndMaterials(selectedTokens, workingStepType)
    )
  },
  220,
  { leading: false, trailing: true }
)

const initMaterialSelector = (
  bomItemPointers: BomItemPointer[],
  workingStepType: WorkingStepType,
  materialHeaderPointer: MaterialHeaderPointer,
  getState: () => RootAppState,
  dispatch: AppDispatch,
  initialKeywords?: KeywordDto[]
) => {
  const rows: PartTypeRow[] = []

  bomItemPointers.forEach((x: BomItemPointer) => {
    rows.push(bomItemSelector(getState(), x) as PartTypeRow)
  })

  dispatch({
    type: MaterialActionsConsts.OpenMaterialModal,
    activeRows: rows,
    workingStepType: workingStepType,
    materialHeaderPointer: materialHeaderPointer,
  })

  const currentRow = rows[0]
  let activity = currentRow.activities?.find(
    (x) => x.primaryWorkingStep === workingStepType
  )

  if (activity?.usesRawMaterial) {
    activity = {
      ...activity,
      keywords: currentRow.rawMaterialSummary?.tokens,
      article: {
        ...currentRow.rawMaterialSummary,
        id: currentRow.rawMaterialSummary?.id,
        description: currentRow.rawMaterialSummary?.description,
        financial: currentRow.rawMaterialSummary?.financial,
      },
    }
  }

  setTimeout(() => {
    const activityKeywordsArray = Object.values(activity?.keywords || {}).flat()

    dispatch(
      MaterialActions.getTokensAndMaterials(
        activityKeywordsArray?.length
          ? activityKeywordsArray
          : initialKeywords || [],
        workingStepType
      )
    )
    if (activity?.article?.id) {
      dispatch(MaterialActions.selectMaterial(activity?.article))
    }
  })
}

function translateKeyword(keyword: KeywordDto): string {
  const t = i18next.t

  let translatedString = ''

  if (keyword.translationKey) {
    if (keyword.originalKeyword.startsWith('RAL')) {
      const ralCode = keyword.originalKeyword.substring(3).trim()
      const ralData: RALColorInfo = RALData[ralCode]

      if (ralData) {
        translatedString = t(`RAL_${ralCode}`, ralData.names?.en, {
          ns: 'colors',
        })
      }
    } else {
      translatedString = t(`${keyword.translationKey}`, {
        value: keyword.translationArgument,
        defaultValue: keyword.translationArgument
          ? '{{value}} ' + keyword.translationKey
          : keyword.originalKeyword,
        ns: 'keywords',
      })
    }

    // console.log('translated', keyword.originalKeyword, 'to', translatedString)
  } else {
    translatedString = keyword.originalKeyword
    // console.log('no translation key for keyword', keyword.originalKeyword)
  }

  return translatedString
}

export const MaterialActions = {
  getTokensAndMaterials:
    (selectedTokens: KeywordDto[], workingStepType: WorkingStepType) =>
    (dispatch: AppDispatch, getState: () => AppState) => {
      const { partyId: organizationId, projectId } = getContext(getState)
      let materialUrl = `api/parties/${organizationId}`

      if (getState().materials.activeRows?.[0]?.id === '000-000-000') {
        materialUrl += '/materials'
      } else
        materialUrl += `/projects/${projectId}/activities/${workingStepType}/materials`

      const activities = getState().materials.activeRows?.[0]?.activities

      if (!activities) {
        console.warn('no active rows with working to get materials')
        return null
      }

      // const bomItemWorkingSteps = workingSteps
      //   ? workingSteps.map((x) => x.primaryWorkingStep)
      //   : []
      dispatch(
        APICall(
          new APICallArgs({
            label: 'loading-materials',
            configuration: {
              url: materialUrl,
              method: 'GET',
              data: {
                selectedTokens: selectedTokens.map(
                  (token) => token.originalKeyword
                ),
              },
              onSuccess: (data: MaterialSelectorDto) => {
                if (!getState().materials?.activeRows?.length) {
                  // maybe the user closed the modal before the data was fetched
                  return
                } else {
                  let dataWithTranslations: MaterialSelectorDtoWithTranslations

                  if (data) {
                    dataWithTranslations = {
                      ...data,
                    } as MaterialSelectorDtoWithTranslations

                    for (const [category, keywords] of Object.entries(
                      data.selectableTokens
                    )) {
                      dataWithTranslations.selectableTokens[category] =
                        keywords.map((keyword) => {
                          return {
                            ...keyword,
                            translatedString: translateKeyword(keyword),
                          }
                        })
                    }
                  }

                  dispatch({
                    type: MaterialActionsConsts.SetPossibleTokensAndMaterials,
                    tokensAndMaterials: data,
                  })

                  // if (data) {
                  //   dataWithTranslations.materials = data.materials;
                  //   dataWithTranslations.selectedTokens = data.selectedTokens;

                  //   dataWithTranslations.selectableTokens = data.selectableTokens.reduce((acc, token) => {

                  //   }, {})

                  // }
                }
              },
              onError: (error: string) => {
                ShowException('material selector', error)
              },
            },
          })
        )
      )
    },

  initAndOpenArticleSelector:
    (
      bomItemPointers: BomItemPointer[],
      workingStepType: WorkingStepType,
      materialHeaderPointer?: MaterialHeaderPointer
    ) =>
    (dispatch: AppDispatch, getState: () => RootAppState) => {
      initMaterialSelector(
        bomItemPointers,
        workingStepType,
        materialHeaderPointer,
        getState,
        dispatch
      )
      dispatch(
        UIActions.OpenModal('ArticleSelector', {
          modalId: materialHeaderPointer?.id || bomItemPointers[0].id,
          isPurchasingPrice: false,
          showSaveButton: true,
        } as ArticleSelectorDialogProps)
      )
    },
  initMaterialSelector:
    (
      bomItemPointers: BomItemPointer[],
      workingStepType: WorkingStepType,
      materialHeaderPointer?: MaterialHeaderPointer,
      initialKeywords?: KeywordDto[]
    ) =>
    (dispatch: AppDispatch, getState: () => RootAppState) => {
      initMaterialSelector(
        bomItemPointers,
        workingStepType,
        materialHeaderPointer,
        getState,
        dispatch,
        initialKeywords
      )
    },

  changeTokens:
    (selectedTokens: KeywordDto[], workingStepType: WorkingStepType) =>
    (
      dispatch: (arg0: {
        type: MaterialActionsConsts
        selectedTokens: KeywordDto[]
      }) => void
    ) => {
      dispatch({
        type: MaterialActionsConsts.SetTokens,
        selectedTokens: selectedTokens,
      })

      debouncedChangeTokens(dispatch, selectedTokens, workingStepType)
    },
  selectMaterial: (material: MaterialSummaryDto) => {
    // the material will be updated on materialSelector state first
    return {
      type: MaterialActionsConsts.SetMaterial,
      material: material,
    }
  },
  saveSelectedTokensAndMaterials:
    () => async (dispatch: AppDispatch, getState: () => AppState) => {
      const { partyId: organizationId, projectId } = getContext(getState)

      const bomItemController = new BomItemController(organizationId, projectId)

      const materialsState = getState().materials

      try {
        await bomItemController.SetKeywordsOrArticleToParts({
          bomItemPointers: materialsState.activeRows,
          keywords: materialsState.articleSelectorUI.selectedTokens,
          articleId: materialsState.articleSelectorUI.selectedMaterial.id,
          articleDescription:
            materialsState.articleSelectorUI.selectedMaterial.description,
          materialHeaderPointer: materialsState.materialHeaderPointer,
        })

        dispatch(fetchProject({ projectId: projectId }))
        dispatch(UIActions.CloseModal())

        dispatch(
          newProjectActions.setBomItemInSearch({
            id: getState().materials.activeRows[0].id,
            type: BomItemType.partType,
          })
        )
      } catch (err) {
        ShowException('project', err)
        dispatch(fetchProject({ projectId: projectId }))
      }
    },
  closeModal: () => {
    return {
      type: MaterialActionsConsts.CloseWithoutSave,
    }
  },
  openModalRemnantStrategy: (
    projectId: string,
    resourceId: string,
    materialId: string
  ) => {
    return {
      type: MaterialActionsConsts.OpenModalRemnantStrategy,
      materialId,
      resourceId,
      projectId,
    }
  },

  closeModalRemnantStrategy: () => ({
    type: MaterialActionsConsts.CloseModalRemnantStrategy,
  }),

  clipboardClear: () => {
    return {
      type: MaterialActionsConsts.ClipboardClear,
    }
  },

  copyMaterial: (args: {
    baseBomItemPointer: BomItemPointer
    activities: Array<BoMItemActivityDto>
  }) => {
    return {
      type: MaterialActionsConsts.CopyActivityToClipboard,
      baseBomItemPointer: args.baseBomItemPointer,
      activities: args.activities,
    }
  },

  pasteMaterial:
    (bomItemPointers: BomItemPointer[], workingStepType: WorkingStepType) =>
    async (dispatch: AppDispatch, getState: () => AppState) => {
      const activitiesInClipboard = getState().materials.activitiesClipboard

      if (!activitiesInClipboard) {
        return
      }
      // const { organizationId, projectId } = getContext(getState)
      bomItemPointers.forEach((pointer) => {
        const bomItem = bomItemSelector(getState(), pointer) as PartTypeRow

        if (!bomItem) {
          return
        }

        const activityToChange = bomItem.activities.find(
          (x) => x.primaryWorkingStep === workingStepType
        )

        if (!activityToChange) {
          return
        }

        if (activityToChange.usesRawMaterial) {
          // needs to change the raw material
          const newRawMaterialSummary = {
            ...bomItem.rawMaterialSummary,
          }

          newRawMaterialSummary.tokens = {
            ...newRawMaterialSummary.tokens,
            ...activitiesInClipboard.activities[0].keywords,
          }

          dispatch(
            newProjectActions.setBomItemProperties({
              bomItemPointer: pointer,
              properties: {
                rawMaterialSummary: newRawMaterialSummary,
              },
              replacementBehaviour: 'fullReplace',
            })
          )
        } else {
          const newActivities = bomItem.activities.map((activity) => {
            if (activity.primaryWorkingStep === workingStepType) {
              const clipboardItem = activitiesInClipboard.activities.find(
                (x) => x.primaryWorkingStep === workingStepType
              )

              if (!clipboardItem) {
                return activity
              }

              return {
                ...activity,
                keywords: {
                  ...activity.keywords,
                  ...clipboardItem.keywords,
                },
              }
            }
            return activity
          })
          dispatch(
            newProjectActions.setBomItemProperties({
              bomItemPointer: pointer,
              properties: {
                activities: newActivities,
              },
              replacementBehaviour: 'fullReplace',
            })
          )
        }
      })

      // try {
      //   // const bomItemController = new BomItemController(organizationId, projectId)
      //   // const materialController = new MaterialsController(
      //   //   organizationId,
      //   //   dispatch
      //   // )

      //   // await materialController.AddKeywordsToRows(projectId, {
      //   //   rowIds: rowIds,
      //   //   keywords: Object.values(materialSummary.tokens).flat(),
      //   // })
      // } catch (err) {
      //   dispatch(fetchProject({projectId: projectId}))
      //   throw err
      // }

      // dispatch(projectActions.RemoveAllCalculatedFields())

      // dispatch(
      //   APICall(
      //     new APICallArgs({
      //       label: 'saving-tokens-and-materials',
      //       configuration: {
      //         url: `api/parties/${organizationId}/projects/${projectId}/materials`,
      //         method: 'PUT',
      //         data: {
      //           rowIds: rowIds,
      //           materialSummary: materialSummary,
      //         },
      //         onSuccess: () => {
      //           // SuccessMessage('project', 'project saved')
      //           // dispatch(
      //           //   projectActions.LoadProjectInfo({
      //           //     projectId,
      //           //     shouldSubscribleToUpdates: false,
      //           //   })
      //           // )
      //         },
      //         onError: (message: string) => {
      //           ErrorMessage('project', message)
      //           dispatch(
      //             projectActions.LoadProjectInfo({
      //               projectId,
      //               shouldSubscribleToUpdates: false,
      //             })
      //           )
      //         },
      //       },
      //     })
      //   )
      // )
    },
  flipModel:
    (rowId: string, reset: boolean) =>
    (
      dispatch: (arg0: {
        type: MaterialActionsConsts | ApplicationActionTypes
        payload?: APICallArgs
        rowId?: string
        threeDModelPath?: string
      }) => void,
      getState: () => AppState
    ) => {
      const { partyId: organizationId, projectId } = getContext(getState)

      dispatch(
        APICall(
          new APICallArgs({
            label: 'flip-model',
            configuration: {
              url: `api/parties/${organizationId}/projects/${projectId}/items/${rowId}/flip3dModel?reset=${
                reset || false
              }`,
              method: 'PUT',
              onSuccess: (fileName: string) => {
                dispatch({
                  type: MaterialActionsConsts.Set3dModelPath,
                  rowId: rowId,
                  threeDModelPath: fileName,
                })
              },
              onError: (message: string) => {
                ErrorMessage('project', message)
              },
            },
          })
        )
      )
    },
  rotateModel:
    (rowId: string) =>
    (
      dispatch: (arg0: {
        type: MaterialActionsConsts | ApplicationActionTypes
        payload?: APICallArgs
        rowId?: string
        threeDModelPath?: string
      }) => void,
      getState: () => AppState
    ) => {
      const { partyId: organizationId, projectId } = getContext(getState)

      dispatch(
        APICall({
          label: 'rotate-model',
          configuration: {
            url: `api/parties/${organizationId}/projects/${projectId}/items/${rowId}/rotate3dModel`,
            method: 'PUT',
            onSuccess: (fileName: string) => {
              dispatch({
                type: MaterialActionsConsts.Set3dModelPath,
                rowId: rowId,
                threeDModelPath: fileName,
              })
            },
            onError: (message: string) => {
              ErrorMessage('project', message)
            },
          },
        })
      )
    },
}
