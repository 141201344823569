import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { QuantityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemWeightSelector =
  (bomItemPointer: BomItemPointer) =>
  (state: RootAppState): QuantityDto => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem) {
      return undefined
    }

    switch (bomItem.type) {
      case BomItemType.assemblyType:
      case BomItemType.assemblyInstance:
      case BomItemType.partInstance:
      case BomItemType.partType:
        return bomItem.dimensionsSummary.weight
      case BomItemType.project:
        return bomItem.weight
      default:
        return null
    }
  }
