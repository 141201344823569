import * as React from "react";
const SvgEngravingSm = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 100 100", style: {
  enableBackground: "new 0 0 100 100"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { className: "icon-white", points: "49.9,35.7 14,56.4 14,66.7 50.1,87.5 86,66.8 86,56.5  " }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { className: "icon-outline", d: "M86.6,55.5L50.5,34.7c-0.4-0.2-0.8-0.2-1.1,0L13.5,55.4c-0.4,0.2-0.6,0.6-0.6,1v10.4 c0,0.4,0.2,0.8,0.6,1l36.1,20.8c0.2,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l35.9-20.7c0.4-0.2,0.6-0.6,0.6-1V56.5 C87.1,56.1,86.9,55.7,86.6,55.5z M49,77.9v7.7L15.2,66v-7.7L49,77.9z M83.7,56.5L50.1,75.9L16.3,56.4L49.9,37L83.7,56.5z  M84.8,58.5v7.7L51.3,85.6v-7.7L84.8,58.5z" }), /* @__PURE__ */ React.createElement("path", { className: "icon-highlight", d: "M47.5,54.7c-0.5,0.3-0.8,0.7-1.1,1.2c-0.5,0.8-0.7,1.7-0.8,2.3c-1.6,0.8-3.1,0.9-4.3,0.2 c-1-0.6-1.7-1.6-2-3c-0.5-2.4,0.3-6.3,2-7.3c0.5-0.3,1.2-0.3,1.6-0.1c0.5,0.3,0.7,1.1,0.6,2.2c-0.2,1.2-0.9,2.2-1.8,2.8 c-0.1,0.1-0.6,0.3-0.6,0.8c0,0.2,0.1,0.5,0.4,0.6c0.4,0.2,0.9,0.2,1.3,0.1c0.2,0,0.5-0.1,0.7-0.3c1.1-0.6,1.9-2.2,2.1-3.9 c0.3-2.3-0.8-3.3-1.4-3.7c-1.1-0.6-2.6-0.5-3.9,0.2c-2.9,1.7-3.9,6.3-3.3,8.9c0.4,1.9,1.5,3.4,3,4.3c1.5,0.9,3.4,1,5.4,0.2 c0.1,2.7,1.3,4.9,3.5,6.1c1.3,0.8,2.9,1.2,4.6,1.2c1.8,0,3.8-0.5,5.5-1.4l0,0l0.3-0.2c3.1-1.8,5-4.8,4.7-7.3 c-0.1-1.1-0.6-1.5-1-1.7c-0.5-0.3-1.1-0.3-1.5,0c-0.2,0.2-0.4,0.3-0.4,0.6c-0.1,0.4,0.1,0.7,0.4,1c0.2,0.2,0.4,0.5,0.4,0.8 c0.3,1.6-1.1,3.7-3.4,5c-2.6,1.5-5.6,1.7-7.8,0.3c-2-1.2-3.1-3.2-3-5.5c1.1-0.7,1.9-1.4,2.3-2.1c0.5-0.7,0.5-1.2,0.4-1.5 c-0.1-0.4-0.3-0.7-0.7-0.9C49,54.2,48.2,54.3,47.5,54.7z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { className: "icon-highlight", style: {
  stroke: "var(--icon-highlight)",
  strokeWidth: 0.4167,
  strokeMiterlimit: 10
}, d: "M62.3,25.3c-0.5,0-0.8-0.4-0.8-0.8 v-1.7c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v1.7C63.1,25,62.7,25.3,62.3,25.3z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { className: "icon-highlight", style: {
  stroke: "var(--icon-highlight)",
  strokeWidth: 0.4167,
  strokeMiterlimit: 10
}, d: "M62.3,53c-0.5,0-0.8-0.4-0.8-0.8v-3.5 c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v3.5C63.1,52.6,62.7,53,62.3,53z M62.3,46.1c-0.5,0-0.8-0.4-0.8-0.8v-3.5 c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v3.5C63.1,45.7,62.7,46.1,62.3,46.1z M62.3,39.2c-0.5,0-0.8-0.4-0.8-0.8v-3.5 c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v3.5C63.1,38.8,62.7,39.2,62.3,39.2z M62.3,32.2c-0.5,0-0.8-0.4-0.8-0.8V28 c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v3.5C63.1,31.9,62.7,32.2,62.3,32.2z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { className: "icon-highlight", style: {
  stroke: "var(--icon-highlight)",
  strokeWidth: 0.4167,
  strokeMiterlimit: 10
}, d: "M62.3,58.1c-0.5,0-0.8-0.4-0.8-0.8 v-1.7c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v1.7C63.1,57.7,62.7,58.1,62.3,58.1z" }))))));
export default SvgEngravingSm;
