import { WorkingStepFilter } from 'store/Project/ProjectTypes'

export function workingStepFilterActive(byWorkingStep: {
  filter: WorkingStepFilter
  active: boolean
}) {
  if (!byWorkingStep?.active || !byWorkingStep.filter) {
    return false
  }

  return (
    byWorkingStep.filter?.enabledFilters?.length !==
    byWorkingStep.filter?.availableFilters?.length
  )
}
