import { Button, Tooltip, Typography } from '@mui/material'
import { useAppLogout } from 'features/Auth/useAppLogout'
import _, { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { gettingStartedRoutes } from '../GettingStartedRoutes'

const useStyles = makeStyles({ name: 'GettingStartedUserHeader' })((theme) => ({
  userInfo: {
    padding: theme.spacing(0, 2),
  },

  connectedParties: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
  },
  userCaption: {
    display: 'flex',
    alignItems: 'flex-start',
  },
}))

export const GettingStartedUserHeader = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  // const context = useContext(GettingStartedPageContext)

  const userProfile = useAppSelector((state) => state.user.profile, isEqual)
  const parties = useAppSelector((state) => state.user.organizations, isEqual)

  const { appLogout } = useAppLogout()

  const handleReturnToSignup = async () => {
    await appLogout({
      logoutParams: {
        returnTo: window.location.origin + gettingStartedRoutes.welcome.path,
      },
    })
  }

  return (
    <>
      {!_.isEmpty(userProfile) && (
        <div className={classes.userInfo}>
          <>
            <div className={classes.userCaption}>
              <div>
                <Typography variant="h6">
                  {`${userProfile?.firstName || ''} ${
                    userProfile?.lastName || ''
                  }`.trim()}
                </Typography>
                <Typography variant="caption" style={{ textAlign: 'left' }}>
                  {userProfile?.email}
                </Typography>
              </div>

              <div className={classes.connectedParties}>
                {parties?.length ? (
                  <Tooltip title="workspaces page">
                    <Link to="/app/workspaces">
                      <Typography variant="caption">
                        {t('getting-started:header-connected-to', {
                          defaultValue:
                            'You are currently connected to {{count}} factories',
                          count: parties?.length,
                        })}
                      </Typography>
                    </Link>
                  </Tooltip>
                ) : null}
                <Button
                  size="small"
                  onClick={handleReturnToSignup}
                  color="error"
                  variant="text"
                  style={{
                    marginLeft: 'auto',
                    width: 'fit-content',
                    justifySelf: 'flex-end',
                  }}
                >
                  <Typography variant="caption">
                    {t('getting-started:header-logout', 'logout')}
                  </Typography>
                </Button>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  )
}
