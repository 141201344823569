import { createAsyncThunk } from '@reduxjs/toolkit'
import { ShowException } from 'store/Application/appActions'
import { RootAppState } from 'store/configureStore'
import { projectAPIBuilder } from '../asyncActions/BoMAsyncActions'
import { fetchProject } from './fetchProject'

export const saveProjectRequestedDeliveryDate = createAsyncThunk<
  void,
  string,
  { state: RootAppState }
>(
  'project/saveProjectRequestedDeliveryDate',
  async (requestedDeliveryDate: string, thunkAPI) => {
    const { projectId, api } = projectAPIBuilder(thunkAPI.getState)

    try {
      await api.SetRequestedDeliveryDate(projectId, requestedDeliveryDate)
    } catch (err) {
      ShowException('Requested Delivery Date', err)
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      throw err
    }
  }
)
