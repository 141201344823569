import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ProjectState } from 'store/Project/ProjectTypes'
import { updateHeadersFilteredParts } from '../filters/updateHeadersFilteredParts'

export const toggleActiveFilter: CaseReducer<
  ProjectState,
  PayloadAction<{
    filterType:
      | 'IssuesFilter'
      | 'MaterialKeywordsFilter'
      | 'WorkingStepFilter'
      | 'PartTypeIdFilter'
      | 'ArticleIdFilter'
      | 'ResourceIdFilter'
      | 'ErpErrorFilter'
      | 'TodosFilter'
    open?: boolean
  }>
> = (state, action) => {
  switch (action.payload.filterType) {
    case 'TodosFilter':
      state.filters.byTodos.active =
        action.payload.open ?? !state.filters.byTodos.active
      if (!state.filters.byTodos.active) {
        state.filters.byTodos.filter.enabledFilters = []
      } else {
        state.filters.byTodos.filter.enabledFilters =
          state.filters.byTodos.filter.availableFilters.map((x) => x.issueCode)
      }

      break

    case 'IssuesFilter':
      state.filters.byIssues.active =
        action.payload.open ?? !state.filters.byIssues.active
      if (!state.filters.byIssues.active) {
        state.filters.byIssues.filter.enabledFilters = []
      } else {
        state.filters.byIssues.filter.enabledFilters =
          state.filters.byIssues.filter.availableFilters.map((x) => x.issueCode)
      }

      break
    case 'MaterialKeywordsFilter':
      state.filters.byMaterialKeywords.active =
        action.payload.open ?? !state.filters.byMaterialKeywords?.active
      if (!state.filters.byMaterialKeywords.active) {
        state.filters.byMaterialKeywords.filter.enabledFilters = {}
      }
      break
    case 'WorkingStepFilter':
      state.filters.byWorkingStep.active =
        action.payload.open ?? !state.filters.byWorkingStep?.active
      if (!state.filters.byWorkingStep.active) {
        state.filters.byWorkingStep.filter.enabledFilters = []
      }
      break
    case 'PartTypeIdFilter':
      state.filters.byPartTypeId.active =
        action.payload.open || !state.filters.byPartTypeId?.active
      if (!state.filters.byPartTypeId.active) {
        state.filters.byPartTypeId.filter.enabledFilters = []
      }
      break
    case 'ArticleIdFilter':
      state.filters.byArticleId.active =
        action.payload.open ?? !state.filters.byArticleId?.active
      if (!state.filters.byArticleId.active) {
        state.filters.byArticleId.filter.enabledFilters = []
      }
      break
    case 'ResourceIdFilter':
      state.filters.byResourceId.active =
        action.payload.open ?? !state.filters.byResourceId?.active
      if (!state.filters.byResourceId.active) {
        state.filters.byResourceId.filter.enabledFilters = []
      }
      break
    case 'ErpErrorFilter':
      state.filters.byErpError.active = action.payload.open
      break
    default:
      return
  }
  updateHeadersFilteredParts(state)
}
