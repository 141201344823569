import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ProjectListState } from './NewProjectListReducer'

export const deleteProjectsFromStore: CaseReducer<
  ProjectListState,
  PayloadAction<{
    projectIds: string[]
  }>
> = (state, action) => {
  const { projectIds } = action.payload
  state.projectList = state.projectList?.filter(
    (x) => !projectIds.includes(x.id)
  )
}
