import { BomItemType, PartTypeRow } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemActivityHasArticleSelected =
  (bomItemPointer: BomItemPointer, activityId: string) =>
  (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItemPointer.type) {
      case BomItemType.partType:
        return Boolean(
          (bomItem as PartTypeRow).activities.find((x) => x.id === activityId)
            .article?.id
        )
      default:
        return false
    }
  }
