import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectsAPI } from 'controllers/Projects/ProjectsAPI'
import { ShowException } from 'store/Application/appActions'
import { GetPartyId } from 'store/storeUtils'
import { newProjectListActions } from '../NewProjectListReducer'
import { subscribeToProjectListUpdates } from './subscribeToProjectListUpdates'
import { RootAppState } from 'store/configureStore'

export const fetchProjectSummaries = createAsyncThunk<
  void,
  { subscribeToUpdates?: boolean; isRefresh?: boolean },
  { state: RootAppState }
>(
  'projectList/fetchProjectSummaries',
  async (
    {
      subscribeToUpdates,
    }: { subscribeToUpdates?: boolean; isRefresh?: boolean },
    thunkAPI
  ) => {
    const partyId = GetPartyId()

    try {
      const projectsAPI = new ProjectsAPI(partyId)

      const { projectList, projectStates } = await projectsAPI.GetProjectsList()

      thunkAPI.dispatch(
        newProjectListActions.projectListReceived({
          projectList,
          projectStates,
        })
      )

      if (subscribeToUpdates) {
        thunkAPI.dispatch(subscribeToProjectListUpdates())
      }
    } catch (err) {
      ShowException('project list', err)
    }
  }
)
