import {
  MaterialSummaryDto,
  WorkingStepType,
} from '../services/APIs/InternalAPI/internal-api.contracts'
import {
  KeywordTranslated,
  MaterialSelectorDtoWithTranslations,
} from './materials/MaterialSelectorDtoWithTranslation'

export interface MaterialSelector {
  selectableTokens: string[]
  selectedTokens: string[]
  materials: MaterialSummaryDto[]
  selectedMaterial: MaterialSummaryDto
  isActive: boolean
  isLoading: boolean
}

const MaterialsSorter = (articles: Array<MaterialSummaryDto>) => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  })
  return articles?.sort((a, b) => collator.compare(a.id, b.id))
}

export class ArticleSelectorUI {
  public selectableTokens: KeywordTranslated[]
  public selectedTokens: KeywordTranslated[]
  public materials: MaterialSummaryDto[]
  public selectedMaterial: MaterialSummaryDto
  public workingStepType: WorkingStepType

  public constructor(
    tokensAndMaterials: MaterialSelectorDtoWithTranslations,
    selectedArticle: MaterialSummaryDto,
    workingStepType: WorkingStepType
  ) {
    if (!tokensAndMaterials) return this

    const selectableTokens = Object.values(
      tokensAndMaterials?.selectableTokens || {}
    ).flat()

    const selectedTokens = Object.values(
      tokensAndMaterials.selectedTokens || {}
    ).flat()

    return {
      selectableTokens,
      selectedTokens,
      materials: MaterialsSorter(tokensAndMaterials.materials),
      selectedMaterial: selectedArticle,
      workingStepType: workingStepType,
    }
  }
}
