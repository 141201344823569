import { RootAppState } from 'store/configureStore'
import { ProjectViews } from 'store/UI/UITypes'
import { BomItemPointer } from '../../../../../model/Project/BomItemPointer'

export const getPreviousAndNextItemOfProject = (state: RootAppState) => {
  let next: BomItemPointer = null

  if (!state.project.filteredAssemblyHeaderIds?.length) {
    return { previousBomItem: null, nextBomItem: null }
  }

  switch (state.project.currentView) {
    case ProjectViews.MaterialsView: {
      const { id, type } =
        state.project.materialHeaders[
          state.project.filteredMaterialHeaderIds[0]
        ]?.filteredPartTypePointers[0]
      next = {
        id: id,
        type: type,
      }
      break
    }
    case ProjectViews.RoutesView: {
      const { id, type } =
        state.project.routingHeaders[state.project.filteredRoutingHeaderIds[0]]
          ?.filteredPartTypePointers[0]
      next = {
        id: id,
        type: type,
      }
      break
    }
    default: {
      const { id, type } =
        state.project.assemblyHeaders[
          state.project.filteredAssemblyHeaderIds[0]
        ]
      next = {
        id: id,
        type: type,
      }
      break
    }
  }

  return { previousBomItemPointer: null, nextBomItemPointer: next }
}
