import { createAsyncThunk } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemUpdateRequestDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from '../selectors/bomItemSelector'
import { projectAPIBuilder } from './BoMAsyncActions'
import { fetchProject } from './fetchProject'

export const batchUpdateBomItems = createAsyncThunk<
  void,
  BomItemUpdateRequestDto[],
  { state: RootAppState }
>(
  'project/batch-update-bom-items',
  async (payload: BomItemUpdateRequestDto[], thunkAPI) => {
    const { projectId, api } = projectAPIBuilder(thunkAPI.getState)

    try {
      const state = thunkAPI.getState()
      const filteredPayload: BomItemUpdateRequestDto[] = payload
        .filter((item) => {
          const currentBomItem = bomItemSelector(state, {
            id: item.bomItemId,
            type: BomItemType.partType,
          })

          if (currentBomItem.type !== BomItemType.partType) {
            return false
          }

          // batch update only updates quality of first activity that uses raw materials
          const currentBomItemKeywords = currentBomItem.activities.find(
            (x) => x.usesRawMaterial
          ).keywords

          return (
            !isEqual(currentBomItemKeywords, item.keywords) ||
            currentBomItem.financial?.quantity !== item.quantity ||
            currentBomItem.comment != item.comment ||
            currentBomItem.name != item.name
          )
        })
        .map((item) => ({
          bomItemId: item.bomItemId,
          quantity: item.quantity,
          drawingNumber: item.drawingNumber,
          revision: item.revision,
          keywords: item.keywords,
          comment: item.comment,
          name: item.name,
        }))

      await api.BatchUpdateBomItems(projectId, filteredPayload)
    } catch (err) {
      ShowException('project', err)
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      throw err
    } finally {
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
    }
  }
)
