import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemAttachmentsController } from 'services/APIs/InternalAPI/AttachmentAPI'
import { ShowException } from 'store/Application/appActions'
import { GetContext } from 'store/storeUtils'
import { RootAppState } from 'store/configureStore'

export const downloadAttachment = createAsyncThunk<
  Promise<void>,
  { bomItemId: string; fileName: string },
  { state: RootAppState }
>(
  'bomItem/downloadAttachment',
  async (
    { bomItemId, fileName }: { bomItemId: string; fileName: string },
    thunkAPI
  ) => {
    const { partyId, projectId } = GetContext()
    const api = new BomItemAttachmentsController(partyId, projectId)

    try {
      await api.DownloadFile(bomItemId, fileName)
    } catch (err) {
      ShowException('project', err)
      thunkAPI.rejectWithValue(err)
    }
  }
)
