import { LogoutOptions, useAuth0 } from '@auth0/auth0-react'
import { useCallback } from 'react'
import { ClearLoginTries } from './useAppLogin'

export const useAppLogout = () => {
  const { logout } = useAuth0()

  const appLogout = useCallback(
    async (options?: LogoutOptions) => {
      ClearLoginTries()
      window['Intercom']?.('shutdown')
      window.localStorage.removeItem('rh24_lastVisitedWorkspaceId')

      console.log('appLogoutOptions', options)

      const logoutOptions = {
        logoutParams: {
          returnTo: `${window.location.origin}/logout`,
        },
        ...(options || {}),
      }

      logoutOptions.clientId = import.meta.env.VITE_AUTH0_CLIENT_ID

      console.log('logoutOptions', logoutOptions)

      try {
        await logout(logoutOptions)
        console.log('logged out')
      } catch (err) {
        console.error('logout error', err)
      }
    },
    [logout]
  )

  return {
    appLogout,
  }
}
