import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

const inMemoryStore: Record<string, string> = {}

const testLocalStorage = () => {
  try {
    window.localStorage.setItem('test', 'test')
    window.localStorage.removeItem('test')
    return true
  } catch (error) {
    return false
  }
}

const testSessionStorage = () => {
  try {
    window.sessionStorage.setItem('test', 'test')
    window.sessionStorage.removeItem('test')
    return true
  } catch (error) {
    return false
  }
}

export function useClientStorage<T>(
  key: string,
  initialValue?: T,
  storageType: 'localStorage' | 'sessionStorage' = 'localStorage',
  /**
   * Migration function to migrate the value from the storage to the new format.
   */
  migrationFn?: (value: T) => T
) {
  const localStorageAvailable = useRef(testLocalStorage())
  const sessionStorageAvailable = useRef(testSessionStorage())

  const getItem = useCallback(
    (key: string) => {
      if (storageType === 'localStorage' && localStorageAvailable.current) {
        return window.localStorage.getItem(key)
      } else if (
        storageType === 'sessionStorage' &&
        sessionStorageAvailable.current
      ) {
        return window.sessionStorage.getItem(key)
      } else {
        console.warn(
          `using in-memory storage since ${storageType} is not available`
        )
        return inMemoryStore[key]
      }
    },
    [storageType]
  )

  const setItem = useCallback(
    (key: string, value: string) => {
      if (storageType === 'localStorage' && localStorageAvailable.current) {
        if (value) {
          window.localStorage.setItem(key, value)
        } else {
          window.localStorage.removeItem(key)
        }
      } else if (
        storageType === 'sessionStorage' &&
        sessionStorageAvailable.current
      ) {
        if (value) {
          window.sessionStorage.setItem(key, value)
        } else {
          window.sessionStorage.removeItem(key)
        }
      } else {
        console.warn(
          `using in-memory storage since ${storageType} is not available`
        )
        inMemoryStore[key] = value
      }
    },
    [storageType]
  )

  const [value, setValue] = useState<T>(() => {
    try {
      const item = getItem(key)

      if (item == undefined) {
        return initialValue ?? ''
      } else {
        try {
          const obj = JSON.parse(item)
          if (migrationFn) {
            const migratedValue = migrationFn(obj)
            setItem(key, JSON.stringify(migratedValue))

            return migratedValue
          } else {
            return obj
          }
        } catch (error) {
          return item
        }
      }
    } catch (error) {
      console.error(error)
      return initialValue
    }
  })

  useEffect(() => {
    setItem(key, JSON.stringify(value))
  }, [key, setItem, value])

  /*
   * saveOnStorageOnly: if true, only save on local storage, not on state, maining that the component will not be re-rendered
   */
  const setLocalStorage = useCallback(
    (
      value: T | SetStateAction<T>,
      options?: { saveOnStorageOnly?: boolean }
    ) => {
      try {
        if (!options?.saveOnStorageOnly) {
          setValue(value)
        } else {
          setItem(key, JSON.stringify(value))
        }
      } catch (error) {
        console.error(error)
      }
    },
    [key, setItem]
  )

  return [value, setLocalStorage] as const
}
