import { ModelController } from "../manager/ModelController"

export const ExplodeTool = (props: { viewController: ModelController }) => {
  return (
    <input
      type="range"
      min={0}
      max={1}
      step={0.01}
      defaultValue={0}
      onChange={(e) => props.viewController.explode(Number(e.currentTarget.value))}
      onDrag={(e) => e.stopPropagation()}
    />
  )
}
