import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemController } from 'controllers/Project/BomItemController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { ShowException } from 'store/Application/appActions'
import { getContext } from 'store/getContext'
import { RootAppState } from 'store/configureStore'

export const downloadBomItemFiles = createAsyncThunk<
  Promise<void>,
  { bomItemPointer: BomItemPointer },
  { state: RootAppState; rejectValue: unknown }
>('BoM/download-bom-item-files', async ({ bomItemPointer }, thunkAPI) => {
  const { projectId, partyId } = getContext(thunkAPI.getState)
  const bomItemController = new BomItemController(partyId, projectId)

  try {
    await bomItemController.downloadBomItemFiles(bomItemPointer)
  } catch (err) {
    ShowException('download', err)
    thunkAPI.rejectWithValue(err)
  }
})
