import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'
import { BomItemPointer } from '../../../../model/Project/BomItemPointer'

export const bomItemIsOpenSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem) return false

    return bomItem.type === BomItemType.partType ||
      bomItem.type === BomItemType.partInstance ||
      bomItem.type === BomItemType.project
      ? false
      : bomItem.isOpen
  }
