import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { MoneyDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { newProjectActions } from '../projectReducer'
import { projectAPIBuilder } from './BoMAsyncActions'
import { fetchProject } from './fetchProject'
import { RootAppState } from 'store/configureStore'

export const saveBomItemTargetPrice = createAsyncThunk<
  void,
  { bomItemPointer: BomItemPointer; targetPrice: MoneyDto },
  { state: RootAppState }
>(
  'bomItem/saveBomItemTargetPrice',
  async ({ bomItemPointer, targetPrice }, thunkAPI) => {
    const { api, projectId } = projectAPIBuilder(thunkAPI.getState)

    try {
      thunkAPI.dispatch(
        newProjectActions.setBomItemProperties({
          bomItemPointer,
          properties: {
            financial: {
              targetSalesPricePerItem: targetPrice,
            },
          },
        })
      )

      await api.SetTargetPrice(projectId, bomItemPointer.id, targetPrice)
    } catch (error) {
      ShowException('target price', error)
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      throw error
    }
  }
)
