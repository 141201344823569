import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemController } from 'controllers/Project/BomItemController'
import { ConfirmationDialogProps } from 'features/ConfirmationDialog/ConfirmationDialog'
import i18n from 'i18next'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { UIActions } from 'store/UI/UIActions'
import { RootAppState } from 'store/configureStore'
import { getContext } from 'store/getContext'
import { newProjectActions } from '../projectReducer'
import { bomItemSelector } from '../selectors/bomItemSelector'
import { projectSelectors } from '../selectors/projectSelectors'
import { fetchCurrentProject, fetchProject } from './fetchProject'

export const saveBomItemIsPurchasingPart = createAsyncThunk<
  void,
  { bomItemPointer?: BomItemPointer },
  { state: RootAppState }
>('boM/saveBomItemIsPurchasingPart', async ({ bomItemPointer }, thunkAPI) => {
  const updateBomItems = async (bomItemPointers: BomItemPointer[]) => {
    const { partyId, projectId } = getContext(thunkAPI.getState)

    const controller = new BomItemController(partyId, projectId)

    try {
      const selectedBomItems = bomItemPointers.map((x) =>
        bomItemSelector(thunkAPI.getState(), x)
      )
      const purchasingPartsToUnset = selectedBomItems.filter((x) => {
        if (
          x.type === BomItemType.partType ||
          x.type === BomItemType.assemblyInstance
        ) {
          return x.isPurchasingBomItem
        } else {
          return false
        }
      })

      const notPurchasingPartsToSetAsPurchasing = selectedBomItems.filter(
        (x) => {
          if (
            x.type === BomItemType.partType ||
            x.type === BomItemType.assemblyInstance
          ) {
            return !x.isPurchasingBomItem
          } else {
            return false
          }
        }
      )

      if (purchasingPartsToUnset?.length > 0) {
        for (let i = 0; i < purchasingPartsToUnset.length; i++) {
          thunkAPI.dispatch(
            newProjectActions.setBomItemProperties({
              bomItemPointer: {
                id: purchasingPartsToUnset[i].id,
                type: purchasingPartsToUnset[i].type,
              },
              properties: {
                isPurchasingBomItem: false,
                workingSteps: [],
                activities: [],
                rawMaterialSummary: null,
              },
              replacementBehaviour: 'fullReplace',
            })
          )
          thunkAPI.dispatch(
            newProjectActions.resetBomItemFinancials(
              purchasingPartsToUnset.map((x) => ({ id: x.id, type: x.type }))
            )
          )
        }
      }

      if (notPurchasingPartsToSetAsPurchasing?.length > 0) {
        for (let i = 0; i < notPurchasingPartsToSetAsPurchasing.length; i++) {
          if (
            notPurchasingPartsToSetAsPurchasing[i].type === BomItemType.partType
          ) {
            thunkAPI.dispatch(
              newProjectActions.setBomItemProperties({
                bomItemPointer: {
                  id: notPurchasingPartsToSetAsPurchasing[i].id,
                  type: notPurchasingPartsToSetAsPurchasing[i].type,
                },
                properties: {
                  isPurchasingBomItem: true,
                  rawMaterialSummary: null,
                  workingSteps: [
                    {
                      primaryWorkingStep: WorkingStepType.Purchasing,
                      secondaryWorkingStep: WorkingStepType.Purchasing,
                    },
                  ],
                  activities: [
                    {
                      primaryWorkingStep: WorkingStepType.Purchasing,
                      secondaryWorkingStep: WorkingStepType.Purchasing,
                    },
                  ],
                },
                replacementBehaviour: 'fullReplace',
              })
            )
            thunkAPI.dispatch(
              newProjectActions.resetBomItemFinancials(
                notPurchasingPartsToSetAsPurchasing.map((x) => ({
                  id: x.id,
                  type: x.type,
                }))
              )
            )
          }
        }
      }

      if (purchasingPartsToUnset?.length > 0) {
        await controller.UnsetAsPurchasingItem(
          purchasingPartsToUnset.map((x) => ({ id: x.id, type: x.type }))
        )
        thunkAPI.dispatch(fetchCurrentProject())
      }

      if (notPurchasingPartsToSetAsPurchasing?.length > 0) {
        const partTypes = notPurchasingPartsToSetAsPurchasing
          .filter((x) => x.type === BomItemType.partType)
          ?.map((x) => ({ id: x.id, type: x.type }))

        if (partTypes?.length > 0) {
          await controller.SetAsPurchasingItem(partTypes)
          thunkAPI.dispatch(fetchCurrentProject())
        }

        const assemblyInstances = notPurchasingPartsToSetAsPurchasing
          .filter((x) => x.type === BomItemType.assemblyInstance)
          ?.map((x) => ({ id: x.id, type: x.type }))

        if (assemblyInstances?.length > 0) {
          thunkAPI.dispatch(
            UIActions.OpenModal('ConfirmationDialog', {
              title: i18n.t(
                'project:convert-to-purchasing-part',
                'purchasing part'
              ),
              onConfirm: async () => {
                try {
                  await controller.ConvertSubAssemblyToPurchasingPart(
                    assemblyInstances[0]
                  )
                  thunkAPI.dispatch(fetchCurrentProject())
                } catch (err) {
                  thunkAPI.dispatch(fetchProject({ projectId: projectId }))
                  ShowException(
                    i18n.t('project:convert-to-purchasing-part'),
                    err
                  )
                  throw err
                }
              },
            } as ConfirmationDialogProps)
          )
        }
      }
    } catch (err) {
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      throw err
    }
  }

  try {
    const bomItemPointers = bomItemPointer
      ? [bomItemPointer]
      : projectSelectors
          .selectedBomItemsPointersSelector(thunkAPI.getState())
          .map((x) => x)

    if (!bomItemPointers.length) {
      console.error('no bom item to set as purchasing parts')
      return
    }

    await updateBomItems(bomItemPointers)

    if (bomItemPointers.length > 1) {
      thunkAPI.dispatch(newProjectActions.clearSelectedBomItems())
    }
  } catch (err) {
    ShowException('project', err)
    thunkAPI.dispatch(fetchCurrentProject())

    throw err
  }
})
