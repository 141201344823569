import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemController } from 'controllers/Project/BomItemController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  MoneyDto,
  PriceScope,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { getContext } from 'store/getContext'
import { newProjectActions } from '../projectReducer'
import { fetchProject } from './fetchProject'
import { RootAppState } from 'store/configureStore'

export type Props = {
  bomItemPointer: BomItemPointer
  materialCostPrice: MoneyDto
  priceScope: PriceScope
}

export const saveBomItemMaterialCostPrice = createAsyncThunk<
  void,
  Props,
  { state: RootAppState }
>(
  'boM/saveMaterialCostPrice',
  async ({ bomItemPointer, materialCostPrice, priceScope }, thunkAPI) => {
    const { partyId: organizationId, projectId } = getContext(thunkAPI.getState)

    const controller = new BomItemController(organizationId, projectId)

    thunkAPI.dispatch(
      newProjectActions.resetBomItemFinancials([bomItemPointer])
    )

    try {
      thunkAPI.dispatch(
        newProjectActions.setBomItemProperties({
          bomItemPointer: bomItemPointer,
          properties: {
            financial: {
              salesPricePerItem: materialCostPrice,
            },
          },
        })
      )

      await controller.SetMaterialCostPrice(
        bomItemPointer,
        materialCostPrice,
        priceScope
      )
    } catch (err) {
      ShowException('project', err)
      await thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      throw err
    }
  }
)
