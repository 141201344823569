import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemController } from 'controllers/Project/BomItemController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { getContext } from 'store/getContext'
import { newProjectActions } from '../projectReducer'
import { fetchProject } from './fetchProject'
import { RootAppState } from 'store/configureStore'

export const saveBomItemName = createAsyncThunk<
  void,
  { bomItemPointer: BomItemPointer; name: string },
  { state: RootAppState }
>('boM/saveBomItemName', async ({ bomItemPointer, name }, thunkAPI) => {
  const { partyId: organizationId, projectId } = getContext(thunkAPI.getState)

  thunkAPI.dispatch(
    newProjectActions.setBomItemProperties({
      bomItemPointer: bomItemPointer,
      properties: {
        name: name,
      },
    })
  )

  const controller = new BomItemController(organizationId, projectId)

  try {
    await controller.SetBomItemName(bomItemPointer, name)
  } catch (err) {
    thunkAPI.dispatch(fetchProject({ projectId: projectId }))
    throw err
  }
})
