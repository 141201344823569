import { partInstancePartTypeSelector } from 'features/BillOfMaterials/store/selectors/partInstancePartTypeSelector'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useEffect, useMemo } from 'react'
import { useAppSelector } from 'store/configureStore'
import { ModelController } from './ModelController'

export const useModelViewInstance = (bomItemPointer: BomItemPointer) => {
  const pointer = useAppSelector(
    partInstancePartTypeSelector(bomItemPointer),
    isEqual
  )

  const viewManagerInstance = useMemo(() => {
    return new ModelController(pointer)
  }, [pointer])

  useEffect(() => {
    return () => {
      try {
        viewManagerInstance.dispose()
      } catch (err) {
        console.error('unable to dispose the ModelController instance', err)
      }
    }
  }, [viewManagerInstance])

  return [viewManagerInstance]
}
