import { BoMItemRow, BomItemType } from 'model/Project/BoMItemRow'
import { TodosFilter } from 'store/Project/ProjectTypes'
import { showPartBasedOnTodos } from './showPartBasedOnTodos'

export function applyTodosFilter(
  filter: TodosFilter,
  row: BoMItemRow
): boolean {
  let issuesFilterAppliedShowItem = false

  switch (row.type) {
    case BomItemType.partType:
    case BomItemType.partInstance: {
      issuesFilterAppliedShowItem = showPartBasedOnTodos(row, filter)
    }
  }

  return issuesFilterAppliedShowItem
}
