import { RootAppState } from 'store/configureStore'

export const projectRequestedDeliveryDateSelector = (state: RootAppState) => {
  if (state.project.activeProject?.requestedDeliveryDate) {
    return state.project.activeProject.requestedDeliveryDate
    // const dateWithTimezone = new Date(
    //   state.project.activeProject.requestedDeliveryDate
    // )

    // return new Date(
    //   dateWithTimezone.getUTCFullYear(),
    //   dateWithTimezone.getUTCMonth(),
    //   dateWithTimezone.getUTCDate()
    // )
  }

  return null
}
