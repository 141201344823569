import {
  AssemblyInstanceRow,
  BomItemType,
  PartTypeRow,
} from 'model/Project/BoMItemRow'
import {
  WorkingStepDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { BomItemPointer } from '../../../../model/Project/BomItemPointer'
import { bomItemSelector } from './bomItemSelector'

export type WorkingStepStats = {
  count: number
  primaryWorkingStep: WorkingStepType
}

export const projectPriceSummariesWorkingStepSummary = (
  state: RootAppState
) => {
  if (!state.project.priceSummaries) {
    return null
  }

  const stats: Partial<Record<WorkingStepType, WorkingStepStats>> = {}

  Object.values(state.project.priceSummaries).map((priceSummary) => {
    if (
      priceSummary?.isWorkingStep &&
      priceSummary.workingStep.primaryWorkingStep ===
        priceSummary.workingStep.secondaryWorkingStep
    ) {
      const workingStep = priceSummary.workingStep.primaryWorkingStep

      if (!stats[workingStep]) {
        stats[workingStep] = {
          count: 0,
          primaryWorkingStep: workingStep,
        }
      }

      stats[workingStep].count += 1
    }
  })

  return stats
}

export const assemblyWorkingStepsSummaryWithCounterSelector =
  (
    bomItemPointer: BomItemPointer,
    onlyAssemblyWorkingSteps?: boolean
  ): ((state: RootAppState) => Record<string, WorkingStepStats>) =>
  (state: RootAppState): Record<string, WorkingStepStats> => {
    if (!bomItemPointer?.id) return null

    const bomItem = bomItemSelector(state, bomItemPointer)

    if (
      bomItem?.type !== BomItemType.assemblyInstance &&
      bomItem?.type !== BomItemType.assemblyType &&
      bomItem?.type !== BomItemType.partType
    ) {
      return null
    }

    const workingStepsStats: Record<string, WorkingStepStats> = {}

    const addToStats = (workingStep: WorkingStepDto) => {
      if (workingStep.primaryWorkingStep === workingStep.secondaryWorkingStep) {
        workingStepsStats[workingStep.primaryWorkingStep] = {
          count:
            workingStepsStats[workingStep.primaryWorkingStep]?.count + 1 || 1,
          primaryWorkingStep: workingStep.primaryWorkingStep,
        }
      }
    }

    if (bomItem?.type === BomItemType.assemblyType) {
      if (onlyAssemblyWorkingSteps) {
        bomItem.assemblyActivities.forEach(addToStats)
      } else {
        bomItem.partTypeIds.forEach((partTypeId) => {
          const partType = bomItemSelector(state, {
            id: partTypeId,
            type: BomItemType.partType,
          }) as PartTypeRow

          partType?.activities?.forEach(addToStats)
        })

        const addSubAssembliesStats = (subAssemblyPointer: BomItemPointer) => {
          const subAssembly = bomItemSelector(
            state,
            subAssemblyPointer
          ) as AssemblyInstanceRow

          if (!subAssembly) return

          if (subAssembly?.subAssembliesPointers?.length > 0) {
            subAssembly.subAssembliesPointers.forEach((x) =>
              addSubAssembliesStats(x)
            )
          }

          subAssembly.assemblyActivities?.forEach(addToStats)

          subAssembly.partInstancePointers?.forEach((pointer) => {
            const partType = bomItemSelector(state, pointer)

            if (partType?.type === BomItemType.partType) {
              partType.activities.forEach(addToStats)
            }
          })
        }

        addSubAssembliesStats(bomItemPointer)
      }
    } else if (bomItem?.type === BomItemType.assemblyInstance) {
      bomItem.assemblyActivities.forEach(addToStats)
    } else {
      bomItem.activities.forEach(addToStats)
    }

    const sortedStats = Object.fromEntries(
      Object.entries(workingStepsStats).sort(
        ([, a], [, b]) => b.count - a.count
      )
    )

    return sortedStats
  }
