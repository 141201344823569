import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import {
  FinancialDto,
  MoneyDto,
  QuantityDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectState } from 'store/Project/ProjectTypes'
import { bomItemSelector } from '../selectors/bomItemSelector'

const GetEmptyMoneyDto = (moneyDto: MoneyDto) => {
  return {
    ...moneyDto,
    value: undefined,
  }
}

const GetEmptyQuantityDto = (quantityDto: QuantityDto) => {
  return {
    ...quantityDto,
    value: undefined,
  }
}

const getResetedFinancials = (financial: FinancialDto) => {
  const emptyMoneyDto = GetEmptyMoneyDto({ ...financial['costPricePerItem'] })
  return {
    ...financial,
    costPriceOfItems: emptyMoneyDto,
    salesPriceOfItems: emptyMoneyDto,
    salesPricePerItem: emptyMoneyDto,
    totalProjectQuantity: undefined,
    discountValueOfItems: emptyMoneyDto,
    discountValuePerItem: emptyMoneyDto,
    surchargeValueOfItems: emptyMoneyDto,
    surchargeValuePerItem: emptyMoneyDto,
    surchargeRatio: GetEmptyQuantityDto(financial.surchargeRatio),
    discountRatio: GetEmptyQuantityDto(financial.discountRatio),
  } as FinancialDto
}

export const resetBomItemFinancials: CaseReducer<
  ProjectState,
  PayloadAction<BomItemPointer[]>
> = (state, action) => {
  action.payload.forEach((bomItemPointer) => {
    if (!bomItemPointer) return null

    if (bomItemPointer.id === state.activeProject?.id) {
      state.activeProject.financial = getResetedFinancials(
        state.activeProject.financial
      )

      state.assemblyHeadersIds?.forEach((assemblyHeaderId) => {
        resetBomItemFinancials(state, {
          type: '',
          payload: [
            {
              id: assemblyHeaderId,
              type: BomItemType.assemblyType,
            },
          ],
        })
        resetBomItemFinancials(state, {
          type: '',
          payload: [
            {
              id: assemblyHeaderId,
              type: BomItemType.assemblyInstance,
            },
          ],
        })
      })
    }

    const bomItem = bomItemSelector({ project: state }, bomItemPointer)

    if (!bomItem) return

    switch (bomItem?.type) {
      case BomItemType.assemblyType: {
        bomItem.financial = getResetedFinancials(bomItem.financial)

        resetBomItemFinancials(state, {
          type: '',
          payload: bomItem.partTypePointers,
        })
        break
      }
      case BomItemType.assemblyInstance: {
        bomItem.financial = getResetedFinancials(bomItem.financial)
        resetBomItemFinancials(state, {
          type: '',
          payload: bomItem.partInstancePointers,
        })
        break
      }
      case BomItemType.partType:
      case BomItemType.partInstance: {
        bomItem.financial = getResetedFinancials(bomItem.financial)
      }
    }

    Object.keys(state.priceSummaries).forEach((priceSummaryId) => {
      state.priceSummaries[priceSummaryId] = {
        ...state.priceSummaries[priceSummaryId],
        totalSalesPrice: GetEmptyMoneyDto(
          state.priceSummaries[priceSummaryId].totalSalesPrice
        ),
      }
    })
  })
}
