import {
  CaseReducer,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import { ProjectSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { deleteProjectsAction } from './asyncActions/deleteProjects'
import { fetchProjectSummaries } from './asyncActions/fetchProjectSummaries'
import { deleteProjectsFromStore } from './deleteProjectsFromStore'

export type ProjectListState = {
  projectList?: ProjectSummaryDto[]
  projectStates?: string[]
  loading?: boolean
  operation?: string
  operationStatus?: 'pending' | 'fulfilled' | 'rejected'
  operationError?: string
  error?: SerializedError
}

const projectListReceived: CaseReducer<
  ProjectListState,
  PayloadAction<{
    projectList: ProjectSummaryDto[]
    projectStates: string[]
  }>
> = (state, action) => {
  state.projectList = action.payload.projectList
  state.projectStates = action.payload.projectStates
  state.loading = false

  return state
}

const projectListInitialState: ProjectListState = {
  projectList: [],
  projectStates: [],
  loading: false,
}

export const projectsListSlice = createSlice({
  name: 'projects-list',
  initialState: projectListInitialState,
  reducers: {
    projectListReceived: projectListReceived,
    clearProjectList: (state) => {
      state.projectList = []
      state.projectStates = []
      state.loading = false
    },
    deleteProject: deleteProjectsFromStore,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectSummaries.pending, (state, action) => {
      state.loading = true
      state.operation = action.meta.arg.isRefresh
        ? 'fetchProjectSummaries-refresh'
        : 'fetchProjectSummaries'

      return state
    })
    builder.addCase(fetchProjectSummaries.fulfilled, (state) => {
      state.loading = false

      return state
    })
    builder.addCase(fetchProjectSummaries.rejected, (state, action) => {
      state.loading = false
      state.error = action.error

      return state
    })
    builder.addCase(deleteProjectsAction.pending, (state) => {
      state.operation = 'delete'
      state.operationStatus = 'pending'
      state.loading = true

      return state
    })
    builder.addCase(deleteProjectsAction.fulfilled, (state) => {
      state.operation = 'delete'
      state.operationStatus = 'fulfilled'
      state.loading = false

      // projectsListSlice.actions.deleteProject({
      //   projectIds: action.meta.arg.map((x) => x.projectId),
      // })

      return state
    })

    builder.addCase(deleteProjectsAction.rejected, (state, action) => {
      state.operation = 'delete'
      state.operationStatus = 'rejected'
      state.loading = false
      state.error = action.error

      return state
    })
  },
})

export default projectsListSlice.reducer
export const newProjectListActions = projectsListSlice.actions
