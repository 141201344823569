import LogRocket from 'logrocket'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { ProjectViews } from 'store/UI/UITypes'
import { RootAppState } from 'store/configureStore'
import { getPreviousAndNextItemOfProject } from './getPreviousAndNextItemOfProject'
import { getPreviousAndNextPointers } from './getPreviousAndNextPointers'

export const previousAndNextBomItemIdsSelector =
  (bomItemPointer: BomItemPointer) =>
  (
    state: RootAppState
  ): {
    previousBomItemPointer?: BomItemPointer
    nextBomItemPointer?: BomItemPointer
  } => {
    try {
      if (bomItemPointer.type === BomItemType.project) {
        return getPreviousAndNextItemOfProject(state)
      }

      let headerType: BomItemType = undefined
      switch (state.project.currentView) {
        case ProjectViews.MaterialsView: {
          headerType = BomItemType.materialHeader
          break
        }
        case ProjectViews.RoutesView: {
          headerType = BomItemType.routingHeader
          break
        }
        default: {
          if (
            bomItemPointer.type === BomItemType.partInstance ||
            bomItemPointer.type === BomItemType.assemblyInstance
          ) {
            headerType = BomItemType.assemblyInstance
          } else {
            headerType = BomItemType.assemblyType
          }
          break
        }
      }

      return getPreviousAndNextPointers(state, headerType, bomItemPointer)
    } catch (err) {
      console.error(err)
      LogRocket.captureException(err as Error)

      return {
        nextBomItemPointer: undefined,
        previousBomItemPointer: undefined,
      }
    }
  }
