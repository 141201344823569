import { BaseController } from 'controllers/BaseController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RememberBomItemAsPurchasingPartArgs } from 'services/APIs/InternalAPI/internal-api.contracts'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import { GetPartyId } from 'store/storeUtils'

type IGeometryAPI = {
  RememberOrForgetGeometryAsPurchasingItem: (
    args: RememberBomItemAsPurchasingPartArgs
  ) => Promise<void>
}

class GeometryAPI extends InternalAPI implements IGeometryAPI {
  constructor(partyId: string, private onRequestChanged) {
    super(`/api/parties/${partyId}/geometries`)
  }

  public async RememberOrForgetGeometryAsPurchasingItem(
    args: RememberBomItemAsPurchasingPartArgs
  ) {
    return await this.PostAsync<void>({
      relativePath: '/setAsPurchasingPart',
      data: args,
      id: 'remember-geometry-as-purchasing-item',
      onRequestChange: this.onRequestChanged,
    })
  }
}

export class GeometryController extends BaseController<IGeometryAPI> {
  constructor() {
    super((onRequestChange) => new GeometryAPI(GetPartyId(), onRequestChange))
  }

  public async RememberOrForgetGeometryAsPurchasingItem(
    projectId: string,
    bomItemPointer: BomItemPointer,
    shallRemember: boolean,
    articleId?: string
  ) {
    try {
      await this.api.RememberOrForgetGeometryAsPurchasingItem({
        projectId,
        itemId: bomItemPointer.id,
        shallRemember,
        articleId,
      })
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }
}
