import { GetAllBomItemActivitiesKeywords } from 'components/OrganizationSettings/VirtualFactory/Capabilities/Activities/ActivitiesUtils'
import { ProjectState } from 'store/Project/ProjectTypes'
import { bomItemAllActivitiesSelector } from '../selectors/activities/allBomItemActivitiesSelector'

/**
 * @param state
 * @returns
 */
export const refreshMaterialFilter = (state: ProjectState) => {
  const availableFilters = GetAllBomItemActivitiesKeywords(
    Object.values(state.partTypes).flatMap((x) => {
      return bomItemAllActivitiesSelector({
        id: x.id,
        type: x.type,
      })({ project: state })
    })
  )

  const newState: ProjectState = {
    ...state,
    filters: {
      ...state.filters,
      byMaterialKeywords: {
        ...state.filters.byMaterialKeywords,
        filter: {
          ...state.filters.byMaterialKeywords.filter,
          availableFilters,
        },
        active:
          state.filters?.byMaterialKeywords?.active &&
          Object.keys(availableFilters || {}).length > 0,
      },
    },
  }

  return newState
}
