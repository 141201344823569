import { BomItemPointer } from 'model/Project/BomItemPointer'
import { ProjectModel } from 'model/ProjectModel'
import {
  AssemblyDto,
  RowDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemManufacturabilitySelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    if (!bomItemPointer) return null

    let bomItem: ProjectModel | AssemblyDto | RowDto

    if (bomItemPointer.id === state.project?.activeProject?.id) {
      bomItem = state.project.activeProject
    } else {
      bomItem = bomItemSelector(state, bomItemPointer)
    }

    if (!bomItem) {
      return null
    }

    return {
      // isExported: bomItem.isExportedToErp,
      // exportedResult: bomItem.exportedToErpResult,
      // exportedStatus: bomItem.exportedToErpStatus,
      isManufacturable: bomItem.isManufacturable,
      manufacturableResult: bomItem.manufacturableResult,
      manufacturableStatus: bomItem.manufacturableStatus,
    }
  }
