import { ProjectsAPI } from 'controllers/Projects/ProjectsAPI'
import { RootAppState } from 'store/configureStore'
import { getContext } from 'store/getContext'

export const projectAPIBuilder = (getState: () => RootAppState) => {
  const { projectId, partyId: organizationId } = getContext(getState)

  return {
    projectId: projectId,
    api: new ProjectsAPI(organizationId),
  }
}
