import { BoMItemRow, BomItemType } from 'model/Project/BoMItemRow'
import { KeywordDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { MaterialKeywordsFilter } from 'store/Project/ProjectTypes'
import { showPartTypeBasedOnTokens } from './showPartTypeBasedOnMaterials'

export function applyMaterialFilter(
  filter: MaterialKeywordsFilter,
  row: BoMItemRow,
  showWithAllKeywords: boolean
): boolean {
  let materialFilterAppliedShowItem = false

  switch (row.type) {
    case BomItemType.partType:
    case BomItemType.partInstance: {
      const allTokens = row.activities?.reduce((acc, curr) => {
        return {
          ...acc,
          ...curr.keywords,
        }
      }, {} as Record<string, KeywordDto[]>)

      materialFilterAppliedShowItem = showPartTypeBasedOnTokens(
        { ...allTokens, ...(row.rawMaterialSummary?.tokens || {}) },
        filter,
        showWithAllKeywords
      )
    }
  }

  return materialFilterAppliedShowItem
}
