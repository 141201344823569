import { CapabalitiesSettingsActionTypes, Actions } from './Actions'
import { ResourceDto } from '../../services/APIs/InternalAPI/internal-api.contracts'
import { ResourceSummaryDto } from '../../services/APIs/InternalAPI/internal-api.contracts'
import { ActivityDto } from '../../services/APIs/InternalAPI/internal-api.contracts'
import _ from 'lodash'

export type CapabilitiesSettingsState = {
  Machines: ResourceDto[]
  Templates: ResourceSummaryDto[]
}

const initialState: CapabilitiesSettingsState = {
  Machines: undefined,
  Templates: undefined,
}

function updateMachineActivity(
  state: CapabilitiesSettingsState,
  machineIdToUpdate: string,
  activityToUpdate: Partial<ActivityDto>
): ResourceDto[] {
  return [
    ...state.Machines.map((machine) => {
      if (machine.id === machineIdToUpdate) {
        const newResource = Object.assign({}, machine)

        newResource.activities = newResource.activities.map((activity) => {
          if (
            _.isEqual(activity.workingStepType, activityToUpdate.workingStepType)
          ) {
            return {
              ...activity,
              ...activityToUpdate,
            }
          }

          return activity
        })
        return newResource
      } else {
        return machine
      }
    }),
  ]
}

export const CapabilitiesSettingsReducer = (
  state = initialState,
  action: CapabalitiesSettingsActionTypes
): CapabilitiesSettingsState => {
  switch (action.type) {
    case Actions.MACHINES_LOADED:
      return {
        ...state,
        Machines: action.machines,
      }
    case Actions.CAPABILITIES_UPLOAD_PROGRESS:
      // todo: UPDATE UPLOAD PROGRESS STATE
      return {
        ...state,
        // Machines: updateMachineActivity(state, action.machineId, {
        //   uploadProgress: action.progressPercentage,
        // }),
      }
    case Actions.UNLOAD_MACHINES:
      return initialState
    case Actions.MACHINE_TEMPLATES_LOADED:
      return {
        ...state,
        Templates: action.templates,
      }
    case Actions.TECHNIQUE_UPDATED:
      return {
        ...state,
        Machines: updateMachineActivity(
          state,
          action.machineId,
          action.technique
        ),
      }
    case Actions.UNLOAD_MACHINE_TEMPLATES:
      return {
        ...state,
        Templates: undefined,
      }
    default:
      return state
  }
}
