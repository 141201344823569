import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectController } from 'controllers/Project/ProjectController'
import { ShowException } from 'store/Application/appActions'
import { RootAppState } from 'store/configureStore'

export const downloadProjectDeliveryDateEstimation = createAsyncThunk<
  Promise<void>,
  { projectId?: string },
  { state: RootAppState }
>(
  'project/download-project-delivery-date-estimation',
  async (payload, thunkAPI) => {
    try {
      const projectController = new ProjectController()

      await projectController.DownloadProjectDeliveryTimeEstimation()
    } catch (err) {
      ShowException('delivery time estimation', err)
      thunkAPI.rejectWithValue(err)
    }
  }
)
