import { createAsyncThunk } from '@reduxjs/toolkit'
import { SignalRService } from 'features/SignalR/service/SignalRService'
import { debounce } from 'lodash'
import { RootAppState } from 'store/configureStore'

export const subscribeToUpdates = createAsyncThunk<
  void,
  {
    groupName: string
    clientMethodName: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clientMethod: (...args: any[]) => void
    onLeaveGroupCallback?: (groupName: string) => void
  },
  { state: RootAppState }
>(
  'live-updates/subscribeToUpdates',
  async ({
    groupName,
    clientMethodName,
    clientMethod,
    onLeaveGroupCallback,
  }) => {
    const hub = await SignalRService.GetHub()

    hub.unregister(clientMethodName)

    hub.registerHandler(
      clientMethodName,
      debounce(clientMethod, 1000, {
        leading: true,
        trailing: true,
        maxWait: 1000,
      })
    )

    if (onLeaveGroupCallback) {
      hub.registerHandler(`onleavegroup-${groupName}`, () => {
        onLeaveGroupCallback(groupName)
      })
    }

    await hub.JoinGroup(
      groupName,
      () => {
        console.info('joinedGroup', groupName)
      },
      true
    )
  }
)

export const unsubscribeFromUpdates = createAsyncThunk<
  void,
  { groupName: string; clientMethodName?: string },
  { state: RootAppState }
>(
  'live-updates/unsubscribeFromUpdates',
  async ({ groupName, clientMethodName }) => {
    const hub = await SignalRService.GetHub()

    if (clientMethodName) {
      hub.unregister(clientMethodName)
    }

    await hub.LeaveGroup(groupName, () => {
      console.info('leftGroup', groupName)
      hub.unregister(`onleavegroup-${groupName}`)
    })
  }
)
