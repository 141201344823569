import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

/**
 * selects the PartTypePointer related to a PartInstance.
 * If the bomItemPointer is not a PartInstance, it returns the same bomItemPointer
 * @param bomItemPointer a pointer to a part instance
 * @returns partTypePointer related to this part instance
 */
export const partInstancePartTypeSelector =
  (bomItemPointer: BomItemPointer) =>
  (state: RootAppState): BomItemPointer => {
    if (bomItemPointer.type !== BomItemType.partInstance) {
      return bomItemPointer
    }

    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem) {
      return bomItemPointer
    }

    if (bomItem.type !== BomItemType.partInstance) {
      return bomItemPointer
    }

    return {
      id: bomItem.partTypeId,
      type: BomItemType.partType,
    }
  }
