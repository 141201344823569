import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { BomItemType } from 'model/Project/BoMItemRow'
import { ProjectState } from 'store/Project/ProjectTypes'
import { BomItemPointer } from '../../../../model/Project/BomItemPointer'
import { setBoMItemOpen } from './setBoMItemOpen'

export const setSearchedBomItem: CaseReducer<
  ProjectState,
  PayloadAction<BomItemPointer>
> = (state, action) => {
  state.searchedBomItemPointer = action.payload
}

export const setSearchedBomItemByRowNumber: CaseReducer<
  ProjectState,
  PayloadAction<number>
> = (state, action) => {
  const partType = Object.values(state.partTypes).find(
    (x) => x.rowNumber === action.payload
  )

  if (partType) {
    state.searchedBomItemPointer = {
      id: partType.id,
      type: BomItemType.partType,
    }
    setBoMItemOpen(state, {
      payload: {
        bomItemPointer: { id: partType.id, type: BomItemType.partType },
        isOpen: true,
      },
      type: 'project/setOpen',
    })
  }
}
