import _ from 'lodash'
import { FileWithDetails } from 'model/FileWithDetails'
import { ProjectEventType } from 'model/ProjectEventLevel'
import {
  BomItemUpdateRequestDto,
  KeywordDto,
  ProjectDto,
  ProjectSummaryDto,
  RowDto,
  SetEstimatedDeliveryDateRequest,
  SetEstimatedDeliveryDaysRequest,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { SelectableMaterials } from 'store/NewMaterialSelector/NewMaterialSelectorTypes'
import {
  OnRequestChangeCallback,
  RequestStatus,
} from '../../services/APIs/BaseAPI'
import { InternalAPI } from '../../services/APIs/InternalAPI/InternalAPI'
import {
  CreateProjectRequest,
  IssueDto,
  MoneyDto,
  MoneyPerQuantityDto,
  QuantityDto,
  WorkingStepDto,
  WorkingStepType,
} from '../../services/APIs/InternalAPI/internal-api.contracts'
import { MaterialsOperations } from '../MaterialsController'

export class ProjectsAPI extends InternalAPI {
  constructor(
    organizationId: string,
    onRequestChanged?: OnRequestChangeCallback
  ) {
    super(`/api/parties/${organizationId}/projects`, onRequestChanged)
  }

  public CreateNewProject = async (args: CreateProjectRequest) => {
    const ret = await this.PostAsync<string>({
      relativePath: '/',
      data: { ...args },
      id: 'create-project',
    })

    return ret
  }

  public GetProjectsList = async () => {
    return await this.GetAsync<{
      projectList: ProjectSummaryDto[]
      projectStates: string[]
    }>({
      relativePath: '/',
    })
  }

  public DeleteProjects = async (projectIds: string[]) => {
    return await this.PostAsync({
      relativePath: '/delete',
      data: {
        projectIds: projectIds,
      },
    })
  }

  public DeleteProject = async (projectId: string) => {
    return await this.DeleteAsync({
      relativePath: `/${projectId}`,
    })
  }

  public GetProjectInfo = async (
    projectId: string,
    reason?: ProjectEventType,
    reasonExtra?: string
  ) => {
    this.CancelRequests()
    return await this.GetAsync<ProjectDto>({
      relativePath: `/${projectId}`,
      id: `get-project-info-${projectId}`,
      headers: {
        'X-Event-Reason': reason
          ? ProjectEventType[reason]?.toString() ?? "unknown"
          : ProjectEventType[ProjectEventType.NotInitialized].toString(),
        'X-Event-ReasonExtra': reasonExtra
      },
    })
  }

  public GetBomItem = async (
    projectId: string,
    bomItemId: string,
    onRequestChange?: OnRequestChangeCallback
  ) => {
    return await this.GetAsync<RowDto>({
      relativePath: `/${projectId}/items/${bomItemId}`,
      onRequestChange: onRequestChange,
    })
  }

  public DeleteRowOrAssembly = async (
    projectId: string,
    rowOrAssemblyId: string[]
  ) => {
    return await this.PostAsync({
      relativePath: `/${projectId}/items/delete`,
      data: rowOrAssemblyId,
    })
  }

  /**
   * @deprecated in favor of ProjectResourcesAPI.
   */
  public SetResourceSurchargeRatio = async (
    projectId: string,
    resourceId: string,
    primaryWorkingStep: WorkingStepType,
    secondaryWorkingStep: WorkingStepType,
    surchargeRatio: QuantityDto
  ) => {
    return await this.PutAsync<void>({
      id: `setPriceSummary`,
      relativePath: `/${projectId}/resources/${resourceId}/${primaryWorkingStep}/${secondaryWorkingStep}/surcharge`,
      data: { surchargeRatio },
    })
  }

  /**
   * @deprecated in favor of ProjectResourcesAPI.
   */
  public SetResourceSurchargeValue = async (
    projectId: string,
    resourceId: string,
    primaryWorkingStep: WorkingStepType,
    secondaryWorkingStep: WorkingStepType,
    surchargeValue: MoneyPerQuantityDto
  ) => {
    return await this.PutAsync<void>({
      id: `setPriceSummary`,
      relativePath: `/${projectId}/resources/${resourceId}/${primaryWorkingStep}/${secondaryWorkingStep}/surcharge`,
      data: { surchargeValue },
    })
  }

  /**
   * @deprecated in favor of ProjectResourcesAPI.
   */
  public SetResourceDiscountRatio = async (
    projectId: string,
    resourceId: string,
    primaryWorkingStep: WorkingStepType,
    secondaryWorkingStep: WorkingStepType,
    discountRatio: QuantityDto
  ) => {
    return await this.PutAsync<void>({
      id: `setPriceSummary`,
      relativePath: `/${projectId}/resources/${resourceId}/${primaryWorkingStep}/${secondaryWorkingStep}/discount`,
      data: { discountRatio },
    })
  }

  /**
   * @deprecated in favor of ProjectResourcesAPI.
   */
  public SetResourceDiscountValue = async (
    projectId: string,
    resourceId: string,
    primaryWorkingStep: WorkingStepType,
    secondaryWorkingStep: WorkingStepType,
    discountValue: MoneyPerQuantityDto
  ) => {
    return await this.PutAsync<void>({
      id: `setPriceSummary`,
      relativePath: `/${projectId}/resources/${resourceId}/${primaryWorkingStep}/${secondaryWorkingStep}/discount`,
      data: { discountValue },
    })
  }

  /**
   * @deprecated in favor of ProjectResourcesAPI.
   */
  public SetResourceCostPrice = async (
    projectId: string,
    resourceId: string,
    primaryWorkingStep: WorkingStepType,
    secondaryWorkingStep: WorkingStepType,
    costPrice: MoneyPerQuantityDto
  ) => {
    return await this.PutAsync<void>({
      id: `${resourceId}_costprice`,
      relativePath: `/${projectId}/resources/${resourceId}/${secondaryWorkingStep}/costPrice`,
      data: costPrice,
    })
  }

  /**
   * @deprecated in favor of ProjectResourcesAPI.
   */
  public DeleteResourceDiscount = async (
    projectId: string,
    resourceId: string,
    activity: WorkingStepDto
  ) => {
    return await this.DeleteAsync({
      id: `delete-discount-${resourceId}-${activity.primaryWorkingStep}-${activity.secondaryWorkingStep}`,
      relativePath: `/${projectId}/resources/${resourceId}/${activity.primaryWorkingStep}/${activity.primaryWorkingStep}/discount`,
    })
  }

  /**
   * @deprecated in favor of ProjectResourcesAPI.
   */
  public DeleteResourceSurcharge = async (
    projectId: string,
    resourceId: string,
    activity: WorkingStepDto
  ) => {
    return await this.DeleteAsync({
      id: `delete-surcharge-${resourceId}-${activity.primaryWorkingStep}-${activity.secondaryWorkingStep}`,
      relativePath: `/${projectId}/resources/${resourceId}/${activity.primaryWorkingStep}/${activity.primaryWorkingStep}/surcharge`,
    })
  }

  /**
   * @deprecated in favor of ProjectResourcesAPI.
   */
  public DeleteResourceCostPrice = async (
    projectId: string,
    resourceId: string,
    activity: WorkingStepDto
  ) => {
    return await this.DeleteAsync({
      id: `delete-cost-price-${resourceId}-${activity.primaryWorkingStep}-${activity.secondaryWorkingStep}`,
      relativePath: `/${projectId}/resources/${resourceId}/${activity.primaryWorkingStep}/${activity.secondaryWorkingStep}/costPrice`,
    })
  }

  /**
   * @deprecated in favor of ProjectArticleAPI.
   */
  public SetArticleSurchargeRatio = async (
    projectId: string,
    articleId: string,
    surchargeRatio: QuantityDto
  ) => {
    return await this.PutAsync<void>({
      id: 'SetArticleSurchargeRatio',
      relativePath: `/${projectId}/articles/surcharge`,
      data: {
        surchargeRatio,
        articleId: articleId,
      },
    })
  }

  /**
   * @deprecated in favor of ProjectArticleAPI.
   */
  public SetArticleSurchargeValue = async (
    projectId: string,
    articleId: string,
    surchargeValue: MoneyDto
  ) => {
    return await this.PutAsync<void>({
      id: 'surchargeValue',
      relativePath: `/${projectId}/articles/surcharge`,
      data: {
        surchargeValue,
        articleId,
      },
    })
  }

  /**
   * @deprecated in favor of ProjectArticleAPI.
   */
  public SetArticleDiscountRatio = async (
    projectId: string,
    articleId: string,
    discountRatio: QuantityDto
  ) => {
    return await this.PutAsync<void>({
      id: 'SetArticleDiscountRatio',
      relativePath: `/${projectId}/articles/discount`,
      data: {
        discountRatio,
        articleId: articleId,
      },
    })
  }

  /**
   * @deprecated in favor of ProjectArticleAPI.
   */
  public SetArticleDiscountValue = async (
    projectId: string,
    articleId: string,
    discountValue: MoneyDto
  ) => {
    return await this.PutAsync<void>({
      id: 'discountValue',
      relativePath: `/${projectId}/articles/discount`,
      data: {
        discountValue,
        articleId,
      },
    })
  }

  public DeleteArticleDiscount = async (
    projectId: string,
    articleId: string
  ) => {
    return await this.DeleteAsync({
      id: `delete-discount-${articleId}`,
      relativePath: `/${projectId}/articles/discount`,
      params: {
        articleId: articleId,
      },
    })
  }

  public DeleteArticleSurcharge = async (
    projectId: string,
    articleId: string
  ) => {
    return await this.DeleteAsync({
      id: `delete-surcharge-${articleId}`,
      relativePath: `/${projectId}/articles/surcharge`,
      params: {
        articleId: articleId,
      },
    })
  }

  /**
   * @deprecated in favor of ProjectArticleAPI
   */
  public SetArticleCostPrice = async (
    projectId: string,
    articleId: string,
    costPrice: MoneyPerQuantityDto
  ) => {
    //removing properties that crash with server side serialization
    const costPriceWithoutSelectableAbbreviations = _.cloneDeep(costPrice)
    delete costPriceWithoutSelectableAbbreviations.quantity
      ?.selectableAbbreviations

    return await this.PutAsync<void>({
      id: `${articleId}_costprice`,
      relativePath: `/${projectId}/articles/costPrice`,
      data: {
        costPrice: costPriceWithoutSelectableAbbreviations,
        articleId: articleId,
      },
    })
  }

  /**
   * @deprecated
   */
  public DeleteArticleCostPrice = async (
    projectId: string,
    articleId: string
  ) => {
    return await this.DeleteAsync({
      id: `delete-cost-price-${articleId}`,
      relativePath: `/${projectId}/articles/costPrice`,
      params: {
        articleId: articleId,
      },
    })
  }

  public SetProjectStatus = async (
    projectId: string,
    from: string,
    to: string,
    onRequestChange?: (status: Partial<RequestStatus>) => void
  ) => {
    return await this.PutAsync<void>({
      relativePath: `/${projectId}/status`,
      data: {
        initialStatus: from,
        targetStatus: to,
      },
      onRequestChange,
    })
  }

  public SetProjectReference = async (
    projectId: string,
    projectReference,
    onRequestChange?
  ) => {
    return await this.PutAsync<void>({
      relativePath: `/${projectId}/reference`,
      data: {
        projectReference,
      },
      onRequestChange,
    })
  }

  public SetOrderNumber = async (
    projectId: string,
    order: string,
    onRequestChange?
  ) => {
    return await this.PutAsync<void>({
      relativePath: `/${projectId}/orderNumber`,
      data: {
        orderNumber: order,
      },
      onRequestChange,
    })
  }

  public SetProjectBatchSize = async (
    projectId: string,
    batchSize: number,
    onRequestChange?
  ) => {
    return await this.PutAsync<void>({
      relativePath: `/${projectId}/quantities`,
      data: {
        quantity: batchSize,
      },
      onRequestChange,
    })
  }

  public SetWorkingStepResource = async (
    projectId: string,
    rowIds: string[],
    workingStepType: WorkingStepType,
    resourceId: string,
    handleRequestChange: (req: Partial<RequestStatus>) => void
  ) => {
    return await this.PutAsync<void>({
      relativePath: `/${projectId}/items/workingSteps/resources`,
      data: {
        rowIds,
        workingStepType,
        resourceId,
      },
      onRequestChange: handleRequestChange,
    })
  }

  public SetMaterialTokens = async (
    projectId: string,
    rowIds: string[],
    selectedTokens: KeywordDto[]
  ) => {
    return await this.PutAsync<void>({
      id: `saving-tokens-${rowIds[0]}`,
      relativePath: `/${projectId}/materials`,
      data: {
        rowIds,
        keywords: selectedTokens.map((x) => x.originalKeyword),
        // materialSummary: {
        //   tokens: selectedTokens.map((x) => x.originalKeyword),
        // },
      },
    })
  }

  public GetMaterialTokensInProjectContext = async (
    projectId: string,
    filterTokens: string[],
    isPurchasingPart?: boolean,
    bomItemWorkingSteps?: WorkingStepType[],
    handleRequestChange?: (req: Partial<RequestStatus>) => void
  ) => {
    return await this.GetAsync<SelectableMaterials>({
      relativePath: `/${projectId}/materials`,
      params: {
        selectedTokens: filterTokens,
        isPurchasingPart,
        bomItemWorkingSteps,
      },
      onRequestChange: (req) => {
        handleRequestChange({
          ...req,
          id: MaterialsOperations.GetMaterials,
        })
      },
    })
  }

  public GetProjectIssues = async (projectId: string) => {
    return await this.GetAsync<IssueDto[]>({
      id: 'get-project-issues',
      relativePath: `/${projectId}/getIssues`,
    })
  }

  public Recalculate = async (projectId: string) => {
    return await this.PostAsync<void>({
      id: 'recalculate',
      relativePath: `/${projectId}/recalculate`,
    })
  }

  public RecalculateWithUpdatedPrices = async (projectId: string) => {
    return await this.PostAsync<void>({
      id: 'recalculate-with-updated-prices',
      relativePath: `/${projectId}/UpdatePricesAndRecalculate`,
    })
  }

  public UploadGeometry = async (
    projectId: string,
    files: FileWithDetails[],
    onProgressCallback: (progress: number) => void
  ): Promise<void> => {
    return await this.UploadAsync<void>({
      id: 'upload-geometries',
      relativePath: `/${projectId}/uploadGeometryWithDetails`,
      data: files,
      onUploadProgress: onProgressCallback,
    })
  }

  public SetTargetPrice = async (
    projectId: string,
    bomItemId: string,
    targetPrice: MoneyDto
  ) => {
    return await this.PutAsync<void>({
      id: `set-target-price-${bomItemId}`,
      relativePath: `/${projectId}/TargetSalesPricePerItem`,
      data: {
        id: bomItemId,
        targetSalesPricePerItem: targetPrice,
      },
    })
  }

  public BatchUpdateBomItems = async (
    projectId: string,
    payload: BomItemUpdateRequestDto[]
  ) => {
    return await this.PostAsync<void>({
      id: 'batch-update-bom-items',
      relativePath: `/${projectId}/batch-update`,
      data: { bomItemUpdates: payload },
    })
  }

  public async SetRequestedDeliveryDate(
    projectId: string,
    requestedDeliveryDate: string
  ) {
    return await this.PostAsync<void>({
      id: 'set-requested-delivery-date',
      relativePath: `/${projectId}/RequestedDeliveryDate`,
      data: {
        requestedDeliveryDate,
      },
    })
  }

  public async SetFeasibleDeliveryDate(
    projectId: string,
    feasibleDeliveryDate: Date
  ) {
    return await this.PostAsync<void>({
      id: 'set-requested-feasible-delivery-date',
      relativePath: `/${projectId}/FeasibleDeliveryDate`,
      data: {
        feasibleDeliveryDate,
      },
    })
  }

  public async ManualExportToErp(projectId: string) {
    return await this.PostAsync<void>({
      id: 'manual-export-to-erp',
      relativePath: `/${projectId}/ManualExportToErp`,
    })
  }

  public async SetEstimatedDeliveryDate(
    projectId: string,
    req: SetEstimatedDeliveryDateRequest
  ) {
    return await this.PostAsync<void>({
      id: 'set-estimated-delivery-date',
      relativePath: `/${projectId}/EstimatedDeliveryDate`,
      data: req,
    })
  }

  public async SetEstimatedDeliveryDays(
    projectId: string,
    req: SetEstimatedDeliveryDaysRequest
  ) {
    return await this.PostAsync<void>({
      id: 'set-estimated-delivery-days',
      relativePath: `/${projectId}/EstimatedDeliveryDays`,
      data: req,
    })
  }
}
