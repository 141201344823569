import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequestStatus } from 'services/APIs/BaseAPI'

export type ConnectionStatus =
  | 'online'
  | 'offline'
  | 'disconnected'
  | 'reconnecting'
  | 'connecting'

export type ConnectionState = {
  status: ConnectionStatus
  error?: Error
  retryDescription?: string
  groups: string[]
  retryCount: number
  lastRequest: RequestStatus
}

const initialState: ConnectionState = {
  status: 'offline',
  error: undefined,
  retryDescription: undefined,
  groups: [],
  retryCount: 0,
  lastRequest: undefined,
}

const connectionsSlide = createSlice({
  initialState: initialState,
  name: 'connections',
  reducers: {
    SetError: (state, action: PayloadAction<{ error: Error }>) => {
      state.error = action.payload.error
      state.retryCount = 0
    },
    SetOnlineStatus: (
      state,
      action: PayloadAction<{
        connectionState: ConnectionStatus
        error?: Error
        retryDescription?: string
        retryCount?: number
      }>
    ) => {
      state.status = action.payload.connectionState
      state.error = action.payload.error
      state.retryDescription = action.payload.retryDescription
      state.retryCount = action.payload.retryCount || 0
    },
    AddGroup: (state, action: PayloadAction<{ group: string }>) => {
      state.groups.push(action.payload.group)
    },
    RemoveGroup: (state, action: PayloadAction<{ group: string }>) => {
      state.groups = state.groups.filter((g) => g !== action.payload.group)
    },
    SetLatestRequest: (
      state,
      action: PayloadAction<{ request: Partial<RequestStatus> }>
    ) => {
      state.lastRequest = {
        ...state.lastRequest,
        ...action.payload.request,
      }
    },
  },
})

export default connectionsSlide.reducer
export const NetworkActions = connectionsSlide.actions
