import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemQuantitySelector =
  (bomItemPointer: BomItemPointer) =>
  (state: RootAppState): number => {
    if (!state.project.activeProject) {
      return null
    }

    if (!bomItemPointer) return null
    if (bomItemPointer.id === state.project?.activeProject?.id) {
      return state.project.activeProject.financial.quantity
    }

    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItem?.type) {
      case BomItemType.materialHeader:
        return bomItem.cuttingPlans.reduce((acc, cp) => acc + cp.quantity, 0)
      case BomItemType.project:
      case BomItemType.assemblyInstance:
      case BomItemType.partType:
      case BomItemType.partInstance:
        return bomItem.financial.quantity
      case BomItemType.assemblyType:
        return bomItem.financial.quantity
      case BomItemType.routingHeader:
        return 0
    }
  }
