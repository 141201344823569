/* eslint-disable @typescript-eslint/no-explicit-any */
import { Theme } from '@mui/material'
import { AxiosProgressEvent } from 'axios'
import { Action } from 'redux'
import { BuiltInThemes } from 'utils/appTheme'
import { Issue } from '../../model/IssueContext'
import { Notification } from '../../model/Notification'

export type Rh24Theme = {
  logoSrc: string
} & Theme

export interface ApplicationState {
  readonly isNotificationOpen: boolean
  readonly language: 'en'
  readonly showNewItemsModal: boolean
  readonly isLoading: boolean
  readonly currentNetworkAction: string
  readonly loadingMessage: string
  readonly signalR: {
    [group: string]: {
      isConnected: boolean
      isReconnecting: boolean
      errorMessage: string
    }
  }
  readonly userFeedback: {
    show: boolean
    issue: Issue
  }
  readonly newProjectForm: {
    show: boolean
    isSellerRole: boolean
  }
  readonly theme: Theme
  readonly builtInThemeName?: BuiltInThemes
}

export enum ApplicationActionTypes {
  API = 'app/API_CALL',
  SubscribeToUpdates = 'app/SignalR',
  StartNetwork = 'app/startNetwork',
  EndNetwork = 'app/endNetwork',
  ToggleNewItemsModal = 'app/ToggleNewItemsModal',
  OpenMaterialSelector = 'app/OpenMaterialSelector',
  ToggleNotifications = 'app/ToggleNotifications',
  NotificationReceived = 'app/NotificationReceived',
  SetCurrentTabIndex = 'app/navigation/SetCurrentTabIndex',
  SetLoadingMessage = 'app/SetLoadingMessage',
  SignalRSubscriptionInfo = 'SignalRSubscriptionInfo',
  ShowUserFeedbackForm = 'app/showUserFeedbackForm',
  ShowNewProjectForm = 'app/ShowNewProjectForm',
  SetAppTheme = 'app/SetAppTheme',
  SetBuiltInTheme = 'app/SetBuiltInTheme',
  CloseUserFeedbackForm = 'CloseUserFeedbackForm',
}

interface ToggleNewItemsModal extends Action {
  type: ApplicationActionTypes.ToggleNewItemsModal
  open?: boolean
}

interface OpenMaterialSelector extends Action {
  type: ApplicationActionTypes.OpenMaterialSelector
  isOpen: boolean
}

interface ToggleNotifications extends Action {
  type: ApplicationActionTypes.ToggleNotifications
  shouldOpen: boolean
}

interface NotificationReceived extends Action {
  type: ApplicationActionTypes.NotificationReceived
  notifications: Notification[]
}

interface SetCurrentTabIndex extends Action {
  type: ApplicationActionTypes.SetCurrentTabIndex
  tabIndex: number
}

interface NetworkStart extends Action {
  type: ApplicationActionTypes.StartNetwork
  payload: string
}

interface NetworkEnd extends Action {
  type: ApplicationActionTypes.EndNetwork
}

interface SetLoadingMessage extends Action {
  type: ApplicationActionTypes.SetLoadingMessage
  message: string
}

interface SignalRSubscriptionInfo extends Action {
  type: ApplicationActionTypes.SignalRSubscriptionInfo
  payload: {
    [groupName: string]: {
      isConnected: boolean
      isReconnecting: boolean
      errorMessage: string
    }
  }
}

interface ShowUserFeedbackForm extends Action {
  type: ApplicationActionTypes.ShowUserFeedbackForm
  show: boolean
  issue: Issue
}

interface ShowNewProjectForm extends Action {
  type: ApplicationActionTypes.ShowNewProjectForm
  show: boolean
  isSellerRole: boolean
}

interface SetAppTheme extends Action {
  type: ApplicationActionTypes.SetAppTheme
  theme: Theme
}

interface SetBuiltInTheme extends Action {
  type: ApplicationActionTypes.SetBuiltInTheme
  themeName: BuiltInThemes
}

interface CloseUserFeedbackForm extends Action {
  type: ApplicationActionTypes.CloseUserFeedbackForm
}

export type AppActionTypes =
  | ToggleNewItemsModal
  | OpenMaterialSelector
  | ToggleNotifications
  | NotificationReceived
  | SetCurrentTabIndex
  | NetworkStart
  | NetworkEnd
  | SetLoadingMessage
  | SignalRSubscriptionInfo
  | ShowUserFeedbackForm
  | ShowNewProjectForm
  | SetAppTheme
  | SetBuiltInTheme
  | CloseUserFeedbackForm

export class APICallArgs {
  public label: string
  public configuration: {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE'
    url: string
    data?: any
    isFileUpload?: boolean
    isFileDownload?: boolean
    additionalHeaders?: Record<string, any>
    onSuccess?: (data: any) => void
    onError?:
      | ((message: string) => void)
      | ((message: string, statusCode: number) => void)
    onProgress?: (progress: AxiosProgressEvent) => void
    responseType?: 'text' | 'blob'
    uploadParameterName?: string
  }

  public constructor(configuration: APICallArgs) {
    return configuration
  }
}

export type SignalRSubscribleArgs = {
  onRejected: (reason: any) => void | PromiseLike<void>
  clientMethodName: string
  context: 'project'
  groupName: string
  action: (...args: any) => void
}

export type SignalRUnsubscribleArgs = {
  groupName: string
}
