import i18next from 'i18next'
import { QuantityDto } from '../services/APIs/InternalAPI/internal-api.contracts'

export function QuantityParser(
  str: string,
  selectableAbbreviations?: string[]
): QuantityDto {
  const parsed = str.match(/(-?\d*\.?\,?\d+)\s?([a-z%]+)/)
  return {
    value: parseFloat(parsed[1]),
    unit:
      parsed[2] ??
      (selectableAbbreviations && selectableAbbreviations[0]) ??
      '',
    selectableAbbreviations: selectableAbbreviations || ['m', 'cm', 'mm'],
  }
}

export function QuantityIsValid(quantity: QuantityDto) {
  if (!quantity) return false

  return quantity.unit && quantity.value >= 0
}

export const formatFn = (value: number, padStart = 2) =>
  value.toString().padStart(padStart, '0')

export type QuantityStringOptions = {
  showValue1?: boolean
  showUnit?: boolean
  maxDecimalFractions?: number
  minDecimalFractions?: number
  showPlusSign?: boolean
}

export function QuantityString(
  quantity: QuantityDto,
  options: QuantityStringOptions = {
    showValue1: true,
    showUnit: true,
    minDecimalFractions: 0,
    maxDecimalFractions: 2,
    showPlusSign: false,
  }
) {
  if (!quantity || isNaN(quantity.value)) return ''

  const value = new Intl.NumberFormat(i18next.language, {
    maximumFractionDigits: options?.maxDecimalFractions ?? 2,
    minimumFractionDigits: options?.minDecimalFractions
      ? options?.minDecimalFractions
      : (quantity.value * 100) % 10 === 0
      ? 0
      : 2,
  }).format(quantity.value)

  if (options.showUnit === false) return value

  let localizedUnitTKey = ''

  if (quantity.unit) {
    localizedUnitTKey = `units.${quantity.unit}`

    if (quantity.value <= 1 && quantity.value >= 0) {
      localizedUnitTKey = `common:units-${quantity.unit}_one`
    } else {
      localizedUnitTKey = `common:units-${quantity.unit}_other`
    }
  }

  const localizedUnitString = localizedUnitTKey
    ? i18next.t(localizedUnitTKey, { defaultValue: quantity.unit })
    : ''

  if (!options.showValue1 && quantity.value === 1) return localizedUnitString

  return `${
    options.showPlusSign && quantity.value > 0 ? '+' : ''
  }${value} ${localizedUnitString}`?.trim()
}

export function RoundedQuantityString(quantity: QuantityDto) {
  if (!quantity) return ''

  const roundedQuantity = { ...quantity }
  roundedQuantity.value = parseInt(
    parseFloat(quantity.value?.toString() || '0')?.toFixed(0)
  )
  return QuantityString(roundedQuantity)
  // return `${numeral(quantity.value).format('0,0')} ${quantity.unit}`
}
