import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { makeStyles } from 'tss-react/mui'
import { FactoryProfile } from '../store/WorkingStepsProfiles'

type Props = {
  factoryProfile: FactoryProfile
  onAdd: (factoryProfile: FactoryProfile) => void
  isActive: (factoryProfile: FactoryProfile) => boolean
  onRemove: (factoryProfile: FactoryProfile) => void
}

const useStyles = makeStyles({ name: 'WorkingStepProfile' })((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },

    cursor: 'pointer',
  },
  active: {
    outline: `2px solid ${theme.palette.primary.main}`,
  },
  workingStepList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  workingStepListContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '90px',
    flex: '1 1 0%',
    justifyContent: 'center',
  },
  description: {
    flexGrow: 1,
    marginBottom: 'auto',
    textAlign: 'left',
  },
}))

export const WorkingStepProfile = (props: Props) => {
  const { classes, cx } = useStyles()

  const isActive = props.isActive(props.factoryProfile)

  return (
    <div
      className={cx(classes.root, {
        [classes.active]: isActive,
      })}
      onClick={() => {
        if (isActive) {
          props.onRemove(props.factoryProfile)
        } else {
          props.onAdd(props.factoryProfile)
        }
      }}
    >
      <div className={classes.workingStepListContainer}>
        <ul className={classes.workingStepList}>
          {props.factoryProfile.workingSteps.map((workingStep) => (
            <li key={workingStep}>
              <WorkingStepIconSvg
                workingStepType={workingStep}
                attributes={{
                  width: '32px',
                }}
              />
            </li>
          ))}
        </ul>
      </div>

      <div className={classes.description}>
        <LocalizedTypography
          translationKey={`getting-started:factory-profile-title-${props.factoryProfile.title}`}
          variant="body1"
          fontWeight={'bold'}
          component="p"
        >
          {props.factoryProfile.title}
        </LocalizedTypography>
        <LocalizedTypography
          translationKey={`getting-started:factory-profile-summary-${props.factoryProfile.title}`}
          variant="caption"
        >
          {props.factoryProfile.summary}
        </LocalizedTypography>
      </div>
    </div>
  )
}
