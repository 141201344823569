import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectOpenedEventData } from 'features/AppEventTrack/ProjectOpenedEventData'
import { AppEventsController } from 'features/AppEventTrack/appEventsController'
import { ShowException } from 'store/Application/appActions'

export const projectOpenedEvent = createAsyncThunk<
  void,
  ProjectOpenedEventData['eventData']
>('project/projectOpenedEvent', async (args) => {
  try {
    const controller = new AppEventsController()

    await controller.PostEvent({
      eventType: 'ProjectOpened',
      eventData: args,
    })
  } catch (err) {
    ShowException('project', err)
  }
})
