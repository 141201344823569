import { Typography } from '@mui/material'
import { SaveButton } from 'components/Common/Button/SaveButton'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { useAppController } from 'customHooks/useAppController'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { AppModule } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { GettingStartedBody } from '../Components/GettingStartedBody'
import { GettingStartedCard } from '../Components/GettingStartedCard'
import { GettingStartedPreviousAndNextButtons } from '../Components/GettingStartedPreviousAndNextButtons'
import { GettingStartedUserHeader } from '../Components/GettingStartedUserHeader'
import { GettingStartedStep } from '../Components/GettingStatedStep'
import { gettingStartedRoutes } from '../GettingStartedRoutes'
import { GettingStartedController } from '../Services/GettingStartedController'
import { gettingStartedSelectors } from '../store/GettingStartedSelectors'

const useStyles = makeStyles({ name: 'ActivityWorkingStep' })((theme) => ({
  workingStep: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    width: theme.spacing(20),
    height: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    textAlign: 'center',
  },
  workingStepList: {
    display: 'grid',
    padding: theme.spacing(0),
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: theme.spacing(2),

    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    // display: 'flex',
    // gap: theme.spacing(2),
    // flexWrap: 'wrap',
    // justifyContent: 'center',
    // padding: theme.spacing(2, 2, 0),
  },
}))

export function ReviewAndConfirmStep() {
  const { classes } = useStyles()
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()
  const navigate = useNavigate()

  const userProfile = useAppSelector((state) => state.user.profile)
  const factoryForm = useAppSelector(
    gettingStartedSelectors.getFactoryFormSelector
  )
  const allowAutoJoin = useAppSelector(gettingStartedSelectors.getAllowAutoJoin)

  const { controller, loading, error } = useAppController(() => {
    return new GettingStartedController()
  })

  const handleCreateWorkspace = async () => {
    try {
      await controller.createWorkspace({
        name: factoryForm?.factoryName,
        team: factoryForm?.team,
        enableAutoDomainJoin: factoryForm?.enableAutoDomainJoin,
        currencyCode: factoryForm?.currency.code,
        workingStepTypes: factoryForm?.availableWorkingSteps,
        chosenTemplates: factoryForm?.chosenTemplates,
        modules: [AppModule.Enterprise],
      })

      navigate(gettingStartedRoutes.nextStep.path, {
        state: {
          from: 'next',
        },
      })
    } catch (err) {
      console.error('create workspace', err)
    }
  }

  return (
    <GettingStartedStep>
      <GettingStartedCard>
        <GettingStartedUserHeader />
        <GettingStartedBody>
          <LocalizedTypography
            translationKey="getting-started:review-review-title"
            variant="h5"
            style={{ textAlign: 'center' }}
          >
            Review your information
          </LocalizedTypography>

          <LocalizedTypography translationKey="getting-started:review-review-info">
            Please review the information you have provided. If everything is
            correct, click the confirm button to complete the onboarding
            process.
          </LocalizedTypography>

          <span>
            <LocalizedTypography
              translationKey="getting-started:name"
              fontWeight={'bold'}
              variant="caption"
            >
              name:
            </LocalizedTypography>
            <Typography>
              {`${userProfile?.firstName || ''} ${userProfile?.lastName}`}
            </Typography>
          </span>

          <span>
            <LocalizedTypography
              translationKey="getting-started:email"
              fontWeight={'bold'}
              variant="caption"
            >
              Email:
            </LocalizedTypography>
            <Typography>{userProfile?.email}</Typography>
          </span>
          <span>
            <LocalizedTypography
              translationKey="getting-started:factory-info-factory-name"
              fontWeight={'bold'}
              variant="caption"
            >
              factory name:
            </LocalizedTypography>
            <Typography>{factoryForm?.factoryName}</Typography>
          </span>
          <span>
            <LocalizedTypography
              translationKey="getting-started:currency"
              fontWeight={'bold'}
              variant="caption"
              component="p"
            >
              currency:
            </LocalizedTypography>

            <LocalizedTypography
              translationKey={`getting-started:factory-currency-${factoryForm?.currency?.code}`}
              prefix={factoryForm?.currency?.symbol + ' - '}
            ></LocalizedTypography>
          </span>
          {allowAutoJoin && (
            <>
              <span>
                <LocalizedTypography
                  translationKey="getting-started:enable-auto-domain"
                  fontWeight={'bold'}
                  variant="caption"
                >
                  enable auto domain join:
                </LocalizedTypography>
                <Typography>
                  {factoryForm?.enableAutoDomainJoin
                    ? '@' + userProfile?.email.split('@')[1]
                    : t('getting-started:no')}
                </Typography>
              </span>

              <span>
                <LocalizedTypography
                  translationKey="getting-started:factory-info-team"
                  fontWeight={'bold'}
                  variant="caption"
                >
                  team:
                </LocalizedTypography>
                <Typography>
                  {t(
                    `getting-started:team-option-${factoryForm?.team}`,
                    factoryForm?.team
                  ).toString()}
                </Typography>
              </span>
            </>
          )}

          <span>
            <LocalizedTypography
              translationKey="getting-started:factory-available-workingsteps"
              fontWeight={'bold'}
              variant="caption"
            >
              available working steps:
            </LocalizedTypography>
            <ul className={classes.workingStepList}>
              {factoryForm?.availableWorkingSteps.map((workingStep) => (
                <li key={workingStep}>
                  <div className={classes.workingStep}>
                    <div className={classes.title}>
                      <Typography
                        variant="body2"
                        color={(t) => t.palette.info.main}
                      >
                        {getLocalizedWorkingStep(workingStep)}
                      </Typography>
                    </div>
                    <div>
                      <WorkingStepIconSvg
                        workingStepType={workingStep}
                        attributes={{
                          width: '56px',
                        }}
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </span>

          <SaveButton
            saving={loading['createWorkspace']}
            translationKey="getting-started:review-confirm-save-button"
            onClick={() => {
              handleCreateWorkspace()
            }}
            variant="contained"
            color="success"
          ></SaveButton>
          {error['createWorkspace'] && (
            <Typography
              variant="body2"
              color="error"
              fontWeight={'bold'}
              style={{ textAlign: 'center' }}
            >
              {error['createWorkspace'].message}
            </Typography>
          )}
          {/* <Button
            onClick={() => {
              handleReturnToSignup()
            }}
            variant="contained"
            color="error"
          >
            cancel
          </Button> */}
        </GettingStartedBody>
        <GettingStartedPreviousAndNextButtons
          handlePrevious={() =>
            navigate(gettingStartedRoutes.workingSteps.path, {
              state: {
                from: 'previous',
              },
            })
          }
          currentStep={4}
        />
      </GettingStartedCard>
    </GettingStartedStep>
  )
}
