import { BaseController } from 'controllers/BaseController'
import {
  CreateWorkspaceRequest,
  CreateWorkspaceResponse,
  GetAvailableWorkspacesResponse,
  JoinWorkspaceRequest,
  JoinWorkspaceResponse,
  ManagementPartyDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { newUserActions } from 'store/User/newUserReducer'
import store from 'store/configureStore'
import { GettingStartedAPI } from './GettingStartedAPI'

export interface IGettingStartedAPI {
  createWorkspace: (
    data: CreateWorkspaceRequest
  ) => Promise<CreateWorkspaceResponse>
  getAvailableWorkspaces: () => Promise<GetAvailableWorkspacesResponse>
  joinWorkspace: (req: JoinWorkspaceRequest) => Promise<JoinWorkspaceResponse>
}

export class GettingStartedController extends BaseController<IGettingStartedAPI> {
  constructor() {
    super((onRequestChange) => {
      return new GettingStartedAPI(onRequestChange)
    })
  }

  public async createWorkspace(
    data: CreateWorkspaceRequest
  ): Promise<ManagementPartyDto> {
    try {
      const createdParty = (await this.api.createWorkspace(data))?.partyDto

      store.dispatch(newUserActions.setOrganizationContext(createdParty))
      store.dispatch(newUserActions.setOrganizations([createdParty]))

      return createdParty
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async GetAvailableWorkspaces() {
    try {
      const workspaces = await this.api.getAvailableWorkspaces()

      return workspaces
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async JoinDomain(partyId: string) {
    try {
      const joinedParty = await this.api.joinWorkspace({
        workspaceId: partyId,
      })

      store.dispatch(
        newUserActions.setOrganizationContext(joinedParty.partyDto)
      )

      return joinedParty.partyDto
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
