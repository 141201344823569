import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { MaterialSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemActivityArticleSelector =
  (bomItemPointer: BomItemPointer, activityId: string) =>
  (state: RootAppState): MaterialSummaryDto | undefined => {
    if (
      !activityId &&
      (bomItemPointer.type === BomItemType.partType ||
        bomItemPointer.type === BomItemType.partInstance)
    ) {
      return null
    }

    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItem?.type) {
      case BomItemType.partInstance:
      case BomItemType.partType: {
        const activity = bomItem.activities.find((x) => x.id === activityId)

        if (activity.usesRawMaterial) {
          return bomItem.rawMaterialSummary
        } else {
          return {
            ...activity.article,
            tokens: activity.keywords,
          }
        }
      }
      case BomItemType.materialHeader:
        return {
          description: bomItem.materialDescription,
          id: bomItem.materialId,
          tokens: bomItem.materialKeywords,
        }
    }

    return undefined
  }
