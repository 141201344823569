import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { RowDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemRecognizedGeometryTypesSelector = (
  bomItemPointer: BomItemPointer
) => {
  return (state: RootAppState) => {
    if (!bomItemPointer) return null

    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItemPointer.type) {
      case BomItemType.partType:
      case BomItemType.partInstance: {
        const recognizedGeometryTypes = (bomItem as RowDto)
          .recognizedGeometryTypes

        return recognizedGeometryTypes
      }
      default:
        return []
    }
  }
}
