import { ModelEnvironment } from './ModelEnvironment'
import { SceneDataStore } from './store/SceneDataStore'
import { Rh24SceneNode } from './store/types/Rh24SceneNode'
import { ModelDataExtractorVisitor } from './visitors/ModelDataExtractorVisitor'

export type ModelData = {
  children?: ModelData[]
} & Rh24SceneNode

function sortByNodeType(a: ModelData, b: ModelData): number {
  if (a.nodeType === 'ASSEMBLY' && b.nodeType !== 'ASSEMBLY') {
    return -1
  }

  if (b.nodeType === 'ASSEMBLY' && a.nodeType !== 'ASSEMBLY') {
    return 1
  }

  if (a.nodeType === 'SUBASSEMBLY' && b.nodeType !== 'SUBASSEMBLY') {
    return -1
  }

  if (b.nodeType === 'SUBASSEMBLY' && a.nodeType !== 'SUBASSEMBLY') {
    return 1
  }

  if (a.nodeType === 'FACE' && b.nodeType !== 'FACE') {
    return 1
  }

  if (a.nodeType !== 'FACE' && b.nodeType === 'FACE') {
    return -1
  }

  if (a.nodeType === 'INSTANCE' && b.nodeType !== 'INSTANCE') {
    return 1
  }

  if (b.nodeType === 'INSTANCE' && a.nodeType !== 'INSTANCE') {
    return -1
  }

  return 0
}

export class TreeDataManager {
  store: SceneDataStore
  data: ModelData[]
  environment: ModelEnvironment

  constructor(environment: ModelEnvironment, store: SceneDataStore) {
    this.store = store
    this.environment = environment
  }

  private findNode = (node: ModelData[], id: string): ModelData => {
    if (!node) return undefined

    let i = 0
    let nodeFound = null

    while (i < node?.length) {
      if (node[i].elementId === id) {
        nodeFound = node[i]
        break
      }

      nodeFound = this.findNode(node[i].children, id)

      if (nodeFound) {
        break
      }

      i++
    }

    return nodeFound
  }

  private ignoreItemsInTreeView(data: ModelData) {
    if (data.nodeType === 'INSTANCE') return true
    if (data.nodeType === 'ASSEMBLY' && data.name === 'Rh24RootBlock')
      return true

    return false
  }

  private transformToTree(data: ModelData[]): ModelData[] {
    data.sort(sortByNodeType)

    const tree: ModelData[] = []

    data.forEach((modelData) => {
      if (this.ignoreItemsInTreeView(modelData)) return

      if (modelData.parentId) {
        const node = this.findNode(tree, modelData.parentId)
        if (node) {
          if (node.children) {
            if (
              !node.children.find((x) => x.elementId === modelData.elementId)
            ) {
              node.children.push(modelData)
            }
          } else {
            node.children = [modelData]
          }
        } else {
          tree.push(modelData)
          console.error('expecting a parent node', modelData.parentId, tree)
        }
      } else {
        tree.push(modelData)
      }
    })

    return tree
  }

  public async ExtractDetailedData() {
    const visitor = new ModelDataExtractorVisitor(this.store)
    await this.environment.accept(visitor)

    return this.GetDetailedData()
  }

  public async GetDetailedData() {
    const asArray = Array.from(this.store.getAllSceneNodes()).flatMap((x) => x)

    const detailedData = this.transformToTree(asArray)

    return detailedData
  }
}
