import { WorkingStepTypeLabel } from 'model/WorkingStepType'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'

export function useLocalizedWorkingStepLabel() {
  const { t } = useTranslation()

  const getLocalizedWorkingStep = useCallback(
    (workingStepType: WorkingStepType): string => {
      if (!workingStepType) return ''

      return t(
        WorkingStepType[workingStepType],
        WorkingStepTypeLabel.get(workingStepType)
      )
    },
    [t]
  )

  return getLocalizedWorkingStep
}
