import React, { useLayoutEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({ name: 'GettingStartedStep' })(() => ({
  root: {
    width: '100%',
    maxHeight: '100%',
    display: 'grid',
    alignItems: 'center',
    // justifyContent: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const GettingStartedStep = (props: React.PropsWithChildren) => {
  const { classes } = useStyles()

  useLayoutEffect(() => {
    document.getElementById('getting-started-content')?.scrollTo(0, 0)
  }, [])
  return (
    <div className={classes.root}>
      <div className={classes.container}>{props.children}</div>
    </div>
  )
}
