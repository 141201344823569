import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { BoMItemsFilter, ProjectState } from 'store/Project/ProjectTypes'
import { updateHeadersFilteredParts } from '../filters/updateHeadersFilteredParts'

export const setFilterProperty: CaseReducer<
  ProjectState,
  PayloadAction<{ filter: BoMItemsFilter }>
> = (state, action) => {
  switch (action.payload.filter.type) {
    case 'IssuesFilter':
      state.filters.byIssues.active = true
      state.filters.byIssues.filter.enabledFilters =
        action.payload.filter.enabledFilters
      break
    case 'TodosFilter':
      state.filters.byTodos.active = true
      state.filters.byTodos.filter.enabledFilters =
        action.payload.filter.enabledFilters
      break
    case 'WorkingStepsFilter':
      state.filters.byWorkingStep.active = true
      state.filters.byWorkingStep.filter.enabledFilters =
        action.payload.filter.enabledFilters
      break
    case 'MaterialKeywordsFilter':
      state.filters.byMaterialKeywords.active = true
      state.filters.byMaterialKeywords.filter.enabledFilters =
        action.payload.filter.enabledFilters
      state.filters.byMaterialKeywords.filter.showIfAll =
        action.payload.filter.showIfAll

      localStorage.setItem(
        'material-filter:showIfAll',
        action.payload.filter.showIfAll?.toString()
      )

      break
    case 'PartTypeIdFilter':
      state.filters.byPartTypeId.active =
        !!action.payload.filter.enabledFilters?.length
      state.filters.byPartTypeId.filter.enabledFilters =
        action.payload.filter.enabledFilters
      break
    case 'ArticleIdFilter':
      state.filters.byArticleId.active = true
      state.filters.byArticleId.filter.enabledFilters =
        action.payload.filter.enabledFilters
      break
    case 'ResourceIdFilter':
      state.filters.byResourceId.active = true
      state.filters.byResourceId.filter.enabledFilters =
        action.payload.filter.enabledFilters
      break
    case 'ManufacturabilityCheckFilter':
      state.filters.byManufacturability.active = true
      state.filters.byManufacturability.filter.manufacturabilityStatus =
        action.payload.filter.manufacturabilityStatus
      break
    case 'ErpErrorFilter':
      state.filters.byErpError.active = true
      break
    default:
      throw `filter ${JSON.stringify(action.payload.filter)} not defined`
  }

  return updateHeadersFilteredParts(state)
}
