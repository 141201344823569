import { AssemblyHeaderRow } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

/**
 * gets the selected BOM items from the Redux state
 * @param state The Redux state
 * @returns The selected BOM items data
 */
export const selectedBomItemsSelector = (state: RootAppState) => {
  return state.project.selectedBomItemPointers.map((bomItemPointer) =>
    bomItemSelector(state, bomItemPointer)
  )
}

/**
 * gets the selected BOM item pointers from the Redux state
 * @param state
 * @returns
 */
export const selectedBomItemsPointersSelector = (state: RootAppState) => {
  return state.project.selectedBomItemPointers
}

export const allFilteredBomItemsSelector = (state: RootAppState) => {
  return state.project.selectedHeaderPointers?.reduce((acc, id) => {
    const assembly = bomItemSelector(state, id) as AssemblyHeaderRow
    if (assembly) {
      return [
        ...acc,
        ...assembly.filteredPartTypePointers.map((x) =>
          bomItemSelector(state, x)
        ),
      ]
    }
    return acc
  }, [])
}
