import { AppState } from '../'
import { action } from 'typesafe-actions'
import { ApplicationActionTypes, APICallArgs } from '../Application/appState'
import { SettingsConstants } from './SettingsConstants'
import { SuccessMessage, ErrorMessage } from '../Application/appActions'
import { PurchasingDataDto } from '../../services/APIs/InternalAPI/internal-api.contracts'
import { MaterialSummaryDto } from '../../services/APIs/InternalAPI/internal-api.contracts'

const SettingsActions = {
  setMaterialList: (materialSettings: MaterialSummaryDto[]) => ({
    type: SettingsConstants.MATERIAL_SETTINGS_LIST_RECEIVED,
    materialsSettings: materialSettings,
  }),
  setMaterialData: (materialData: MaterialSummaryDto) => ({
    type: SettingsConstants.MATERIAL_DATA_RECEIVED,
    materialData,
  }),
  getMaterialsSettings: () => async (dispatch, getState: () => AppState) => {
    await dispatch(
      action(
        ApplicationActionTypes.API,
        new APICallArgs({
          label: 'getMaterialsSettings',
          configuration: {
            method: 'GET',
            url: `api/parties/${
              getState().user.organizationContext.id
            }/settings/purchasing-prices`,
            onSuccess: (data) => {
              dispatch(SettingsActions.setMaterialList(data))
            },
          },
        })
      )
    )
  },
  closeMaterialsSettings: () => ({
    type: SettingsConstants.MATERIAL_SETTINGS_CLEAR_LIST,
  }),
  addPurchasingData:
    (material: MaterialSummaryDto) =>
    async (dispatch, getState: () => AppState) => {
      const materialId = material.id

      await dispatch(
        action(
          ApplicationActionTypes.API,
          new APICallArgs({
            label: 'addMaterial',
            configuration: {
              method: 'PUT',
              url: `api/parties/${
                getState().user.organizationContext.id
              }/settings/purchasing-prices`,
              data: { materialId: materialId },
              onSuccess: (data) => {
                SuccessMessage(
                  'Settings',
                  `Material ${material.description} added to purchasing price configuration`
                )

                dispatch({
                  type: SettingsConstants.MATERIAL_SETTINGS_ITEM_RECEIVED,
                  materialSetting: data,
                })
              },
            },
          })
        )
      )
    },
  deletePurchasingData:
    (materialId: string) => async (dispatch, getState: () => AppState) => {
      await dispatch(
        action(
          ApplicationActionTypes.API,
          new APICallArgs({
            label: 'delete purchasing data',
            configuration: {
              method: 'DELETE',
              url: `api/parties/${
                getState().user.organizationContext.id
              }/settings/purchasing-prices/${materialId}`,
              onSuccess: (data) => {
                // SuccessMessage('Settings', `Purchasing data of material ${materialId} was deleted`)
                // WarningMessage(
                //   'Settings',
                //   `Purchasing data of material ${materialId} was deleted`
                // )

                dispatch({
                  type: SettingsConstants.MATERIAL_SETTINGS_LIST_RECEIVED,
                  materialsSettings: data,
                })
              },
            },
          })
        )
      )
    },
  editPurchasingData: (
    materialId: string,
    purchasingData: PurchasingDataDto
  ) => ({
    type: SettingsConstants.MATERIAL_SETTINGS_ITEM_CHANGED,
    materialId,
    purchasingData,
  }),

  saveChanges:
    (materialId: string) => async (dispatch, getState: () => AppState) => {
      await dispatch(
        action(
          ApplicationActionTypes.API,
          new APICallArgs({
            label: 'edit purchasing data',
            configuration: {
              method: 'PUT',
              url: `api/parties/${
                getState().user.organizationContext.id
              }/settings/purchasing-prices`,
              data: {
                materialId: materialId,
                purchasingData: getState().settings.MaterialSettingsList.find(
                  (x) => x.model.id === materialId
                ).purchasingData,
              },
              onSuccess: (data) => {
                SuccessMessage('Settings', `Material updated`, {
                  showCloseButton: false,
                })

                dispatch({
                  type: SettingsConstants.MATERIAL_SETTINGS_ITEM_RECEIVED,
                  materialSetting: data,
                })
              },
              onError: (error) => {
                ErrorMessage('settings', `material was not updated: ${error}`)
                dispatch(SettingsActions.getMaterialsSettings())
              },
            },
          })
        )
      )
    },
}

export { SettingsActions }
