import { CaseReducer } from '@reduxjs/toolkit'
import { FinancialDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectState } from 'store/Project/ProjectTypes'

/**
 * removes the prices from all BOM items financials.
 * this is used every time the user changes any quantity, material or working step (price factors)
 * so the prices are show again when the server calculates them
 * @param state
 * @returns
 */
export const removePricesFromAllBoMItems: CaseReducer<ProjectState> = (
  state
) => {
  const clearPrice = (financial: FinancialDto) => {
    return {
      ...financial,
      salesPriceOfItems: undefined,
      salesPricePerItem: undefined,
      totalProjectQuantity: undefined,
    }
  }
  state.activeProject.financial = clearPrice(state.activeProject.financial)

  state.assemblyHeadersIds.forEach((headerId) => {
    const header = state.assemblyHeaders[headerId]
    header.financial = clearPrice(header.financial)
  })
  state.partTypeIds.forEach((partTypeId) => {
    const partType = state.partTypes[partTypeId]
    partType.financial = clearPrice(partType.financial)
  })

  state.partInstanceIds.forEach((partInstanceId) => {
    const partInstance = state.partInstances[partInstanceId]
    partInstance.financial = clearPrice(partInstance.financial)
  })

  state.assemblyInstancesIds.forEach((assemblyInstanceId) => {
    const assemblyInstance = state.assemblyInstances[assemblyInstanceId]
    assemblyInstance.financial = clearPrice(assemblyInstance.financial)
  })

  return state
}
