/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { FileWithDetails } from 'model/FileWithDetails'
import { ShowException } from 'store/Application/appActions'
import { newProjectActions } from '../projectReducer'
import { projectAPIBuilder } from './BoMAsyncActions'
import { RootAppState } from 'store/configureStore'

export const uploadGeometry = createAsyncThunk<
  void,
  FileWithDetails[],
  { state: RootAppState }
>(
  'project/upload-files-to-project',
  async (files: FileWithDetails[], thunkAPI) => {
    const { projectId, api } = projectAPIBuilder(thunkAPI.getState)

    try {
      const fileDataWithFixedTokens = files.map((file) => {
        return {
          ...file,
          tokens: Object.values(file.tokens || {})
            .flat()
            .map((x) => x.originalKeyword?.trim()),
        }
      })

      const result = await api.UploadGeometry(
        projectId,
        fileDataWithFixedTokens as never,
        (progress) =>
          thunkAPI.dispatch(
            newProjectActions.uploadProgress({
              operation: uploadGeometry.typePrefix,
              progress,
            })
          )
      )

      return result
    } catch (err) {
      ShowException('upload files', err)
      throw err
    }
  }
)
