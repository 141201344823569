import { Button, Typography } from '@mui/material'
import BillOfMaterialsIcon from '_icons/bill-of-materials.svg?react'
import MyProjectsIcon from '_icons/ui/kanban.svg?react'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ShowNewProjectForm } from 'store/Application/appActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { GettingStartedBody } from '../Components/GettingStartedBody'
import { GettingStartedCard } from '../Components/GettingStartedCard'
import { GettingStartedPreviousAndNextButtons } from '../Components/GettingStartedPreviousAndNextButtons'
import { GettingStartedUserHeader } from '../Components/GettingStartedUserHeader'
import { GettingStartedStep } from '../Components/GettingStatedStep'
import { navigateToNextStep } from '../store/GettingStartedActions'
import { gettingStartedSelectors } from '../store/GettingStartedSelectors'

const useStyles = makeStyles({ name: 'NextStepsStep' })((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: theme.spacing(2, 2, 0),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },
    '& svg': {
      width: '36px',
      height: '36px',
    },
    '& button': {
      marginTop: 'auto',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  container: {
    display: 'flex',
    margin: '0 auto',
    gap: theme.spacing(4),
  },
}))

export function NextStepsStep() {
  const navigate = useNavigate()
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handleCreateProject = () => {
    dispatch(ShowNewProjectForm(true, true))
  }

  const handleManageTeamMembers = () => {
    navigate('/app/settings/general/user-management', {
      replace: true,
    })
  }

  const goToMyProjects = () => {
    navigate('/app')
  }

  const organizationContext = useAppSelector(
    (state) => state.user.organizationContext
  )

  const confirmIsDone = useAppSelector(
    gettingStartedSelectors.stepIsDoneSelector('confirm')
  )
  const joinWorkspaceIsDone = useAppSelector(
    gettingStartedSelectors.stepIsDoneSelector('joinWorkspace')
  )

  useEffect(() => {
    if (!confirmIsDone || !joinWorkspaceIsDone) {
      dispatch(navigateToNextStep(navigate))
    }
  }, [confirmIsDone, dispatch, joinWorkspaceIsDone, navigate])

  return (
    <GettingStartedStep>
      <GettingStartedCard>
        <GettingStartedUserHeader />
        <GettingStartedBody>
          <Typography variant="h6" textAlign="center">
            <Trans
              i18nKey={'getting-started:next-steps-welcome-text'}
              components={{ b: <b /> }}
              values={{
                partyName: organizationContext?.name,
              }}
            >
              {'Welcome to your new virtual factory: <b>{{partyName}}</b>'}
            </Trans>
          </Typography>

          <div className={classes.root}>
            <div className={classes.container}>
              <div className={classes.button} onClick={handleCreateProject}>
                <BillOfMaterialsIcon />
                <Button
                  color="primary"
                  fullWidth
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                >
                  {t(
                    'getting-started:create-new-project',
                    'create a new project'
                  )}
                </Button>
              </div>
              <div className={classes.button} onClick={goToMyProjects}>
                <MyProjectsIcon />
                <Button
                  color="primary"
                  fullWidth
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                >
                  {t('getting-started:projects-overview', 'projects overview')}
                </Button>
              </div>
              {/* <div className={classes.button} onClick={handleManageTeamMembers}>
                <ManageAccountsOutlined />
                <Button
                  fullWidth
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                >
                  {t(
                    'getting-started:manage-team-members',
                    'invite team members'
                  )}
                </Button>
              </div> */}
            </div>
          </div>
        </GettingStartedBody>
        <GettingStartedPreviousAndNextButtons currentStep={5} />
      </GettingStartedCard>
    </GettingStartedStep>
  )
}
