import { createAsyncThunk } from '@reduxjs/toolkit'
import { noop } from 'lodash'
import { RootAppState } from 'store/configureStore'
import { newProjectActions } from '../projectReducer'
import { subscribeToUpdates } from './liveUpdatesActions'

export const subscribeToProjectOperationsLogs = createAsyncThunk<
  void,
  string,
  { state: RootAppState }
>('project-operations/subscribeToUpdates', async (groupName, thunkAPI) => {
  thunkAPI.dispatch(
    subscribeToUpdates({
      groupName,
      clientMethodName: 'onProjectOperationProgress',
      clientMethod: () => {
        thunkAPI.dispatch(
          newProjectActions.updateOperationsFlags({
            shallUpdateOperationsSummary: true,
            shallUpdateOperationsLogs: true,
          })
        )
      },
      onLeaveGroupCallback: (groupName) => {
        noop(groupName)
      },
    })
  )
})
