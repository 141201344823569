import { createAsyncThunk } from '@reduxjs/toolkit'
import { GeometryController } from 'controllers/Party/Relations/GeometryController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { ShowException } from 'store/Application/appActions'
import { newProjectActions } from '../projectReducer'
import { fetchProject } from './fetchProject'
import { RootAppState } from 'store/configureStore'

export const rememberAsPurchasingPart = createAsyncThunk<
  void,
  { bomItemPointer: BomItemPointer; articleId: string },
  { state: RootAppState }
>(
  'project/rememberAsPurchasingPart',
  async ({ bomItemPointer, articleId }, thunkAPI) => {
    const projectId = thunkAPI.getState().project.activeProject?.id

    const controller = new GeometryController()

    try {
      thunkAPI.dispatch(
        newProjectActions.setBomItemProperties({
          bomItemPointer,
          properties: {
            isRememberedPurchasingBoMItem: true,
          },
        })
      )

      return await controller.RememberOrForgetGeometryAsPurchasingItem(
        projectId,
        bomItemPointer,
        true,
        articleId
      )
    } catch (ex) {
      ShowException('project', ex)
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      return thunkAPI.rejectWithValue(ex)
    }
  }
)

export const forgetAsPurchasingPart = createAsyncThunk<
  void,
  { bomItemPointer: BomItemPointer },
  { state: RootAppState }
>(
  'project/rememberAsPurchasingPart',
  async ({ bomItemPointer }: { bomItemPointer: BomItemPointer }, thunkAPI) => {
    const projectId = thunkAPI.getState().project.activeProject?.id

    const controller = new GeometryController()

    try {
      await controller.RememberOrForgetGeometryAsPurchasingItem(
        projectId,
        bomItemPointer,
        false
      )

      thunkAPI.dispatch(
        newProjectActions.setBomItemProperties({
          bomItemPointer,
          properties: {
            isRememberedPurchasingBoMItem: false,
          },
        })
      )

      return Promise.resolve()
    } catch (ex) {
      ShowException('project', ex)
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      return thunkAPI.rejectWithValue(ex)
    }
  }
)
