import { RootAppState } from 'store/configureStore'
import { ProjectViews } from 'store/UI/UITypes'

export const projectCurrentViewSelector = (state: RootAppState) => {
  const fromLocalStorage = localStorage.getItem('projectView') as string

  const isBuyer = state.project.activeProject?.isBuyingPartyView

  if (isBuyer) {
    if (
      fromLocalStorage &&
      fromLocalStorage !== ProjectViews.DashboardView.toString()
    ) {
      return ProjectViews[fromLocalStorage]
    }

    if (state.project.currentView === ProjectViews.DashboardView) {
      return ProjectViews.BoMBoLView
    }

    return state.project.currentView
  } else {
    return fromLocalStorage || state.project.currentView
  }
}
