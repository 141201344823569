export const useLocalizationKey = (
  key: string,
  defaultNamespace = 'common'
) => {
  const fixedKey =
    key.indexOf(':') > 0 ? key : `${defaultNamespace || 'common'}:${key}`

  const [namespace, translationKey] = fixedKey.split(':')

  return {
    namespace,
    tKey: translationKey,
  }
}
