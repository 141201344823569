import { createAsyncThunk } from '@reduxjs/toolkit'
import i18n from 'i18next'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemAttachmentsController } from 'services/APIs/InternalAPI/AttachmentAPI'
import { ShowException } from 'store/Application/appActions'
import { UIActions } from 'store/UI/UIActions'
import { RootAppState } from 'store/configureStore'
import { GetContext } from 'store/storeUtils'
import { newProjectActions } from '../projectReducer'
import { bomItemSelector } from '../selectors/bomItemSelector'
import { fetchProject } from './fetchProject'

export const deleteBomItemAttachment = createAsyncThunk<
  Promise<void>,
  { bomItemPointer: BomItemPointer; fileName: string[] },
  { state: RootAppState }
>('bomItem/deleteFileName', async ({ bomItemPointer, fileName }, thunkAPI) => {
  const { partyId, projectId } = GetContext()
  const api = new BomItemAttachmentsController(partyId, projectId)

  thunkAPI.dispatch(
    UIActions.OpenModal('ConfirmationDialog', {
      title: i18n.t('project:delete-attachment', 'Delete Attachment'),
      onConfirm: async () => {
        try {
          if (!bomItemPointer) return null

          if (bomItemPointer.id === projectId) {
            return await updateProjectAttachments(
              thunkAPI,
              fileName,
              api,
              projectId
            )
          }

          return await updateBomItemAttachments(
            thunkAPI,
            bomItemPointer,
            fileName,
            api
          )
        } catch (err) {
          ShowException('project', err)
          thunkAPI.dispatch(fetchProject({ projectId: projectId }))
          return thunkAPI.rejectWithValue(err)
        }
      },
    })
  )
})

async function updateBomItemAttachments(
  thunkAPI,
  bomItemPointer: BomItemPointer,
  fileName: string[],
  api: BomItemAttachmentsController
) {
  const bomItem = bomItemSelector(thunkAPI.getState(), bomItemPointer)
  if (
    bomItem?.type === BomItemType.materialHeader ||
    bomItem?.type === BomItemType.routingHeader
  ) {
    throw new Error(
      'Cannot delete attachment from Material Header or Routing Header'
    )
  }

  let newAttachments =
    bomItem?.type === BomItemType.assemblyType
      ? bomItem.attachments
      : bomItem.attachments

  newAttachments = newAttachments?.filter((x) => !fileName.includes(x.fileName))

  thunkAPI.dispatch(
    newProjectActions.setBomItemProperties({
      bomItemPointer,
      properties: {
        attachments: newAttachments,
      },
    })
  )

  return await api.DeleteFile(bomItemPointer.id, fileName)
}

async function updateProjectAttachments(
  thunkAPI,
  fileName: string[],
  api: BomItemAttachmentsController,
  projectId: string
) {
  thunkAPI.dispatch(
    newProjectActions.setActiveProjectProperties({
      attachments: thunkAPI
        .getState()
        .project.activeProject.attachments.filter((x) => {
          return !fileName.includes(x.fileName)
        }),
    })
  )

  return await api.DeleteFile(projectId, fileName)
}
