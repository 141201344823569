import { Check } from '@mui/icons-material'
import { Button, ButtonProps, CircularProgress, useTheme } from '@mui/material'
import React, {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void | Promise<unknown>
  saving?: boolean
  progress?: number
  translationKey?: string
  marginTop?: string
  formId?: string
  disableCtrlS?: boolean
  progressSize?: number
}

export type SaveButtonActions = {
  setDisabled: (disabled: boolean) => void
}

function SaveButtonComponent(
  props: ButtonProps & Props,
  ref: React.Ref<SaveButtonActions>
) {
  const { t } = useTranslation()
  const styles: CSSProperties = {}
  const theme = useTheme()

  const { saving, progress, translationKey, formId, ...buttonProps } = props
  const [disabled, setDisabled] = React.useState(props.disabled)
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  useEffect(() => {
    setDisabled(props.disabled)

    const handleCtrlS = (e: KeyboardEvent) => {
      if (props.disableCtrlS || props.disabled) {
        return
      }

      if (e.ctrlKey && e.key === 's') {
        e.preventDefault()
        e.stopPropagation()

        buttonRef.current?.click()

        setTimeout(() => {
          const selected = document.querySelector(
            'div[aria-selected="true"]'
          ) as HTMLElement

          selected?.focus?.()
        }, 420)
      }
    }

    if (!props.disableCtrlS) {
      document.addEventListener('keydown', handleCtrlS)
    }

    return () => {
      document.removeEventListener('keydown', handleCtrlS)
    }
  }, [props.disableCtrlS, props.disabled])

  useImperativeHandle(ref, () => {
    return {
      setDisabled: (disabled: boolean) => setDisabled(disabled),
    }
  })

  if (props.marginTop) {
    styles.marginTop = props.marginTop
  }

  return (
    <Button
      {...buttonProps}
      ref={buttonRef}
      type={props.type || 'submit'}
      color={props.color || 'primary'}
      variant={props.variant || 'contained'}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !props.disabled) {
          e.preventDefault()
          e.stopPropagation()
          props.onClick?.(e as never)
        }
      }}
      onClick={(e) => {
        props.onClick?.(e)
      }}
      disabled={disabled || saving}
      style={{ ...buttonProps.style, ...styles }}
      form={formId}
      startIcon={
        saving || progress ? (
          <CircularProgress
            variant={progress ? 'determinate' : 'indeterminate'}
            value={progress}
            size={props.progressSize ?? ''}
            style={{ marginRight: '1ch', width: '1em' }}
          />
        ) : (
          props.startIcon ?? (
            <Check
              htmlColor={`${
                props.disabled ? theme.palette.action.disabled : ''
              }`}
            />
          )
        )
      }
    >
      {props.children ?? t(translationKey || 'common:save')}
    </Button>
  )
}

export const SaveButton = forwardRef(SaveButtonComponent)
