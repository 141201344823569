/* eslint-disable @typescript-eslint/no-unused-vars */
import cadex, {
  ModelPrs_SelectedPolyShapeEntity,
  ModelPrs_SelectedPolyVertexEntity,
  ModelPrs_SelectedShapeEntity,
} from '@cadexchanger/web-toolkit'
import { Rh24SceneNode } from '../store/types/Rh24SceneNode'

export class SelectedEntityVisitor extends cadex.ModelPrs_SelectedEntityVisitor {
  node: Rh24SceneNode
  shapeId: number
  shapeName: string
  shapeType: string
  visitPolyShapeEntity(
    polyShapeEntity: ModelPrs_SelectedPolyShapeEntity
  ): void {}
  visitPolyVertexEntity(
    polyVertexEntity: ModelPrs_SelectedPolyVertexEntity
  ): void {}
  visitShapeEntity(shapeEntity: ModelPrs_SelectedShapeEntity): void {
    // console.log('visitShapeEntity', shapeEntity)

    if (this.node.nodeType === 'FACE') {
      this.shapeId = Number.parseInt(this.node.elementId)
      this.shapeName = this.node.name
      this.shapeType = this.node.nodeType

      return
    }

    if (this.node.representation) {
      const brepRep = this.node
        .representation as cadex.ModelData_BRepRepresentation
      const shapeId = brepRep.shapeId(shapeEntity.shape)
      const shapeName = brepRep.shapeName(shapeEntity.shape)
      const shapeType = Object.keys(cadex.ModelData_ShapeType).find(
        (n) => cadex.ModelData_ShapeType[n] === shapeEntity.shape.type
      )

      this.shapeId = shapeId
      this.shapeName = shapeName
      this.shapeType = shapeType
    }
  }
}
