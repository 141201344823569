import { VerticalAlignBottom } from '@mui/icons-material'
import { ClickAwayListener, Paper } from '@mui/material'
import { SaveButton } from 'components/Common/Button/SaveButton'
import { BomItemController } from 'controllers/Project/BomItemController'
import { useAppController } from 'customHooks/useAppController'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import {
  FeatureDetailsByFaceId,
  FeatureDetailsWithoutFaceId,
} from 'features/UpperSideSelection/FeaturesDtoHelpers'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FeatureDto,
  IssueCode,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { useShowException } from 'utils/useShowException'
import { ModelContextMenuHandler } from '../manager/InputHandlers/ModelContextMenuHandler'
import { ModelController } from '../manager/ModelController'

type ModelContextMenuProps = {
  modelViewer: ModelController
  bomItemPointer: BomItemPointer
  onReload: () => void
}

type Position = {
  x: number
  y: number
}

type ShapeData = {
  partName: string
  shapeId: string
  shapeName: string
  shapeType: string
}

export const ModelContextMenu = (props: ModelContextMenuProps) => {
  const { t } = useTranslation()
  const contextMenuManager = useRef<ModelContextMenuHandler | undefined>(
    undefined
  )

  const ShowException = useShowException('project')

  const upperSideNotNeededTodo = useAppSelector(
    projectSelectors.bomItemIssueByCodeSelector(
      props.bomItemPointer,
      IssueCode.UpperSideSelectedNotNeeded
    )
  )

  const { controller } = useAppController(() => {
    return new BomItemController()
  })

  const [saving, setSaving] = useState(false)

  const [position, setPosition] = useState<Position | undefined>(undefined)

  const [shapeData, setShapeData] = useState<ShapeData | undefined>(undefined)

  const [faceFeatures, setFaceFeatures] = useState<FeatureDto[]>([])

  const menuRef = useRef<HTMLDivElement>(null)

  const onSelectionChanged = useCallback(
    (
      name: string,
      positionClicked: { x: number; y: number },
      shapeId: string,
      shapeName: string,
      shapeType: string
    ) => {
      const faceFeatures = props.modelViewer.modelFeatures?.filter(
        (f) =>
          f.details.find((x) => x.faceIds.includes(parseInt(shapeId))) ||
          f.sections.find((x) => x.faceIds.includes(parseInt(shapeId))) ||
          f.sections.find((x) =>
            x.details.find((y) => y.faceIds.includes(parseInt(shapeId)))
          )
      )

      setFaceFeatures(faceFeatures)

      // to fit in the screen
      setPosition(positionClicked)
      setShapeData({ partName: name, shapeId, shapeName, shapeType })
    },
    [props.modelViewer.modelFeatures]
  )

  useEffect(() => {
    const initContextManager = () => {
      if (contextMenuManager.current) {
        props.modelViewer.RemoveInputHandler(contextMenuManager.current)
      }
      contextMenuManager.current = new ModelContextMenuHandler(
        props.modelViewer.getScene(),
        props.modelViewer.sceneStore,
        onSelectionChanged
      )

      props.modelViewer.AddInputHandler(contextMenuManager.current)
    }

    props.modelViewer.onFileLoaded(initContextManager)

    return () => {
      props.modelViewer.RemoveInputHandler(contextMenuManager.current)
    }
  }, [onSelectionChanged, props.modelViewer])

  if (!faceFeatures?.length) return null

  // ensure it fits in the screen
  const newPosition = { x: 0, y: 0 }
  const parentElement = menuRef.current?.parentElement
  if (parentElement?.clientWidth - position?.x < 350) {
    newPosition.x = position.x - 250
  } else {
    newPosition.x = position?.x || 0
  }

  if (parentElement?.clientHeight - position?.y < 200 * faceFeatures?.length) {
    newPosition.y =
      position.y - menuRef?.current?.getBoundingClientRect().height
  } else {
    newPosition.y = position?.y || 0
  }

  const feature = FeatureDetailsByFaceId(
    props.modelViewer.modelFeatures,
    parseInt(shapeData.shapeId)
  )

  const handleRemoveUpperSide = async () => {
    try {
      setSaving(true)

      await props.modelViewer.setFacesColor(
        feature.faceIds.map((x) => x.toString()),
        undefined
      )

      setTimeout(async () => {
        await props.modelViewer.updateScene()
      })

      await controller.SetBomItemUpperSide(props.bomItemPointer.id, {
        sideId: 0,
      })

      setPosition(undefined)

      props.onReload()
    } catch (err) {
      ShowException(err)
      props.modelViewer.reload3DModel()
    } finally {
      setSaving(false)
    }
  }

  const handleSetUpperSide = async () => {
    try {
      setSaving(true)

      const featureWithoutFaceIds = FeatureDetailsWithoutFaceId(
        props.modelViewer.modelFeatures,
        parseInt(shapeData.shapeId)
      )

      const sideId = parseInt(feature.label.replace('side ', ''))

      if (sideId < 0) {
        return
      }

      await props.modelViewer.setFacesColor(
        feature.faceIds.map((x) => x.toString()),
        props.modelViewer.upperSideColor
      )

      // await props.modelViewer.updateScene()

      await props.modelViewer.setFacesColor(
        featureWithoutFaceIds.faceIds.map((x) => x.toString()),
        undefined
      )

      await props.modelViewer.updateScene()

      await controller.SetBomItemUpperSide(props.bomItemPointer.id, {
        sideId,
      })

      props.onReload()

      setPosition(undefined)
    } catch (err) {
      ShowException(err)
      props.modelViewer.reload3DModel()
    } finally {
      setSaving(false)
    }
  }

  if (!feature) {
    return null
  }

  if (!props.modelViewer?.canSelectUpperSide && !upperSideNotNeededTodo) {
    return null
  }

  return (
    <ClickAwayListener onClickAway={() => setPosition(undefined)}>
      <Paper
        sx={{
          position: 'absolute',
          top: newPosition?.y || -1000,
          left: newPosition?.x || -1000,
          zIndex: 10000,
          minWidth: '250px',
          maxHeight: '500px',
          padding: (theme) => theme.spacing(1),
          overflow: 'auto',
        }}
        ref={menuRef}
      >
        {props.modelViewer?.canSelectUpperSide && (
          <div>
            <SaveButton
              saving={saving}
              fullWidth
              variant="outlined"
              onClick={() => handleSetUpperSide()}
              startIcon={<VerticalAlignBottom />}
            >
              {t('project:set-as-upper-side')}
            </SaveButton>
          </div>
        )}
        {upperSideNotNeededTodo && (
          <div>
            <SaveButton
              saving={saving}
              fullWidth
              variant="outlined"
              onClick={() => {
                handleRemoveUpperSide()
              }}
              startIcon={<VerticalAlignBottom />}
            >
              {t('project:remove-upper-side-selection')}
            </SaveButton>
          </div>
        )}
      </Paper>
    </ClickAwayListener>
  )
}
