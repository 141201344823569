import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemMaterialIsProvidedSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem?.isRawMaterialProvided) {
      return false
    }

    switch (bomItem.type) {
      case BomItemType.materialHeader:
      case BomItemType.partInstance:
      case BomItemType.partType:
        return bomItem.isRawMaterialProvided
      default:
        return false
    }
  }
