export type Currency = {
  symbol: string
  name: string
  code: string
}

export const CurrenciesOptions: Record<string, Currency> = {
  EUR: {
    symbol: '€',
    name: 'Euro',
    code: 'EUR',
  },
  GBP: {
    symbol: '£',
    name: 'British Pound',
    code: 'GBP',
  },
  CAD: {
    symbol: 'C$',
    name: 'Canadian Dollar',
    code: 'CAD',
  },
}
