import { PartInstanceRow, PartTypeRow } from 'model/Project/BoMItemRow'
import { IssuesFilter } from 'store/Project/ProjectTypes'

export function showPartBasedOnIssues(
  row: PartTypeRow | PartInstanceRow,
  filter: IssuesFilter
): boolean {
  if ((filter.enabledFilters?.length || 0) === 0) {
    return (row.issues?.length || 0) === 0
  }

  return filter.enabledFilters?.some((filter) =>
    row.issues?.map((x) => x.issueCode).includes(filter)
  )
}


