import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const headerFilteredRowsSelector =
  (
    bomItemPointer: BomItemPointer,
    typesOrInstances: 'types' | 'instances' = 'types'
  ) =>
  (state: RootAppState): BomItemPointer[] => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem) {
      return null
    }

    switch (bomItem.type) {
      case BomItemType.assemblyInstance:
      case BomItemType.assemblyType:
        if (
          bomItem.type === BomItemType.assemblyType &&
          typesOrInstances === 'types'
        ) {
          return bomItem.filteredPartTypePointers.map((x) => ({
            id: state.project.partTypes[x.id]?.id,
            type: BomItemType.partType,
          }))
        } else {
          return bomItem.filteredPartInstancePointers.map((x) => {
            if (state.project.partInstances[x?.id]) {
              return {
                id: state.project.partInstances[x.id]?.id,
                type: BomItemType.partInstance,
              }
            } else {
              return {
                id: state.project.partTypes[bomItem.id]?.id,
                type: BomItemType.partType,
              }
            }
          })
        }
      case BomItemType.routingHeader:
      case BomItemType.materialHeader:
        return bomItem.filteredPartTypePointers.map((x) => ({
          id: state.project.partTypes[x.id].id,
          type: BomItemType.partType,
        }))
      default:
        return null
    }
  }
