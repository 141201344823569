import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export function bomItemThumbnailSelector(
  state: RootAppState,
  bomItemPointer: BomItemPointer,
  get2dFilename?: boolean
): string {
  const bomItem = bomItemSelector(state, bomItemPointer)

  if (!bomItem?.type) {
    return undefined
  }

  switch (bomItem?.type) {
    case BomItemType.assemblyType:
    case BomItemType.assemblyInstance:
      return bomItem.thumbnail3d
    case BomItemType.partType:
    case BomItemType.partInstance:
      return get2dFilename
        ? bomItem.thumbnail2d || undefined
        : bomItem.thumbnail3d || undefined
  }

  return undefined
}
