import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import {
  MoneyPerQuantityDto,
  SetResourceDiscountRequest,
  SetResourceSurchargeRequest,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'

export interface IProjectResourceAPI {
  DeleteResourceDiscount: () => Promise<void>
  DeleteResourceSurcharge: () => Promise<void>
  SetResourceSurcharge: (req: SetResourceSurchargeRequest) => Promise<void>
  SetResourceDiscount: (req: SetResourceDiscountRequest) => Promise<void>
  SetResourceWorkingStepCostPrice: (
    costPrice: MoneyPerQuantityDto,
    workingStepType: WorkingStepType
  ) => Promise<void>
  DeleteResourceWorkingStepCostPrice: (
    workingStepType: WorkingStepType
  ) => Promise<void>
}

export class ProjectResourceAPI
  extends InternalAPI
  implements IProjectResourceAPI
{
  constructor(
    partyId: string,
    projectId: string,
    private resourceId: string,
    private onRequestChanged: OnRequestChangeCallback
  ) {
    super(
      `/api/parties/${partyId}/projects/${projectId}/resources/${resourceId}`
    )
  }

  public SetResourceSurcharge = async (req: SetResourceSurchargeRequest) => {
    return await this.PutAsync<void>({
      id: `setPriceSummary`,
      relativePath: `/surcharge`,
      data: req,
      onRequestChange: this.onRequestChanged,
    })
  }

  public SetResourceDiscount = async (req: SetResourceDiscountRequest) => {
    return await this.PutAsync<void>({
      id: `setPriceSummary`,
      relativePath: `/discount`,
      data: req,
      onRequestChange: this.onRequestChanged,
    })
  }

  public SetResourceCostPrice = async (costPrice: MoneyPerQuantityDto) => {
    return await this.PutAsync<void>({
      id: `${this.resourceId}_costprice`,
      relativePath: `/costPrice`,
      data: costPrice,
      onRequestChange: this.onRequestChanged,
    })
  }

  public SetResourceWorkingStepCostPrice = async (
    costPrice: MoneyPerQuantityDto,
    workingStepType: WorkingStepType
  ) => {
    return await this.PutAsync<void>({
      id: `${this.resourceId}_costprice`,
      relativePath: `/${workingStepType}/costPrice`,
      data: costPrice,
      onRequestChange: this.onRequestChanged,
    })
  }

  public DeleteResourceDiscount = async () => {
    return await this.DeleteAsync({
      id: `delete-discount-${this.resourceId}`,
      relativePath: `/discount`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public DeleteResourceSurcharge = async () => {
    return await this.DeleteAsync({
      id: `delete-surcharge-${this.resourceId}`,
      relativePath: `/surcharge`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public DeleteResourceWorkingStepCostPrice = async (
    workingStepType: WorkingStepType
  ) => {
    return await this.DeleteAsync({
      id: `delete-cost-price-${this.resourceId}-${workingStepType}`,
      relativePath: `/${workingStepType}/costPrice`,
      onRequestChange: this.onRequestChanged,
    })
  }
}
