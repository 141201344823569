import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemController } from 'controllers/Project/BomItemController'
import { WorkingStepsController } from 'controllers/WorkingStepsController'
import i18n from 'i18next'
import { uniqBy } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  IssueCode,
  RowDto,
  WorkingStepDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { UIActions } from 'store/UI/UIActions'
import { RootAppState } from 'store/configureStore'
import { newProjectActions } from '../projectReducer'
import { bomItemSelector } from '../selectors/bomItemSelector'
import { projectSelectors } from '../selectors/projectSelectors'
import { projectAPIBuilder } from './BoMAsyncActions'
import { fetchProject } from './fetchProject'

export const SolveIssueAsync = createAsyncThunk<
  void,
  { bomItemPointer: BomItemPointer; issueCode: IssueCode },
  { state: RootAppState }
>(
  'bom/solveIssue',
  async (
    {
      bomItemPointer,
      issueCode,
    }: {
      bomItemPointer: BomItemPointer
      issueCode: IssueCode
    },
    thunkAPI
  ) => {
    try {
      const controller = new BomItemController()

      const bomItem = bomItemSelector(
        thunkAPI.getState(),
        bomItemPointer
      ) as RowDto

      thunkAPI.dispatch(
        newProjectActions.setBomItemProperties({
          bomItemPointer,
          properties: {
            issues: bomItem.issues?.filter((x) => x.issueCode !== issueCode),
          },
          replacementBehaviour: 'keepLatestSourceIfArrayOrToken',
        })
      )

      return await controller.SolveIssue([bomItemPointer], issueCode)
    } catch (ex) {
      ShowException('solve issue', ex)
      throw ex
    }
  }
)

export const SolveAllIssuesByIssueCodeAsync = createAsyncThunk<
  void,
  { issueCode: IssueCode },
  { state: RootAppState }
>('bom/solveAllIssuesByIssueCode', async ({ issueCode }, thunkAPI) => {
  try {
    const currentState = thunkAPI.getState()
    const notAllowedWorkingSteps: WorkingStepDto[] = []
    const bomItemsIds = new Set<string>()

    currentState.project.partTypeIds.forEach((partTypeId) => {
      const bomItem = projectSelectors.bomItemSelector(currentState, {
        id: partTypeId,
        type: BomItemType.partType,
      }) as RowDto

      const workingStepNotAvailableIssue = bomItem.issues?.find(
        (x) => x.issueCode === IssueCode.WorkingStepNotAvailable
      )

      if (workingStepNotAvailableIssue) {
        const notAvailableWorkingStepsTypeInThisRow: WorkingStepType[] =
          JSON.parse(
            workingStepNotAvailableIssue.metadata
          ).NotAllowedWorkingSteps

        if (notAvailableWorkingStepsTypeInThisRow?.length > 0) {
          notAvailableWorkingStepsTypeInThisRow.forEach((wsType) => {
            notAllowedWorkingSteps.push(
              bomItem.activities.find((x) => x.primaryWorkingStep === wsType)
            )
          })
          bomItemsIds.add(partTypeId)
        }
      }
    })

    const modalDescription = i18n.t('project:resolve-issues-parts-count', {
      'part-count': bomItemsIds.size,
      workingSteps: uniqBy(notAllowedWorkingSteps, (x) => x.primaryWorkingStep)
        .map((workingStep) =>
          i18n.t(`common:${workingStep.primaryWorkingStep}`)
        )
        .join(' / '),
      defaultValue:
        'allow working steps {{workingSteps}} over {{part-count}} parts?',
    })

    thunkAPI.dispatch(
      UIActions.OpenModal('ConfirmationDialog', {
        title: i18n.t('project:resolve-issues-title', 'solve issues'),
        message: modalDescription,
        onConfirm: async () => {
          try {
            const controller = new BomItemController()

            return await controller.SolveIssue(
              Array.from(bomItemsIds).map((x) => ({
                id: x,
                type: BomItemType.partType,
              })),
              issueCode
            )
          } catch (err) {
            ShowException(modalDescription, err)
            thunkAPI.dispatch(
              fetchProject({
                projectId: currentState.project.activeProject?.id,
              })
            )
            throw err
          }
        },
      })
    )
  } catch (ex) {
    ShowException('solve all issues by issue code', ex)
    throw ex
  }
})

export const RemoveRowsByIssueCode = createAsyncThunk<
  void,
  { issueCode: IssueCode },
  { state: RootAppState }
>('bom/removeRowsByIssueCode', async ({ issueCode }, thunkAPI) => {
  try {
    const currentState = thunkAPI.getState()

    const bomItemsId = currentState.project.partTypeIds.filter(
      (x) =>
        (currentState.project.partTypes[x] as RowDto).issues.findIndex(
          (y) => y.issueCode === issueCode
        ) > -1
    )

    const projectAPI = projectAPIBuilder(thunkAPI.getState)

    const modalTitle = i18n.t('project:delete-parts-count', {
      count: bomItemsId.length,
    })

    thunkAPI.dispatch(
      UIActions.OpenModal('ConfirmationDialog', {
        title: modalTitle,
        onConfirm: () => {
          try {
            return projectAPI.api.DeleteRowOrAssembly(
              projectAPI.projectId,
              bomItemsId
            )
          } catch (err) {
            ShowException(modalTitle, err)
            thunkAPI.dispatch(fetchProject(projectAPI.projectId))
            throw err
          }
        },
      })
    )
  } catch (ex) {
    ShowException('remove rows by issue code', ex)
    throw ex
  }
})

export const RemoveAllWorkingStepsNotAvailableInPortal = createAsyncThunk<
  void,
  null,
  { state: RootAppState }
>('bom/removeAllWorkingStepsNotAvailableInPortal', async (_, thunkAPI) => {
  try {
    const currentState = thunkAPI.getState()
    const bomItemsIds = new Set<string>()
    const notAllowedWorkingSteps: WorkingStepDto[] = []

    currentState.project.partTypeIds.forEach((partTypeId) => {
      const bomItem = projectSelectors.bomItemSelector(currentState, {
        id: partTypeId,
        type: BomItemType.partType,
      }) as RowDto

      const workingStepNotAvailableIssue = bomItem.issues?.find(
        (x) => x.issueCode === IssueCode.WorkingStepNotAvailable
      )

      if (workingStepNotAvailableIssue) {
        const notAvailableWorkingStepsTypeInThisRow: WorkingStepType[] =
          JSON.parse(
            workingStepNotAvailableIssue.metadata
          ).NotAllowedWorkingSteps

        if (notAvailableWorkingStepsTypeInThisRow?.length > 0) {
          notAvailableWorkingStepsTypeInThisRow.forEach((wsType) => {
            notAllowedWorkingSteps.push(
              bomItem.activities.find((x) => x.primaryWorkingStep === wsType)
            )
          })
          bomItemsIds.add(partTypeId)
        }
      }
    })

    if (bomItemsIds.size > 0) {
      const modalDescription = i18n.t('project:remove-multiple-working-steps', {
        workingStep: uniqBy(notAllowedWorkingSteps, (x) => x.primaryWorkingStep)
          .map((workingStep) =>
            i18n.t(`common:${workingStep.primaryWorkingStep}`)
          )
          .join(' / '),
        count: bomItemsIds.size,
        defaultValue:
          'remove working steps ({{workingStep}}) from project? ({{count}} parts affected)',
      })

      thunkAPI.dispatch(
        UIActions.OpenModal('ConfirmationDialog', {
          message: modalDescription,
          title: i18n.t(
            'project:delete-working-steps-title',
            'delete working steps'
          ),
          onConfirm: async () => {
            try {
              const controller = new WorkingStepsController()

              return await controller.handleSaveAndRemoveWorkingSteps(
                Array.from(bomItemsIds).map((id) => ({
                  id,
                  type: BomItemType.partType,
                })),
                [],
                uniqBy(notAllowedWorkingSteps, (x) => x.primaryWorkingStep)
              )
            } catch (err) {
              ShowException(modalDescription, err)
              thunkAPI.dispatch(
                fetchProject({
                  projectId: currentState.project.activeProject?.id,
                })
              )
              throw err
            }
          },
        })
      )
    }
  } catch (ex) {
    throw ex
  }
})
