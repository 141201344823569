import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemController } from 'controllers/Project/BomItemController'
import { PartTypeRow } from 'model/Project/BoMItemRow'
import { fetchProject } from './fetchProject'
import { RootAppState } from 'store/configureStore'

export const fetchBomItem = createAsyncThunk<
  PartTypeRow,
  string,
  { state: RootAppState }
>('BoM/fetchBomItem', async (bomItemId: string, thunkAPI) => {
  const partyId = thunkAPI.getState().user.organizationContext.id
  const projectId = thunkAPI.getState().project.activeProject?.id

  if (projectId === bomItemId) {
    thunkAPI.dispatch(fetchProject({ projectId: projectId }))
    return null
  } else {
    const bomItemController = new BomItemController(partyId, projectId)

    return (await bomItemController.GetBomItem(bomItemId)) as PartTypeRow
  }
})
