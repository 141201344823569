import { RootAppState } from 'store/configureStore'

export const projectEstimatedDeliveryDateSelector = (state: RootAppState) => {
  if (state.project.activeProject?.estimatedDeliveryDate) {
    const dateWithTimezone = new Date(
      state.project.activeProject.estimatedDeliveryDate
    )

    return new Date(
      dateWithTimezone.getUTCFullYear(),
      dateWithTimezone.getUTCMonth(),
      dateWithTimezone.getUTCDate()
    )
  }

  return null
}
