import { BaseAPI, OnRequestChangeCallback } from '../BaseAPI'

export const baseAddress: string =
  import.meta.env.VITE_API_BASE_URL || 'https://localhost:44367'

export type HttpGetMetaData<T> = {
  page?: number
  pageSize?: number
  search?: string
  orderBy?: keyof T
  totalCount?: number
  orderDirection?: 'desc' | 'asc'
}
export abstract class InternalAPI extends BaseAPI {
  /**
   *
   * @param relativePath - relative path to the API /api/...
   */
  constructor(
    relativePath?: string,
    onRequestChanged?: OnRequestChangeCallback
  ) {
    super(baseAddress, onRequestChanged)
    this.relativePath = relativePath ?? '/'
  }
}
