/* eslint-disable @typescript-eslint/no-unused-vars */
import cadex from '@cadexchanger/web-toolkit'
import { SceneDataStore } from '../store/SceneDataStore'

export class ModelDataExtractorVisitor extends cadex.ModelData_SceneGraphElementVisitor {
  private readonly store: SceneDataStore
  private currentParentId: string
  private grandParentId: string

  constructor(store: SceneDataStore) {
    super()
    this.store = store
  }

  visitAssemblyEnter(
    assembly: cadex.ModelData_Assembly
  ): boolean | Promise<boolean> {
    const assemblyDataNode = this.store.getSceneNodeItem(
      assembly.uuid.toString()
    )

    if (!assemblyDataNode) {
      console.error('assemblyData not found', assembly.uuid.toString())
      return true
    }

    assemblyDataNode.forEach((node) => {
      node.parentId = this.currentParentId
      node.nodeType = this.currentParentId ? 'SUBASSEMBLY' : 'ASSEMBLY'
    })

    this.grandParentId = this.currentParentId
    this.currentParentId = assembly.uuid.toString()
    return true
  }

  visitAssemblyLeave(assembly: cadex.ModelData_Assembly): void | Promise<void> {
    this.currentParentId = this.grandParentId
    this.grandParentId = undefined
    return
  }

  visitInstanceEnter(
    instance: cadex.ModelData_Instance
  ): boolean | Promise<boolean> {
    const instanceDataNode = this.store.getSceneNodeItem(
      instance.uuid.toString()
    )

    if (!instanceDataNode) {
      console.error('instanceData not found', instance.uuid.toString())
      return true
    }

    instanceDataNode.forEach((node) => {
      node.parentId = instance.reference.uuid.toString()
      node.name = instance.reference.name
      node.nodeType = 'INSTANCE'
    })

    return true
  }

  visitInstanceLeave(instance: cadex.ModelData_Instance): void | Promise<void> {
    return
  }

  visitPart(thePart: cadex.ModelData_Part): void | Promise<void> {
    const partDataNode = this.store.getSceneNodeItem(thePart.uuid.toString())

    if (!partDataNode) {
      console.error('partData not found', thePart.uuid.toString())
      return
    }

    partDataNode.forEach((node) => {
      node.parentId = this.currentParentId
      node.nodeType = 'PART'
    })

    return
  }
}
