/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import cadex, {
  ModelData_Assembly,
  ModelData_Instance,
  ModelData_Part,
} from '@cadexchanger/web-toolkit'
import { PMIViewData } from '../ModelPMIDataManager'

export class ModelPMIVisitor extends cadex.ModelData_SceneGraphElementVisitor {
  private _pmiViewData: Map<string, PMIViewData[]> = new Map()

  public get PmiViewData() {
    return this._pmiViewData
  }

  public get PMIDataAvailable() {
    return this._pmiViewData.size > 0
  }

  visitAssemblyEnter(assembly: ModelData_Assembly): boolean | Promise<boolean> {
    if (assembly.pmi) {
      const pmiViewData: Partial<PMIViewData> = assembly.pmi

      pmiViewData.parentId = assembly.uuid.toString()
      pmiViewData.name = assembly.name

      this.addToViewData(pmiViewData as PMIViewData)
    }

    return true
  }
  visitAssemblyLeave(assembly: ModelData_Assembly): void | Promise<void> {}
  visitInstanceEnter(instance: ModelData_Instance): boolean | Promise<boolean> {
    return true
  }
  visitInstanceLeave(instance: ModelData_Instance): void | Promise<void> {}
  visitPart(part: ModelData_Part): void | Promise<void> {
    // eslint-disable-next-line no-console
    if (part.pmi) {
      const pmiViewData: Partial<PMIViewData> = part.pmi

      pmiViewData.parentId = part.uuid.toString()
      pmiViewData.name = part.name

      this.addToViewData(pmiViewData as PMIViewData)
    }
  }

  private addToViewData(viewData: PMIViewData) {
    if (this._pmiViewData.has(viewData.parentId)) {
      this._pmiViewData.get(viewData.parentId).push(viewData)
    } else {
      this._pmiViewData.set(viewData.parentId, [viewData])
    }
  }
}
