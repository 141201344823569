import cadex from '@cadexchanger/web-toolkit'
import { Rh24SceneNode } from './types/Rh24SceneNode'

export class SceneDataStore {
  private sceneNodeMap: Map<string, Array<Rh24SceneNode>>
  private sceneNodeFactory: cadex.ModelPrs_SceneNodeFactory

  constructor() {
    this.sceneNodeMap = new Map<string, Array<Rh24SceneNode>>()
    this.sceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory()
  }

  public addToSceneMap(element: Rh24SceneNode) {
    if (this.sceneNodeMap.has(element.elementId)) {
      this.sceneNodeMap.get(element.elementId).push(element)
    } else {
      this.sceneNodeMap.set(element.elementId, [element])
    }
  }

  public getSceneNodeItem(id: string) {
    return this.sceneNodeMap.get(id)
  }

  public getAllSceneNodes() {
    return this.sceneNodeMap.values()
  }

  public createFromRepresentation(
    brepRep: cadex.ModelData_BRepRepresentation,
    shape: cadex.ModelData_Shape
  ) {
    if (shape.type === cadex.ModelData_ShapeType.Face) {
      const shapeId = brepRep.shapeId(shape)
      const shapeName = brepRep.shapeName(shape)

      const node: Rh24SceneNode =
        this.sceneNodeFactory.createNodeFromRepresentation(brepRep)

      node.elementId = shapeId.toString()
      node.name = shapeName
      node.nodeType = 'FACE'
      node.shape = shape
      node.parentId = brepRep.uuid.toString()

      node['parentRep'] = brepRep

      return node
    } else {
      throw new Error(`Shape type ${shape.type} is not supported`)
    }
  }

  public createFromSceneGraphElement(
    element: cadex.ModelData_SceneGraphElement
  ) {
    const sceneNodeToAdd =
      this.sceneNodeFactory.createNodeFromSceneGraphElement(
        element
      ) as Rh24SceneNode

    if (element.uuid) {
      sceneNodeToAdd.elementId = element.uuid.toString()
    } else {
      console.warn('cadex.ModelData_SceneGraphElement without uuid', element)
    }

    if (element instanceof cadex.ModelData_Assembly) {
      sceneNodeToAdd.nodeType = 'ASSEMBLY'
      sceneNodeToAdd.name = element.name
    }

    if (element instanceof cadex.ModelData_Instance) {
      sceneNodeToAdd.nodeType = 'INSTANCE'
      sceneNodeToAdd.name = element.reference.name
    }

    if (element instanceof cadex.ModelData_Part) {
      sceneNodeToAdd.nodeType = 'PART'
      sceneNodeToAdd.name = element.name
    }

    sceneNodeToAdd.sceneGraphElement = element

    return sceneNodeToAdd
  }
}
