import {
  AppActionTypes,
  ApplicationActionTypes,
  ApplicationState,
} from './appState'

import { Reducer } from 'redux'

const initialState: ApplicationState = {
  language: 'en',
  showNewItemsModal: false,
  isNotificationOpen: false,
  isLoading: false,
  currentNetworkAction: '',
  loadingMessage: '',
  signalR: {},
  userFeedback: {
    show: false,
    issue: undefined,
  },
  newProjectForm: {
    show: false,
    isSellerRole: true,
  },
  theme: undefined,
  builtInThemeName: undefined,
}

export const appReducer: Reducer<ApplicationState> = (
  state: ApplicationState = initialState,
  action: AppActionTypes
) => {
  switch (action.type) {
    case ApplicationActionTypes.StartNetwork: {
      return {
        ...state,
        isLoading: true,
        currentNetworkAction: action.payload,
      }
    }
    case ApplicationActionTypes.EndNetwork: {
      return {
        ...state,
        isLoading: false,
        currentNetworkAction: undefined,
      }
    }
    case ApplicationActionTypes.ToggleNewItemsModal: {
      let open = false

      if (action.open === undefined) open = !state.showNewItemsModal
      else open = action.open

      return Object.assign({}, state, {
        showNewItemsModal: open,
      })
    }
    case ApplicationActionTypes.ToggleNotifications: {
      let isNotificationOpen: boolean

      if (action.shouldOpen) {
        isNotificationOpen = true
      } else {
        isNotificationOpen = !state.isNotificationOpen
      }

      return Object.assign({}, state, {
        isNotificationOpen: isNotificationOpen,
      })
    }
    case ApplicationActionTypes.NotificationReceived: {
      return Object.assign({}, state, {
        notifications: action.notifications,
      })
    }
    case ApplicationActionTypes.SetCurrentTabIndex: {
      return {
        ...state,
        currentTabIndex: action.tabIndex,
      }
    }
    case ApplicationActionTypes.SetLoadingMessage: {
      return {
        ...state,
        loadingMessage: action.message,
      }
    }
    case ApplicationActionTypes.SignalRSubscriptionInfo: {
      let newSignalRInfo = state.signalR

      newSignalRInfo = action.payload

      return {
        ...state,
        signalR: newSignalRInfo,
      }
    }
    case ApplicationActionTypes.ShowUserFeedbackForm: {
      return {
        ...state,
        userFeedback: {
          ...state.userFeedback,
          show: action.show,
          issue: {
            ...state.userFeedback.issue,
            ...(action.issue || {}),
            context: {
              ...state.userFeedback.issue?.context,
              ...(action.issue?.context || {}),
            },
          },
        },
      }
    }
    case ApplicationActionTypes.CloseUserFeedbackForm: {
      return {
        ...state,
        userFeedback: {
          ...state.userFeedback,
          show: false,
          issue: undefined,
        },
      }
    }

    case ApplicationActionTypes.ShowNewProjectForm: {
      return {
        ...state,
        newProjectForm: {
          show: action.show,
          isSellerRole: action.isSellerRole,
        },
      }
    }
    case ApplicationActionTypes.SetAppTheme: {
      return {
        ...state,
        theme: {
          // ...state.theme,
          ...action.theme,
        },
      }
    }
    case ApplicationActionTypes.SetBuiltInTheme: {
      return {
        ...state,
        builtInThemeName: action.themeName,
      }
    }
    default:
      return state
  }
}
