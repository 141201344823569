import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'

export type FactoryProfile = {
  key: string
  title: string
  summary: string
  workingSteps: WorkingStepType[]
}

export const GettingStartedWorkingStepsAvailable: WorkingStepType[] = [
  WorkingStepType.SheetCutting,
  WorkingStepType.Sawing,
  WorkingStepType.SheetBending,
  WorkingStepType.CounterSinking,
  WorkingStepType.Milling,
  WorkingStepType.TubeOrProfileCutting,
  WorkingStepType.Turning,
  WorkingStepType.Purchasing,
  WorkingStepType.Engraving,
  WorkingStepType.ThreadTapping,
  WorkingStepType.Anodising,
  WorkingStepType.Assembling,
  WorkingStepType.BoltPressing,
  WorkingStepType.Deburring,
  WorkingStepType.Drilling,
  WorkingStepType.EdgeMilling,
  WorkingStepType.EdgeTrimming,
  WorkingStepType.LevelTensioning,
  WorkingStepType.NutPressing,
  WorkingStepType.PicklingPassivating,
  WorkingStepType.Polishing,
  WorkingStepType.PowderCoating,
  WorkingStepType.Punching,
  WorkingStepType.Rolling,
  WorkingStepType.StudWelding,
  WorkingStepType.TubeProfileOrBarBending,
  WorkingStepType.Welding,
  WorkingStepType.ZincPlating,
  WorkingStepType.Designing,
  WorkingStepType.Certifying,
  WorkingStepType.Shipping,
  WorkingStepType.Programming,
  WorkingStepType.OrderPicking,
  WorkingStepType.Packaging,
  WorkingStepType.BeadBlasting,
  WorkingStepType.SandBlasting,
  WorkingStepType.SteelGridBlasting,
  WorkingStepType.WetBlasting,
  WorkingStepType.VacuumBlasting,
  WorkingStepType.CentrifugalBlasting,
  WorkingStepType.SodaBlasting,
  WorkingStepType.BristleBlasting,
  WorkingStepType.DryIceBlasting,
  WorkingStepType.PencilBlasting,
  WorkingStepType.WeldGrinding,
  WorkingStepType.RobotWelding,
  WorkingStepType.RobotStudWelding,
  WorkingStepType.Nitriding,
  WorkingStepType.DieSinking,
  WorkingStepType.WireErosion,
  WorkingStepType.Hemming,
  WorkingStepType.VibratoryDeburring,
  WorkingStepType.Foiling,
  WorkingStepType.Cleaning,
  WorkingStepType.QualityControlling,
  WorkingStepType.ElectrolyticPolishing,
  WorkingStepType.CathodicDipCoating,
  WorkingStepType.ElectrophoreticDipCoating,
  WorkingStepType.FlowDrilling,
  WorkingStepType.HoleProtectioning,
  WorkingStepType.Operating,
  WorkingStepType.Researching,
  WorkingStepType.Sawing3d,
  WorkingStepType.NicklePlating,
]

export const FactoryProfiles: Record<string, FactoryProfile> = {
  SheetMetal: {
    key: 'SheetMetal',
    title: 'Sheet Metal',
    summary:
      'Support for 3D and 2D CAD models. Includes feature & PMI recognition, unfolding, true shape nesting, remnant stragies, auto sheet size selection. Automate with business rules and ability to integrate your ERP and CAM systems.',
    workingSteps: [
      WorkingStepType.SheetCutting,
      WorkingStepType.SheetBending,
      WorkingStepType.Engraving,
      WorkingStepType.ThreadTapping,
      WorkingStepType.Punching,
      WorkingStepType.Rolling,
      WorkingStepType.Drilling,
      WorkingStepType.FlowDrilling,
      WorkingStepType.CounterSinking,
      WorkingStepType.Deburring,
      WorkingStepType.BoltPressing,
      WorkingStepType.NutPressing,
      WorkingStepType.Hemming,
    ],
  },
  TubesProfilesBarsBeamsChannels: {
    key: 'TubesProfilesBarsBeamsChannels',
    title: 'Tubes, Profiles, Bars, Beams, Channels',
    summary:
      'Includes feature & PMI recognition, nesting, remnant stragies, shape and profile recognition. Automate with business rules and ability to integrate your ERP and CAM systems.',
    workingSteps: [
      WorkingStepType.TubeOrProfileCutting,
      WorkingStepType.TubeProfileOrBarBending,
      WorkingStepType.Engraving,
      WorkingStepType.ThreadTapping,
      WorkingStepType.Drilling,
      WorkingStepType.FlowDrilling,
      WorkingStepType.CounterSinking,
      WorkingStepType.Deburring,
      WorkingStepType.Sawing,
    ],
  },
  CNCMachining: {
    key: 'CNCMachining',
    title: 'CNC Machining',
    summary:
      'Includes feature & PMI recognition, bar based nesting, auto determine stock material size, machine learning based setup & cycle time estimation. Automate with business rules and ability to integrate your ERP and CAM systems.',
    workingSteps: [
      WorkingStepType.Milling,
      WorkingStepType.Turning,
      WorkingStepType.Sawing,
      WorkingStepType.ThreadTapping,
      WorkingStepType.Engraving,
      WorkingStepType.DieSinking,
      WorkingStepType.WireErosion,
    ],
  },
  AssemblingInclWelding: {
    key: 'AssemblingInclWelding',
    title: 'Assembling (Incl. Welding)',
    summary:
      'Includes welding seam detection, feature & PMI recognition, recognition of all common shapes like sheet, tubes, bars, beams. Automate with business rules and ability to integrate your ERP and CAM systems.',
    workingSteps: [
      WorkingStepType.Welding,
      WorkingStepType.RobotWelding,
      WorkingStepType.RobotStudWelding,
      WorkingStepType.StudWelding,
      WorkingStepType.Assembling,
      WorkingStepType.LevelTensioning,
    ],
  },
  SurfaceTreatments: {
    key: 'SurfaceTreatments',
    title: 'Surface Treatments',
    summary:
      'Includes feature recognition at (sub)assembly level such as 3D bounding box, mass/weight and various area estimations. Automate with business rules and ability to integrate your ERP and CAM systems.',
    workingSteps: [
      WorkingStepType.Anodising,
      WorkingStepType.PicklingPassivating,
      WorkingStepType.Polishing,
      WorkingStepType.PowderCoating,
      WorkingStepType.ZincPlating,
      WorkingStepType.ElectrolyticPolishing,
      WorkingStepType.CathodicDipCoating,
      WorkingStepType.ElectrophoreticDipCoating,
      WorkingStepType.BeadBlasting,
    ],
  },
}
