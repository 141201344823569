import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  RoutingStep,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectState } from 'store/Project/ProjectTypes'
import { bomItemSelector } from '../selectors/bomItemSelector'
import { bomItemActivitiesSelector } from '../selectors/bomItemWorkingStepsSelector'

export const setWorkingStepProductionOrder: CaseReducer<
  ProjectState,
  PayloadAction<{
    bomItemPointer: BomItemPointer
    workingStepTypeToMove: WorkingStepType
    moveAfter?: WorkingStepType
  }>
> = (state, action): ProjectState => {
  const workingSteps = bomItemActivitiesSelector({
    bomItemPointer: action.payload.bomItemPointer,
  })({ project: state })

  const workingStepToMove = workingSteps.find(
    (ws) => ws.primaryWorkingStep === action.payload.workingStepTypeToMove
  )

  let newWorkingStepsList = workingSteps.filter(
    (ws) => ws.primaryWorkingStep !== action.payload.workingStepTypeToMove
  )

  if (!workingSteps) return state

  if (!action.payload.moveAfter) {
    newWorkingStepsList = [workingStepToMove, ...newWorkingStepsList]
  }

  if (action.payload.moveAfter) {
    const index = newWorkingStepsList.findIndex(
      (ws) => ws.primaryWorkingStep === action.payload.moveAfter
    )
    newWorkingStepsList.splice(index + 1, 0, workingStepToMove)
  }

  const bomItem = bomItemSelector(
    { project: state },
    action.payload.bomItemPointer
  )

  if (bomItem) {
    switch (bomItem.type) {
      case BomItemType.project:
      case BomItemType.partInstance: {
        bomItem.activities = newWorkingStepsList
        break
      }
      case BomItemType.partType: {
        bomItem.activities = newWorkingStepsList
        if (bomItem.routingHeaderPointer) {
          const routingHeaderWS =
            state.routingHeaders[bomItem.routingHeaderPointer.id].routingSteps

          let newRoutingSteps: Array<RoutingStep> = []

          if (routingHeaderWS.length > newWorkingStepsList.length) {
            // this happens when we have a shared working step that is show in router header but not in part type (e.g. shipping)
            newRoutingSteps = routingHeaderWS.reduce((prev, curr) => {
              if (
                newRoutingSteps.findIndex(
                  (x) => x.workingStepType === curr.workingStepType
                ) === -1
              ) {
                prev.push(curr)
              } else {
                prev.push(
                  newWorkingStepsList.find(
                    (x) => x.primaryWorkingStep === curr.workingStepType
                  )
                )
              }

              return prev
            }, [])
          } else {
            newRoutingSteps = newWorkingStepsList.map((x) => ({
              resourceId: x.resource?.id,
              resourceName: x.resource?.name,
              workingStepType: x.primaryWorkingStep,
            }))
          }

          state.routingHeaders[bomItem.routingHeaderPointer.id].routingSteps =
            newRoutingSteps
        }
        break
      }
      case BomItemType.assemblyType:
      case BomItemType.assemblyInstance: {
        bomItem.assemblyActivities = newWorkingStepsList
        break
      }
    }
  }

  return state
}
