import { User } from '@auth0/auth0-react'
import { AvailableWorkspace } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { GettingStartedRouteKeys } from '../GettingStartedRoutes'
import { FactoryForm, UserForm } from './GettingStartedTypes'
import { FactoryProfiles } from './WorkingStepsProfiles'

function stepIsDoneSelector(
  stepKey: GettingStartedRouteKeys
): (state: RootAppState) => boolean {
  return (state: RootAppState) => {
    switch (stepKey) {
      case 'welcome': {
        return Boolean(state.gettingStarted.auth0User)
      }
      case 'validateEmail': {
        return state.gettingStarted.auth0User?.email_verified
      }
      case 'factorySetup': {
        return (
          stepIsDoneSelector('validateEmail')(state) &&
          Boolean(
            state.gettingStarted.factoryForm?.factoryName &&
              state.gettingStarted.factoryForm?.team
          )
        )
      }
      case 'workingSteps': {
        return (
          stepIsDoneSelector('factorySetup')(state) &&
          Boolean(
            state.gettingStarted.factoryForm?.availableWorkingSteps?.length
          )
        )
      }
      case 'confirm': {
        return (
          stepIsDoneSelector('workingSteps')(state) &&
          Boolean(state.user.organizationContext?.id)
        )
      }

      case 'joinWorkspace': {
        return Boolean(state.user.organizationContext?.id)
      }
      default:
        return false
    }
  }
}

function stepIsAvailableSelector(
  stepKey: GettingStartedRouteKeys
): (state: RootAppState) => boolean {
  return (state) => {
    switch (stepKey) {
      case 'welcome': {
        return !state.gettingStarted.auth0User
      }
      case 'validateEmail': {
        return state.gettingStarted.auth0User?.email_verified === false
      }
      case 'factorySetup': {
        return (
          stepIsDoneSelector('validateEmail')(state) &&
          !state.user.organizationContext
        )
      }
      case 'workingSteps': {
        return (
          stepIsDoneSelector('factorySetup')(state) &&
          !state.user.organizationContext
        )
      }
      case 'confirm': {
        return (
          stepIsDoneSelector('workingSteps')(state) &&
          !state.user.organizationContext
        )
      }
      case 'joinWorkspace': {
        return (
          stepIsDoneSelector('validateEmail')(state) &&
          state.user.organizations?.length > 0
        )
      }
      case 'nextStep': {
        return Boolean(state.user.organizationContext)
      }
    }
  }
}

function getNextRouteSelector(): (
  state: RootAppState
) => GettingStartedRouteKeys {
  return (state: RootAppState) => {
    if (state.user.organizationContext) {
      return 'nextStep'
    }

    if (!stepIsDoneSelector('welcome')(state)) {
      return 'welcome'
    }

    if (!stepIsDoneSelector('validateEmail')(state)) {
      return 'validateEmail'
    }

    if (state.gettingStarted.availableWorkspaces?.availableWorkspaces.length) {
      return 'joinWorkspace'
    }

    if (!stepIsDoneSelector('factorySetup')(state)) {
      return 'factorySetup'
    }

    if (!stepIsDoneSelector('workingSteps')(state)) {
      return 'workingSteps'
    }

    if (!stepIsDoneSelector('confirm')(state)) {
      return 'confirm'
    }

    return 'nextStep'
  }
}

function getAuth0UserSelector(state: RootAppState): User {
  return state.gettingStarted.auth0User
}

function getFactoryFormSelector(state: RootAppState): FactoryForm {
  return state.gettingStarted.factoryForm
}

function getUserFormSelector(state: RootAppState): UserForm {
  return state.gettingStarted.userForm
}

function getAvailableWorkspaces(state: RootAppState): AvailableWorkspace[] {
  return state.gettingStarted.availableWorkspaces?.availableWorkspaces || []
}

function getCanEnableAutoJoin(state: RootAppState): boolean {
  return state.gettingStarted.availableWorkspaces?.canEnableAutoJoin
}

function getAllowAutoJoin(state: RootAppState): boolean {
  return state.gettingStarted.factoryForm?.enableAutoDomainJoin
}

function getIsLoading(state: RootAppState): boolean {
  return state.gettingStarted.loading
}

function getChosenProfiles(
  state: RootAppState
): Array<keyof typeof FactoryProfiles> {
  return state.gettingStarted.factoryForm?.chosenTemplates || []
}

export const gettingStartedSelectors = {
  stepIsDoneSelector,
  stepIsAvailableSelector,
  getNextRouteSelector,
  getAuth0UserSelector,
  getFactoryFormSelector,
  getUserFormSelector,
  getAvailableWorkspaces,
  getAllowAutoJoin,
  getCanEnableAutoJoin,
  getIsLoading,
  getChosenProfiles,
}
