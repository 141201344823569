import { FeatureDto } from 'services/APIs/InternalAPI/internal-api.contracts'

export function FeatureDetailsByFaceId(features: FeatureDto[], faceId: number) {
  return features
    .filter((f) =>
      f.sections
        .flatMap((x) => x.details.map((y) => y.faceIds?.length))
        .reduce((p, c) => c > 0, false)
    )
    .flatMap((x) => x.sections.map((s) => s.details))
    .map((details) => {
      const i = details.findIndex((x) => x.faceIds.includes(faceId))
      return details[i]
    })?.[0]
}

export function FeatureDetailsWithoutFaceId(
  features: FeatureDto[],
  faceId: number
) {
  return features
    .filter((f) =>
      f.sections
        .flatMap((x) => x.details.map((y) => y.faceIds?.length))
        .reduce((p, c) => c > 0, false)
    )
    .flatMap((x) => x.sections.map((s) => s.details))
    .map((details) => {
      const i = details.findIndex((x) => !x.faceIds.includes(faceId))
      return details[i]
    })?.[0]
}

export function SideIdByFaceId(features: FeatureDto[], faceId: number) {
  return parseInt(
    FeatureDetailsByFaceId(features, faceId)?.label.replace('side ', '')
  )
}
